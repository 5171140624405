import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'

import reducer from '../reducers'
import { State } from '../models'
import { requests } from '../middleware'
import composeWithDevTools from '@/shared/utils/composeWithDevTools.js'

export default function configureStore(props, history) {
  const initialState = State.initialState(props)

  return createStore(
    reducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        requests,
        routerMiddleware(history)
      )
    )
  )
}
