import { LOCATION_CHANGE, push } from 'react-router-redux'
import axios from 'axios'
import {
  GET_NEXT_PAGE,
  ADD_RESULT_COLUMN,
  REMOVE_RESULT_COLUMN,
  UPDATE_SORT_COLUMN,
  REPLACE_RESULT_COLUMNS,
  SAVE_RESULT_COLUMNS,
  SUBMIT_QUERY,
  UPDATE_DATA_SOURCES,
  updateSortColumn,
  saveResultColumns,
  cacheResultColumns,
  initialized,
} from '../actions'
import getEntries from '../services'
import constants from 'javascripts/constants.js'

const { ELN_DEFAULT_SORT_COLUMN, ELN_INDEX_PAGE_LIMIT } = constants

const manageRequests = ({ dispatch, getState }) => next => action => {
  const result = next(action)

  switch (action.type) {
    case SUBMIT_QUERY: {
      const { query, constants } = getState()
      dispatch(push({
        pathname: constants.elnEntryUrl,
        query: Object.assign(query.toParams()),
      }))

      break
    }
    case GET_NEXT_PAGE: {
      const { elnEntries, page } = getState()

      // DoS less
      // If the counts get out of sync for some reason, ie, you delete an entry
      // from the db, but the elasticsearch hasn't been updated, you could end
      // up with a larger count that actual entries. The infinite scroller can
      // trigger a chain of ever increasing page requests.
      if (page * ELN_INDEX_PAGE_LIMIT <= elnEntries.all.size) {
        getEntries({ dispatch, getState })
      }

      break
    }
    case ADD_RESULT_COLUMN:
    case REMOVE_RESULT_COLUMN:
    case REPLACE_RESULT_COLUMNS: {
      const { resultColumns } = getState()
      const sortCol = resultColumns.find((column) => (column.direction))
      const defaultSortCol = resultColumns.find((column) => (column.selectValue == ELN_DEFAULT_SORT_COLUMN.get('field_name')))
      if (!sortCol && defaultSortCol) {
        dispatch(updateSortColumn({ selectValue: ELN_DEFAULT_SORT_COLUMN.get('field_name'), direction: ELN_DEFAULT_SORT_COLUMN.get('direction') }))
      } else {
        dispatch(saveResultColumns())
      }
      break
    }
    case UPDATE_SORT_COLUMN: {
      const { resultColumns, constants } = getState()
      const data = {
        result_columns: resultColumns.filter((column) => (!!column.selectValue)).toJS(),
      }

      axios.post(constants.elnResultColumnUrl, data, null).then(response => {
        dispatch(cacheResultColumns({ resultColumns: response.data }))
        getEntries({ dispatch, getState })
      })
      break
    }
    case SAVE_RESULT_COLUMNS: {
      const { resultColumns, constants } = getState()
      const data = {
        result_columns: resultColumns.filter((column) => (!!column.selectValue)).toJS(),
      }

      axios.post(constants.elnResultColumnUrl, data, null).then(response => {
        dispatch(cacheResultColumns({ resultColumns: response.data }))
      })
      break
    }
    case UPDATE_DATA_SOURCES:
      // We return early so CDD.DataSourceSelection can handle the promise
      getEntries({ dispatch, getState })
      return Promise.resolve()
    case LOCATION_CHANGE: {
      const { status } = getState()

      // react-router sends an initial LOCATION_CHANGE to initialize the
      // store. But, we initialize everything already, so skip it.
      if (status.initialized) {
        getEntries({ dispatch, getState })
      } else {
        dispatch(initialized())
      }

      break
    }
  }

  return result
}

export default manageRequests
