import { TSearchQuery } from '@/Samples/types';
import { Chip } from '@mui/material';
import React from 'react';

// TODO dedupe with eln entries stuff where possible
export type FilterSummaryProps = {
  queryFilters: (TSearchQuery['query_filters']);
  handleFilterPanelToggle: () => void;
};

export class FilterSummary extends React.Component<FilterSummaryProps> {
  renderFilterSummary = () => {
    const { queryFilters } = this.props;
    const appliedFilters = queryFilters.filter((filter) => filter !== null);
    return (
      <>
        {appliedFilters.length > 0
          ? (
          <p className='search-bar__summary__text'>
            Filters currently applied:{' '}
            {appliedFilters.map((filter, index) => {
              return (
                <Chip
                  key={index}
                  label={filter.field_name}
                  className='search-bar__filter__chip'
                />
              );
            })}
          </p>
            )
          : null}
      </>
    );
  };

  render() {
    const { handleFilterPanelToggle } = this.props;

    return (
      <div
        className='search-bar__filter__summary'
        onClick={handleFilterPanelToggle}
      >
        {<this.renderFilterSummary />}
      </div>
    );
  }
}
