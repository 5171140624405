/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ColumnForDisplay, ID } from '@/Protocols/types';
import { ColumnsEditor } from '@/components/ColumnsEditor/ColumnsEditor';

type Props<TColID extends ID = ID> = {
  options: ColumnForDisplay<TColID>[];
  value: Array<TColID>;
  onChangeValue: (value: Array<TColID>) => void;
};

export class ProtocolsColumnsEditor extends React.Component<Props> {
  render() {
    const { value, options, onChangeValue } = this.props;
    const availableColumns = options.map((option) => ({
      ...option,
      children: option.protocol_field_definitions,
    }));

    return (
      <ColumnsEditor
        availableColumns={availableColumns}
        value={value}
        onChangeValue={onChangeValue}
        headerClassName={'search_results__columns__project'}
        itemClassName={'search_results__columns__field-name'}
      />
    );
  }
}
