import React, { useState } from 'react';
import { Link } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';
import { BioisosterResultsDialog } from './BioisosterResultsDialog';
import { DLBioisosterResponse, DLError } from './BioisosterResultsTypes';
import { processBioisostereResults } from './BioisosterResultsUtilities';
import magnifierIcon from 'ASSETS/images/cdd30/icons/magnifier.png';

const fetchDLBioisoster = async (
  smiles: string,
  count: number,
): Promise<DLBioisosterResponse | DLError> => {
  // remove CXSMILES information if present
  const structure = smiles.trim().split(/(\s+)/)[0]; // eslint-disable-line @typescript-eslint/no-unused-vars
  try {
    const response = await fetch('/models/bioisosters', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ structure, number_suggestions: count, return_known_identifiers: true }),
    });
    const json = await response.json();
    return processBioisostereResults(json);
  } catch (e) {
    return { error: e };
  }
};

type LaunchBioisosterResultsDialogProps = {
  smiles: string;
  ban_external_links: boolean;
  skip_external_link_warning: boolean;
  fetchDLBioisoster?: (
    smiles: string,
    count: number,
  ) => Promise<DLBioisosterResponse | DLError>;
  title?: string;
  showIcon?: boolean;
  linkClassName?: string
};

const LaunchBioisosterResultsDialog = (
  props: LaunchBioisosterResultsDialogProps,
) => {
  const { showIcon = true, title, linkClassName } = props;
  const [open, setOpen] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    setOpen(true);
    e.preventDefault();
  };

  const onClose = () => setOpen(false);
  return (
    <>
      <Link className={linkClassName} href={'#'} onClick={onClick} style={{ textDecoration: 'none' }}>
        {showIcon && <Img
          width={16}
          height={16}
          className='icon-16'
          alt='explore'
          src={magnifierIcon}
        />}
        {title || 'Suggest bioisosteres using deep learning similarity'}
      </Link>
      <BioisosterResultsDialog
        open={open}
        fetchDLBioisoster={props.fetchDLBioisoster || fetchDLBioisoster}
        smiles={props.smiles}
        banExternalLinks={props.ban_external_links}
        skipExternalLinkWarning={props.skip_external_link_warning}
        onClose={onClose}
      />
    </>
  );
};

export { LaunchBioisosterResultsDialog };
