import React from 'react'
import { createRoot } from 'react-dom/client'
import ApiKeyCopyButton from './component.jsx'

// Search help: copytoclipboard copy_to_clipboard copy to clipboard

export default function ApiKeyCopyButtonRenderer(props) {
  const container = document.getElementById('apiKeyCopyButton')
  const root = createRoot(container)
  return root.render(
    <ApiKeyCopyButton {...props} />
  )
}
