import React, { ChangeEvent, KeyboardEvent } from 'react';

import ItemActions from './ItemActions';
import { FieldPickValue } from '@/FieldDefinitions/types';
import { StringOrNumber } from '@/types';

type Props = {
  pickListValue: FieldPickValue;
  showSingleUseOption?: boolean;
  unmodifiableSingleUseItems: Set<StringOrNumber>;
  update: (id: StringOrNumber, value: Partial<FieldPickValue>) => void;
  onSelect?: () => void;
  remove: (id: StringOrNumber) => void;
  invalid: boolean;
}

type State = {
  value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default class Item extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { value: props.pickListValue.value };
  }

  updateValue = (value: string) => {
    const id = this.props.pickListValue.id;

    this.setState({ value }, () => {
      this.props.update(id, { value });
    });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.updateValue(value);
  };

  handleBlur = () => {
    const { value } = this.state;
    if (value !== value.trim()) {
      this.updateValue(value.trim());
    }
  };

  handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.code) {
      case 'Enter': {
        event.nativeEvent.stopImmediatePropagation();
        this.updateValue(this.state.value.trim());
        const { onSelect } = this.props;
        if (onSelect) {
          onSelect();
        }
        break;
      }
    }
  };

  render() {
    const { pickListValue, update, remove, showSingleUseOption, unmodifiableSingleUseItems, invalid } = this.props;

    return (
      <div id={'' + pickListValue.id} className="pickListItem">
        <input
          className={`pickListItemEditor ${invalid ? 'errors' : ''}`}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
        />
        <ItemActions
          pickListValue={pickListValue}
          showSingleUseOption={showSingleUseOption}
          unmodifiableSingleUseItems={unmodifiableSingleUseItems}
          update={update}
          remove={remove}
        />
      </div>
    );
  }
}
