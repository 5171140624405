import React from 'react'

import KeywordCriterion from './KeywordCriterion/index.js'
import { AddButton } from '@/shared/components'
import { MoleculeCriterion } from './models.js'
import withStore from './store.jsx'
import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx'

class MoleculeSearchBuilder extends React.PureComponent {
  renderCriterionAddButton = () => {
    const { onClickAddCriterion } = this.props

    return (
      <AddButton
        onClick={onClickAddCriterion}
        text={'Add a term'}
      />
    )
  }

  renderKeywordCriteria = () => {
    const { fields, molecule_criteria, ...rest } = this.props

    return molecule_criteria.map((molecule_criterion, criterionIndex) => {
      return (
        <KeywordCriterion
          key={criterionIndex}
          criterionIndex={criterionIndex}
          molecule_criterion={molecule_criterion}
          fields={fields}
          {...rest}
        />
      )
    })
  }

  render() {
    const { fields, molecule_criteria } = this.props

    return (
      <div id="molecules" className="clear">
        <RailsHiddenFields
          name="search[molecule_criteria_attributes]"
          value={MoleculeCriterion.toAttributes(fields, molecule_criteria)}
        />

        <div className="molecule_criteria">
          {this.renderKeywordCriteria()}
        </div>

        <div className="add_condition">
          {this.renderCriterionAddButton()}
        </div>
      </div>
    )
  }
}

export default withStore(MoleculeSearchBuilder)
