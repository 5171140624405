export const READ_ADMINS = 'eln/entry/admins/read'
export const CACHE_ADMINS = 'eln/entry/admins/cache'

export const readAdmins = () => ({
  type: READ_ADMINS,
  payload: {},
})

export const cacheAdmins = (admins) => ({
  type: CACHE_ADMINS,
  payload: { admins },
})
