import React from 'react'
import { createRoot } from 'react-dom/client'

import MoleculeSearchBuilder from './component.jsx'

const _render = (props) => {
  const container = document.getElementById('moleculeSearchBuilder')
  const root = createRoot(container)
  return (CDD.Component.Instance.moleculeSearchBuilder = root.render(
    <MoleculeSearchBuilder {...props} />
  ))
}

export default function MoleculeSearchBuilderRenderer(props) {
  const container = document.getElementById('moleculeSearchBuilder')
  const form = container.closest('form')
  const searchButton = document.getElementById('search_button')
  const propsWithSubmit = {
    onSubmit: () => {
      // To properly trigger jQuery and Buttony controlled event listeners, we
      // are causing a click on the form submit button. Eventually, this might
      // become a native submit event or trigger a frontend state change.
      searchButton.click()
    },
    ...props,
  }

  /*
   * The search page caches its form in the History. When restoring, it
   * overwrites the html, so it seems our only recourse is to rerender.
   */
  form.addEventListener(CDD.History.restoreFormStateEvent, (event) => {
    const { molecule_criteria } = event.detail
    _render({ ...propsWithSubmit, molecule_criteria })
  })

  return _render(propsWithSubmit, container)
}
