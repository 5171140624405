import React from 'react';

import { FieldDataType, FilterStyleKey } from '@/FieldDefinitions/types';
import { FilterNumberValue } from './FilterNumberValue';
import { FilterValueSelectProps } from './FilterValueSelect.types';

export class FilterNumberRangeValue extends React.Component<
  FilterValueSelectProps<FieldDataType.Number>
> {
  // memoize handlers
  changeHandlers = {};
  errorHandlers = {};

  get filterValues() {
    return this.props.filter.field_values;
  }

  handleChange = ({ index }: { index: number }) => {
    if (this.changeHandlers[index]) {
      return this.changeHandlers[index];
    }

    const result = (value: string) => {
      const filterValues = [...this.filterValues];
      filterValues[index] = Array.isArray(value) ? value[0] : value;

      this.props.handleValueChange(filterValues);
    };

    this.changeHandlers[index] = result;
    return result;
  };

  handleError = (index) => {
    if (this.errorHandlers[index]) {
      return this.errorHandlers[index];
    }

    const result = (_error) => {
      const filterValues = [...this.filterValues];

      this.filterValues[index] = null;
      this.props.handleValueChange(filterValues);
    };

    this.errorHandlers[index] = result;
    return result;
  };

  renderNumberPicker = ({ index }) => {
    const { filterValues } = this;
    const value = filterValues[index] ? filterValues[index] : null;
    return (
      <FilterNumberValue
        {...this.props}
        filter={{ ...this.props.filter, field_values: [value] }}
        handleValueChange={this.handleChange({ index })}
      />
    );
  };

  render() {
    const filterStyle = this.props.filter.filter_style_key;

    return (
      <span>
        {this.renderNumberPicker({ index: 0 })}
        {filterStyle == FilterStyleKey.FROM && (
          <React.Fragment>
            <span className="filter-to">to</span>
            {this.renderNumberPicker({ index: 1 })}
          </React.Fragment>
        )}
      </span>
    );
  }
}
