import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppContainer as AppReloader } from 'react-hot-loader'
import { Router, Route, useRouterHistory } from 'react-router'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import { syncHistoryWithStore } from 'react-router-redux'
import { stringify, parse } from 'qs'

import AppContainer from '@/shared/components/AppContainer.jsx'
import ElnEntriesContainer from './container.js'
import configureStore from './store/index.js'
import { insertGlobalError } from 'javascripts/application_global'

export default function ElnEntries({ error_message, ...props }) {
  const stringifyQuery = query => stringify(query, { arrayFormat: 'brackets', encode: false })
  const railsifiedHistory = useRouterHistory(createBrowserHistory)({ parseQueryString: parse, stringifyQuery })
  const store = configureStore(props, railsifiedHistory)
  const history = syncHistoryWithStore(railsifiedHistory, store)
  const rootElement = document.getElementById('elnEntries')
  const rootPath = props.links.eln_entries_url

  const root = createRoot(rootElement)

  const renderApp = () => {
    root.render(
      <AppReloader>
        <AppContainer store={store}>
          <Router history={history}>
            <Route path={rootPath} component={ElnEntriesContainer} />
          </Router>
        </AppContainer>
      </AppReloader>
    )
    return root
  }

  if (error_message) {
    insertGlobalError(error_message)
  }
  renderApp()

  if (module.hot) {
    module.hot.accept(['./container.js'], renderApp)
  }
}
