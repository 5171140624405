import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function MoleculeIcon(props = {
  id: 'molecule-icon',
}) {
  return (
    <SvgIcon {...props}>
      <g>
        <g>
          <path
            d="M2.2,24c0-3,0-5.6,0-7.9c0-1,0-2,0-2.9c0-1,0-1.8,0-2.6s0-1.4,0-1.8c0-0.5,0-0.7,0-0.7s0.2-0.1,0.6-0.3
            c0.4-0.2,0.9-0.5,1.6-0.9C5,6.4,5.7,6,6.5,5.5C7.4,5,8.2,4.5,9.1,4c2-1.2,4.3-2.5,6.9-4l13.8,8V24l-13.8,8L2.2,24z M16,29.4
            l11.6-6.7V9.3L16,2.6l-5.8,3.3C9.4,6.4,8.7,6.8,8,7.2S6.7,7.9,6.2,8.2C5.6,8.5,5.2,8.8,4.9,9C4.6,9.2,4.4,9.3,4.4,9.3
            c0,0.8,0,1.5,0,2.1c0,0.6,0,1.3,0,2.2c0,0.8,0,1.6,0,2.5c0,2,0,4.2,0,6.7L16,29.4z M16,5.5c2.8,0,5.3,1,7.3,3.1
            c2.1,2.1,3.1,4.5,3.1,7.3c0,2.9-1,5.4-3,7.4c-2,2-4.5,3.1-7.4,3.1c-2.9,0-5.4-1-7.4-3c-2-2-3-4.5-3-7.4c0-2.8,1-5.3,3.1-7.3
            C10.7,6.5,13.1,5.5,16,5.5z M15.9,7.3c-2.4,0-4.4,0.8-6.1,2.5c-1.7,1.7-2.5,3.7-2.5,6.1c0,2.4,0.8,4.4,2.5,6.1
            c1.7,1.7,3.7,2.5,6.2,2.5c2.4,0,4.4-0.8,6-2.5c1.7-1.7,2.5-3.7,2.5-6c0-2.4-0.8-4.4-2.5-6.1C20.3,8.2,18.3,7.3,15.9,7.3z"
          />
        </g>
      </g>
    </SvgIcon>
  )
}
