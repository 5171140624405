import React, { ReactNode } from 'react';
import autobind from 'class-autobind';
import $ from 'jquery';
import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx';
import { unescapeHTML } from '@/shared/utils/stringUtils';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import FileInput from 'react-fine-uploader/file-input';

import Icon from '@/shared/components/Icon.jsx';
import { A } from '@/shared/components/sanitizedTags.js';
import { FileObject } from './types';

export type Props = {
  attributeName: string,
  file: FileObject,
  filesPath: string,
  additionalActions: (file?: FileObject) => void,
  children?: ReactNode
};

type State = {
  file: FileObject,
  uploading: boolean,
  failed: boolean,
  uploadingFileName: string,
  hover: boolean;
};

export default class FileSingleAttachment extends React.Component<Props, State> {
  private static counter = 0;
  private readonly id: string;
  private readonly filesPath: string;
  private readonly uploader: FineUploaderTraditional;

  constructor(props: Props) {
    super(props);
    autobind(this);

    this.id = this.constructor.name + FileSingleAttachment.counter++;
    const { file, filesPath } = this.props;
    this.filesPath = filesPath;
    this.state = { file, uploading: false, failed: false, uploadingFileName: '', hover: false };

    this.uploader = this.initUploader();
  }

  uploading(name: string): void {
    this.setState({
      failed: false,
      uploading: true,
      uploadingFileName: name,
    });
  }

  succeeded(file: FileObject): void {
    const { additionalActions } = this.props;
    if (typeof additionalActions == 'function') {
      additionalActions(file);
    }

    this.setState({
      file,
      failed: false,
      uploading: false,
      uploadingFileName: '',
    });
  }

  failed(): void {
    this.setState({
      failed: true,
      uploading: false,
    });
  }

  reset(): void {
    const { additionalActions } = this.props;
    if (typeof additionalActions == 'function') {
      additionalActions();
    }

    this.setState({
      file: undefined,
      failed: false,
      uploading: false,
      uploadingFileName: '',
    });
  }

  initUploader(): FineUploaderTraditional {
    return new FineUploaderTraditional({
      options: {
        request: {
          endpoint: this.filesPath,
          params: {
            format: 'json',
            authenticity_token: $.rails.csrfToken(),
          },
        },
        callbacks: {
          onSubmitted: (_id, name: string) => {
            this.uploading(name);
          },
          onComplete: (_id, name: string, responseJSON: { success: boolean, uploaded_file_id: number, file_preview: string; }) => {
            if (responseJSON.success) {
              const file = { id: responseJSON.uploaded_file_id, preview: unescapeHTML(responseJSON.file_preview), name };
              this.succeeded(file);
            } else {
              this.failed();
            }
          },
          onError: (_id, _name, errorReason: string) => {
            // For some reason it kicks off an upload before we are ready, ignore it
            if (errorReason === 'No files to upload.') return;

            this.failed();
          },
        },
      },
    });
  }

  renderRemoveIcon(text: string): JSX.Element {
    return (
      <span className='upload-file-cancel'>
        <A href='#' className='cancel' title={text} onClick={this.reset}>
          <Icon icon='delete' forceSize='16' />
          {text}
        </A>
      </span>
    );
  }

  render(): JSX.Element {
    const { attributeName, children } = this.props;
    const { failed, file, uploading, uploadingFileName } = this.state;

    if (uploading) {
      return (
        <>
          <span className='upload-file upload-file-text'>
            Saving &quot;{uploadingFileName}&quot;
            <Icon icon='spinner' forceSize='16' />
          </span>
          <span className='upload-file-actions'>
            {children}
          </span>
        </>
      );
    } else if (failed) {
      return (
        <span className='upload-file'>
          <div className='upload-file-text'>
            Failed to save &quot;{uploadingFileName}&quot;
          </div>
          <span className='upload-file-actions'>
            {children}
            {this.renderRemoveIcon('Reset')}
          </span>
        </span>
      );
    } else if (file) {
      return (
        <span className='upload-file'>
          <span className='upload-file-preview' dangerouslySetInnerHTML={{ __html: file.preview }} />
          <span className='upload-file-actions'>
            {children}
            {this.renderRemoveIcon('Remove')}
          </span>
          <RailsHiddenFields
            name={attributeName}
            value={file.id}
          />
        </span>
      );
    } else {
      return (
        <>
          <span className='upload-file'>
            <FileInput name='qqfile' accept='*' uploader={this.uploader}>
              <A href='#' className='upload-file-upload-link'>
                <Icon icon='add' forceSize='16' />
                <span>Upload File</span>
              </A>
            </FileInput>
          </span>
          <span className='upload-file-actions'>
            {children}
          </span>
        </>
      );
    }
  }
}
