import { TInventoryFilter } from '@/Search/SearchFilters/FilterValueSelect.types';
import { FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import React from 'react';

// TODO dedupe with eln entries stuff where possible
type FilterStyleSelectProps = {
  filter_style_key: TInventoryFilter['filter_style_key'];
  handleStyleChange: (val: string) => void;
  applicableFilterStyles: { key: string; value: string }[];
};

export class FilterStyleSelect extends React.Component<FilterStyleSelectProps> {
  handleChange = (event) => {
    this.props.handleStyleChange(event.target.value);
  };

  render() {
    const { filter_style_key, applicableFilterStyles } = this.props;

    return (
      <FormControl className="filter-style-select">
        <Select
          value={filter_style_key || applicableFilterStyles[0].key}
          onChange={this.handleChange}
          inputProps={{ name: 'filterStyle' }}
          className="filter__style__select"
        >
          {applicableFilterStyles.map((filterStyle) => (
            <MenuItem key={filterStyle.key} value={filterStyle.key}>
              <ListItemText primary={filterStyle.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
