import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'
import Icon from '@/shared/components/Icon.jsx'

export default class DownloadButton extends React.PureComponent {
  render() {
    const { onClick, text } = this.props

    return (
      <A className="download-associated-model" href="#" onClick={onClick}>
        <Icon icon="pageWhitePut" forceSize="16" />
        <span>{' ' + text}</span>
      </A>
    )
  }
}
