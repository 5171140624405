import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';

import GHS01_Icon from '../assets/GHS01.svg';
import GHS02_Icon from '../assets/GHS02.svg';
import GHS03_Icon from '../assets/GHS03.svg';
import GHS04_Icon from '../assets/GHS04.svg';
import GHS05_Icon from '../assets/GHS05.svg';
import GHS06_Icon from '../assets/GHS06.svg';
import GHS07_Icon from '../assets/GHS07.svg';
import GHS08_Icon from '../assets/GHS08.svg';
import GHS09_Icon from '../assets/GHS09.svg';
import GHS_assume_toxic_Icon from '../assets/GHS_assume_toxic.svg';
import GHS_not_classified_Icon from '../assets/GHS_not_classified.svg';
import { SafetyData } from '../data';

export const NOT_CLASSIFIED = 'Not Classified';
export const ASSUME_TOXIC = 'Assume Toxic and/or Hazardous';

export const HazardClassIconsData = {
  'GHS01': { icon: GHS01_Icon, label: 'Explosive' },
  'GHS02': { icon: GHS02_Icon, label: 'Flammable' },
  'GHS03': { icon: GHS03_Icon, label: 'Oxidizing' },
  'GHS04': { icon: GHS04_Icon, label: 'Compressed gas' },
  'GHS05': { icon: GHS05_Icon, label: 'Corrosive' },
  'GHS06': { icon: GHS06_Icon, label: 'Toxic' },
  'GHS07': { icon: GHS07_Icon, label: 'Harmful' },
  'GHS08': { icon: GHS08_Icon, label: 'Health hazard' },
  'GHS09': { icon: GHS09_Icon, label: 'Environmental hazard' },
  [NOT_CLASSIFIED]: { icon: GHS_not_classified_Icon, label: 'Not classified' },
  [ASSUME_TOXIC]: { icon: GHS_assume_toxic_Icon, label: 'Assume toxic and/or hazardous' },
};

export const HazardClassIcons = (props: {
  safetyData: SafetyData;
  display_assume_toxic: boolean;
  width?: number;
  size?: number;
  add_tooltip?: boolean;
  columns?: number;
  sx?: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
}) => {
  const { safetyData, display_assume_toxic = false } = props;
  if (safetyData.no_hazard_codes) {
    if (display_assume_toxic) {
      return <HazardClassIcon hazardClass={ASSUME_TOXIC} size={props.size} add_tooltip={props.add_tooltip} />;
    }
    return null;
  }
  if (safetyData.not_classified) {
    return <HazardClassIcon hazardClass={NOT_CLASSIFIED} size={props.size} add_tooltip={props.add_tooltip} />;
  }

  if (safetyData.no_hazard_codes && !display_assume_toxic) {
    return null;
  }

  const columns = props.columns || safetyData.hclasses.length;
  let width = props.width;
  if (!width && props.size) {
    width = props.size * columns;
  }
  return (
    <Box width={width} sx={props.sx} component="span">
      <Grid container columns={columns}>
        {
          safetyData.hclasses.map((hc, idx) =>
            <Grid xs={1} sm={1} md={1} item key={idx}>
              <HazardClassIcon
                hazardClass={hc}
                size={props.size}
                add_tooltip={props.add_tooltip} />
            </Grid>,
          )
        }
      </Grid>
    </Box>
  );
};

const HazardClassIcon = (props: {
  hazardClass: string;
  size?: number;
  add_tooltip?: boolean;
}) => {
  const { hazardClass, size = 50, add_tooltip = true } = props;
  if (!Object.keys(HazardClassIconsData).includes(hazardClass)) {
    return null;
  }
  const { icon, label } = HazardClassIconsData[hazardClass];
  const img = <Img src={icon} width={size} height={size} />;
  if (!add_tooltip) {
    return img;
  }
  const title = hazardClass.toLowerCase() == label.toLowerCase() ? hazardClass : `${hazardClass} ${label}`;
  return (
    <Tooltip title={title}>
      <Box>{img}</Box>
    </Tooltip>
  );
};
