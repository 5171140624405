/* eslint-disable multiline-ternary, no-nested-ternary */

import React from 'react';
import { EditFormDefinitionStore } from '../../stores/editFormDefinitionStore';
import { observer } from 'mobx-react';
import { FormLayoutForEditing } from '@/FormDefinitions/types';
import { A } from '@/shared/components/sanitizedTags';
import { OntologyTemplate, SuggestionType, TemplateAssignment } from '@/Annotator/data/templates';
import { Schema, SchemaBranch } from '@/Annotator/data/Schema';
import { SearchCache, SearchCacheRoot } from '@/Annotator/data/SearchCache';
import { PickTermDialog } from '@/Annotator/Templates/PickTermDialog';
import { initializeOntologies } from '@/Annotator/data/utils';

type Props = {
  field: FormLayoutForEditing;
  store: EditFormDefinitionStore;
  isTemplateField?: boolean;
  groupIndent?: number;
}

type State = {
  isPickerOpen: boolean,
  pickerTemplate?: OntologyTemplate,
  pickerAssn?: TemplateAssignment,
  pickerNest?: string[],
  pickerRoots?: SchemaBranch[],
  pickerSelected?: string[],
  pickerSearch?: SearchCache,
};

const getIndentStyle = (indent: number) => {
  return {
    marginLeft: `${indent * 10}px`,
    width: `calc(100% - ${10 + 10 * indent}px)`,
  };
};

@observer
export class SidebarField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isPickerOpen: false };
  }

  readonly indentPixels = 10;

  render() {
    const { field, store, isTemplateField, groupIndent = 0 } = this.props;

    // For now, we need to disable the field if it's already in use.
    // The reason is that we refer to items through unique ids based on protocol fields and
    // template ids, so they can't be duplicated.
    //
    // This is okay for now, because we only allow one table per form, and it would be a mistake
    // to have a duplicate field in the same form.
    //
    // The origin of this issue is that we needed the id when we used a 3rd party drag and drop
    // library. But now we have a custom method, and so perhaps could just use the FormLayout objects
    // directly and not bother with ids. TBD.

    if (isTemplateField && !field.ontologyAssn) {
      // this is a group
      return <>
        <div className='group-label' style={getIndentStyle(groupIndent + 1)}>+ {field.label}</div>
        {
          field.contents.map(field =>
            <SidebarField key={field.layoutID} field={field} store={store} isTemplateField={true}
              groupIndent={groupIndent + 1}/>)
        }
      </>;
    }
    let className = 'SidebarField FormField-selectable';
    if (store.inUseFormIds.has(field.layoutID)) {
      className += ' disabled';
    }

    const clickViewBranch = isTemplateField && field.ontologyAssn && this.assignmentHasBranch(field.ontologyAssn) ? (
      <div className="FormField-divbtn-view" >
        <A
          href="#"
          className="ProtocolAnnotator-buttonadd"
          onClick={() => this.handleViewOntologyBranch(field.ontologyAssn)}
          >
          View
        </A>
      </div>
    ) : null;

    const { isPickerOpen, pickerTemplate, pickerAssn, pickerNest, pickerRoots, pickerSearch } = this.state;

    const viewPickTermDialog = isPickerOpen ? (
      <PickTermDialog
        isOpen={isPickerOpen}
        template={pickerTemplate}
        assn={pickerAssn}
        groupNest={pickerNest}
        roots={pickerRoots || []}
        selected={[]}
        search={pickerSearch}
        titlePrefix='Branch for'
        onDialogCancel={this.handleClosePickTerm}
        onDialogSubmit={null}
      />
    ) : null;

    return (
      <>
        <div className={className} data-draggable={field.layoutID} style={getIndentStyle(groupIndent)}>
          <div className="FormField-selectable-text">
            <div className="FormField-selectable-text-inline">
              {field.label ?? '(no label)'}
            </div>
            {clickViewBranch}
          </div>
        </div>
        {viewPickTermDialog}
      </>
    );
  }

  private assignmentHasBranch(assnURIs: string[]): boolean {
    const { store } = this.props;

    const schemaPrefix = store.currentTemplateSchemaPrefix || store.selectedTemplate.schemaPrefix;
    if (!schemaPrefix) return false;

    const template = store.templates.find((look) => look.schemaPrefix == schemaPrefix);
    if (!template) return false;

    const schema = new Schema(template);
    const groupNest = assnURIs.slice(1);
    const assn = schema.findAssignment(assnURIs[0], groupNest);
    if (!assn) return false;

    return assn.suggestions == SuggestionType.Full || assn.suggestions == SuggestionType.Disabled;
  }

  private handleViewOntologyBranch(assnURIs: string[]): void {
    const { store } = this.props;

    const schemaPrefix = store.currentTemplateSchemaPrefix || store.selectedTemplate.schemaPrefix;
    const template = store.templates.find((look) => look.schemaPrefix == schemaPrefix);
    if (!template) return;

    const schema = new Schema(template);
    const groupNest = assnURIs.slice(1);
    const assn = schema.findAssignment(assnURIs[0], groupNest);
    if (!assn) return;

    (async () => {
      await initializeOntologies();
      const roots = await schema.composeBranch(assn, []);
      const cacheRoots: SearchCacheRoot[] = roots.map((branch) => {
        return {
          assn,
          groupNest,
          branch,
        };
      });

      this.setState({
        isPickerOpen: true,
        pickerTemplate: schema.template,
        pickerAssn: assn,
        pickerNest: groupNest,
        pickerRoots: roots,
        pickerSelected: [],
        pickerSearch: new SearchCache(schema, cacheRoots),
      });
    })();
  }

  private handleClosePickTerm = (): void => {
    this.setState({ isPickerOpen: false });
  };
}
