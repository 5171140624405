import React from 'react'
import { isString } from 'lodash'
import { A } from './sanitizedTags'
import constants from 'javascripts/constants.js'
const { ELN_INTERNAL_LINK_VALID_URL_SCHEMES } = constants

const emptyTag = {
  br: (<br/>),
}

const entities = {
  deg: (<React.Fragment>&deg;</React.Fragment>),
}

/* eslint-disable react/display-name */
const filledTag = {
  b: s => (<b>{s}</b>),
  p: s => (<p>{s}</p>),
  i: s => (<i>{s}</i>),
  em: s => (<em>{s}</em>),
  span: s => (<span>{s}</span>),
  sup: s => (<sup>{s}</sup>),
  sub: s => (<sub>{s}</sub>),
}
/* eslint-enable react/display-name */

/* eslint-disable security/detect-non-literal-regexp */
const tagRegex = new RegExp('</*(' + Object.keys(filledTag).join('|') + ')>', 'i')
const emptyTagRegex = new RegExp('<(' + Object.keys(emptyTag).join('|') + ') */?>', 'i')
const entityRegex = new RegExp('&(' + Object.keys(entities).join('|') + ');', 'i')
const endRegex = Object.keys(filledTag).reduce((p, k) => {
  p[k] = new RegExp('</' + k + '>', 'i')
  return p
}, {})
const schemes = `(${ELN_INTERNAL_LINK_VALID_URL_SCHEMES.join('|')})`
const urlRegex = `${schemes}:\\/\\/[/\\w?.=#%]*`
const linkRegex = new RegExp(urlRegex)
const markdownRegex = new RegExp(`\\[([\\w\\s\\d]*)\\]\\((${urlRegex})\\)`)
/* eslint-enable security/detect-non-literal-regexp */

const splitAtTag = (text, tag) => {
  return [
    text.substring(0, tag.index),
    text.substring(tag.index + tag[0].length),
  ]
}

/* eslint-disable react/display-name */
const supported = {
  tag: {
    regex: tagRegex,
  },
  emptyTag: {
    regex: emptyTagRegex,
    render: (textMatch) => emptyTag[textMatch[1]],
  },
  entity: {
    regex: entityRegex,
    render: (textMatch) => entities[textMatch[1]],
  },
  link: {
    regex: linkRegex,
    render: (textMatch) => (<A href={textMatch[0]}>{textMatch[0]}</A>),
  },
  markdownLink: {
    regex: markdownRegex,
    render: (textMatch) => (<A href={textMatch[2]}>{textMatch[1] || textMatch[2]}</A>),
  },
}
/* eslint-enable react/display-name */

const processLine = (text, allowLinks) => {
  if (text !== 0 && !text) return null
  if (!isString(text)) text = text.toString()

  // find the next match if any
  let nextIndex = text.length
  let nextMatch = {}
  Object.entries(supported).forEach(([key, { regex }]) => {
    if (['link', 'markdownLink'].includes(key) && !allowLinks) return

    const textMatch = text.match(regex)
    if (!textMatch || (textMatch.index > nextIndex)) return
    nextIndex = textMatch.index
    nextMatch = { key, textMatch }
  })
  const { key, textMatch } = nextMatch

  // No more supported tags - return unchanged
  if (!key) {
    return (<React.Fragment>{text}</React.Fragment>)
  }

  const [frag1, frag2] = splitAtTag(text, textMatch)
  if (supported[key].render) {
    return (<React.Fragment>{frag1}{supported[key].render(textMatch)}{processLine(frag2, allowLinks)}</React.Fragment>)
  }

  const tag = textMatch[1].toLowerCase()
  const wrapIt = filledTag[textMatch[1].toLowerCase()]
  const fullTag = textMatch[0]

  // The found tag is an end tag - assume that a start tag is missing at the beginning of the text
  if (fullTag.includes('/')) {
    return (<React.Fragment>{wrapIt(frag1)}{processLine(frag2, allowLinks)}</React.Fragment>)
  }

  const matchFound = frag2.match(endRegex[tag])
  // Find no matching tag
  if (!matchFound) {
    return (<React.Fragment>{frag1}{wrapIt(processLine(frag2, allowLinks))}</React.Fragment>)
  }

  // Found matching tag
  const [frag21, frag22] = splitAtTag(frag2, matchFound)
  return (<React.Fragment>{frag1}{wrapIt(processLine(frag21, allowLinks))}{processLine(frag22, allowLinks)}</React.Fragment>)
}

const RenderSimpleHTML = ({ text, allowLinks = false }) => (<React.Fragment>{processLine(text, allowLinks)}</React.Fragment>)
export default RenderSimpleHTML
const internals = {
  linkRegex,
  markdownRegex,
}
export { internals }
