import { Record } from 'immutable'
import {
  REQUEST_SUCCESS,
  GET_NEXT_PAGE,
  UPDATE_SORT_COLUMN,
  SUBMIT_QUERY,
  UPDATE_DATA_SOURCES,
} from '../actions'
import { LOCATION_CHANGE } from 'react-router-redux'
import { Entries } from '@/models/Eln/Entry.js'
import {
  Constants,
  Request,
  Results,
  ResultColumns,
  Query,
  Status,
} from './index.js'
import { FieldDefinitions } from '@/Eln/Entry/models/FieldDefinition'
import { Fields } from '@/Eln/Entry/models/Field'

const DEFAULTS = {
  elnEntries: new Entries(),
  elnFieldDefinitions: new FieldDefinitions(),
  constants: new Constants(),
  query: new Query(),
  request: new Request(),
  results: new Results(),
  resultColumns: ResultColumns(),
  status: new Status(),
  page: 0,
  routing: {},
}

export class State extends Record(DEFAULTS) {
  static initialState(props = {}) {
    const { eln_entries, eln_field_definitions, count, links, results: resultProps, filters, result_columns } = props
    const { eln_entries_url, eln_entries_export_url, eln_summary_export_url, eln_entries_export_progress_url, eln_result_columns_url } = links
    const elnEntries = Entries.initialState(eln_entries)
    const elnFieldDefinitions = FieldDefinitions.initialState(eln_field_definitions || [], new Fields([], []), false)
    const constants = new Constants({
      elnEntryUrl: eln_entries_url,
      elnEntriesExportUrl: eln_entries_export_url,
      elnSummaryExportUrl: eln_summary_export_url,
      elnEntriesExportProgressUrl: eln_entries_export_progress_url,
      elnResultColumnUrl: eln_result_columns_url,
    })
    const status = new Status({ trueCount: count })
    const query = new Query({ query_filters: filters })
    const results = new Results(resultProps)
    const resultColumns = ResultColumns(result_columns)
    const page = 0

    return new State({ elnEntries, elnFieldDefinitions, eln_entries_url, constants, query, results, resultColumns, status, page })
  }
}

export const state = (state = new State(), action) => {
  switch (action.type) {
    case REQUEST_SUCCESS: {
      let { elnEntries, results, resultColumns, elnFieldDefinitions, query } = state

      switch (state.request.type) {
        case GET_NEXT_PAGE:
          elnEntries = state.elnEntries.append(action.response.eln_entries)
          results = state.results.append(action.response.results)
          break
        case UPDATE_SORT_COLUMN:
        case SUBMIT_QUERY:
        case UPDATE_DATA_SOURCES:
          elnEntries = Entries.initialState(action.response.eln_entries)
          results = new Results(action.response.results)
          elnFieldDefinitions = FieldDefinitions.initialState(action.response.eln_field_definitions || [], new Fields([], []), false)
          query = Query.scopeFilters(query, elnFieldDefinitions)
          resultColumns = ResultColumns(action.response.result_columns)

          if (state.request.type == SUBMIT_QUERY && query.ignore_sort_column) {
            resultColumns = resultColumns.map((column) => (column.set('direction', null)))
          }
          break
      }

      return state.set('elnEntries', elnEntries)
        .set('results', results)
        .set('elnFieldDefinitions', elnFieldDefinitions)
        .set('query', query)
        .set('resultColumns', resultColumns)
    }
    case LOCATION_CHANGE: {
      let { resultColumns, query } = state

      if (query.ignore_sort_column) {
        return state.set('resultColumns', resultColumns.map((column) => (column.set('direction', null))))
      }
      return state
    }
    default:
      return state
  }
}
