import React from 'react';
import { getRootStore } from '@/stores/rootStore';
import { observer } from 'mobx-react';
import { ProtocolReadoutDefinitionsTable } from './components/ProtocolReadoutDefinitionsTable';
import { A } from '@/shared/components/sanitizedTags';
import { EditProtocolReadoutDefinitionDialog } from './components/EditProtocolReadoutDefinitionDialog';
import { term } from '@/shared/utils/stringUtils';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import { Img } from '@/shared/components/sanitizedTags.js';

type Props = {
  editingAllowed: boolean;
  createOnEmpty: boolean;
}

@observer
export default class ProtocolReadoutDefinitionsView extends React.Component<Props> {
  constructor(props) {
    super(props);
    getRootStore().protocolReadoutDefinitionsStore.loadReadoutDefinitions(props.createOnEmpty);
  }

  get store() {
    return getRootStore().protocolReadoutDefinitionsStore;
  }

  render() {
    const { editingAllowed } = this.props;

    const {
      displayRows, doseResponseCalculations, customCalculations,
      handleChangeOrder,
      editStore: {
        inited,
        handleCreateNewReadoutDefinition,
        handleEditReadoutDefinition,
      },
    } = this.store;

    return <>
      {inited && <div className='editableData ProtocolReadoutDefinitionsView'>
        {editingAllowed && <A href='#' className='createNewReadoutDefinition' id='add_readout_definition_link' onClick={handleCreateNewReadoutDefinition}>
          <Img
            width={16} height={16} className='icon-16' alt='Add'
            src={addIcon} />
          {`Create a new ${term('readout_definition')}`}
        </A>}

        <ProtocolReadoutDefinitionsTable
          readonly={!editingAllowed}
          rows={displayRows}
          doseResponseCalculations={doseResponseCalculations}
          customCalculations={customCalculations}
          onChangeOrder={handleChangeOrder}
          onClickRow={handleEditReadoutDefinition}
        />

        <EditProtocolReadoutDefinitionDialog
          store={this.store.editStore}
        />
      </div>}
    </>;
  }
}
