/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';
import { FieldDataType, FieldPickValue } from '@/FieldDefinitions/types';
import { PickListItemsEditor } from './PickListItemsEditor';
import { Typography, Box } from '@mui/material';
import { ReadoutDefinitionUtils } from '@/ProtocolReadoutDefinitions/readoutDefinitionUtils';

type Props = React.ComponentProps<typeof PickListItemsEditor> & {
  prefix?: string;
  showForm?: boolean;
  onChange?: (value: FieldPickValue[]) => void;
}

type State = {
  values: FieldPickValue[];
}

export class PickListItemsEditorInForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      values: props.values,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.values !== this.props.values) {
      this.setState({ values: this.props.values });
    }
  }

  render() {
    const { prefix = 'form_prefix', showForm } = this.props;
    const { values } = this.state;

    return <div style={{ position: 'relative' }}>
      <PickListItemsEditor
        {...this.props}
        values={values}
        dataType={FieldDataType.PickList}
        onChange={
          (values) => {
            this.setState({ values });
            this.props.onChange?.(values);
          }
      }
        editButtonStyle={{ position: 'unset', top: 'unset' }}
      />
      <Typography
        component={'span'}
        className='pickListSummaryInformation buttony-description-small'>
        {ReadoutDefinitionUtils.getPickListSummary({ pick_list_values: this.state.values })}
      </Typography>

      {values.map((value, index) =>
        <div key={`${prefix}${index}`} style={{ display: showForm ? 'block' : 'none' }}>
          {Object.keys(value).map(key => <input key={key}
            name={`${prefix}[picklist_values][${index}][${key}]`} value={value[key]} />,
          )}
        </div>)}
    </div>;
  }
}
