import { ensureChemUtils, peekChemUtils } from '@/shared/utils/chemUtils';
import { KetCanvas, KetRoot, KetRef, KetArrow, KetPlus, KetMol, KetAtom, convertKetToMol, convertKetToMrv, convertMolToKet, convertMrvToKet, ketIsBlank, prepareMolfileForKetcher } from '@cdd/ui-kit/lib/components/elements/molImage/v2/ketcherFormat';
import { MarvinCML } from '@cdd/ui-kit/lib/components/elements/molImage/v2/MarvinCML';
import { molfileIsValidAndHasAllZeroCoords } from '@cdd/ui-kit/lib/components/elements/molImage/v2/RenderMoleculeSVG';
import { StructureFormat } from './StructureEditorTypes';
import { Ketcher } from 'ketcher-core';

export async function addKetcherStructure(structure: string, ketcher: Ketcher = window.ketcher) {
  if (structure.includes('mrvSchema')) {
    const cml = new MarvinCML(structure);
    cml.parse();
    if (cml.coordinatesAllZero()) {
      const chemUtils = await ensureChemUtils();
      let molfile = chemUtils.smilesToMol(chemUtils.molToSmiles(cml.getMolfile()));
      molfile = prepareMolfileForKetcher(molfile) ?? molfile;
      structure = convertMolToKet(molfile);
    } else {
      let molfile = cml.getMolfile();
      molfile = prepareMolfileForKetcher(molfile) ?? molfile;
      structure = convertMolToKet(molfile);
    }
  } else {
    if (molfileIsValidAndHasAllZeroCoords(structure)) {
      const chemUtils = await ensureChemUtils();
      structure = chemUtils.smilesToMol(chemUtils.molToSmiles(structure));
    }
    structure = prepareMolfileForKetcher(structure) ?? structure;
  }

  const ketStructure = await ketcher.getKet();
  const newStructure = mergeKetcherStructures(ketStructure, structure);
  ketcher.setMolecule(newStructure);
}

export async function getKetcherStructure(format = StructureFormat.MRV, ketcher: Ketcher = window.ketcher) {
  if (format == StructureFormat.MOL || format == StructureFormat.MOLV3000) {
    /*
      NOTE: the following code would ideally be replaced with an alternate option where Ketcher automatically picks
      v3000 if necessary features are used, or v2000 if not...

      ... and this doesn't actually work, because V3000 gets stalled for some reason:
      const v2000 = await this.ketcher.getMolfile('v2000');
      const v3000 = await this.ketcher.getMolfile('v3000');
      if (v3000.includes('MDLV30/STEABS') || v3000.includes('MDLV30/STERAC') || v3000.includes('MDLV30/STEREL')) {
        return v3000;
      } else {
        return v2000;
      }

      Also, Ketcher unpacks incoming SMILES strings with aromatic bonds (type 4) and reading its version of the
      Molfile returns it in query format, which we don't want.
    */
    const ketStructure = await ketcher.getKet();
    if (ketIsBlank(ketStructure)) return '';
    const requireV3000 = format == StructureFormat.MOLV3000;
    return convertKetToMol(ketStructure, requireV3000); // our own Ket-to-Molfile, could be V2000 or V3000
  } else if (format == StructureFormat.MRV) {
    const ketStructure = await ketcher.getKet();
    if (ketIsBlank(ketStructure)) return '';
    return convertKetToMrv(ketStructure);
  } else if (format == StructureFormat.SMILES || format == StructureFormat.CXSMILES) {
    await ensureChemUtils();
    const mol = convertKetToMol(await ketcher.getKet());
    return peekChemUtils().molToSmiles(mol);
  } else {
    throw new Error(`Format not supported: ${format}`);
  }
}

// Unused?
export async function replaceKetcherStructure(structure: string, ketcher: Ketcher = window.ketcher) {
  if (structure.includes('mrvSchema')) {
    structure = convertMrvToKet(structure);
  } else {
    structure = prepareMolfileForKetcher(structure) ?? structure;
  }

  ketcher.setMolecule(structure);
}

export async function setKetcherSettings(settings: Record<string, any>, ketcher: Ketcher = window.ketcher) {
  ketcher.setSettings(settings);
}

export async function setKetcherStructure(structure: string, ketcher: Ketcher = window.ketcher) {
  let marvinZero = false;

  if (structure?.includes('mrvSchema')) {
    marvinZero = marvinNeedsLayout(structure);
    structure = convertMrvToKet(structure);
  } else {
    const modStructure = prepareMolfileForKetcher(structure);
    structure = modStructure ?? structure;
  }

  const lines = (structure?.split('\n') ?? []).filter((line) => !!line);
  if (lines.length == 1) structure = structure.trim();

  if (structure?.trim()) ketcher.setMolecule(structure);

  if (marvinZero || molfileIsValidAndHasAllZeroCoords(structure)) {
    requestCoordinateLayout();
  }
}

function average(values: number[]) {
  return values.reduce((sum, v) => sum + v, 0) / values.length;
}

function getCoordinates(ketObject: KetCanvas, molNodes: string[], coordinate: number): number[] {
  const atomCoords = molNodes
    .map((name): KetAtom[] => (ketObject[name] as KetMol).atoms)
    .map((atoms) => atoms.map((atom) => atom.location[coordinate]))
    .flat();
  const arrowCoords = (((ketObject.root) as KetRoot).nodes as KetArrow[])
    .filter((node) => node.type == 'arrow')
    .map((arrow) => coordinate == 0 ? [arrow.data.pos[0].x, arrow.data.pos[1].x] : [arrow.data.pos[0].y, arrow.data.pos[1].y])
    .flat();
  const plusCoords = (((ketObject.root) as KetRoot).nodes as KetPlus[])
    .filter((node) => node.type == 'plus')
    .map((plus) => plus.location[coordinate]);
  return [...atomCoords, ...arrowCoords, ...plusCoords];
}

// returns true if either the Marvin CML file has all zero coordinates, or it signified that the input coords are messy
function marvinNeedsLayout(mrvxml: string): boolean {
  try {
    const marvinCML = new MarvinCML(mrvxml);
    marvinCML.parse();
    if (marvinCML.isReaction()) return false;
    return marvinCML.requestsLayout || molfileIsValidAndHasAllZeroCoords(marvinCML.getMolfile());
  } catch (ex) {
    return false;
  }
}

// Exported for unit test
export function mergeKetcherStructures(struct1: string, struct2: string): string {
  const ketObject1: KetCanvas = JSON.parse(struct1);
  const ketObject2: KetCanvas = JSON.parse(struct2);
  const nodes1 = (ketObject1.root as KetRoot).nodes;
  const nodes2 = (ketObject2.root as KetRoot).nodes;

  if (!nodes1.length) {
    return struct2;
  }

  if (!nodes2.length) {
    return struct1;
  }

  // position struct2 to the right of struct1
  const ket1MolNodeNames = nodes1.map((n: KetRef) => n.$ref).filter((name) => !!name) as string[];
  const ket2MolNodeNames = nodes2.map((n: KetRef) => n.$ref).filter((name) => !!name) as string[];
  const deltaX = (2 +
    Math.max(...getCoordinates(ketObject1, ket1MolNodeNames, 0)) -
    Math.min(...getCoordinates(ketObject2, ket2MolNodeNames, 0))
  );
  const deltaY = (
    average(getCoordinates(ketObject1, ket1MolNodeNames, 1)) -
    average(getCoordinates(ketObject2, ket2MolNodeNames, 1))
  );

  ket2MolNodeNames.map((name) => (ketObject2[name] as KetMol).atoms)
    .map((atoms) => atoms.forEach((atom) => {
      atom.location[0] = atom.location[0] + deltaX;
      atom.location[1] = atom.location[1] + deltaY;
    }));

  const nextMol = `mol${nodes1.length}`;
  // eslint-disable-next-line quote-props
  nodes1[nodes1.length] = { '$ref': `mol${nodes1.length}` };
  ketObject1[nextMol] = ketObject2[((ketObject2.root as KetRoot).nodes[0] as KetRef).$ref];
  return JSON.stringify(ketObject1);
}

const KETCHER_SELECTOR = '.Ketcher-root';

function requestCoordinateLayout(): void {
  let timeouts = 30;
  const clickLayout = () => {
    const container = document.querySelector(KETCHER_SELECTOR) as HTMLDivElement;
    const btn = container.querySelector('button[title^="Layout"]') as HTMLButtonElement;
    if (!btn || btn.disabled) {
      if (--timeouts > 0) setTimeout(clickLayout, 100);
    } else {
      btn.click();
    }
  };
  setTimeout(clickLayout, 100);
}

// converts a molfile into a variant that can be accepted by RDKit for query purposes
export function prepareStructureForQuery(molfile: string): string {
  if (!molfile) return molfile;

  const rdr = new WebMolKit.MDLMOLReader(molfile);
  rdr.parse();
  const mol = rdr.mol;
  if (!mol) return '';
  mol.keepTransient = true;

  const EXCEPTIONS = ['A', 'Q', '*', 'AH', 'QH', 'X', 'XH', 'M', 'MH'];

  for (let n = 1; n <= mol.numAtoms; n++) {
    if (mol.atomicNumber(n) > 0) continue;
    const label = mol.atomElement(n);
    if (WebMolKit.MolUtil.hasAbbrev(mol, n)) continue;
    if (EXCEPTIONS.includes(label) || (label.startsWith('R') && parseInt(label.substring(1)) > 0)) continue;

    mol.setAtomElement(n, '*');
    WebMolKit.ForeignMolecule.markSgroupMultiAttach(mol, label, [n]);
  }

  return new WebMolKit.MDLMOLWriter(mol).writeV3000();
}
