export const GET_TEMP_LINK = 'eln/entry/link/temp'
export const GET_TEMP_LINK_RESPONSE = 'eln/entry/link/temp/response'
export const REMOVE_TEMP_LINK = 'eln/entry/link/temp/remove'

export const LINK_ADD = 'eln/entry/link/add'
export const LINK_ADD_UPDATE = 'eln/entry/link/add_update'
export const LINK_ADD_UPDATE_RESPONSE = 'eln/entry/link/add_update/response'
export const LINK_ADD_UPDATE_BY_ID = 'eln/entry/link/add_update/by_id'
export const LINK_STATE_REMOVE = 'eln/entry/link/state/remove'
export const LINK_DETAILS = 'eln/entry/link/details'
export const LINK_DETAILS_RESPONSE = 'eln/entry/link/details/response'
export const LINK_DETAILS_CANCEL = 'eln/entry/link/details/cancel'

export function getTempLink(href) {
  return {
    type: GET_TEMP_LINK,
    payload: { href },
  }
}

export function getTempLinkResponse(response) {
  return {
    type: GET_TEMP_LINK_RESPONSE,
    payload: { response },
  }
}

export function removeTempLink() {
  return {
    type: REMOVE_TEMP_LINK,
    payload: {},
  }
}

export function addLink(key, id) {
  return {
    type: LINK_ADD,
    payload: { key, id },
  }
}

export function addUpdateLink(key, href, id, label, display) {
  return {
    type: LINK_ADD_UPDATE,
    payload: { key, href, id, label, display },
  }
}

export function addUpdateLinkResponse(key, response) {
  return {
    type: LINK_ADD_UPDATE_RESPONSE,
    payload: { key, response },
  }
}

export function addUpdateLinkById(response) {
  return {
    type: LINK_ADD_UPDATE_BY_ID,
    payload: { response },
  }
}

export function removeStateLink(key) {
  return {
    type: LINK_STATE_REMOVE,
    payload: { key },
  }
}

export function generateLinkDetails(key, nextUrl) {
  return {
    type: LINK_DETAILS,
    payload: { key, nextUrl },
  }
}

export function generateLinkDetailsResponse(key, nextUrl, response) {
  return {
    type: LINK_DETAILS_RESPONSE,
    payload: { key, nextUrl, response },
  }
}

export function cancelLinkDetails() {
  return {
    type: LINK_DETAILS_CANCEL,
    payload: {},
  }
}
