import React, { MouseEvent, useState } from 'react';
import { get_safety_data } from '../data';
import { HazardClassIcons } from './HazardClassIcons';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Stack } from '@mui/material';
import { HazardCodes, PrecautionaryCodes } from './Codes';
import { InfoButton } from '@/Molecule/DeepLearning/controls';
import { SafetySummaryInfoDialog } from './SafetySummaryInfoDialog';

export const SafetySummary = (props: {
  hazard_codes: string[] | string;
  pubchem_id?: number;
}) => {
  const safetyData = get_safety_data(props.hazard_codes);
  const display_assume_toxic = true;
  return (
    <Stack direction='row' justifyItems={'flex-start'} spacing={1}>
      <HazardClassIcons safetyData={safetyData} size={50} columns={1} display_assume_toxic={display_assume_toxic} />
      <Stack direction='column' justifyItems={'flex-start'}>
        <PubchemLink pubchem_id={props.pubchem_id} />
        <HazardCodes header='Hazard codes' safetyData={safetyData} add_details={true} display_assume_toxic={display_assume_toxic} />
        <PrecautionaryCodes safetyData={safetyData} add_details={true} />
      </Stack>
    </Stack>
  );
};

export const SafetySummaryDialog = (props: {
  hazard_codes: string[] | string;
  pubchem_id?: number;
  open: boolean;
  onClose?: () => void;
  banExternalLinks: boolean;
  skipExternalLinkWarning: boolean;
}) => {
  if (!props.open) {
    return null;
  }
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      props.onClose();
    }
  };
  const onClick = (e: React.MouseEvent) => {
    props.onClose();
    e.stopPropagation();
  };
  const pubchem_id = props.banExternalLinks ? undefined : props.pubchem_id;

  return (
    <Dialog
      className='SafetySummaryDialog'
      open={props.open}
      fullWidth
      maxWidth='xl'
      PaperProps={{ sx: { height: 'calc(100% - 64px)' } }}
      onKeyDown={handleKeyDown}
    >
      <SafetySummaryDialogTitle banExternalLinks={props.banExternalLinks} />
      <DialogContent>
        <SafetySummary hazard_codes={props.hazard_codes} pubchem_id={pubchem_id} />
      </DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack
          direction='row'
          justifyContent='flex-start'
          spacing={1}
          divider={<Divider orientation='vertical' flexItem />}
          sx={{ padding: '10px' }}
        >
          <ExternalLinkFooter
            skipExternalLinkWarning={props.skipExternalLinkWarning}
            banExternalLinks={props.banExternalLinks}
          />
          <HazardCodesDisclaimer />
        </Stack>
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={onClick}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const ExternalLinkFooter = (props: {
  skipExternalLinkWarning: boolean;
  banExternalLinks: boolean;
}) => {
  const footer = [];
  if (!props.skipExternalLinkWarning && !props.banExternalLinks) {
    footer.push(
      <Box style={{ fontWeight: 'light', color: 'grey' }} key='external'>
        Warning: links open an external website
      </Box>,
    );
  }
  return <> {footer} </>;
};

const HazardCodesDisclaimer = () => {
  return (
    <Box style={{ fontWeight: 'light', color: 'grey' }}>
      CDD disclaims any and all warranties regarding the completeness or accuracy of the contents of the CDD Vault
    </Box>
  );
};

const SafetySummaryDialogTitle = ({ banExternalLinks }: { banExternalLinks: boolean }) => {
  return (
    <DialogTitle className='muiDialog-title'>
      <Box display='flex' justifyContent='space-between'>
        <Box>Safety Summary</Box>
        <SafetySummaryInfoButton banExternalLinks={banExternalLinks} />
      </Box>
    </DialogTitle>
  );
};

export const SafetySummaryInfoButton = ({ banExternalLinks }: { banExternalLinks: boolean }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoButton onClick={() => setOpen(true)} tooltip='Information' />
      {open && (
        <SafetySummaryInfoDialog banExternalLinks={banExternalLinks} open={open} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

const PubchemLink = (props: {
  pubchem_id?: number
}) => {
  if (!props.pubchem_id) {
    return null;
  }
  const href = `https://pubchem.ncbi.nlm.nih.gov/compound/${props.pubchem_id}#datasheet=LCSS`;
  const label = 'Laboratory Chemical Safety Summary (LCSS) on PubChem';
  return (
    <Box>
      {'Go to '}
      <Link
        href={href}
        underline='none'
        target='_blank'
        rel='noreferrer'
        aria-label={label}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {label}
      </Link>
    </Box>
  );
};
