import React from 'react';
import { action, computed, makeObservable } from 'mobx';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { CustomNucleotidesSchema } from '@/Accounts/CustomNucleotides/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { EditCustomNucleotideStore } from '../stores/editCustomNucleotideStore';
import './EditCustomNucleotideDialog.sass';
import { StructureEditorLauncherButton } from '@/Accounts/components/StructureEditor/StructureEditorLaunchButton';
import {
  customNucleotideUnmodifiedCodeOptions,
  customNucleotideStructures,
} from './constants';
import { launchStructureEditor } from '@/Accounts/components/StructureEditor/StructureEditor';

type Props = {
  store: EditCustomNucleotideStore;
  existingResidues: Array<CustomNucleotidesSchema>;
};

const {
  column,
  textInput,
  select,
  typography,
  custom,
  checkbox,
} = layoutBuilder;

@observer
export class EditCustomNucleotideDialog extends React.Component<Props> {
  disposers: Array<() => void> = [];
  structureEditor;

  constructor(props: Props) {
    super(props);
    this.structureEditor = React.createRef();
    makeObservable(this, {
      layout: computed,
      handleOK: action,
    });
  }

  componentWillUnmount() {
    this.disposers.forEach((disposer) => disposer());
  }

  handleOK = () => {
    this.props.store.handleSubmit();
  };

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    // ignore the background clicks per Kellan, as it's too easy to accidentally click off and lose work
    const { store } = this.props;
    if (reason === 'escapeKeyDown') {
      store.handleCancelEdit();
    }
  };

  handleStructureChange = (newMolFile: string, newMolecularFormula: string, newMolecularWeight: number) => {
    const { store } = this.props;
    store.setStructure(newMolFile, newMolecularFormula, newMolecularWeight);
  };

  handleStructureLaunchButton = () => {
    launchStructureEditor(this.props.store.value.mol_file, this.handleStructureChange);
  };

  get layout() {
    const {
      props: {
        store: {
          value,
          codesError,
          nameError,
        },
      },
    } = this;

    return column([
      textInput({
        key: 'codes',
        label: 'Codes',
        required: true,
        autoFocus: true,
        disabled: value?.id && !value?.can_edit_codes,
        error: codesError,
      }),
      textInput({
        key: 'name',
        label: 'Name',
        required: true,
        error: nameError,
      }),
      textInput({
        key: 'description',
        label: 'Description',
      }),
      select({
        key: 'unmodified_nucleotide',
        selectOptions: customNucleotideUnmodifiedCodeOptions,
        label: 'Unmodified Nucleotide',
        required: true,
        translateSetValue: (value: string) => {
          const newStructure = customNucleotideStructures[value];
          launchStructureEditor(newStructure, this.handleStructureChange);
          return value;
        },
        error: !value.unmodified_nucleotide
          ? 'Unmodified Nucleotide is required'
          : null,
      }),
      textInput({
        key: 'family',
        label: 'Modification Family',
      }),
      textInput({
        key: 'vendor_link',
        label: 'Vendor Link',
      }),
      textInput({
        key: 'formula',
        label: 'Formula',
      }),
      textInput({
        key: 'molecular_weight',
        label: 'Molecular Weight',
        required: true,
        error: !value.molecular_weight
          ? 'Molecular Weight is required'
          : null,
      }),
      checkbox({
        key: 'is_attachment_point',
        label: 'Is Attachment Point',
        width: 'default',
      }),
      column({ className: 'structure' }, [
        typography({ label: 'Structure' }),
        custom({
          render: () => (
            <>
              <StructureEditorLauncherButton
                    structure={value.mol_file}
                    onClick={this.handleStructureLaunchButton}
              />
            </>
          ),
        }),
      ]),
    ]);
  }

  get isCreatingNew() {
    return !this.props.store.value?.id;
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  get dialogTitle() {
    if (this.isEditingExisting) {
      return 'Edit Nucleotide';
    } else {
      return 'Create New Nucleotide';
    }
  }

  render() {
    const {
      props: {
        store: {
          value,
          isOpen,
          handleCancelEdit,
          handleSubmit,
        },
      },
    } = this;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          className='EditCustomNucleotideDialog edit-account-object-dialog'
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {value && (
            <>
              <DialogTitle className='muiDialog-title'>
                {this.dialogTitle}
              </DialogTitle>

              <DialogContent>
                <CDDModalForm
                  data={value ?? {}}
                  layout={this.layout}
                  showNextBackStepper={this.isCreatingNew}
                  onOK={handleSubmit}
                  onCancel={handleCancelEdit}
                  terminology={{ OK: 'Save' }}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}
