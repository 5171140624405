import { sanitizeESFragment } from '@/shared/components/sanitizeTags';
import React from 'react';
import { TSingleSearchHighlight } from '../types';

export default class InventoryHighlight extends React.Component<{
  highlight: TSingleSearchHighlight;
}> {
  render() {
    const {
      highlight: { fragments },
    } = this.props;

    // We 'dangerouslySetInnerHTML' because the fragments coming from
    // elasticsearch will have 'em' tags for emphasis. Elasticsearch should
    // escape unsafe html content, but we additionally run it through a sanitize call
    // to be extra safe.
    return (
      <>
        {fragments.map((fragment) => {
          const sanitizedFragment = sanitizeESFragment(fragment);

          return (
            <span
              className='search_results__value'
              key={sanitizedFragment}
              dangerouslySetInnerHTML={{ __html: sanitizedFragment }}
            />
          );
        })}
      </>
    );
  }
}
