import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';

import { PropertySelection } from '../Properties/PropertySelection';
import { BioisosterSettings } from './BioisosterResultsTypes';
import { CountSelection } from '../Properties/CountSelection';
import { CollectionSelection } from '../Similarity/Collections';

type BioisosterSettingsDialogProps = {
  open: boolean;
  settings: BioisosterSettings;
  onClose: (settings: BioisosterSettings) => void;
};

export const BioisosterSettingsDialog = (props: BioisosterSettingsDialogProps) => {
  const { open, onClose } = props;
  const [settings, setSettings] = useState<BioisosterSettings>({ ...props.settings });

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      onClose(props.settings);
    }
  };

  const returnSelection = () => {
    onClose(settings);
  };

  return (
    <Dialog
      className='PropertySelectionDialog'
      open={open}
      onKeyDown={handleKeyDown}
      data-testid='property-selection-dialog'
    >
      <DialogTitle className='muiDialog-title'>
        Bioisostere settings
      </DialogTitle>
      <DialogContent>
        <CountSelection
          count={settings.count}
          onChange={(count) => setSettings({ ...settings, count })}
          values={[12, 18, 24]}
          label='Maximum number of hits per fragment:'
        />
        <CollectionSelection
          enabled={settings.showCollectionInformation}
          onChange={(enabledCollections) => setSettings({ ...settings, showCollectionInformation: enabledCollections })}
        />
        <PropertySelection
          selected={settings.properties}
          onChange={properties => setSettings({ ...settings, properties })}
        />
      </DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <span />
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={returnSelection}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
