export const customAminoAcidUnmodifiedCodeOptions = [
  { id: 'A', label: 'A' },
  { id: 'C', label: 'C' },
  { id: 'D', label: 'D' },
  { id: 'E', label: 'E' },
  { id: 'F', label: 'F' },
  { id: 'G', label: 'G' },
  { id: 'H', label: 'H' },
  { id: 'I', label: 'I' },
  { id: 'K', label: 'K' },
  { id: 'L', label: 'L' },
  { id: 'M', label: 'M' },
  { id: 'N', label: 'N' },
  { id: 'P', label: 'P' },
  { id: 'Q', label: 'Q' },
  { id: 'R', label: 'R' },
  { id: 'S', label: 'S' },
  { id: 'T', label: 'T' },
  { id: 'Y', label: 'Y' },
  { id: 'V', label: 'V' },
  { id: 'W', label: 'W' },
];

export const customAminoAcidStructures = {
  A: '\nGenerated by Molecular Materials Informatics\n\n  7  6  0  0  0  0  0  0  0  0999 V2000\n   -6.5731    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.5731    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8721    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.2741    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.1712    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8721    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.4702    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  5  7  1  0  0  0  0\nM  END\n> <Name>\nalanine\n\n> <Abbrev>\nAla\n\n> <Mnemonic>\nA\n\n$$$$\n',
  C: '\nGenerated by Molecular Materials Informatics\n\n  8  7  0  0  0  0  0  0  0  0999 V2000\n   -6.8514    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.8514    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.1504    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.5524    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.4495    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.1504    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.8515    5.0000    0.0000 S   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.7485    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  5  8  1  0  0  0  0\nM  END\n> <Name>\ncysteine\n\n> <Abbrev>\nCys\n\n> <Mnemonic>\nC\n\n$$$$\n',
  D: '\nGenerated by Molecular Materials Informatics\n\n 10  9  0  0  0  0  0  0  0  0999 V2000\n   -7.5010    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2020    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0991    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5009    3.5000    0.0000 O   0  5  0  0  0  0  0  0  0  0  0  0\n   -6.2020    5.7499    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.3981    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  7  9  2  0  0  0  0\n  5 10  1  0  0  0  0\nM  CHG  1   8  -1\nM  END\n> <Name>\naspartic acid\n\n> <Abbrev>\nAsp\n\n> <Mnemonic>\nD\n\n$$$$\n',
  E: '\nGenerated by Molecular Materials Informatics\n\n 11 10  0  0  0  0  0  0  0  0999 V2000\n   -7.3830    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3830    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6820    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.0840    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.9811    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6820    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3830    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3828    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.0838    2.7500    0.0000 O   0  5  0  0  0  0  0  0  0  0  0  0\n   -8.6819    2.7500    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2801    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  9  1  0  0  0  0\n  8 10  2  0  0  0  0\n  5 11  1  0  0  0  0\nM  CHG  1   9  -1\nM  END\n> <Name>\nglutamic acid\n\n> <Abbrev>\nGlu\n\n> <Mnemonic>\nE\n\n$$$$\n',
  F: '\nGenerated by Molecular Materials Informatics\n\n 13 13  0  0  0  0  0  0  0  0999 V2000\n   -8.8000    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0990    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.3981    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0990    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    2.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2019    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2019    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -12.6971    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  7  9  2  0  0  0  0\n  8 10  2  0  0  0  0\n 10 11  1  0  0  0  0\n 11 12  2  0  0  0  0\n 12  9  1  0  0  0  0\n  5 13  1  0  0  0  0\nM  END\n> <Name>\nphenylalanine\n\n> <Abbrev>\nPhe\n\n> <Mnemonic>\nF\n\n$$$$\n',
  G: '\nGenerated by Molecular Materials Informatics\n\n  6  5  0  0  0  0  0  0  0  0999 V2000\n   -6.6349    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.6349    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.9339    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.3359    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.2330    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.5320    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  5  6  1  0  0  0  0\nM  END\n> <Name>\nglycine\n\n> <Abbrev>\nGly\n\n> <Mnemonic>\nG\n\n$$$$\n',
  H: '\nGenerated by Molecular Materials Informatics\n\n 12 12  0  0  0  0  0  0  0  0999 V2000\n   -8.4232    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.4232    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.7222    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.1242    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.0213    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.7222    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.4232    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.0475    4.4955    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7961    3.2002    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.2664    3.5081    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.0492    5.6069    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -12.3203    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n 10  9  1  0  0  0  0\n  9  8  1  0  0  0  0\n  8 11  2  0  0  0  0\n 11  7  1  0  0  0  0\n  7 10  2  0  0  0  0\n  5 12  1  0  0  0  0\nM  END\n> <Name>\nhistidine\n\n> <Abbrev>\nHis\n\n> <Mnemonic>\nH\n\n$$$$\n',
  I: '\nGenerated by Molecular Materials Informatics\n\n 10  9  0  0  0  0  0  0  0  0999 V2000\n   -6.7216    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7216    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.0206    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.4226    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.3197    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.0206    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7216    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.3196    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7214    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.6187    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  6  8  1  0  0  0  0\n  7  9  1  0  0  0  0\n  5 10  1  0  0  0  0\nM  END\n> <Name>\nisoleucine\n\n> <Abbrev>\nIle\n\n> <Mnemonic>\nI\n\n$$$$\n',
  K: '\nGenerated by Molecular Materials Informatics\n\n 11 10  0  0  0  0  0  0  0  0999 V2000\n   -7.8553    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8553    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.1543    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.5563    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.4534    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.1543    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8553    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8552    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.5561    2.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.5561    1.2500    0.0000 N   0  3  0  0  0  0  0  0  0  0  0  0\n  -11.7524    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  9  1  0  0  0  0\n  9 10  1  0  0  0  0\n  5 11  1  0  0  0  0\nM  CHG  1  10   1\nM  END\n> <Name>\nlysine\n\n> <Abbrev>\nLys\n\n> <Mnemonic>\nK\n\n$$$$\n',
  L: '\nGenerated by Molecular Materials Informatics\n\n 10  9  0  0  0  0  0  0  0  0999 V2000\n   -7.5010    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2020    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0991    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5008    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2019    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.3981    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  7  9  1  0  0  0  0\n  5 10  1  0  0  0  0\nM  END\n> <Name>\nleucine\n\n> <Abbrev>\nLeu\n\n> <Mnemonic>\nL\n\n$$$$\n',
  M: '\nGenerated by Molecular Materials Informatics\n\n 10  9  0  0  0  0  0  0  0  0999 V2000\n   -7.5010    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2020    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0991    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5008    3.5000    0.0000 S   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2018    2.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.3981    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  9  1  0  0  0  0\n  5 10  1  0  0  0  0\nM  END\n> <Name>\nmethionine\n\n> <Abbrev>\nMet\n\n> <Mnemonic>\nM\n\n$$$$\n',
  N: '\nGenerated by Molecular Materials Informatics\n\n 10  9  0  0  0  0  0  0  0  0999 V2000\n   -7.5010    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2020    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.0991    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.8000    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5010    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.5008    3.5000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.2019    5.7500    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.3981    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  7  9  2  0  0  0  0\n  5 10  1  0  0  0  0\nM  END\n> <Name>\nasparagine\n\n> <Abbrev>\nAsn\n\n> <Mnemonic>\nN\n\n$$$$\n',
  P: '\nGenerated by Molecular Materials Informatics\n\n  9  9  0  0  0  0  0  0  0  0999 V2000\n   -5.7561    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.7561    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.0551    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -4.4571    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.2122    5.7621    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6806    5.4455    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.4222    7.8579    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.4306    6.7446    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.7323    9.3255    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  6  5  1  0  0  0  0\n  3  5  1  6  0  0  0\n  3  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  6  1  0  0  0  0\n  7  9  1  0  0  0  0\nM  END\n> <Name>\nproline\n\n> <Abbrev>\nPro\n\n> <Mnemonic>\nP\n\n$$$$\n',
  Q: '\nGenerated by Molecular Materials Informatics\n\n 11 10  0  0  0  0  0  0  0  0999 V2000\n   -7.3829    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3829    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6819    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.0839    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.9810    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6819    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3829    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.3828    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.0837    2.7500    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.6818    2.7500    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2800    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  9  1  0  0  0  0\n  8 10  2  0  0  0  0\n  5 11  1  0  0  0  0\nM  END\n> <Name>\nglutamine\n\n> <Abbrev>\nGln\n\n> <Mnemonic>\nQ\n\n$$$$\n',
  R: '\nGenerated by Molecular Materials Informatics\n\n 13 12  0  0  0  0  0  0  0  0999 V2000\n  -11.2850    5.8016    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2850    7.3016    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -12.5840    5.0517    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.9860    5.0516    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -13.8830    5.8017    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -12.5840    3.5516    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2849    2.8015    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2849    1.3015    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.9859    0.5516    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.9859   -0.9483    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.2850   -1.6984    0.0000 N   0  3  0  0  0  0  0  0  0  0  0  0\n   -8.6869   -1.6983    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -15.1821    5.0517    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  8  9  1  0  0  0  0\n  9 10  1  0  0  0  0\n 10 11  2  0  0  0  0\n 10 12  1  0  0  0  0\n  5 13  1  0  0  0  0\nM  CHG  1  11   1\nM  END\n> <Name>\narginine\n\n> <Abbrev>\nArg\n\n> <Mnemonic>\nR\n\n$$$$\n',
  S: '\nGenerated by Molecular Materials Informatics\n\n  8  7  0  0  0  0  0  0  0  0999 V2000\n   -6.8514    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.8514    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.1504    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.5525    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.4496    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.1504    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.8514    5.0000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.7486    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  5  8  1  0  0  0  0\nM  END\n> <Name>\nserine\n\n> <Abbrev>\nSer\n\n> <Mnemonic>\nS\n\n$$$$\n',
  T: '\nGenerated by Molecular Materials Informatics\n\n  9  8  0  0  0  0  0  0  0  0999 V2000\n   -6.4906    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.4906    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.7896    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.1916    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.0887    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.7896    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.0887    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.4906    5.0000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.3877    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  6  0  0  0\n  3  6  1  0  0  0  0\n  6  7  1  0  0  0  0\n  6  8  1  6  0  0  0\n  5  9  1  0  0  0  0\nM  END\n> <Name>\nthreonine\n\n> <Abbrev>\nThr\n\n> <Mnemonic>\nT\n\n$$$$\n',
  Y: '\nGenerated by Molecular Materials Informatics\n\n 14 14  0  0  0  0  0  0  0  0999 V2000\n   -9.3567    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.3567    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.6557    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.0577    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.9548    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.6557    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.3567    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.3567    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.0577    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.0577    2.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7586    3.5000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7586    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.4596    2.7500    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -13.2538    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  7  8  1  0  0  0  0\n  7  9  2  0  0  0  0\n  8 10  2  0  0  0  0\n 10 11  1  0  0  0  0\n 11 12  2  0  0  0  0\n 12  9  1  0  0  0  0\n 11 13  1  0  0  0  0\n  5 14  1  0  0  0  0\nM  END\n> <Name>\ntyrosine\n\n> <Abbrev>\nTyr\n\n> <Mnemonic>\nY\n\n$$$$\n',
  V: '\nGenerated by Molecular Materials Informatics\n\n  9  8  0  0  0  0  0  0  0  0999 V2000\n   -6.4906    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.4906    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.7896    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -5.1916    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.0887    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.7896    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.0887    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.4906    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.3877    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  6  8  1  0  0  0  0\n  5  9  1  0  0  0  0\nM  END\n> <Name>\nvaline\n\n> <Abbrev>\nVal\n\n> <Mnemonic>\nV\n\n$$$$\n',
  W: '\nGenerated by Molecular Materials Informatics\n\n 16 17  0  0  0  0  0  0  0  0999 V2000\n   -9.1949    8.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.1949    9.5000    0.0000 O   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.4939    7.2500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.8959    7.2500    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  -11.7930    8.0000    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -10.4939    5.7500    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.1949    5.0000    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -9.4755    2.2797    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.7255    0.9806    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -8.7255    3.5787    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.2255    0.9806    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.4755    2.2797    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.2255    3.5787    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -7.9866    5.8824    0.0000 C   0  0  0  0  0  0  0  0  0  0  0  0\n   -6.7662    5.0065    0.0000 N   0  0  0  0  0  0  0  0  0  0  0  0\n  -13.0920    7.2501    0.0000 X   0  0  0  0  0  0  0  0  0  0  0  0\n  1  2  2  0  0  0  0\n  1  3  1  0  0  0  0\n  1  4  1  0  0  0  0\n  3  5  1  0  0  0  0\n  3  6  1  6  0  0  0\n  6  7  1  0  0  0  0\n  8  9  1  0  0  0  0\n  8 10  2  0  0  0  0\n  9 11  2  0  0  0  0\n 11 12  1  0  0  0  0\n 12 13  2  0  0  0  0\n 13 10  1  0  0  0  0\n 10  7  1  0  0  0  0\n  7 14  2  0  0  0  0\n 14 15  1  0  0  0  0\n 15 13  1  0  0  0  0\n  5 16  1  0  0  0  0\nM  END\n> <Name>\ntryptophan\n\n> <Abbrev>\nTrp\n\n> <Mnemonic>\nW\n\n$$$$\n',
};

Object.freeze(customAminoAcidUnmodifiedCodeOptions);
Object.freeze(customAminoAcidStructures);
