import React from 'react'
import DataType from '@/models/DataType.js'

export default function DataTypeSelect(props) {
  const { value, onChange, readOnly, uneditable, disabled, disabledText, options } = props

  const title = disabled ? disabledText : ''

  if (uneditable) {
    return null
  } else {
    return (
      <select
        name="data_type_name"
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        title={title}
      >
        {options.map(function (dataTypeName) {
          return (
            <option key={dataTypeName} value={dataTypeName}>
              {DataType.displayNameForDataTypeName(dataTypeName)}
            </option>
          )
        })}
      </select>
    )
  }
}

DataTypeSelect.defaultProps = {
  disabledText: 'This field already contains values',
}
