/* eslint-disable no-nested-ternary */
import React from 'react';
import { A } from '@/shared/components/sanitizedTags';
import { MolImage } from '@cdd/ui-kit/lib/components/elements/molImage/v2';
import { term } from '@/shared/utils/stringUtils';
import { CDD } from '@/typedJS';

type Props = {
  mixtureData: string; // the primary mixture which is associated with the molecule
  batchData?: string; // the variant mixture associated with the batch: define only if we're displaying the delta
  linkToNameMap: Record<string, string>; // vault molecule URL to molecule name
}

export class MixtureComponentBreakout extends React.Component<Props> {
  private mainMixture: Mixtures.Mixture;
  private batchMixture: Mixtures.Mixture = null;

  constructor(props: Props) {
    super(props);

    this.mainMixture = Mixtures.Mixture.deserialise(props.mixtureData);
    if (props.batchData) {
      this.batchMixture = Mixtures.Mixture.deserialise(props.batchData);
    }
  }

  public render(): JSX.Element {
    if (this.batchMixture) {
      return this.renderDelta();
    } else {
      return this.renderMixture('Mixture Components', this.mainMixture);
    }
  }

  private renderMixture(heading: string, mixture: Mixtures.Mixture): JSX.Element {
    const tableRows: JSX.Element[] = [];

    const enumerateComponent = (mixcomp: Mixtures.MixfileComponent, sequence: Mixtures.MixfileComponent[]) => {
      const element = this.renderComponent(mixcomp, sequence, tableRows.length);
      if (element) tableRows.push(element);

      for (const child of (mixcomp.contents ?? [])) {
        enumerateComponent(child, [...sequence, mixcomp]);
      }
    };
    enumerateComponent(mixture.mixfile, []);

    return (
      <div className="subcontainer" id="properties-properties">
        <h3>{heading}</h3>
        <table>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </div>
    );
  }

  private renderDelta(): JSX.Element {
    const makeComponentHash = (mixcomp: Mixtures.MixfileComponent): string => {
      const bits: string[] = [];
      for (const [key, val] of Object.entries(mixcomp)) {
        if (val == null || (Array.isArray(val) && val.length === 0) || (typeof val == 'object' && Object.keys(val).length === 0)) continue;
        if (key == 'contents') {
          const sub = (val as Mixtures.MixfileComponent[]).map((child) => makeComponentHash(child)).sort();
          bits.push(key + '::' + sub.join(':*:'));
        } else {
          bits.push(key + '::' + JSON.stringify(val));
        }
      }
      return bits.sort().join('\n');
    };

    const mainChildHashes = (this.mainMixture.mixfile.contents ?? []).map((comp) => makeComponentHash(comp));

    const delta: Mixtures.Mixfile = { ...this.batchMixture.mixfile, contents: [] };
    for (const child of (this.batchMixture.mixfile.contents || [])) {
      const hash = makeComponentHash(child);
      if (!mainChildHashes.includes(hash)) {
        delta.contents.push(child);
      }
    }
    if (delta.contents.length == 0) return null; // it's the same, show nothing

    return this.renderMixture('Differing Components', new Mixtures.Mixture(delta));
  }

  private renderComponent(mixcomp: Mixtures.MixfileComponent, sequence: Mixtures.MixfileComponent[], pos: number): JSX.Element {
    const { linkToNameMap } = this.props;
    const vaultLink = mixcomp.links?.vaultMolecule as string;
    const vaultBatch = mixcomp.identifiers?.vaultBatch as string;

    let formula = mixcomp.formula, weight: number = null;
    if (!formula) {
      const combined = this.deriveCombinedFormulae(mixcomp);
      formula = combined?.formula;
      weight = combined?.weight;
    }

    if (!mixcomp.name && !formula && !mixcomp.molfile && !vaultLink) return null;

    let structure: JSX.Element;
    if (mixcomp.molfile) {
      structure = (
        <div style={{ border: '1px solid #C0C0C0', backgroundColor: '#F0F0F0', padding: '4px' }}>
          <MolImage
            mol={mixcomp.molfile}
            width={140}
            height={140}
            options={{ angstromToPixels: 15 }}
            structureRenderingMnemonics={CDD.debug?.structureRenderingMnemonics}
          />
        </div>
      );
    } else {
      structure = (
        <div style={{ display: 'inline-block', border: '1px dashed #C0C0C0', borderRadius: '5px', padding: '1em' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'calc(152px - 2em)', height: 'calc(152px - 2em)' }}>
            <div>(no structure)</div>
          </div>
        </div>
      );
    }

    const details: JSX.Element[] = [];
    const lineKey = () => `linekey-${details.length}`;

    const compName = mixcomp.name;
    const vaultName = vaultLink && linkToNameMap ? linkToNameMap[vaultLink] : null;
    if (compName && compName != vaultName) {
      details.push((
        <div key={lineKey()} className="name">
          {compName}
        </div>
      ));
    }

    if (vaultName) {
      details.push((
        <div key={lineKey()} className="vaultLink">
          <A href={vaultLink} target="_blank" rel="noopener noreferrer">
            {vaultName}
          </A>
        </div>
      ));
    }

    if (vaultBatch) {
      details.push((
        <div key={lineKey()} className="vaultBatch">
          {term('batch', true)}: {vaultBatch}
        </div>
      ));
    }

    for (const synonym of (mixcomp.synonyms || [])) {
      details.push((
        <div key={lineKey()} className="synonym">
          {synonym}
        </div>
      ));
    }

    if (mixcomp.description) {
      details.push((
        <div key={lineKey()} className="description">
          {mixcomp.description}
        </div>
      ));
    }

    const quantity = this.formatMixtureSequence(mixcomp, sequence);
    if (quantity) {
      details.push((
        <div key={lineKey()} className="quantityFormat">
          {quantity}
        </div>
      ));
    }

    const { molfile } = mixcomp;
    if (!molfile) {
      const { smiles, inchi } = mixcomp;
      if (smiles) {
        details.push((
          <div key={lineKey()} className="smiles">
            {smiles}
          </div>
        ));
      }
      if (inchi) {
        details.push((
          <div key={lineKey()} className="inchi">
            {inchi}
          </div>
        ));
      }
    }

    if (molfile || formula) {
      const fmw = this.formatMolecularFormulaWeight(molfile, formula, weight);
      fmw && details.push((
        <div key={lineKey()}>
          {fmw}
        </div>
      ));
    }

    for (const [key, val] of Object.entries(mixcomp.identifiers || {})) {
      if (key == 'vaultBatch') continue;
      details.push((
        <div key={lineKey()}>
          <b>{key}</b>:{' '}
          {Array.isArray(val) ? val.join(', ') : val}
        </div>
      ));
    }

    for (const [key, val] of Object.entries(mixcomp.links || {})) {
      if (key == 'vaultMolecule') continue;
      const links = (Array.isArray(val) ? val : [val]).map((url, idx) => (
        <React.Fragment key={`key-${idx}`}>
          {idx > 0 ? ', ' : ''}
          <A href={vaultLink} target="_blank" rel="noopener noreferrer">{url}</A>
        </React.Fragment>
      ));

      details.push((
        <div key={lineKey()}>
          <b>{key}</b>:{' '}
          {links}
        </div>
      ));
    }

    return (
      <tr key={`rowkey-${pos}`}>
        <td key="structure" className="mixtureProperties-structurecell">
          {structure}
        </td>
        <td key="details" className="mixtureProperties-detailcell">
          {details}
        </td>
      </tr>
    );
  }

  private formatMixtureSequence(mixcomp: Mixtures.MixfileComponent, sequence: Mixtures.MixfileComponent[]): string {
    const endstr = this.formatMixtureQuantity(mixcomp);
    if (!endstr) return null;

    const items: string[] = [];
    for (const compseq of sequence) {
      const str = this.formatMixtureQuantity(compseq);
      if (str) items.push(str);
    }

    return [...items, endstr].join(', ');
  }

  private formatMixtureQuantity(mixcomp: Mixtures.MixfileComponent): string {
    const toPrec = (value: number, sigfig: number): string => {
      if (value == null) return '';
      let str = value.toPrecision(sigfig);
      if (str.indexOf('.') > 0) {
        while (str.endsWith('0')) str = str.substring(0, str.length - 1);
        if (str.endsWith('.')) str = str.substring(0, str.length - 1);
      }
      return str;
    };

    const ratio = mixcomp.ratio;
    if (ratio) {
      if (!Array.isArray(ratio) || ratio.length != 2 || !ratio[0] || !ratio[1]) return null;
      return `${toPrec(ratio[0], 3)}/${toPrec(ratio[1], 3)}`;
    }

    const { relation, quantity, error, units } = mixcomp;
    if (quantity == null) return null;

    let str = '';
    if (relation && relation != '=') {
      str = relation;
      if (relation == '>=') {
        str = '\u{2265}';
      } else if (relation == '<=') {
        str = '\u{2264}';
      }
    }

    if (Array.isArray(quantity)) {
      str += `${toPrec(quantity[0], 3)} - ${toPrec(quantity[1], 3)}`;
    } else {
      str += toPrec(quantity, 3);
    }

    if (error) {
      str += ` \u{00B1} ${toPrec(error, 3)}`;
    }

    if (units) {
      if (!units.startsWith('%')) str += ' ';
      str += units;
    }

    return str;
  }

  private formatMolecularFormulaWeight(molfile: string, formula: string, weight: number): JSX.Element {
    if (!formula) {
      const mol = WebMolKit.MoleculeStream.readUnknown(molfile);
      if (mol?.numAtoms > 0) {
        formula = WebMolKit.MolUtil.molecularFormula(mol, false);
      }
    }
    if (!formula) return null;

    const chunks: JSX.Element[] = [];
    const chunkKey = () => `chunkkey-${chunks.length}`;

    const regexElement = /^([A-Z][a-z]?)(\d*)(.*?)$/;
    const regexNumber = /^(\d+)(.*?)$/;
    const regexSpacer = /^([\s.\u{00B7}\u{2192}]+)(.*?)$/u;
    let residue = formula;
    let calcWeight = 0;
    while (residue.length > 0) {
      let groups = residue.match(regexElement);
      if (groups) {
        const [atom, count, remain] = [groups[1], groups[2], groups[3]];
        const atno = WebMolKit.Molecule.elementAtomicNumber(atom);
        if (atno > 0) {
          const num = count ? parseInt(count) : 1;
          if (calcWeight != null) {
            calcWeight += WebMolKit.Chemistry.NATURAL_ATOMIC_WEIGHTS[atno] * num;
          }
          chunks.push(<React.Fragment key={chunkKey()}>{atom}</React.Fragment>);
          if (count) {
            chunks.push(<sub key={chunkKey()}>{count}</sub>);
          }
          residue = remain;
          continue;
        }
      }

      groups = residue.match(regexNumber) || residue.match(regexSpacer);
      if (groups) {
        const [cabbage, remain] = [groups[1], groups[2]];
        chunks.push(<React.Fragment key={chunkKey()}>{cabbage}</React.Fragment>);
        residue = remain;
        calcWeight = null;
        continue;
      }

      calcWeight = null;
      chunks.push(<React.Fragment key={chunkKey()}>{residue.substring(0, 1)}</React.Fragment>);
      residue = residue.substring(1);
    }

    weight = weight ?? calcWeight;
    if (weight > 0) {
      chunks.push(<React.Fragment key={chunkKey()}> [{weight.toFixed(3)} g/mol]</React.Fragment>);
    }

    return <>{chunks}</>;
  }

  private deriveCombinedFormulae(comp: Mixtures.MixfileComponent): {formula: string, weight: number} {
    if (!(comp.contents?.length >= 2)) return null;

    const numerators: number[] = [];
    let denominator: number = null;
    for (const sub of comp.contents) {
      if (sub.ratio) {
        const [n, d] = sub.ratio;
        if (numerators.length == 0) {
          denominator = d;
        } else if (denominator == null || denominator != d) {
          return null;
        }
        numerators.push(n);
        denominator = d;
      } else {
        if (sub.quantity != null) return null;
        if (denominator != null) return null;
        numerators.push(1);
      }
      if (!sub.formula && !sub.molfile) return null;
    }

    const blocks: string[] = [];
    const counts = new Map<string, number>();
    const regexElement = /^([A-Z][a-z]?)(\d*)(.*?)$/;
    for (let n = 0; n < comp.contents.length; n++) {
      let formula = comp.contents[n].formula;
      if (!formula) {
        const mol = WebMolKit.MoleculeStream.readUnknown(comp.contents[n].molfile);
        if (!mol || mol.numAtoms == 0) return null;
        formula = WebMolKit.MolUtil.molecularFormula(mol, false);

        let residue = formula;
        while (residue.length > 0) {
          const groups = residue.match(regexElement);
          if (groups) {
            const [atom, strCount, remain] = [groups[1], groups[2], groups[3]];
            let count = strCount.length == 0 ? 1 : parseInt(strCount);
            if (Number.isNaN(count)) count = 1;
            counts.set(atom, (counts.get(atom) || 0) + count * numerators[n]);
            residue = remain;
            continue;
          }
          residue = residue.substring(1);
        }
      }

      blocks.push((numerators[n] > 1 ? numerators[n].toString() : '') + formula);
    }

    const allAtoms = Array.from(counts.keys()).sort((a1, a2) => {
      const p1 = a1 == 'C' ? -2 : a1 == 'H' ? -1 : WebMolKit.Molecule.elementAtomicNumber(a1);
      const p2 = a2 == 'C' ? -2 : a2 == 'H' ? -1 : WebMolKit.Molecule.elementAtomicNumber(a2);
      return p1 - p2;
    });
    let allFormula = '', allWeight = 0;
    for (const atom of allAtoms) {
      const count = counts.get(atom);
      allFormula += atom + (count > 1 ? count.toString() : '');
      const atno = WebMolKit.Molecule.elementAtomicNumber(atom);
      if (atno > 0 && allWeight != null) {
        allWeight += WebMolKit.Chemistry.NATURAL_ATOMIC_WEIGHTS[atno] * count;
      } else {
        allWeight = null;
      }
    }

    return { formula: blocks.join(' \u{00B7} ') + ' \u{2192} ' + allFormula, weight: allWeight };
  }
}
