import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import constants from 'javascripts/constants.js'
const { ELN_SEARCH_MAX_SIZE, ELN_SEARCH_LIMIT } = constants

export default class EntriesCount extends React.Component {
  render() {
    const { elnEntries, status } = this.props
    const { trueCount } = status
    const count = elnEntries.all.size

    const entryStr = count == 1
      ? Terminology.dictionary['entry']
      : Terminology.dictionary['entry.other']

    const trueCountStr = trueCount >= ELN_SEARCH_MAX_SIZE ? `${trueCount}+` : `${trueCount}`
    const countStr = trueCount > ELN_SEARCH_LIMIT ? `${count} of ${trueCountStr}` : count

    return (
      <p className="search-bar__entries_count">
        Showing {countStr} {entryStr}
      </p>
    )
  }
}
