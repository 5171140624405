export const READ_AUDIT_TRAIL_EVENTS = 'eln/entry/auditTrailEvents/read'
export const CACHE_AUDIT_TRAIL_EVENTS = 'eln/entry/auditTrailEvents/cache'

export const readAuditTrailEvents = () => ({
  type: READ_AUDIT_TRAIL_EVENTS,
  payload: {},
})

export const cacheAuditTrailEvents = (auditTrailEvents) => ({
  type: CACHE_AUDIT_TRAIL_EVENTS,
  payload: { auditTrailEvents },
})
