import { StructureFormat } from '@/components/StructureEditor/StructureEditorTypes';
import { CDD } from '@/typedJS';

export async function openStructureEditor(editor: HTMLElement, structure = null, structureFormat = StructureFormat.MRV, skipInsert = false, moleculesOnly = true) {
  editor.blur();
  return CDD.StructureEditor.openMarvin4JSWithPromise(structure, 'auto', { structureFormat, skipInsert, moleculesOnly });
}

export async function openCDDElnStyleStructureEditor(structure = null, structureFormat = StructureFormat.MRV, skipInsert = false, moleculesOnly = true) {
  return CDD.StructureEditor.openMarvin4JSWithPromise(structure, 'eln', { structureFormat, skipInsert, moleculesOnly });
}
