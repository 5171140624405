import { StringOrNumber } from '@/types';

export type SortableTreeViewNode = {
  id: StringOrNumber;
  parent_id?: StringOrNumber;
  parent?: SortableTreeViewNode;
  children?: Array<SortableTreeViewNode>;
};

export class SortableTreeViewUtils {
  static spliceNode(nodes: Array<SortableTreeViewNode>,
    draggedNode: SortableTreeViewNode,
    dragInsert?: {
      intoNodeId?: StringOrNumber;
      index: number;
    }) {
    const cloneAndFilter = (src:Array<SortableTreeViewNode>, parent: SortableTreeViewNode = null) => {
      const result:Array<SortableTreeViewNode> = [];
      src.forEach(node => {
        if (node.id != draggedNode.id) {
          const newNode = { ...node, parent };
          if (node.children) {
            newNode.children = cloneAndFilter(node.children, node);
          }
          result.push(newNode);
        }
      });
      return result;
    };

    const result = cloneAndFilter(nodes);
    if (dragInsert) {
      const { intoNodeId, index } = dragInsert ?? {};

      const insertIntoNodes = (parentElement?: SortableTreeViewNode) => {
        if (!parentElement) {
          if (intoNodeId === undefined && index !== undefined) {
            result.splice(index, 0, { ...draggedNode, parent_id: null });
          } else {
            result.forEach(node => {
              insertIntoNodes(node);
            });
          }
        } else {
          if (parentElement.id == intoNodeId) {
            parentElement.children = parentElement.children ?? [];
            parentElement.children.splice(index, 0, { ...draggedNode, parent_id: parentElement.id });
          } else {
            (parentElement.children ?? []).forEach(node => {
              insertIntoNodes(node);
            });
          }
        }
      };
      insertIntoNodes();
    }
    return result;
  }

  // just get a simple array indicating the hierarchy, used for comparison of before and after drop
  static computeSimpleHierarchy(nodes: Array<SortableTreeViewNode>) {
    const addLevel = (node: SortableTreeViewNode, destination) => {
      destination.push('' + node.id);
      if (node.children) {
        const leaf = [];
        destination.push(leaf);
        node.children.forEach(child => {
          addLevel(child, leaf);
        });
      }
    };
    const result = [];
    nodes.forEach(node => {
      addLevel(node, result);
    });
    return result;
  }

  static computeMap(nodes: Array<SortableTreeViewNode>) {
    const result: Record<StringOrNumber, SortableTreeViewNode> = {};

    const addLevel = (node: SortableTreeViewNode) => {
      result['' + node.id] = node;
      if (node.children) {
        node.children.forEach(child => {
          addLevel(child);
        });
      }
    };
    nodes.forEach(node => {
      addLevel(node);
    });

    return result;
  }

  static findNodeById(nodes: Array<SortableTreeViewNode>, id: StringOrNumber): SortableTreeViewNode | undefined {
    for (const node of nodes) {
      if (('' + node.id) == ('' + id)) {
        return node;
      }
      if (node.children) {
        const result = this.findNodeById(node.children, id);
        if (result) {
          return result;
        }
      }
    }
  }

  static getExpandedNodes(id?: StringOrNumber, nodes: Array<SortableTreeViewNode> = []) {
    const lookup = this.computeMap(nodes);

    const result = new Set<string>(['' + id]);
    let node = lookup['' + id];
    while (node?.parent_id) {
      result.add('' + node.parent_id);
      node = lookup['' + node.parent_id];
    }

    return Array.from(result);
  }
}
