export const ADD_RESULT_COLUMN = 'eln/entries/result_columns/add'
export const REMOVE_RESULT_COLUMN = 'eln/entries/result_columns/remove'
export const REPLACE_RESULT_COLUMNS = 'eln/entries/result_columns/replace'
export const UPDATE_SORT_COLUMN = 'eln/entries/result_columns/update_sort_column'
export const SAVE_RESULT_COLUMNS = 'eln/entries/result_columns/save'
export const CACHE_RESULT_COLUMNS = 'eln/entries/result_columns/cache'

export function addResultColumn({ fieldDefinition }) {
  return {
    type: ADD_RESULT_COLUMN,
    payload: { fieldDefinition },
  }
}

export function removeResultColumn({ fieldDefinition }) {
  return {
    type: REMOVE_RESULT_COLUMN,
    payload: { fieldDefinition },
  }
}

export function replaceResultColumns({ fieldDefinitions }) {
  return {
    type: REPLACE_RESULT_COLUMNS,
    payload: { fieldDefinitions },
  }
}

export function updateSortColumn({ selectValue, direction }) {
  return {
    type: UPDATE_SORT_COLUMN,
    payload: { selectValue, direction },
  }
}

export function saveResultColumns() {
  return {
    type: SAVE_RESULT_COLUMNS,
  }
}

export function cacheResultColumns({ resultColumns }) {
  return {
    type: CACHE_RESULT_COLUMNS,
    payload: { resultColumns },
  }
}
