import React from 'react'

import CriterionJunction from './CriterionJunction'
import CriterionField from './CriterionField'
import QueryForm from './QueryForm'
import { RemoveButton } from '@/shared/components'

export default class KeywordCriterion extends React.PureComponent {
  onRemoveCriterionButtonClick = (event) => {
    const { criterionIndex, onClickRemoveCriterion } = this.props
    return onClickRemoveCriterion(criterionIndex, event)
  }

  renderCriterionJunction = () => {
    const { onJunctionChange, criterionIndex, molecule_criterion } = this.props

    if (criterionIndex <= 0) {
      return
    }

    const { junction } = molecule_criterion

    return (
      <CriterionJunction
        selectedJunction={junction}
        onJunctionChange={onJunctionChange}
        criterionIndex={criterionIndex}
      />
    )
  }

  renderCriterionCondition = () => {
    const { fields, onFieldChange, onQueryChange, onSecondQueryChange, onQueryStyleChange, onSubmit, criterionIndex, molecule_criterion } = this.props
    const { query, second_query, query_style, path, field, data_type_name } = molecule_criterion

    return (
      <React.Fragment>
        <CriterionField
          className="molecule_criteria__select molecule_criteria__field"
          fields={fields}
          onFieldChange={onFieldChange}
          criterionIndex={criterionIndex}
          path={path}
          field={field}
          data_type_name={data_type_name}
        />

        <QueryForm
          onQueryChange={onQueryChange}
          onSecondQueryChange={onSecondQueryChange}
          onQueryStyleChange={onQueryStyleChange}
          onSubmit={onSubmit}
          criterionIndex={criterionIndex}
          field={field}
          data_type_name={data_type_name}
          query={query}
          second_query={second_query}
          query_style={query_style}
        />
      </React.Fragment>
    )
  }

  renderCriterionRemoveButton = () => {
    return (
      <RemoveButton
        onClick={this.onRemoveCriterionButtonClick}
        text={'Remove term'}
      />
    )
  }

  render() {
    return (
      <div className="molecule_criterion">
        <div className="criterion_junction">
          {this.renderCriterionJunction()}
        </div>

        <div className="condition">
          {this.renderCriterionCondition()}
        </div>

        <div className="delete_condition">
          {this.renderCriterionRemoveButton()}
        </div>
      </div>
    )
  }
}
