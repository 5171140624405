/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, TextInputDef } from '@/shared/components/DDForm/types';
import { getElementAttributes, getInputProps } from '../muiRenderers';
import { TextFieldCursorFix } from '../TextFieldCursorFix';

/**
 * Render a field as a text input
 * @param field
 * @returns
 */
export const ddMuiRenderText: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as TextInputDef;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (props.blacklistedCharacters?.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(field, e.currentTarget.value);
  };

  const value = getValue(field);
  const error = props.showErrors && formErrors && formErrors[field.key];

  const inputProps = getInputProps(field, {
    autoComplete: field.autocomplete ? 'on' : 'off',
    'data-1p-ignore': (field.autocomplete === false), // https://1password.community/discussion/comment/677269/#Comment_677269
  });
  return (
    <TextFieldCursorFix
      key={field.renderKey}
      {...getElementAttributes(field, props)}
      label={field.label}
      inputProps={inputProps}
      error={!!error}
      helperText={error}
      onKeyDown={handleKeyPress}
      value={value || ''}
      onChange={handleChange}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled === true}
    />
  );
};
