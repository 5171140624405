/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import { TemplateEditorDialog } from './TemplateEditorDialog';
import { TemplateNewDialog } from './TemplateNewDialog';
import { OntologyTemplate } from '../data/templates';
import { initializeOntologies } from '../data/utils';
import { TemplateServices } from '../data/TemplateServices';
import Icon from '@/shared/components/Icon.jsx';
import { Tooltip } from '@mui/material';
import { ModalUtils } from '@/shared/utils/modalUtils';

interface OntologyTemplateRecord {
  json: OntologyTemplate,
  isPublic: boolean,
  isWriteable: boolean,
}

type Props = {
  headerProps: { to_context_param: string, account_id: number, is_superuser: boolean },
  initTemplates: OntologyTemplateRecord[],
};
type State = {
  isEditing: boolean,
  templateList: OntologyTemplate[],
  isNewOpen: boolean,
  isEditorOpen: boolean,
  editingTemplateIsNew: boolean,
  editingTemplate?: OntologyTemplate,
  editingReadOnly?: boolean,
  editingCanMakePublic?: boolean,
  handleDeleteTemplate?: (pfx: string) => void,
  handleMakePublic?: (pfx: string) => void,
  draggingActive: boolean,
  bootTemplate: OntologyTemplate,
}

export default class TemplateManager extends React.Component<Props, State> {
  private svc: TemplateServices;

  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false,
      templateList: null,
      isEditorOpen: false,
      editingTemplateIsNew: false,
      isNewOpen: false,
      handleDeleteTemplate: props.headerProps.is_superuser ? this.handleDeleteTemplate : null,
      handleMakePublic: props.headerProps.is_superuser ? this.handleMakePublic : null,
      draggingActive: false,
      bootTemplate: null,
    };
    this.svc = new TemplateServices(props.headerProps.to_context_param, props.headerProps.account_id);

    (async () => {
      await initializeOntologies();

      this.setState({ templateList: props.initTemplates.map((rec) => rec.json) });
    })();
  }

  private renderEditButton() {
    const { headerProps: { is_superuser } } = this.props;
    const { isEditing } = this.state;

    if (isEditing) {
      return null;
    } else if (is_superuser) {
      return (
        <a
          href="#"
          onClick={() => this.setState({ isEditing: true })}
        >
          <Icon forceSize="16" icon="pencil" />
          Add/Edit Templates
        </a>
      );
    } else {
      return (
        <Tooltip
          title="Contact CDD Support to modify templates"
          arrow
        >
          <a
            href="#"
            className="disabled"
          >
            <Icon forceSize="16" icon="pencil" />
            Add/Edit Templates
          </a>
        </Tooltip>
      );
    }
  }

  public render(): JSX.Element {
    const { isEditing, templateList, draggingActive } = this.state;
    if (templateList == null) return (<>Loading templates</>);

    const describeStatus = (pfx: string): JSX.Element => {
      const details: string[] = [];
      const rec = this.props.initTemplates.find((rec) => rec.json.schemaPrefix == pfx);
      if (rec?.isPublic) details.push('public');
      if (rec?.isWriteable == false) details.push('read-only');
      return <i>{details.join(', ')}</i>;
    };

    const renderTemplates = (): JSX.Element => {
      if (templateList.length == 0) return (<>No available templates.</>);
      return (
        <div className="OntologyTemplate-schemagrid">
          {
            templateList.map((template, idx) => (
              <React.Fragment key={`template-${idx}`}>
                <div style={{ gridRow: idx + 1, gridColumn: 'title' }}>
                  <Tooltip arrow title={template.root.descr || '(no description)'}>
                    {
                      isEditing ? (
                        <a href="#" onClick={() => this.handleEditTemplate(template)}><b>{template.root.name}</b></a>
                      ) : (
                        <b>{template.root.name}</b>
                      )
                    }
                  </Tooltip>
                </div>
                <div style={{ gridRow: idx + 1, gridColumn: 'uri' }}>
                  <div>{template.schemaPrefix}</div>
                </div>
                <div style={{ gridRow: idx + 1, gridColumn: 'permission' }}>
                  {describeStatus(template.schemaPrefix)}
                </div>
              </React.Fragment>
            ))
          }
        </div>
      );
    };

    const dragClass = isEditing && draggingActive ? 'OntologyTemplate-draginto' : null;

    return (
      <div
        className={dragClass}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
        >
        <div className="OntologyTemplate-oppositesides">
          <h2>Ontology Templates</h2>
          <div>{this.renderEditButton()}</div>
        </div>
        <div>
          {renderTemplates()}
        </div>
        {
          isEditing ? (
            <div>
              <a href="#" onClick={() => this.handleCreateNew()}>
                <Icon forceSize="16" icon="add"/>
                Create new template
              </a>
            </div>
          ) : null
        }
        <TemplateNewDialog
          isOpen={this.state.isNewOpen}
          svc={this.svc}
          templateList={this.state.templateList}
          bootTemplate={this.state.bootTemplate}
          onDialogCancel={this.handleNewDialogCancel}
          onDialogSubmit={(template: OntologyTemplate) => this.handleNewDialogSubmit(template)}
        />
        <TemplateEditorDialog
          isOpen={this.state.isEditorOpen}
          isNewTemplate={this.state.editingTemplateIsNew}
          svc={this.svc}
          template={this.state.editingTemplate}
          readOnly={this.state.editingReadOnly}
          canMakePublic={this.state.editingCanMakePublic}
          onDialogCancel={this.handleEditorDialogCancel}
          onDialogSubmit={this.handleEditorDialogSubmit}
          onDeleteTemplate={this.state.handleDeleteTemplate}
          onMakePublic={this.state.handleMakePublic}
        />
      </div>
    );
  }

  private handleCreateNew = (bootTemplate?: OntologyTemplate): void => {
    this.setState({ isNewOpen: true, bootTemplate });
  };

  private handleEditTemplate = (template: OntologyTemplate): void => {
    const rec = this.props.initTemplates.find((rec) => rec.json.schemaPrefix == template.schemaPrefix);
    this.setState({
      isEditorOpen: true,
      editingTemplateIsNew: false,
      editingTemplate: template,
      editingReadOnly: rec && !rec.isWriteable,
      editingCanMakePublic: this.props.headerProps.is_superuser && (!rec || !rec.isPublic),
    });
  };

  private handleNewDialogCancel = (): void => {
    this.setState({ isNewOpen: false });
  };

  private handleNewDialogSubmit = (template: OntologyTemplate): void => {
    this.setState({ isNewOpen: false, bootTemplate: null, isEditorOpen: true, editingTemplateIsNew: true, editingTemplate: template, editingReadOnly: false });
  };

  private handleEditorDialogCancel = (): void => {
    this.setState({ isEditorOpen: false });
  };

  private handleEditorDialogSubmit = (template: OntologyTemplate): void => {
    (async () => {
      const details = await this.svc.submitTemplate(template);
      if (!details.valid) {
        ModalUtils.showModal('Unable to submit template: ' + details.reason, {});
        return;
      }
      const { templateList } = this.state;
      const idx = templateList.findIndex((look) => look.schemaPrefix == template.schemaPrefix);
      if (idx >= 0) {
        templateList[idx] = template;
      } else {
        templateList.push(template);
      }
      this.setState({ isEditorOpen: false });
    })();
  };

  private handleDeleteTemplate = (pfx: string): void => {
    const passcode = prompt('Deleting a template will disrupt any protocols that rely on it. Type in DELETE! to continue.');
    if (passcode != 'DELETE!') return;

    (async () => {
      await this.svc.deleteTemplate(pfx);
      const templateList = this.state.templateList.filter((template) => template.schemaPrefix != pfx);
      this.setState({ isEditorOpen: false, templateList });
    })();
  };

  private handleMakePublic = (pfx: string): void => {
    const passcode = prompt('Making a template public is permanent and cannot be revoked. Type in PUBLIC! to continue.');
    if (passcode != 'PUBLIC!') return;

    (async () => {
      await this.svc.makeTemplatePublic(pfx);
      window.location.reload();
    })();
  };

  private handleDragLeave = (): void => {
    this.setState({ draggingActive: false });
  };

  private handleDragOver = (event: React.DragEvent): void => {
    if (!this.state.isEditing) return;

    event.stopPropagation();
    event.preventDefault();
    this.setState({ draggingActive: true });
  };

  private handleDrop = (event: React.DragEvent): void => {
    if (!this.state.isEditing) return;

    event.preventDefault();
    this.setState({ draggingActive: false });

    const attemptLaunch = (txt:string) => {
      const template = JSON.parse(txt) as OntologyTemplate;
      if (template && template.root) {
        this.handleCreateNew(template);
      } else {
        ModalUtils.showModal('Dragged content does not appear to be a template.', {});
      }
    };

    const { dataTransfer } = event;
    const txt = dataTransfer.getData('text');
    if (txt) {
      attemptLaunch(txt);
      return;
    }

    if (dataTransfer.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const txt = event.target.result.toString();
        attemptLaunch(txt);
      };
      reader.readAsText(dataTransfer.files[0]);
    }
  };
}
