import theme from '@/shared/components/theme';

export const filter = {
  nextStyle: {
    fontSize: '0.750rem',
    height: 42,
    width: 150,
  },
  nextInput: {},
  style: {
    fontSize: '0.750rem',
    height: 42,
    width: 150,
  },
  iconStyle: {
    padding: 0,
    height: 48,
  },
};

export const searchField = {
  iconStyle: {
    color: 'white',
    height: 16,
    width: 16,
    fontSize: '1.000rem',
  },
  buttonStyle: {
    background: theme.palette.secondary.main,
    height: 39,
    width: 39,
  },
  filterButtonStyle: {
    height: 39,
    width: 39,
  },
  secondaryIconStyle: {
    color: theme.palette.secondary.main,
    height: 16,
    width: 16,
    fontSize: '1.000rem',
  },
  secondaryButtonStyle: {
    background: 'white',
    borderRadius: 0,
    borderTop: 'none',
    borderBottom: 'none',
    height: 38,
    width: 40,
    marginTop: 2,
    marginRight: 2,
  },
};
