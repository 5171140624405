import React from 'react'
import { AddButton } from '@/shared/components'
import { FilterItemContainer } from './FilterItem'

export default class FilterFields extends React.Component {
  renderFilterItems = () => {
    const { numFilters } = this.props

    let items = []

    for (let i = 0; i < numFilters; i++) {
      items.push(
        <FilterItemContainer
          key={i}
          index={i}
        />
      )
    }

    return items
  }

  render() {
    return (
      <div className="search-bar__filters">
        {this.renderFilterItems()}
        <div className="search-bar__filters__actions">
          <AddButton onClick={this.props.addQueryFilter} text={'Add Filter'} />
        </div>
      </div>
    )
  }
}
