import React from 'react'
import {
  TableCell,
  TableSortLabel,
} from '@mui/material'

export default class ResultHeader extends React.Component {
  handleClick = () => {
    const { fieldDefinition } = this.props

    this.props.handleHeaderClick({ selectValue: fieldDefinition.selectValue })
  }

  render() {
    const { fieldDefinition, direction } = this.props
    if (!fieldDefinition) return null

    let displayValue = fieldDefinition.name || 'Unknown Field Name'
    if (!fieldDefinition.id && displayValue == 'Modified') {
      displayValue = 'Last Modified'
    }

    return (
      <TableCell
        key={fieldDefinition.id + fieldDefinition.name}
        name={fieldDefinition.name}
        className='search_results__item'
      >
        <TableSortLabel
          className='search_results__item__sort'
          active={!!direction}
          direction={direction || 'desc'}
          onClick={this.handleClick}
        >
          {displayValue}
        </TableSortLabel>
      </TableCell>
    )
  }
}
