import { RemoveButton } from '@/shared/components';
import React from 'react';

// TODO dedupe with eln entries stuff where possible
type FilterFieldsRowProps = {
  FilterField: () => JSX.Element;
  FilterStyle: () => JSX.Element;
  FilterValue: () => JSX.Element;
  FilterIcon?: () => JSX.Element;

  isRemovable: boolean;
  onRemoveClick: () => void;
};

export class FilterFieldsRow extends React.Component<FilterFieldsRowProps> {
  render() {
    const {
      FilterIcon,
      FilterField,
      FilterStyle,
      FilterValue,
      isRemovable,
      onRemoveClick,
    } = this.props;

    return (
      <div className='search-bar__filters__item'>
        <div className='search-bar__filters__icon'>
          {isRemovable
            ? (
            <RemoveButton onClick={onRemoveClick} text={''} />
              )
            : (
            <FilterIcon />
              )}
        </div>
        <div className='search-bar__filters__field'>
          <FilterField />
          <FilterStyle />
          <FilterValue />
        </div>
      </div>
    );
  }
}
