/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, RowColumnDef } from '@/shared/components/DDForm/types';
import { Stack } from '@mui/material';
import { getElementAttributes, renderElement } from '../muiRenderers';

export const getGroupRenderKey = (field: ElementDef, index = 0) => {
  return field.renderKey ??
    `${field.type}-${field.key ?? field.id ?? ''}-${(field.className ?? '').replace(/\s/g, '')}-${index}`;
};

export const ddMuiRenderSimpleGroup: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as RowColumnDef;

  return <Stack
    key={getGroupRenderKey(field)}
    direction={field.type === 'column' ? 'column' : 'row'}
    {...getElementAttributes(field, props)}
    spacing={field.spacing ?? 1}
  >
    {field.children
      .filter(child => (child.visible !== false || child.renderHidden))
      .map((child, index) => {
        const key = getGroupRenderKey(child, index);
        return renderElement(props, { ...child, renderKey: key, parent: field }, getValue, setValue, formErrors);
      })}
  </Stack>;
};
