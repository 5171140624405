import React from 'react';

import { FieldDataType, FilterStyleKey } from '@/FieldDefinitions/types';
import { KeyboardDatePickerWithIcons } from '@/shared/components';
import constants from 'javascripts/constants.js';
import moment from 'moment';
import { FilterValueSelectProps } from './FilterValueSelect.types';
const { DATE_DISPLAY_FORMAT } = constants;

export class FilterDateValue extends React.Component<
  FilterValueSelectProps<FieldDataType.Date>
> {
  // memoize handlers
  changeHandlers = {};
  errorHandlers = {};

  get filterValues() {
    return this.props.filter.field_values;
  }

  handleChange = (index) => {
    if (this.changeHandlers[index]) {
      return this.changeHandlers[index];
    }

    const result = (date) => {
      if (date && !date.format) {
        date = moment(date);
      }
      const value = date ? date.format(DATE_DISPLAY_FORMAT) : '';
      const filterValues = [...this.filterValues];

      filterValues[index] = value;
      // TODO this cant distinguish "lte with no gte" from "gte wit no lte"
      this.props.handleValueChange(filterValues);
    };

    this.changeHandlers[index] = result;
    return result;
  };

  handleError = (index) => {
    if (this.errorHandlers[index]) {
      return this.errorHandlers[index];
    }

    const result = (_error) => {
      const filterValues = [...this.filterValues];

      this.filterValues[index] = null;
      this.props.handleValueChange(filterValues);
    };

    this.errorHandlers[index] = result;
    return result;
  };

  renderDatePicker = ({ index }) => {
    const { filterValues } = this;
    const value = filterValues[index] ? filterValues[index] : null;

    return (
      <KeyboardDatePickerWithIcons
        className="datepicker filter__value__date"
        onChange={this.handleChange(index)}
        onError={this.handleError(index)}
        value={value}
      />
    );
  };

  render() {
    const filterStyle = this.props.filter.filter_style_key;

    return (
      <span>
        {this.renderDatePicker({ index: 0 })}
        {filterStyle == FilterStyleKey.FROM && (
          <React.Fragment>
            <span className="filter-to">to</span>
            {this.renderDatePicker({ index: 1 })}
          </React.Fragment>
        )}
      </span>
    );
  }
}
