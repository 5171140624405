/* eslint-disable multiline-ternary, no-nested-ternary */

import React, { CSSProperties } from 'react';
import '../Annotator/Templates/Template.sass';
import { ProtocolsStore } from './protocolsStore';
import { observer } from 'mobx-react';
import '@/shared/components/CDDForm/cddElements.sass';
import { PropertyGridData } from './PropertyGridData';
import { PropertyGridFocusPos, PropertyGridRender, SVGBlock, SVGBlockDetail, SVGBlockDetailType } from './PropertyGridRender';
import { Tooltip, Typography } from '@mui/material';
import { expandPrefix } from '@/Annotator/data/utils';
import { term } from '@/shared/utils/stringUtils';

type Props = {
  store: ProtocolsStore,
};

type State = {
  watermarkRedraw: number;
};

@observer
export class PropertyGridWidget extends React.Component<Props, State> {
  private lastStateKey = '';
  private gridData: PropertyGridData = null;
  private canvasRef: HTMLCanvasElement = null; // needed for font metrics
  private gridRender: PropertyGridRender = null;

  constructor(props) {
    super(props);
    this.state = {
      watermarkRedraw: 0,
    };
  }

  public render(): JSX.Element {
    const { store } = this.props;

    if (!store.eligibleProtocols?.length) return null; // nothing to show

    if (!this.canvasRef) {
      this.canvasRef = document.createElement('canvas');
    }

    const stateKey = JSON.stringify([
      store.ontologySchemaPrefix,
      store.filterLayers,
      store.eligibleProtocols.map((proto) => proto.id),
    ]);
    if (stateKey != this.lastStateKey) {
      this.lastStateKey = stateKey;

      this.gridData = new PropertyGridData(
        store.eligibleProtocols,
        store.fieldDefinitions,
        store.currentTemplateSchema(),
        store.filterLayers,
      );
      this.gridData.prebuild();
      this.gridRender = new PropertyGridRender(this.gridData, this.canvasRef.getContext('2d'));
      this.gridRender.arrange();
      setTimeout(() => this.buildPropertyData(stateKey), 1);
    }

    const renderedBlocks: JSX.Element[] = [];

    const blkHeader = this.gridRender.makeSVGHeader();
    if (blkHeader) renderedBlocks.push(this.renderBlock(blkHeader, 'header'));

    const blkCategory: SVGBlock[] = [];
    for (let n = 0; n < this.gridData.categoryData.length; n++) {
      const blk = this.gridRender.makeSVGCategory(n);
      if (blk) {
        renderedBlocks.push(this.renderBlock(blk, `block${n}`));
        blkCategory.push(blk);
      }
    }

    const focusPos = store.propertyGridFocusPos;
    if (focusPos) {
      renderedBlocks.push(this.renderFocus(focusPos, blkHeader, blkCategory));
    }

    return (
      <div className="AdvancedSearch-propgridouter">
        <>{renderedBlocks}</>
      </div>
    );
  }

  private renderBlock(blk: SVGBlock, pfx: string): JSX.Element {
    const { store } = this.props;

    const manufactureDetail = (detail: SVGBlockDetail, idx: number): JSX.Element => {
      const tooltip = (
        <Typography className="ProtocolAnnotator-tooltip">
          <b>{detail.label}</b>
          {detail.uri && (
            <>
              <br/>
              {expandPrefix(detail.uri)}
            </>
          )}
          {detail.linkURL && (
            <>
            <br/>
            <i>Click to open {term('protocol')}</i>
            </>
          )}
        </Typography>
      );

      const handleClick = detail.linkURL ? () => window.open(detail.linkURL, '_blank') : undefined;
      const handleMouseEnter = detail.type == SVGBlockDetailType.Cell ? () => store.setPropertyGridFocusPos(detail.focusPos) : undefined;
      const handleMouseLeave = detail.type == SVGBlockDetailType.Cell ? () => store.setPropertyGridFocusPos(null) : undefined;

      const css: CSSProperties = {
        left: `${detail.x}px`,
        top: `${detail.y}px`,
        width: `${detail.w}px`,
        height: `${detail.h}px`,
      };
      return detail.type != SVGBlockDetailType.Cell ? (
        <Tooltip key={`hoverdetail-tip-${idx}`} title={tooltip} arrow placement={detail.tipPos}>
          <div
            key={`hoverdetail-${idx}`}
            className="AdvancedSearch-propgridhover"
            style={css}
            onClick={handleClick}
            />
        </Tooltip>
      ) : (
        <div
          key={`hoverdetail-${idx}`}
          className="AdvancedSearch-propgridtransp"
          style={css}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          />
      );
    };

    return (
      <div
        key={`propertygrid-wrapper-${pfx}`}
        className="AdvancedSearch-propgridwrapper"
        style={{ position: 'relative', width: `${blk.width}px`, height: `${blk.height}px` }}
        >
        <div
          key={`propertygrid-graphic-${pfx}`}
          className="AdvancedSearch-propgridsvg"
          dangerouslySetInnerHTML={{ __html: blk.svg }}
          />
        {blk.details.map((detail, idx) => manufactureDetail(detail, idx))}
      </div>
    );
  }

  private renderFocus(focusPos: PropertyGridFocusPos, blkHeader: SVGBlock, blkCategory: SVGBlock[]): JSX.Element {
    let totalHeight = blkHeader.height;
    for (const blk of blkCategory) totalHeight += blk.height;

    const css: CSSProperties = {
      left: 0,
      top: 0,
      width: blkHeader.width,
      height: totalHeight,
      pointerEvents: 'none',
    };
    const svg = this.gridRender.makeSVGFocus(focusPos, blkHeader, blkCategory);
    return (
      <div
        key="propertygrid-focus"
        className="AdvancedSearch-propgridtransp"
        style={css}
        dangerouslySetInnerHTML={{ __html: svg }}
        />
    );
  }

  private buildPropertyData(stateKey: string): void {
    if (stateKey != this.lastStateKey) return;

    (async () => {
      const callAgain = await this.gridData.buildBlock();
      this.gridRender.arrange();
      this.setState({ watermarkRedraw: this.state.watermarkRedraw + 1 });
      if (callAgain) {
        setTimeout(() => this.buildPropertyData(stateKey), 1);
      }
    })();
  }
}
