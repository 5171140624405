import { Record } from 'immutable'
import {
  REQUEST_SENT,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  GET_NEXT_PAGE,
  UPDATE_SORT_COLUMN,
  SUBMIT_QUERY,
  UPDATE_DATA_SOURCES,
} from '../actions'

const DEFAULTS = {
  promise: null,
  type: null,
}

export class Request extends Record(DEFAULTS) {
  get cancelable() {
    return this.type !== UPDATE_DATA_SOURCES
  }

  get inProgress() {
    return this.promise !== null
  }
}

export const request = (state = new Request(), action) => {
  // We prioritize data source updates over queries over paging
  // * a data source update always cancels an in progress request
  // * a query cancels a query or a page request
  // * a page request will get ignored if anything else is in progress
  if (state.inProgress) {
    switch (action.type) {
      case GET_NEXT_PAGE:
        return state
      case SUBMIT_QUERY:
        if (!state.cancelable) { return state }
    }

    state.promise.cancel()
  }

  switch (action.type) {
    case GET_NEXT_PAGE:
    case UPDATE_SORT_COLUMN:
    case SUBMIT_QUERY:
    case UPDATE_DATA_SOURCES:
      return new Request({ type: action.type })
    case REQUEST_SENT:
      return state.set('promise', action.promise)
    case REQUEST_SUCCESS:
    case REQUEST_FAIL:
      return state.set('promise', null)
    default:
      return state
  }
}
