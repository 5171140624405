import { Record } from 'immutable'

import { State } from './index.js'
import {
  INITIALIZED,
  REQUEST_SUCCESS,
} from '../actions'

const DEFAULTS = {
  initialized: false,
  trueCount: 0,
}

export class Status extends Record(DEFAULTS) { }

export const status = (state = new State(), action) => {
  switch (action.type) {
    case REQUEST_SUCCESS: {
      const { count } = action.response
      return state.setIn(['status', 'trueCount'], count)
    }
    case INITIALIZED: {
      return state.setIn(['status', 'initialized'], true)
    }
    default:
      return state
  }
}
