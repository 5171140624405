import React from 'react';
import { Field } from './Field';
import { EditFormDefinitionStore } from '@/FormDefinitions/stores/editFormDefinitionStore';
import { FormLayoutForEditing } from '@/FormDefinitions/types';

type Props = {
  row: FormLayoutForEditing;
  store: EditFormDefinitionStore;
}

export class FormRow extends React.Component<Props> {
  render() {
    const { row: { contents }, store } = this.props;

    return (
      <div className="FormRow">
        {
          contents.map(field => {
            return <Field
              key={field.layoutID}
              field={field}
              store={store}
              />;
          })
        }
      </div>
    );
  }
}
