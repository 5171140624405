/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import { TabbedCells, ParsingSection, ParsingArea, ParsingPlateBlock, ParserUtil, ParsingFixedValue, ParsingTabularData, FixedValue, ParsingType, ParsingPlateBlockMultiStamp } from './parserUtil';
import { Button, Card, Checkbox, ListItemText, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { FIELD_WELLNAME } from './TableDataComposer';
import CrossIcon from '@/shared/components/icons/CrossIcon';
import { PickJoinFields } from './PickJoinFields';

export type Props = {
  sheets: TabbedCells[];
  parsingSections: ParsingSection[];
  sectionClosed: boolean[];
  selectedTab: number;
  selectedArea: ParsingArea;
  highlightedSectionLeft: number;
  highlightedSectionRight: number;
  callbackUpdateParsingSections: (parsingSections: ParsingSection[], clearSelection: boolean) => void;
  callbackChangeHighlight: (idx: number) => void;
  callbackChangeClosed: (sectionClosed: boolean[]) => void;
}

type State = {
  // placeholder
};

export class SectionCommandBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // placeholder
    };
  }

  public render(): JSX.Element {
    const { sheets, parsingSections, sectionClosed, selectedTab, selectedArea, highlightedSectionLeft, highlightedSectionRight, callbackChangeHighlight } = this.props;
    const sheet = sheets[selectedTab];

    const applicFixedValue = !!ParserUtil.produceFixedValue(-1, sheet, selectedArea);
    const applicTabularData = !!ParserUtil.produceTabularData(-1, sheet, selectedArea);
    const applicPlateBlock = !!ParserUtil.producePlateBlock(-1, sheet, selectedArea);

    const tooltipFixedValue = (
      <Typography>
        Select a single cell that contains a value that applies to all imported rows. If no cell is selected, you must enter a specific value.
      </Typography>
    );
    const tooltipTabularData = (
      <Typography>
        Select an area that represents tabular data content, organized into rows and columns. Do not include the
        header row in the selection area.
      </Typography>
    );
    const tooltipPlateBlock = (
      <Typography>
        Select an area that represents a plate block. The well labels should be above and to the left of the selection.
      </Typography>
    );

    const toggleClosed = (idx: number) => {
      const closed = [...sectionClosed];
      closed[idx] = !closed[idx];
      this.props.callbackChangeClosed(closed);
    };

    const sectionsInTab: JSX.Element[] = [];

    let sectIdx = 0;

    for (let n = 0; n < parsingSections.length; n++) {
      const section = parsingSections[n];
      if (section.tabIndex != selectedTab) continue;

      let title = '';
      let jsx: JSX.Element = null;
      if (section.type == ParsingType.FixedValue) {
        title = 'Fixed Value';
        jsx = this.buildSectionFixedValue(n, section as ParsingFixedValue);
      } else if (section.type == ParsingType.TabularData) {
        title = 'Tabular Data';
        jsx = this.buildSectionTabularData(n, section as ParsingTabularData);
      } else if (section.type == ParsingType.PlateBlock) {
        title = 'Plate Block';
        jsx = this.buildSectionPlateBlock(n, section as ParsingPlateBlock);
      }

      let className = 'PlateBlockImporter-section';
      if (n == highlightedSectionLeft) {
        className += ' PlateBlockImporter-section-highleft';
      } else if (n == highlightedSectionRight) {
        className += ' PlateBlockImporter-section-highright';
      }

      sectionsInTab.push((
        <div
          key={`section-${sectIdx++}`}
          className={className}
          onMouseEnter={() => callbackChangeHighlight(n)}
          onMouseLeave={() => callbackChangeHighlight(-1)}
        >
          <div className="PlateBlockImporter-flexsides">
            <div className="PlateBlockImporter-toolheader">
              <span
                className="PlateBlockImporter-chevron"
                onClick={() => toggleClosed(n)}
              >
                {sectionClosed[n] ? '\u{25B7}' : '\u{25BD}'}
              </span>
              {title}
            </div>
            <div>
              <Button onClick={() => this.handleRemoveSection(n)}>Remove</Button>
            </div>
          </div>
          {!sectionClosed[n] && jsx}
        </div>
      ));
    }

    return (
      <>
        <Card raised={true} className="PlateBlockImporter-cardpadded">
          <Stack spacing={1}>
            <div className="PlateBlockImporter-toolheader">Assign</div>
            <Tooltip
              key="tip-fixedvalue"
              title={tooltipFixedValue}
              arrow
              placement="left"
            >
              <span>
                <Button
                  onClick={this.handleAssignFixedValue}
                  disabled={!applicFixedValue}
                >
                  Fixed Value
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              key="tip-tabulardata"
              title={tooltipTabularData}
              arrow
              placement="left"
            >
              <span>
                <Button
                  onClick={this.handleAssignTabularData}
                  disabled={!applicTabularData}
                >
                  Tabular Data
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              key="tip-plateblock"
              title={tooltipPlateBlock}
              arrow
              placement="left"
            >
              <span>
                <Button
                  onClick={this.handleAssignPlateBlock}
                  disabled={!applicPlateBlock}
                >
                  Plate Block
                </Button>
              </span>
            </Tooltip>
          </Stack>
          {sectionsInTab}
        </Card>
      </>
    );
  }

  private buildSectionFixedValue(idx: number, section: ParsingFixedValue): JSX.Element {
    const sheet = this.props.sheets[section.tabIndex];
    const isFromGrid = !!section.areaValue;
    const value = isFromGrid ? sheet[section.areaValue.y][section.areaValue.x] : section.specificValue;

    const tooltipField = (
      <Typography>
        Provide the column name to use for the output table.
      </Typography>
    );
    const tooltipValue = (
      <Typography>
        Provide the value to use for the output table, for each and every row.
      </Typography>
    );

    return ((
      <>
        <div className="PlateBlockImporter-grid-labelvalue">
          <div key="label-field">Field:</div>
          <div key="value-field">
            <Tooltip
              key="tip-field"
              title={tooltipField}
              arrow
              placement="left"
            >
              <input
                className="input-text PlateBlockImporter-sidebar-input"
                spellCheck={false}
                value={section.fieldName}
                onChange={(event) => this.handleChangeFixedValueField(idx, event.target.value)}
              />
            </Tooltip>
          </div>
          <div key="label-value">Value:</div>
          <div key="value-value">
            <Tooltip
              key="tip-value"
              title={tooltipValue}
              arrow
              placement="left"
            >
              <span>
                <input
                  className="input-text PlateBlockImporter-sidebar-input"
                  type="text"
                  spellCheck={false}
                  value={value}
                  disabled={isFromGrid}
                  onChange={(event) => isFromGrid || this.handleChangeFixedValueSpecific(idx, event.target.value)}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </>
    ));
  }

  private buildSectionTabularData(idx: number, section: ParsingTabularData): JSX.Element {
    const sheet = this.props.sheets[section.tabIndex];
    const { selectedArea } = this.props;

    const proposedFixedValue = ParserUtil.produceFixedValue(-1, sheet, selectedArea);
    const areaReassignHeader = ParserUtil.reassignTabularHeader(section, selectedArea);

    const fieldNames = ParserUtil.tableFieldNames(sheet, section, true);

    const handleChangeJoinFields = (joinFields: string[]): void => {
      const parsingSections = [...this.props.parsingSections];
      (parsingSections[idx] as ParsingTabularData).joinField = joinFields;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleAddFixedValue = () => {
      if (!proposedFixedValue) return;
      const fixval: FixedValue = {
        areaValue: proposedFixedValue.areaValue,
        specificValue: proposedFixedValue.specificValue,
        fieldName: proposedFixedValue.fieldName,
      };

      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...((parsingSections[idx] as ParsingTabularData).fixedValues ?? []), fixval];
      parsingSections[idx] = { ...section, fixedValues } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, true);
    };

    const handleChangeFixedValueField = (fixidx: number, fieldName: string) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingTabularData).fixedValues];
      fixedValues[fixidx].fieldName = fieldName;
      parsingSections[idx] = { ...section, fixedValues } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleChangeFixedValueSpecific = (fixidx: number, value: string) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingTabularData).fixedValues];
      fixedValues[fixidx].specificValue = value;
      parsingSections[idx] = { ...section, fixedValues } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleDeleteFixedValue = (repidx: number) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingTabularData).fixedValues];
      fixedValues.splice(repidx, 1);
      parsingSections[idx] = { ...section, fixedValues } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleAddReplicate = () => {
      const parsingSections = [...this.props.parsingSections];
      const replicateSets = [...(parsingSections[idx] as ParsingTabularData).replicateSets, []];
      parsingSections[idx] = { ...section, replicateSets } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleDeleteReplicate = (repidx: number) => {
      const parsingSections = [...this.props.parsingSections];
      const replicateSets = [...(parsingSections[idx] as ParsingTabularData).replicateSets];
      replicateSets.splice(repidx, 1);
      parsingSections[idx] = { ...section, replicateSets } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleChangeReplicates = (repidx: number, values: string[]) => {
      const parsingSections = [...this.props.parsingSections];
      const replicateSets = [...(parsingSections[idx] as ParsingTabularData).replicateSets];
      replicateSets[repidx] = [...values];
      replicateSets[repidx].sort((v1, v2) => fieldNames.indexOf(v1) - fieldNames.indexOf(v2));
      parsingSections[idx] = { ...section, replicateSets } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleChangeReplicateFieldName = (detidx: number, value: string) => {
      const parsingSections = [...this.props.parsingSections];
      const replicateDetails = [...((parsingSections[idx] as ParsingTabularData).replicateDetails ?? [])];

      let numDetails = 0;
      for (const replicates of (parsingSections[idx] as ParsingTabularData).replicateSets) {
        numDetails = Math.max(numDetails, replicates.length);
      }
      for (let n = 0; n < numDetails; n++) {
        replicateDetails[n] = replicateDetails[n] ?? { fieldName: null, columnValueField: null };
      }
      replicateDetails[detidx].fieldName = value;

      parsingSections[idx] = { ...section, replicateDetails } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleChangeReplicateColumnValueField = (detidx: number, value: string) => {
      const parsingSections = [...this.props.parsingSections];
      const replicateDetails = [...((parsingSections[idx] as ParsingTabularData).replicateDetails ?? [])];

      let numDetails = 0;
      for (const replicates of (parsingSections[idx] as ParsingTabularData).replicateSets) {
        numDetails = Math.max(numDetails, replicates.length);
      }
      for (let n = 0; n < numDetails; n++) {
        replicateDetails[n] = replicateDetails[n] ?? { fieldName: null, columnValueField: null };
      }
      replicateDetails[detidx].columnValueField = value;

      parsingSections[idx] = { ...section, replicateDetails } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleAssignTabularHeader = () => {
      const parsingSections = [...this.props.parsingSections];

      let { joinField } = section;
      if (typeof joinField == 'string') joinField = [joinField];
      if (!joinField) joinField = [];
      joinField = joinField.filter((fv) => !fv || fieldNames.includes(fv));
      if (joinField.length == 0) joinField = [fieldNames[0]];

      parsingSections[idx] = { ...section, areaHeader: areaReassignHeader, joinField } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, true);
    };

    const areaExtendContent = ParserUtil.extendTabularContent(sheet, section);
    const handleExtendContentArea = () => {
      const parsingSections = [...this.props.parsingSections];
      parsingSections[idx] = { ...section, areaContent: areaExtendContent } as ParsingTabularData;
      this.props.callbackUpdateParsingSections(parsingSections, true);
    };

    const tooltipAddFixedValue = (
      <Typography>
        Add a fixed value for the whole tabular block: select a cell or enter a specific value.
      </Typography>
    );
    const tooltipAddReplicate = (
      <Typography>
        Add a block of one-or-more columns that are converted into subsequent rows (replicates).
      </Typography>
    );
    const tooltipReassignHeader = (
      <Typography>
        Change the header row (or rows) that are used for column names.
      </Typography>
    );
    const tooltipExtendContent = (
      <Typography>
        Extend the content area to include any non-blank rows at the end.
      </Typography>
    );
    const tooltipFixedField = (
      <Typography>
        Provide the column name to use for the fixed field in the output table.
      </Typography>
    );
    const tooltipFixedValue = (
      <Typography>
        Either provide the specific value for the fixed field, or leave it blank to use the page name.
      </Typography>
    );
    const tooltipReplField = (
      <Typography>
        Provide the column name to use for the replicated field in the output table, or leave it blank to use the first-encountered column name from the input grid.
      </Typography>
    );
    const tooltipReplValue = (
      <Typography>
        Optionally provide the name of a column to use in the output table which will store the name of the original column, which will typically vary for each replicate.
      </Typography>
    );

    const componentFixedValues: JSX.Element[] = [];
    for (let n = 0; n < section.fixedValues?.length ?? 0; n++) {
      const fixedValue = section.fixedValues[n];

      let valuePayload: JSX.Element;
      if (fixedValue.areaValue) {
        const { x, y } = fixedValue.areaValue;
        valuePayload = <b>{sheet[y][x] ?? ''}</b>;
      } else {
        valuePayload = (
          <div className="PlateBlockImporter-input-inline">
            <Tooltip
              key="tip-fixedvalue"
              title={tooltipFixedValue}
              arrow
              placement="left"
            >
              <input
                className="input-text PlateBlockImporter-input-grow"
                type="text"
                placeholder="fixed value"
                spellCheck={false}
                value={fixedValue.specificValue}
                onChange={(event) => handleChangeFixedValueSpecific(n, event.target.value)}
              />
            </Tooltip>
          </div>
        );
      }

      componentFixedValues.push((
        <div key={`label-fixv${n}`}>
          <Tooltip
            key="tip-fixedfield"
            title={tooltipFixedField}
            arrow
            placement="left"
          >
            <input
              className="input-text PlateBlockImporter-sidebar-input-stunted"
              type="text"
              placeholder="field name"
              spellCheck={false}
              value={fixedValue.fieldName ?? ''}
              onChange={(event) => handleChangeFixedValueField(n, event.target.value)}
            />
          </Tooltip>
        </div>
      ));
      componentFixedValues.push((
        <div key={`value-fixv${n}`} className="PlateBlockImporter-keeptogether">
          {valuePayload}
          &nbsp;
          <div
            key="delete-icon"
            className="PlateBlockImporter-iconbutton"
            onClick={() => handleDeleteFixedValue(n)}
          >
            <CrossIcon width="12" height="12" />
          </div>
        </div>
      ));
    }

    const componentReplicates: JSX.Element[] = [];
    const detailDefaultName: string[] = [];
    for (let n = 0; n < section.replicateSets.length; n++) {
      const replicates = section.replicateSets[n];
      for (let i = 0; i < replicates.length; i++) {
        detailDefaultName[i] = detailDefaultName[i] || replicates[i];
      }

      const otherReps = new Set<string>();
      for (let i = 0; i < section.replicateSets.length; i++) {
        if (i == n) continue;
        for (const v of section.replicateSets[i]) otherReps.add(v);
      }

      const menuItems: JSX.Element[] = [];
      for (let i = 0; i < fieldNames.length; i++) {
        const checked = replicates.includes(fieldNames[i]);
        const disabled = !checked && otherReps.has(fieldNames[i]);
        menuItems.push((
          <MenuItem key={`menuitem-${i}`} value={fieldNames[i]} disabled={disabled}>
            <Checkbox checked={checked} disabled={disabled} />
            <ListItemText primary={fieldNames[i]} />
          </MenuItem>
        ));
      }

      componentReplicates.push((
        <div key={`label-repl${n}`}>
          Replicate#{n + 1}
        </div>
      ));
      componentReplicates.push((
        <div key={`value-repl${n}`}>
          <Select
            multiple
            value={replicates}
            style={{ width: '10em' }}
            renderValue={(selected) => selected.join(', ')}
            onChange={(event) => handleChangeReplicates(n, event.target.value as string[])}
          >
            {menuItems}
          </Select>
          <div
            key="delete-icon"
            className="PlateBlockImporter-iconbutton"
            onClick={() => handleDeleteReplicate(n)}
          >
            <CrossIcon width="12" height="12" />
          </div>
        </div>
      ));
    }
    for (let n = 0; n < detailDefaultName.length; n++) {
      const details = {
        fieldName: null,
        columnValueField: null,
        ...(section.replicateDetails ? (section.replicateDetails[n] ?? {}) : {}),
      };
      componentReplicates.push((
        <div key={`heading-repdet${n}`} style={{ gridColumn: 'label / span 2' }}>
          Replicated Column #{n + 1}
        </div>
      ));
      componentReplicates.push((
        <div key={`label-repdetname${n}`}>
          Field&nbsp;Name:
        </div>
      ));
      componentReplicates.push((
        <div key={`value-repdetname${n}`}>
          <Tooltip
            key="tip-replfield"
            title={tooltipReplField}
            arrow
            placement="left"
          >
            <input
              className="input-text PlateBlockImporter-sidebar-input"
              type="text"
              spellCheck={false}
              value={details.fieldName ?? ''}
              placeholder={detailDefaultName[n]}
              onChange={(event) => handleChangeReplicateFieldName(n, event.target.value as string)}
            />
          </Tooltip>
        </div>
      ));
      componentReplicates.push((
        <div key={`label-repdetcolval${n}`}>
          Column&nbsp;Value:
        </div>
      ));
      componentReplicates.push((
        <div key={`value-repdetcolval${n}`}>
          <Tooltip
            key="tip-replvalue"
            title={tooltipReplValue}
            arrow
            placement="left"
          >
            <input
              className="input-text PlateBlockImporter-sidebar-input"
              type="text"
              spellCheck={false}
              value={details.columnValueField ?? ''}
              onChange={(event) => handleChangeReplicateColumnValueField(n, event.target.value as string)}
            />
          </Tooltip>
        </div>
      ));
    }

    return ((
      <>
        <div className="PlateBlockImporter-grid-labelvalue">
          <div key="label-columns">Columns:</div>
          <div key="value-columns"><b>{section.areaContent.w}</b></div>

          <div key="label-rows">Rows:</div>
          <div key="value-rows"><b>{section.areaContent.h}</b></div>

          <div key="label-join">Join&nbsp;Field:</div>
          <div key="value-join">
            <PickJoinFields
              joinField={section.joinField}
              fieldNames={fieldNames}
              handleChangeFields={handleChangeJoinFields}
            />
          </div>

          {componentFixedValues}
          {componentReplicates}
        </div>

        <Stack spacing={1}>

          <Tooltip
            key="tip-addfixedvalue"
            title={tooltipAddFixedValue}
            arrow
            placement="left"
          >
            <span>
              <Button
                fullWidth
                onClick={handleAddFixedValue}
                disabled={!proposedFixedValue}
              >
                Associate Fixed Value
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            key="tip-addreplicate"
            title={tooltipAddReplicate}
            arrow
            placement="left"
          >
            <div>
              <Button
                onClick={handleAddReplicate}
              >
                Add Replicate
              </Button>
            </div>
          </Tooltip>
          <Tooltip
            key="tip-reassignheader"
            title={tooltipReassignHeader}
            arrow
            placement="left"
          >
            <div>
              <span>
                <Button
                  onClick={handleAssignTabularHeader}
                  disabled={!areaReassignHeader}
                >
                  Reassign Header Columns
                </Button>
              </span>
            </div>
          </Tooltip>
          <Tooltip
            key="tip-extendcontent"
            title={tooltipExtendContent}
            arrow
            placement="left"
          >
            <div>
              <span>
                <Button
                  onClick={handleExtendContentArea}
                  disabled={!areaExtendContent}
                >
                  Extend Content Area
                </Button>
              </span>
            </div>
          </Tooltip>
        </Stack>
      </>
    ));
  }

  private buildSectionPlateBlock(idx: number, section: ParsingPlateBlock): JSX.Element {
    const sheet = this.props.sheets[section.tabIndex];
    const { selectedArea } = this.props;

    const proposedFixedValue = ParserUtil.produceFixedValue(-1, sheet, selectedArea);
    const proposedShrunkArea = ParserUtil.produceShrunkArea(sheet, section.areaBlock, selectedArea);

    const fieldNames = ParserUtil.plateFieldNames(section, true);

    const handleChangeJoinFields = (joinFields: string[]): void => {
      const parsingSections = [...this.props.parsingSections];
      (parsingSections[idx] as ParsingPlateBlock).joinField = joinFields;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleAddFixedValue = () => {
      if (!proposedFixedValue) return;
      const fixval: FixedValue = {
        areaValue: proposedFixedValue.areaValue,
        specificValue: proposedFixedValue.specificValue,
        fieldName: proposedFixedValue.fieldName,
      };

      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...((parsingSections[idx] as ParsingPlateBlock).fixedValues ?? []), fixval];
      parsingSections[idx] = { ...section, fixedValues } as ParsingPlateBlock;
      this.props.callbackUpdateParsingSections(parsingSections, true);
    };

    const handleShrinkActiveArea = () => {
      const parsingSections = [...this.props.parsingSections];
      parsingSections[idx] = { ...section, areaBlock: proposedShrunkArea } as ParsingPlateBlock;
      this.props.callbackUpdateParsingSections(parsingSections, true);
    };

    const handleChangeFixedValueField = (fixidx: number, fieldName: string) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingPlateBlock).fixedValues];
      fixedValues[fixidx].fieldName = fieldName;
      parsingSections[idx] = { ...section, fixedValues } as ParsingPlateBlock;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleChangeFixedValueSpecific = (fixidx: number, value: string) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingPlateBlock).fixedValues];
      fixedValues[fixidx].specificValue = value;
      parsingSections[idx] = { ...section, fixedValues } as ParsingPlateBlock;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const handleDeleteFixedValue = (repidx: number) => {
      const parsingSections = [...this.props.parsingSections];
      const fixedValues = [...(parsingSections[idx] as ParsingPlateBlock).fixedValues];
      fixedValues.splice(repidx, 1);
      parsingSections[idx] = { ...section, fixedValues } as ParsingPlateBlock;
      this.props.callbackUpdateParsingSections(parsingSections, false);
    };

    const tooltipAddFixedValue = (
      <Typography>
        Add a fixed value for the whole plate block: select a cell or enter a specific value.
      </Typography>
    );
    const tooltipShrinkActiveArea = (
      <Typography>
        Limit the plate block cells to a smaller area.
      </Typography>
    );
    const tooltipFixedField = (
      <Typography>
        Provide the column name to use for the fixed field in the output table.
      </Typography>
    );
    const tooltipFixedValue = (
      <Typography>
        Either provide the specific value for the fixed field, or leave it blank to use the page name.
      </Typography>
    );
    const tooltipPlateField = (
      <Typography>
        Provide the column name to use for the plate block content in the output table.
      </Typography>
    );

    const componentFixedValues: JSX.Element[] = [];
    for (let n = 0; n < section.fixedValues?.length ?? 0; n++) {
      const fixedValue = section.fixedValues[n];

      let valuePayload: JSX.Element;
      if (fixedValue.areaValue) {
        const { x, y } = fixedValue.areaValue;
        valuePayload = <b>{sheet[y][x] ?? ''}</b>;
      } else {
        valuePayload = (
          <div className="PlateBlockImporter-input-inline">
            <Tooltip
              key="tip-fixedvalue"
              title={tooltipFixedValue}
              arrow
              placement="left"
            >
              <input
                className="input-text PlateBlockImporter-input-grow"
                type="text"
                placeholder="fixed value"
                spellCheck={false}
                value={fixedValue.specificValue}
                onChange={(event) => handleChangeFixedValueSpecific(n, event.target.value)}
              />
            </Tooltip>
          </div>
        );
      }

      componentFixedValues.push((
        <div key={`label-fixv${n}`}>
          <Tooltip
            key="tip-fixedfield"
            title={tooltipFixedField}
            arrow
            placement="left"
          >
            <input
              className="input-text PlateBlockImporter-sidebar-input-stunted"
              type="text"
              placeholder="field name"
              spellCheck={false}
              value={fixedValue.fieldName ?? ''}
              onChange={(event) => handleChangeFixedValueField(n, event.target.value)}
            />
          </Tooltip>
        </div>
      ));
      componentFixedValues.push((
        <div key={`value-fixv${n}`} className="PlateBlockImporter-keeptogether">
          {valuePayload}
          &nbsp;
          <div
            key="delete-icon"
            className="PlateBlockImporter-iconbutton"
            onClick={() => handleDeleteFixedValue(n)}
          >
            <CrossIcon width="12" height="12" />
          </div>
        </div>
      ));
    }

    return ((
      <>
        <div className="PlateBlockImporter-grid-labelvalue">
          <div key="label-area">Area:</div>
          <div key="value-area"><b>{section.areaBlock.w}</b> {'\u{00D7}'} <b>{section.areaBlock.h}</b></div>

          <div key="label-well">Well&nbsp;Field:</div>
          <div key="value-well"><b>{FIELD_WELLNAME}</b></div>

          <div key="label-field">Value&nbsp;Field:</div>
          <div key="value-field">
            <Tooltip
              key="tip-field"
              title={tooltipPlateField}
              arrow
              placement="left"
            >
              <input
                // className="input-text"
                // style={{ width: '10em' }}
                // type="text"
                value={section.fieldName}
                onChange={(event) => this.handleChangePlateBlockField(idx, event.target.value)}
              />
            </Tooltip>
          </div>

          <div key="label-join">Join&nbsp;Field:</div>
          <div key="value-join">
            <PickJoinFields
              joinField={section.joinField}
              fieldNames={fieldNames}
              handleChangeFields={handleChangeJoinFields}
            />
          </div>

          <div key="label-pad">Well&nbsp;Padding:</div>
          <div key="value-pad">
            <Select
              value={section.wellNumberPadding ?? 0}
              style={{ width: '11em' }}
              onChange={(event) => this.handleChangePlateBlockPadding(idx, event.target.value as number)}
            >
              <MenuItem key="val0" value={0}>strip zeros</MenuItem>
              <MenuItem key="val2" value={2}>2 digits</MenuItem>
              <MenuItem key="val3" value={3}>3 digits</MenuItem>
            </Select>
          </div>

          {componentFixedValues}

          <div key="label-stamp">Repeat:</div>
          <div key="value-stamp">
            <Select
              value={section.multiStamp || 'single'}
              style={{ width: '11em' }}
              onChange={(event) => this.handleChangePlateBlockStamp(idx, event.target.value as ParsingPlateBlockMultiStamp)}
            >
              <MenuItem key="single" value={'single'}>single</MenuItem>
              <MenuItem key="local" value={ParsingPlateBlockMultiStamp.Local}>this tab</MenuItem>
              <MenuItem key="global" value={ParsingPlateBlockMultiStamp.Global}>all tabs</MenuItem>
            </Select>
          </div>
        </div>

        <Stack spacing={1}>
          <Tooltip
            key="tip-addfixedvalue"
            title={tooltipAddFixedValue}
            arrow
            placement="left"
          >
            <span>
              <Button
                onClick={handleAddFixedValue}
                disabled={!proposedFixedValue}
              >
                Associate Fixed Value
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            key="tip-shrinkactivearea"
            title={tooltipShrinkActiveArea}
            arrow
            placement="left"
          >
            <span>
              <Button
                onClick={handleShrinkActiveArea}
                disabled={!proposedShrunkArea}
              >
                Shrink Active Area
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </>
    ));
  }

  private handleAssignFixedValue = (): void => {
    const { sheets, selectedTab, parsingSections, sectionClosed, selectedArea } = this.props;
    const section = ParserUtil.produceFixedValue(selectedTab, sheets[selectedTab], selectedArea);
    if (section) {
      this.props.callbackUpdateParsingSections([...parsingSections, section], true);
      this.props.callbackChangeClosed([...sectionClosed, false]);
    }
  };

  private handleAssignTabularData = (): void => {
    const { sheets, selectedTab, parsingSections, sectionClosed, selectedArea } = this.props;
    const section = ParserUtil.produceTabularData(selectedTab, sheets[selectedTab], selectedArea);
    if (section) {
      this.props.callbackUpdateParsingSections([...parsingSections, section], true);
      this.props.callbackChangeClosed([...sectionClosed, false]);
    }
  };

  private handleAssignPlateBlock = (): void => {
    const { sheets, selectedTab, parsingSections, sectionClosed, selectedArea } = this.props;
    const section = ParserUtil.producePlateBlock(selectedTab, sheets[selectedTab], selectedArea);
    if (section) {
      this.props.callbackUpdateParsingSections([...parsingSections, section], true);
      this.props.callbackChangeClosed([...sectionClosed, false]);
    }
  };

  private handleChangeFixedValueField(idx: number, value: string): void {
    const parsingSections = [...this.props.parsingSections];
    (parsingSections[idx] as ParsingFixedValue).fieldName = value;
    this.props.callbackUpdateParsingSections(parsingSections, false);
  }

  private handleChangeFixedValueSpecific(idx: number, value: string): void {
    const parsingSections = [...this.props.parsingSections];
    (parsingSections[idx] as ParsingFixedValue).specificValue = value;
    this.props.callbackUpdateParsingSections(parsingSections, false);
  }

  private handleChangePlateBlockField(idx: number, value: string): void {
    const parsingSections = [...this.props.parsingSections];
    (parsingSections[idx] as ParsingPlateBlock).fieldName = value;
    this.props.callbackUpdateParsingSections(parsingSections, false);
  }

  private handleChangePlateBlockPadding(idx: number, value: number): void {
    const parsingSections = [...this.props.parsingSections];
    (parsingSections[idx] as ParsingPlateBlock).wellNumberPadding = value == 2 || value == 3 ? value : null;
    this.props.callbackUpdateParsingSections(parsingSections, false);
  }

  private handleChangePlateBlockStamp(idx: number, value: ParsingPlateBlockMultiStamp): void {
    const STAMP_OPTIONS = [ParsingPlateBlockMultiStamp.Local, ParsingPlateBlockMultiStamp.Global];
    const stamp = STAMP_OPTIONS.includes(value) ? value : null;

    const parsingSections = [...this.props.parsingSections];
    (parsingSections[idx] as ParsingPlateBlock).multiStamp = stamp;
    this.props.callbackUpdateParsingSections(parsingSections, false);
  }

  private handleRemoveSection(idx: number): void {
    const parsingSections = [...this.props.parsingSections];
    parsingSections.splice(idx, 1);
    this.props.callbackUpdateParsingSections(parsingSections, false);

    const closed = [...this.props.sectionClosed];
    closed.splice(idx, 1);
    this.props.callbackChangeClosed(closed);
  }
}
