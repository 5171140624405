import React from 'react';
import { IconButton } from '@mui/material';

import { searchField as styles } from './styles';

export class SearchButton extends React.Component {
  render() {
    return (
      <IconButton
        className='search-bar__submit'
        style={styles.buttonStyle}
        type='submit'
      >
        <i className='fa fa-search' style={styles.iconStyle} />
      </IconButton>
    );
  }
}
