import { Schema, SchemaBranch } from '@/Annotator/data/Schema';
import { FieldDefinitionsForVault, Protocol } from './types';
import { collapsePrefix, compatiblePropGroupNest } from '@/Annotator/data/utils';
import { StringOrNumber } from '@/types';
import {
  FilterLayer,
  determinePresentableFields,
  determinePresentableAssignments,
  PresentableField,
  PresentableAssignment,
} from './advancedSearchHelper';
import { FieldDefinition } from '@/FieldDefinitions/types';

export enum CellState {
  Blank = 0,
  Ancestor = 1,
  Present = 2,
}

export interface RowLine {
  label: string;
  indent: number;
  uri?: string; // for assignments only
  cells?: CellState[]; // one entry per column (aka protocol)
}

export interface CategoryData {
  presentField?: PresentableField;
  presentAssignment?: PresentableAssignment;
  rows?: RowLine[];
  branchMap?: Map<string, SchemaBranch>; // abv.uri to branch
}

export class PropertyGridData {
  public categoryData: CategoryData[] = null;

  private defnFromID = new Map<number, FieldDefinition>();

  constructor(
    public protocols: Protocol[],
    public fieldDefinitions: FieldDefinitionsForVault[],
    public schema: Schema,
    public filterLayers: FilterLayer[],
  ) {
    // ...
  }

  // put together the skeleton outline (fast)
  public prebuild(): void {
    const { protocols, fieldDefinitions, schema, filterLayers } = this;
    const presentFields = determinePresentableFields(fieldDefinitions, filterLayers, protocols).filter((presentField) => presentField.observedValues.size > 0);
    const presentAssignments = determinePresentableAssignments(schema, filterLayers, protocols).filter((presentAssignment) => presentAssignment.isPresent);

    this.categoryData = [
      ...presentFields.map((presentField) => { return { presentField }; }),
      ...presentAssignments.map((presentAssignment) => { return { presentAssignment }; }),
    ];

    const idToNumber = (id: StringOrNumber): number => typeof id == 'number' ? id : 0;
    for (const vault of fieldDefinitions) {
      for (const defn of vault.protocol_field_definitions) {
        this.defnFromID.set(idToNumber(defn.id), defn);
      }
    }
  }

  public isAllBuilt(): boolean {
    return this.categoryData.every((rowBlock) => rowBlock.rows != null);
  }

  // build out a unit increment: it may be slow if it has to lookup schema hierarchies, or it could return quickly; the return value is true if the
  // method ought to be called again to finish it up
  public async buildBlock(): Promise<boolean> {
    let anything = false;

    for (const catBlock of this.categoryData) {
      if (catBlock.rows) {
        // nop
      } else if (catBlock.presentField) {
        this.createRowsField(catBlock);
        this.createCellsField(catBlock);
        anything = true;
      } else if (catBlock.presentAssignment) {
        await this.createRowsAssignment(catBlock);
        this.createCellsAssignment(catBlock);
        return true;
      }
    }
    return anything;
  }

  private createRowsField(catBlock: CategoryData): void {
    const { observedValues } = catBlock.presentField;
    catBlock.rows = [];
    for (const value of Array.from(observedValues).sort()) {
      catBlock.rows.push({ label: value, indent: 0 });
    }
  }

  private async createRowsAssignment(catBlock: CategoryData): Promise<void> {
    const { assn, groupNest } = catBlock.presentAssignment;

    const labelRows: RowLine[] = [];

    const usedLabels = new Set<string>();
    const usedValues = new Set<string>();
    for (const protocol of this.protocols) {
      for (const annot of (protocol.ontology_annotations || [])) {
        if (compatiblePropGroupNest(assn.propURI, groupNest, annot.prop_uri, annot.group_nest)) {
          if (annot.value_uri) {
            usedValues.add(collapsePrefix(annot.value_uri));
          } else if (annot.value_label) {
            if (!usedLabels.has(annot.value_label)) {
              labelRows.push({ label: annot.value_label, indent: 0 });
              usedLabels.add(annot.value_label);
            }
          }
        }
      }
    }

    catBlock.branchMap = new Map();

    const termRows: RowLine[] = [];
    const usedNodes = new Set<string>();
    const scanBranch = (branch: SchemaBranch, indent: number) => {
      // it's a "cabinet" placeholder; subsume it
      if (Schema.isPlaceholderURI(branch.uri)) {
        for (const look of branch.children) scanBranch(look as SchemaBranch, indent);
        return;
      }

      catBlock.branchMap.set(branch.uri, branch);
      termRows.push({ label: branch.label, indent, uri: branch.uri });
      if (usedValues.has(branch.uri)) {
        for (let look = branch; look; look = look.parent as SchemaBranch) {
          usedNodes.add(look.uri);
        }
      }

      for (const look of branch.children) scanBranch(look as SchemaBranch, indent + 1);
    };
    const branches = await this.schema.composeBranch(assn);
    for (const branch of (branches || [])) scanBranch(branch, 0);

    catBlock.rows = [
      ...labelRows.sort((r1, r2) => r1.label.localeCompare(r2.label)),
      ...termRows.filter((row) => usedNodes.has(row.uri)),
    ];
  }

  private createCellsField(rowBlock: CategoryData): void {
    for (const row of rowBlock.rows) {
      row.cells = new Array(this.protocols.length).fill(CellState.Blank);
    }

    const idToNumber = (id: StringOrNumber): number => typeof id == 'number' ? id : 0;
    for (let n = 0; n < this.protocols.length; n++) {
      for (const field of (this.protocols[n].protocol_fields || [])) {
        const defn = this.defnFromID.get(idToNumber(field.protocol_field_definition_id));
        if (defn?.name != rowBlock.presentField.defn.name) continue;
        const row = rowBlock.rows.find((r) => r.label == field.display_string);
        if (row) row.cells[n] = CellState.Present;
      }
    }
  }

  private createCellsAssignment(catBlock: CategoryData): void {
    const { assn, groupNest } = catBlock.presentAssignment;

    for (const row of catBlock.rows) {
      row.cells = new Array(this.protocols.length).fill(CellState.Blank);
    }

    for (let n = 0; n < this.protocols.length; n++) {
      for (const annot of (this.protocols[n].ontology_annotations || [])) {
        if (compatiblePropGroupNest(assn.propURI, groupNest, annot.prop_uri, annot.group_nest)) {
          if (annot.value_uri) {
            const branch = catBlock.branchMap.get(collapsePrefix(annot.value_uri));
            for (let look = branch; look; look = look.parent as SchemaBranch) {
              const row = catBlock.rows.find((r) => r.uri == look.uri);
              const state = look === branch ? CellState.Present : CellState.Ancestor;
              if (row) row.cells[n] = Math.max(row.cells[n], state);
            }
          } else if (annot.value_label) {
            const row = catBlock.rows.find((r) => r.label == annot.value_label && !r.uri);
            if (row) row.cells[n] = CellState.Present;
          }
        }
      }
    }
  }
}
