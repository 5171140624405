/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import { MenuItem, Select } from '@mui/material';
import CrossIcon from '@/shared/components/icons/CrossIcon';
import PlusIcon from '@/shared/components/icons/PlusIcon';

export type Props = {
  joinField: string | string[];
  fieldNames: string[];
  handleChangeFields: (joinFields: string[]) => void;
}

export class PickJoinFields extends React.Component<Props> {
  constructor(props: Props) {
    super(props, null);
  }

  public render(): JSX.Element {
    const { joinField: inValue, fieldNames } = this.props;
    let joinFields = typeof inValue == 'string' ? [inValue] : Array.isArray(inValue) ? inValue : [];
    joinFields = joinFields.filter((fv) => !fv || this.props.fieldNames.includes(fv));
    if (joinFields.length == 0) joinFields = [''];

    const divList: JSX.Element[] = [];

    for (let n = 0; n < joinFields.length; n++) {
      const field = joinFields[n] ?? '';

      const btn = n == joinFields.length - 1 && joinFields.every((fv) => !!fv) ? (
        <div
          key="add-icon"
          className="PlateBlockImporter-iconbutton"
          onClick={() => {
            this.props.handleChangeFields([...joinFields, '']);
          }}
          >
          <PlusIcon width="12" height="12"/>
        </div>
      ) : joinFields.length > 1 && n < joinFields.length ? (
        <div
          key="delete-icon"
          className="PlateBlockImporter-iconbutton"
          onClick={() => {
            const modFields = [...joinFields];
            modFields.splice(n, 1);
            this.props.handleChangeFields(modFields);
          }}
          >
          <CrossIcon width="12" height="12"/>
        </div>
      ) : null;

      divList.push((
        <div
          key={`joinfield-${n}`}
          className='PlateBlockImporter-nowrap'
          >
          <Select
            value={field}
            style={{ width: '11em' }}
            onChange={(event) => {
              const modFields = [...joinFields];
              modFields[n] = event.target.value;
              this.props.handleChangeFields(modFields);
            }}
            >
            {fieldNames.map((fieldName, idx) => (
              <MenuItem key={`val${idx}`} value={fieldName}>{fieldName}</MenuItem>
            ))}
          </Select>
          {btn}
        </div>
      ));
    }

    return (<>{divList}</>);
  }
}
