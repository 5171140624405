import React from 'react';
import TextField from '@mui/material/TextField';

type Props = {
  className: string;
  criterionIndex: number;
  errorMessage: string;
  helperMessage: string;
  id: string;
  onChange: (index: number, query: string) => void;
  onSubmit: () => void;
  query: string;
  textFieldClassName: string;
};

type State = {
  hasFocus: boolean;
};

export default class QueryTextField extends React.PureComponent<Props, State> {
  inputRef: React.RefObject<HTMLTextAreaElement>;

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
    };
    this.inputRef = React.createRef();
  }

  getEndAdornment() {
    return <></>;
  }

  formatQueryChange(eventOrValue) {
    let value = eventOrValue;

    if (eventOrValue && eventOrValue.target) {
      value = eventOrValue.target.value;
    }

    return value || '';
  }

  onChange = (eventOrValue) => {
    const { criterionIndex, onChange } = this.props;
    const value = this.formatQueryChange(eventOrValue);
    return onChange(criterionIndex, value);
  };

  onKeyDown = (event) => {
    const { key, altKey, ctrlKey, shiftKey } = event;
    const { onSubmit, query } = this.props;
    const inputElement = this.inputRef.current;
    const { selectionStart, selectionEnd } = inputElement;
    const preventFormSubmission = altKey || ctrlKey || shiftKey;

    if (key === 'Enter') {
      event.preventDefault();

      if (preventFormSubmission) {
        // Remove unnecessary carriage returns because the selection API indices
        // do not count unnavigable whitespace characters.
        const sanitizedQuery = query.replace(/\r/g, '');

        this.onChange(
          sanitizedQuery.substring(0, selectionStart) +
            '\n' +
            sanitizedQuery.substring(selectionEnd),
        );
        setTimeout(() => {
          inputElement.setSelectionRange(
            selectionStart + 1,
            selectionStart + 1,
          );
        });
      } else {
        onSubmit();
      }
    }
  };

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  onBlur = () => {
    this.setState({
      hasFocus: false,
    });
  };

  isMultiline() {
    return true;
  }

  render() {
    const {
      id,
      query,
      className,
      textFieldClassName,
      errorMessage,
      helperMessage,
    } = this.props;
    const { hasFocus } = this.state;
    const focusClassName = hasFocus ? 'form__textarea__focused' : '';

    const multiline = this.isMultiline();
    const additionalFormClassName = 'molecule_criteria__query_' + (multiline ? 'multiline' : 'single_row');
    return (
      <div className={`item ${className}`}>
        <TextField
          type='text'
          multiline={multiline}
          maxRows={multiline ? 10 : 1}
          error={!!errorMessage.length}
          helperText={errorMessage}
          placeholder={helperMessage}
          className={`input-text form__textarea form__textarea__old molecule_criteria__query ${textFieldClassName} ${focusClassName} ${additionalFormClassName}`}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          inputRef={this.inputRef}
          value={query}
          id={id}
          InputProps={{
            endAdornment: this.getEndAdornment(),
          }}
        />
      </div>
    );
  }
}
