import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ElnEntries from './component.jsx'
import { updateDataSources } from './actions'

function mapStateToProps() {
  return { }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateDataSources,
  }, dispatch)
}

const ElnEntriesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ElnEntries)

export default ElnEntriesContainer
