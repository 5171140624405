import { observer } from 'mobx-react';
import React from 'react';
import { getRootStore } from '@/stores/rootStore';
import RunDataTables from '@/RunData/components/RunDataTables';
import { FormDefinition } from '@/Annotator/data/forms';
import { OntologyTemplate } from '@/Annotator/data/templates';

type Props = {
  project_ids: number[];
  form?: FormDefinition;
  schemaPrefix?: string;
  templateList: OntologyTemplate[],
};

@observer
export default class RunDataView extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.store.loadRunResults();
    this.store.setProtocolForm(props.form ?? null, props.schemaPrefix, props.templateList);
  }

  get store() {
    return getRootStore().runDataStore;
  }

  render() {
    const {
      displayedColumns, availableColumns, rows, sortedColumn, visibleColumnIds,
      setSortBy, setVisibleColumns,
    } = this.store;

    return <RunDataTables
      rows={rows}
      sortBy={sortedColumn}
      setSortBy={setSortBy}
      columns={displayedColumns}
      availableColumns={availableColumns}
      setVisibleColumns={setVisibleColumns}
      visibleColumns={visibleColumnIds}
    />;
  }
}
