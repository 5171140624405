import { connect } from 'react-redux'

import EntriesCount from './component.jsx'

function mapStateToProps(state) {
  const { elnEntries, status } = state

  return {
    elnEntries,
    status,
  }
}

const EntriesCountContainer = connect(
  mapStateToProps
)(EntriesCount)

export default EntriesCountContainer
