/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps } from '@/shared/components/DDForm/DDForm';
import { ElementDef, TypographyDef } from '@/shared/components/DDForm/types';
import { Typography } from '@mui/material';
import { getElementAttributes } from '../muiRenderers';

export const ddMuiRenderTypography = (props: DDFormProps, element: ElementDef) => {
  const field = element as TypographyDef;
  return (
    <Typography
      key={field.renderKey}
      {...getElementAttributes(field, props)}
      variant={field.variant as React.ComponentProps<typeof Typography>['variant']}
    >
      {field.label}
    </Typography>
  );
};
