/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import EditIcon from './EditIcon';
import Modal from './Modal/Modal';
import { FieldDataType, FieldPickValue } from '@/FieldDefinitions/types';
import { StringOrNumber } from '@/types';

type Props = {
  dialogId?: string;
  values: FieldPickValue[];
  dataType?: FieldDataType;
  invalidIds?: Set<StringOrNumber>;
  disabled?: boolean;
  uneditable?: boolean;
  showSingleUseOption?: boolean;
  editButtonStyle?: React.CSSProperties;

  onChange: (value: FieldPickValue[]) => void;
}

type State = {
  inEdit: boolean;
}

export class PickListItemsEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inEdit: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.dataType !== this.props.dataType && this.props.dataType === FieldDataType.PickList) {
      this.setState({ inEdit: true });
    }
  }

  handleSubmit = (values: FieldPickValue[]) => {
    this.props.onChange(values);
    this.setState({ inEdit: false });
  };

  handleCancel = () => {
    this.setState({ inEdit: false });
  };

  handleClickEdit = () => {
    this.setState({ inEdit: true });
  };

  render() {
    const { disabled, uneditable, values, dataType, editButtonStyle } = this.props;
    if (uneditable || dataType !== FieldDataType.PickList) {
      return null;
    } else {
      return (
        <>
          <EditIcon disabled={disabled} onClick={this.handleClickEdit} style={editButtonStyle}/>
          <Modal
            {...this.props}
            open={this.state.inEdit}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        </>
      );
    }
  }
}
