import { AnyObject } from '@/types';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { DDFormUtils } from './DDFormUtils';
import {
  FieldValueType, GroupType,
} from './types';
import { ElementDef } from './types/base/elementDef';
import { FieldDef } from './types/base/fieldDef';
import { GroupDef } from './types/base/groupDef';
import { NumberInputDef } from './types/numberInputDef';
import { PageElementDef } from './types/pageElementDef';
import type { RenderElementFunction } from './renderers/formRenderMap';

export type { RenderElementFunction };

type ValueType = FieldDef['valueType'];

/**
 * A simple flexible, data-driven form.
 *
 * Should be extended to support more types of fields.
 */

export type DDFormState = {
  data: AnyObject; // for stepper values, etc - not mutable form data
  errors: { [key: string]: string; } | null;
};

export interface DDFormProps {
  /**
   * the mutable object the form will operate on
   */
  data: AnyObject;

  /**
   * The state of controls that do not directly modify the object. Includes tab/stepper values and ephemeral values such
   * as filters, etc
   */
  formState?: DDFormState;

  /**
   * The definition of groups and fields in the form
   */
  layout: GroupType;

  /**
   * Prevent these characters from being input into text input fields
   */
  blacklistedCharacters?: string;

  /**
   * general options (spacing, etc)
   */
  renderHiddenForm?: boolean;

  renderNameAttribute?: boolean;

  /**
   * Additional class name
   */
  formClassName?: string;

  /**
   * Form class name will be DDForm-{size}, default is 'medium'
   */
  size?: 'medium' | 'small';

  /**
   * When true, error
   */
  showErrors?: boolean;

  defaults?: { [key: string]: AnyObject; };

  // renderers?: FormElementRendererMap;

  renderElement?: RenderElementFunction;

  /**
   * if supplied, this will be called before modifying form data and give the opportunity to cancel the set (and perhaps make other
   * modifications)
   * @param path
   * @param data
   * @param value
   * @param valueType
   */
  onBeforeSetValue?: (
    path: string,
    data: object,
    value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    valueType: ValueType,
    field: FieldDef
  ) => Promise<boolean>;

  onAfterSetValue?: (
    path: string,
    data: object,
    newValue: FieldValueType,
    previousValue: FieldValueType
  ) => void;

  onClickElement?(
    evt: React.MouseEvent,
    element: ElementDef
  );
}

/**
 * For a given type of field input, the automatic type to use
 */
const defaultValueTypes: { [key: string]: string; } = {
  text: 'text',
  number: 'number',
  date: 'date',
  select: 'text',
  toggle: 'boolean',
  singleSelect: 'string',
  multiselect: 'arrayString',
  stepper: 'number',
};

@observer
export class DDForm extends React.Component<DDFormProps> {
  internalState: DDFormState = { data: {}, errors: null };
  disposers: Array<() => void> = [];

  constructor(props: DDFormProps) {
    super(props);

    makeObservable(this, {
      internalState: observable,
      layout: computed,
    });
  }

  get layout() {
    const { layout, defaults } = this.props;
    return defaults ? DDFormUtils.mergeDefaultsWithLayout(defaults, layout) : layout;
  }

  componentWillUnmount() {
    this.disposers.forEach(disposer => disposer());
  }

  renderFlattenedForm() {
    const {
      props,
      props: { formClassName }, // eslint-disable-line @typescript-eslint/no-unused-vars
    } = this;
    if (this.props.renderHiddenForm) {
      const flattenedFields: AnyObject = {};
      const addFieldsRecursively = (schema: ElementDef) => {
        if ('children' in schema) {
          (schema as GroupDef).children.forEach((childSchema) => {
            addFieldsRecursively(childSchema);
          });
        } else {
          if ('key' in schema) {
            const key = (schema as ElementDef).key ?? '';
            const value = DDFormUtils.getValue(key, props.data);
            flattenedFields[key] = value;
          }
        }
      };
      addFieldsRecursively(this.layout);

      return (
        <form style={{ display: 'none' }}>
          {Object.keys(flattenedFields).map((key) => (
            <div key={key}>
              {key}
              <input
                name={key}
                readOnly={true}
                value={'' + flattenedFields[key]}
              ></input>
            </div>
          ))}
        </form>
      );
    }
  }

  get formState() {
    return this.props.formState ?? this.internalState;
  }

  // get the value from the data for the field
  getValue = (element: ElementDef) => {
    const field = element as FieldDef;
    const { props } = this;
    const valueType =
      field.valueType ?? defaultValueTypes[field.type] ?? 'auto'; // determine the value type for this field
    const data = field.key.startsWith('.') ? this.formState?.data : props.data;
    const key = field.key.startsWith('.') ? field.key.substring(1) : field.key;

    let result = DDFormUtils.getValue(key, data, valueType);
    if (field.translateGetValue) {
      result = field.translateGetValue(result);
    }
    return result as FieldValueType;
  };

  setValue = async (element: ElementDef, value: FieldValueType) => {
    const field = element as FieldDef;
    const { props } = this;
    const defaultValueType = defaultValueTypes[field.type];

    const data = field.key.startsWith('.') ? this.formState.data : props.data;
    const key = field.key.startsWith('.') ? field.key.substring(1) : field.key;

    const previousValue = DDFormUtils.getValue(key, data);

    const valueType: string = field.translateSetValue
      ? 'direct'
      : field.valueType ?? defaultValueType ?? 'auto'; // determine the value type for this field
    if (field.translateSetValue) {
      value = field.translateSetValue(value);
    }
    if (props.onBeforeSetValue) {
      if (!(await props.onBeforeSetValue(key, data, value, field.valueType, field))) {
        return;
      }
    }
    runInAction(() => {
      DDFormUtils.setValue(key, data, value, valueType);
    });
    props.onAfterSetValue?.(key, data, value, previousValue);
  };

  checkForErrors() {
    const errors: { [key: string]: string; } = {};
    const checkFieldAndChildren = (field: ElementDef) => {
      let result = false;
      const { key, error, required, visible } = (field as FieldDef);
      const children = (field as GroupDef).children;
      const contents = (field as PageElementDef).contents;
      if (key && visible !== false) {
        if (error) {
          errors[key] = error;
          result = true;
        } else {
          const value = this.getValue(field as ElementDef);
          if (required) {
            if (!value && value !== 0) {
              errors[key] = (typeof required === 'string') ? required : 'Required field';
              result = true;
            }
          } else {
            if (field.type === 'number') {
              const numValue = parseInt('' + value, 10);
              const { minValue, maxValue, label } = (field as NumberInputDef);
              if (minValue !== undefined && numValue < minValue) {
                errors[key] = `${label} must be equal or greater than ${minValue}`;
              }
              if (maxValue !== undefined && maxValue && numValue > maxValue) {
                errors[key] = `${label} must be equal or lower than ${maxValue}`;
              }
            }
          }
        }
      }
      if (children) {
        children.forEach((child, idx) => {
          const childError = checkFieldAndChildren(child);
          if (childError && key) {
            errors[`${field.key}[${idx}]`] = 'true';
          }
          result = result || childError;
        });
      }
      if (contents) {
        contents.children.forEach((child, idx) => {
          const childError = checkFieldAndChildren(child);
          if (childError && field.key) {
            errors[`${field.key}[${idx}]`] = 'true';
          }
          result = result || childError;
        });
      }
      return result;
    };

    checkFieldAndChildren(this.layout);
    if (JSON.stringify(errors) !== JSON.stringify(this.formState.errors)) {
      runInAction(() => {
        this.formState.errors = Object.keys(errors).length ? errors : null;
      });
    }
  }

  render() {
    const {
      props,
      props: { formClassName, renderElement, size = 'medium' },
      layout,
      getValue, setValue,
      formState: { errors },
    } = this;

    setTimeout(() => {
      // check for form errors immediately after any render, which may force a new
      // render operation
      this.checkForErrors();
    });

    const result = (
      <div className={props.formClassName}>
        <div className={`DDForm DDForm-${size}`}>
          <form>
            {this.renderFlattenedForm()}

            <div className='rows'>
              {renderElement && renderElement(props, layout, getValue, setValue, errors)}
              {!renderElement && <h1>renderElement not provided</h1>}
            </div>
          </form>
        </div>
      </div>
    );

    return result;
  }
}
