import React from 'react'
import { RemoveButton } from '@/shared/components'

export default class FilterFieldsRow extends React.Component {
  render() {
    const { filterIcon, filterField, filterStyle, filterValue, isRemovable, onRemoveClick } = this.props

    return (
      <div className='search-bar__filters__item'>
        <div className='search-bar__filters__icon'>
          {isRemovable ? <RemoveButton onClick={onRemoveClick} text={''}/> : filterIcon}
        </div>
        <div className='search-bar__filters__field'>
          {filterField}
          {filterStyle}
          {filterValue}
        </div>
      </div>
    )
  }
}
