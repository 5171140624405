import React from 'react';

import autobind from 'class-autobind';

import MoleculeIcon from '@/shared/components/icons/MoleculeIcon.jsx';
import { IconButton } from '@mui/material';

import { StructureFormat } from '@/components/StructureEditor/StructureEditorTypes';
import { CDD } from '@/typedJS';
import { TMRVName } from '../types';
import { searchField as styles } from './styles';

export class SubstructureInput extends React.Component<{
  submitQuery: () => void;
  updateQuery: (newQuery: { mrv: TMRVName }) => void;
  mrv: TMRVName;
}> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  handleButtonClick(event) {
    // This prevents ghost click.
    event.preventDefault();

    this.openStructureEditor();
  }

  openStructureEditor() {
    const { mrv } = this.props;

    // TODO what's the point of `editorContext`
    CDD.StructureEditor.openMarvin4JSWithPromise(mrv, 'inventory_search', {
      structureFormat: StructureFormat.MOL,
      isSearch: true,
    })
      .then(this.onSubstructureChange)
      .catch((error) => {
        const reason = error.message;

        if (reason == 'empty') {
          this.onSubstructureChange({ mrv: '' });
        } else if (reason !== 'cancelled') {
          throw error;
        }
      });
  }

  onSubstructureChange({ mrv }: { mrv?: string } = {}) {
    const { submitQuery, updateQuery } = this.props;
    updateQuery({ mrv });
    submitQuery();
  }

  render() {
    const { mrv } = this.props;
    const text = mrv
      ? (
      <span className='search-bar__substructure__moleculesearch'>
        with structure
      </span>
        )
      : null;

    return (
      <div className='search-bar__substructure'>
        {text}
        <IconButton
          className='search-bar__substructure__button'
          onClick={this.handleButtonClick}
          style={styles.secondaryButtonStyle}
          // tooltip='Filter by substructure'
        >
          <MoleculeIcon size='small' style={styles.secondaryIconStyle} />
        </IconButton>
      </div>
    );
  }
}
