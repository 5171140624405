import React, { MouseEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';

export const SafetySummaryInfoDialog = (
  props: {
    banExternalLinks: boolean;
    open: boolean;
    onClose: () => void;
  },
) => {
  let lcss: string | JSX.Element = 'Laboratory Chemical Safety Summary (LCSS) project';
  if (!props.banExternalLinks) {
    lcss = (<Link
      href='https://pubchem.ncbi.nlm.nih.gov/docs/lcss'
      underline='none'
      target='_blank'
      rel='noreferrer'
      aria-label={lcss}
      onClick={(e: MouseEvent) => e.stopPropagation()}
    >
      {lcss}
    </Link>);
  }
  return (
    <Dialog open={props.open}>
      <DialogTitle className='muiDialog-title'>Information</DialogTitle>
      <DialogContent>
        <Typography variant='h5'>Source</Typography>
        <p>
          The safety summary in CDD Vault is based on information from
          the {lcss} on PubChem.
        </p>
        <Typography variant="h5">Structure matching</Typography>
        <p>
          Safety information is looked up using an exact match on the Inchi key of the structure.
          This means that the safety information is only available for the exact structure of the compound
          and will not match stereoisomers.

          If no information is available, it is always best to assume that a substance is toxic and/or
          hazardous or consult other sources.
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>OK</Button>
      </DialogActions>
    </Dialog >
  );
};
