/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { CustomFieldDef, ElementDef, FieldValueType } from '@/shared/components/DDForm/types';
import { getElementAttributes } from '../muiRenderers';

export const ddMuiRenderCustom: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as CustomFieldDef;
  return field.render?.(field, props, field, getValue, setValue, formErrors);
};
