export const formatDateAndName = (date: string, name: string) => {
  return (date ?? '') + (name ? ` (${name})` : '');
};

export const modifiedAtFormatter = (
  modified_by_user_full_name: string,
  modified_at: string,
  created_at: string,
) => {
  if (modified_by_user_full_name) {
    return formatDateAndName(modified_at, modified_by_user_full_name);
  } else {
    if (modified_at != created_at) {
      return formatDateAndName(modified_at, '');
    } else {
      return '';
    }
  }
};
