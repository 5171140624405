import React from 'react'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

import DragLayer from './DragLayer.jsx'

const containerStyles = {
  position: 'relative',
}

class SortableRows extends React.Component {
  constructor(props) {
    super(props)

    this.getBoundingClientRect = this._getBoundingClientRect.bind(this)
  }

  _getBoundingClientRect() {
    if (!this.ref) { return {} }
    return this.ref.getBoundingClientRect()
  }

  render() {
    const { RowPreviewClass, rowPreviewProps } = this.props

    return (
      <div
        className="sortable"
        style={containerStyles}
        ref={(ref) => this.ref = ref}
      >
        {this.props.children}
        <DragLayer
          getBoundingClientRect={this.getBoundingClientRect}
          RowPreviewClass={RowPreviewClass}
          rowPreviewProps={rowPreviewProps}
        />
      </div>
    )
  }
}

export default DragDropContext(HTML5Backend)(SortableRows)
