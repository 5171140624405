import { List, Record } from 'immutable'
import constants from 'javascripts/constants.js'
import {
  ADD_RESULT_COLUMN,
  CACHE_RESULT_COLUMNS,
  REMOVE_RESULT_COLUMN,
  REPLACE_RESULT_COLUMNS,
  UPDATE_SORT_COLUMN,
} from '../actions'
const {
  ELN_DEFAULT_RESULT_COLUMNS,
  SEARCH_SORT_COLUMN_ASCENDING,
  SEARCH_SORT_COLUMN_DESCENDING,
} = constants

const DEFAULTS = {
  eln_field_definition_id: null,
  field_name: '',
  direction: null,
}

export class ResultColumn extends new Record(DEFAULTS) {
  get selectValue() {
    return parseInt(this.eln_field_definition_id) || this.field_name
  }

  setDirection(direction) {
    if (direction == SEARCH_SORT_COLUMN_ASCENDING) {
      return this.merge({ direction: SEARCH_SORT_COLUMN_ASCENDING })
    } else {
      return this.merge({ direction: SEARCH_SORT_COLUMN_DESCENDING })
    }
  }

  flipDirection() {
    if (this.direction == SEARCH_SORT_COLUMN_ASCENDING) {
      return this.setDirection(SEARCH_SORT_COLUMN_DESCENDING)
    } else {
      return this.setDirection(SEARCH_SORT_COLUMN_ASCENDING)
    }
  }

  clearDirection() {
    return this.delete('direction')
  }
}

export const ResultColumns = (resultColumns) => {
  if (!resultColumns || !(resultColumns.size || resultColumns.length)) resultColumns = ELN_DEFAULT_RESULT_COLUMNS
  return List(resultColumns.map(resultColumn => new ResultColumn(resultColumn)))
}

export const resultColumns = (state = ResultColumns(), action) => {
  switch (action.type) {
    case ADD_RESULT_COLUMN: {
      return state.push(new ResultColumn({
        eln_field_definition_id: action.payload.fieldDefinition.id,
        field_name: action.payload.fieldDefinition.name,
      }))
    }
    case REPLACE_RESULT_COLUMNS: {
      const newColumns = action.payload.fieldDefinitions.map((fieldDefinition) => new ResultColumn({
        eln_field_definition_id: fieldDefinition.id,
        field_name: fieldDefinition.name,
      }))
      return ResultColumns(newColumns)
    }
    case REMOVE_RESULT_COLUMN: {
      const removedSelectValue = action.payload.fieldDefinition.selectValue
      const newColumns = state.filter((column) => (column.selectValue != removedSelectValue))
      return newColumns.size > 0 ? newColumns : ResultColumns(ELN_DEFAULT_RESULT_COLUMNS)
    }
    case UPDATE_SORT_COLUMN: {
      const sortColumnSelectValue = action.payload.selectValue
      const sortColumnDirection = action.payload.direction

      return state.map((column) => {
        if (column.selectValue == sortColumnSelectValue) {
          if (sortColumnDirection) {
            return column.setDirection(sortColumnDirection)
          } else {
            return column.flipDirection()
          }
        } else {
          return column.clearDirection()
        }
      })
    }
    case CACHE_RESULT_COLUMNS: {
      return ResultColumns(action.payload.resultColumns)
    }
    default:
      return state
  }
}
