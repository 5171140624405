import React from 'react'

import Highlights from './Highlights.jsx'
import Structures from './Structures.jsx'

export default class SearchContext extends React.Component {
  render() {
    const { result, displayStructureRow } = this.props
    const { highlights, structures } = result

    return (
      <React.Fragment>
        <Highlights highlights={highlights} />
        <Structures structures={structures} displayStructureRow={displayStructureRow}/>
      </React.Fragment>
    )
  }
}
