import React from 'react'
import {
  Chip,
} from '@mui/material'

export default class FilterSummary extends React.Component {
  renderFilterSummary = () => {
    const { activeFilterNames } = this.props

    let chips = []
    activeFilterNames.forEach((filterName, index) => {
      chips.push(<Chip key={index} label={filterName} className="search-bar__filter__chip" />)
    })

    return chips
  }

  render() {
    const { activeFilterNames, handleFilterPanelToggle } = this.props

    return (
      <div className='search-bar__filter__summary' onClick={handleFilterPanelToggle}>
        {activeFilterNames.length > 0 ? <p className="search-bar__summary__text">Filters currently applied: </p> : null}
        {this.renderFilterSummary()}
      </div>
    )
  }
}
