import React from 'react'

import AnyQueryForm from './AnyQueryForm.jsx'
import NumberQueryForm from './NumberQueryForm.jsx'
import DateQueryForm from './DateQueryForm.jsx'
import TextQueryForm from './TextQueryForm.jsx'

export default class QueryForm extends React.PureComponent {
  render() {
    const { data_type_name, field } = this.props

    if (!field) return <AnyQueryForm {...this.props} />

    switch (data_type_name) {
      case 'Number': return <NumberQueryForm {...this.props} />
      case 'Date': return <DateQueryForm {...this.props} />
      default: return <TextQueryForm {...this.props} />
    }
  }
}
