export const ABORT_POLL_FILE_ASSOCIATIONS_REQUEST = 'abort_poll_file_association_request'
export const POLL_FILE_ASSOCIATIONS_TICK = 'poll_file_associations_tick'
export const SET_ABORT_POLL_FILE_ASSOCIATIONS_REQUEST = 'set_abort_poll_file_association_request'
export const SET_POLL_FILE_ASSOCIATIONS_REQUEST = 'set_poll_file_association_request'

export const abortPollFileAssociationsRequest = () => ({
  type: ABORT_POLL_FILE_ASSOCIATIONS_REQUEST,
})

export const pollFileAssociationsTick = () => ({
  type: POLL_FILE_ASSOCIATIONS_TICK,
})

export const setAbortPollFileAssociationsRequest = aborted => ({
  type: SET_ABORT_POLL_FILE_ASSOCIATIONS_REQUEST,
  payload: { aborted },
})

export const setPollFileAssociationsRequest = currentRequest => ({
  type: SET_POLL_FILE_ASSOCIATIONS_REQUEST,
  payload: { currentRequest },
})
