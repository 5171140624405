import React from 'react'

import SvgIcon from './SvgIcon.jsx'

export default function CrossIcon(props = { id: 'cross-icon', 'disabled': false }) {
  const opacity = props.disabled ? '0.4' : '1'
  return (
    <SvgIcon {...props} viewBox="0 0 5.2916665 5.2916666">
      <g opacity={opacity}>
        <path
          fill="#5B6067"
          d="M 4.4433594,0.2109375 0.2109375,4.4433594 a 0.44999999,0.44999999 0 0 0 0,0.6367187 0.44999999,0.44999999 0 0 0 0.63671875,0 L 5.0800781,0.84765625 a 0.44999999,0.44999999 0 0 0 0,-0.63671875 0.44999999,0.44999999 0 0 0 -0.6367187,0 z"
          />
        <path
          fill="#5B6067"
          d="M 0.52929687,0.08007812 A 0.44999999,0.44999999 0 0 0 0.2109375,0.2109375 a 0.44999999,0.44999999 0 0 0 0,0.63671875 L 4.4433594,5.0800781 a 0.44999999,0.44999999 0 0 0 0.6367187,0 0.44999999,0.44999999 0 0 0 0,-0.6367187 L 0.84765625,0.2109375 A 0.44999999,0.44999999 0 0 0 0.52929687,0.08007812 Z"
          />
      </g>
    </SvgIcon>
  )
}
