/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, RadioGroupDef } from '@/shared/components/DDForm/types';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { getElementAttributes } from '../muiRenderers';

export const ddMuiRenderRadioGroup: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as RadioGroupDef;
  const optionFieldId = field.optionFieldId ?? 'id'; // also value
  const optionFieldLabel = field.optionFieldLabel ?? 'label';
  const currentValue = getValue(field);
  const error = props.showErrors && formErrors && formErrors[field.key];

  const handleChange = (event: React.SyntheticEvent, newValue: FieldValueType) => {
    const option = field.selectOptions.find(option => option[optionFieldId] === newValue);
    if (!option?.disabled) {
      setValue(field, newValue);
    }
  };

  return <FormControl
    key={field.renderKey}
    className={field.className}
    required={!!field.required}
    disabled={field.disabled === true}
    error={!!error}
  >
    {field.label && !field.horizontal && <FormLabel id={`${field.key}-label`}>{field.label}</FormLabel>}
    <RadioGroup
      {...getElementAttributes(field, props)}
      aria-labelledby={`${field.key}-label`}
      value={currentValue}
      onChange={handleChange}
      row={field.horizontal === true}
    >
      {field.label && field.horizontal &&
        <FormLabel sx={{ marginRight: '1rem', marginTop: '.6rem' }} id={`${field.key}-label`}>{field.label}</FormLabel>}
      {field.selectOptions.map(option =>
        <FormControlLabel key={'' + option[optionFieldId]}
          value={option[optionFieldId]}
          onClick={() => {
            if (!option.disabled && !field.disabled) {
              setValue(field, option[optionFieldId] as any);
            }
          }}
          control={
            <Radio disabled={option.disabled ?? field.disabled ?? false} checked={('' + currentValue) === ('' + option[optionFieldId])} />
          }
          label={option[optionFieldLabel]} />,
      )}
    </RadioGroup>
    {error && <FormHelperText error={true}>{error}</FormHelperText>}
  </FormControl>;
};
