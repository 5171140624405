/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-types */
import React, { Fragment } from 'react';
import autobind from 'class-autobind';
import {
  IconButton,
  Accordion,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore,
  FilterList,
} from '@mui/icons-material';
import { SearchButton } from './SearchButton.jsx';
import { searchField as styles } from '../styles.js';
import { term } from '@/shared/utils/stringUtils';

export type SearchFieldProps = {
  query: { text: string },
  placeholder: string,
  allowFilters: boolean,
  filtersOpen: boolean,
  oldButtonStyle: boolean,
  enableSearch: boolean,

  setQuery: (value: { text: string }) => void,
  setFiltersOpen: (value: boolean) => void,
  onClickSearch: () => void
};

export class SearchField extends React.Component<SearchFieldProps> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  onSubmit(event) {
    const { onClickSearch, setFiltersOpen } = this.props;
    event.preventDefault();
    event.stopPropagation();

    setFiltersOpen && setFiltersOpen(false);
    onClickSearch();
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSubmit(event);
    }
  }

  onQueryTextChange(event) {
    const { setQuery } = this.props;
    const { value } = event.currentTarget;

    setQuery({ text: value });
  }

  renderFilterIcon = () => {
    const { filtersOpen } = this.props;

    return (
      <IconButton
        className='search-bar__filter'
        style={styles.filterButtonStyle}
        // @ts-ignore
        onClick={this.props.handleFilterPanelToggle}
      >
        { filtersOpen ? <ExpandMore /> : <FilterList /> }
      </IconButton>
    );
  };

  renderFilterPanel = () => {
    // @ts-ignore
    const { filterPanelVisible } = this.props;

    return (
      <div className='search-bar__filter__open'>
        <Accordion expanded={filterPanelVisible} style={styles.panel}>
          {/* <FilterFields /> */}
        </Accordion>
      </div>
    );
  };

  render() {
    const { query, placeholder, allowFilters, filtersOpen, oldButtonStyle, enableSearch } = this.props;

    const text = (query && query.text) || '';

    return (
      <div className='search-bar__form'>
        <div className='search-bar__container'>
          {allowFilters && (<Fragment>
            filterPanelVisible ? this.renderFilterIcon() : (
            <Tooltip title={`Filter ${term('entry.other', true)}`}>
              { this.renderFilterIcon() }
            </Tooltip>
            )
          </Fragment>)
          }
          <input
            className='input-text' // search-bar__input'
            value={text || ''}
            onChange={this.onQueryTextChange}
            onKeyPress={this.handleKeyPress}
            placeholder={placeholder || 'Search (e.g. keyword*)'}
          />
          {oldButtonStyle
            ? (<div className='item'>
              <button name='button'
                disabled={!enableSearch}
                type='submit'
                className={`searchForm-button buttony buttony-small buttony-submit ${enableSearch ? '' : 'disabled'}`}
                onClick={this.onSubmit}>Go</button>
            </div>)
            : <SearchButton />}
        </div>
        {filtersOpen && this.renderFilterPanel() }
      </div>
    );
  }
}
