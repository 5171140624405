import React from 'react'

import Modal from './Modal/component.jsx'
import EditIcon from './EditIcon.jsx'

const PickListEditor = (props) => {
  const {
    id,
    dataTypeName,
    disabled,
    edit,
    uneditable,
  } = props

  if (uneditable || dataTypeName !== 'PickList') {
    return (null)
  } else {
    return (
      <React.Fragment>
        <EditIcon disabled={disabled} edit={edit} />
        <Modal id={id} {...props} />
      </React.Fragment>
    )
  }
}

export default PickListEditor
