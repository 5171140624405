import React from 'react';
import { computed, makeObservable } from 'mobx';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { ALPHANUMERIC_REGEXP } from '@/shared/constants.js';
import { AccountsUtils } from '@/Accounts/accountsUtils';
import {
  HasIdAndName,
  vaultRoleOptions,
  VaultWithProjectsSchema,
} from '@/Accounts/types';
import { CDDElements } from '@/shared/components/CDDForm/cddElements';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { RowColumnDef } from '@/shared/components/DDForm/types/rowColumnDef';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { getRootStore } from '@/stores/rootStore';
import { AccountLayoutHelper } from '@/Accounts/accountLayoutHelper';
import { EditUserStore } from '../stores/editUserStore';
import { ProjectSchema } from '../../types';
import './EditUserDialog.sass';
import { accountUserSettingsHumanMapping } from './constants';
import isEmail from 'validator/lib/isEmail';

type Props = {
  store: EditUserStore;
};

const {
  page,
  stepper,
  row,
  column,
  textInput,
  select,
  typography,
} = layoutBuilder;

@observer
export class EditUserDialog extends React.Component<Props> {
  disposers: Array<() => void> = [];

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      layout: computed,
    });
  }

  componentWillUnmount() {
    this.disposers.forEach((disposer) => disposer());
  }

  handleClose = (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    // ignore the background clicks per Kellan, as it's too easy to accidentally click off and lose work
    const { store } = this.props;
    if (reason === 'escapeKeyDown') {
      store.handleCancelEdit();
    }
  };

  private get layoutDetailsPage() {
    const {
      isEditingExisting,
      isCreatingNew,
      props: {
        store: {
          value,
          prohibitedEmails,
          prohibitedIdentifiersLowercase,
        },
      },
    } = this;

    const getEmailError = (email: string) => {
      if (!isEmail(value?.email ?? '')) {
        return 'Invalid email';
      }
      if (prohibitedEmails.includes(email)) {
        return 'A user with this email already exists in this account.';
      }
      return null;
    };

    const getIdentifierError = (identifier: string) => {
      if (prohibitedIdentifiersLowercase.includes(identifier?.toLowerCase())) {
        return `${accountUserSettingsHumanMapping.identifier} belongs to another user in this account.`;
      } else if (!ALPHANUMERIC_REGEXP.test(identifier)) {
        return `${accountUserSettingsHumanMapping.identifier} can only be alphanumeric.`;
      }
      return null;
    };

    return page({ label: 'User Details' }, [
      row([
        textInput({
          key: 'first_name',
          label: 'First name',
          disabled: isEditingExisting,
          required: isCreatingNew,
          autoFocus: true,
        }),
        textInput({
          key: 'last_name',
          label: 'Last name',
          disabled: isEditingExisting,
          required: isCreatingNew,
        }),
      ]),
      row([
        textInput({
          key: 'email',
          label: 'Email',
          required: true,
          disabled: isEditingExisting,
          error: getEmailError(value?.email),
        }),
        textInput({
          key: 'account_user_settings.identifier',
          label: accountUserSettingsHumanMapping.identifier,
          required: false,
          error: getIdentifierError(value?.account_user_settings?.identifier?.trim()),
        }),
      ]),
    ]);
  }

  private get layoutTeamPage() {
    const {
      props: {
        store,
        store: {
          value,
          root: {
            accountsStore: {
              teamsMap,
            },
          },
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [];

    const teams = Object.values(teamsMap);

    // filter out already selected options
    const availableTeamsToAdd = teams.filter(
      (option) => !value?.team_memberships?.hasOwnProperty(option.id ?? 0), // eslint-disable-line no-prototype-builtins
    );

    /**
     * Set up a typeahead select pulldown of the options
     */
    const addSelect = select({
      className: 'add-option-select',
      typeahead: true,
      visible: availableTeamsToAdd.length > 0,
      key: '.addOption',
      placeholder: 'Add a team',
      selectOptions: availableTeamsToAdd,
      optionFieldId: 'id',
      optionFieldLabel: 'name',
      autoFocus: true,

      translateSetValue: (value: HasIdAndName) => {
        if (value) {
          store.handleAddTeam(value.id);
        }
        return null;
      },
    });

    const currentTeamIds = keysAsNumbers(value.team_memberships);

    rows.push(
      row([addSelect]),
      ...currentTeamIds
        .filter(id => !!teamsMap[id])
        .sort((a, b) =>
          (teamsMap[a]?.name ?? '').localeCompare(teamsMap[b]?.name ?? ''))
        .map((id) =>
          AccountLayoutHelper.teamPermissionForUserRow(teamsMap[id], value),
        ),
    );

    if (!availableTeamsToAdd.length && !currentTeamIds.length) {
      rows.push(row([typography({ label: 'No available teams' })]));
    }
    return page({ label: 'Team Membership' }, rows);
  }

  private get layoutVaultMembershipPage() {
    const {
      props: {
        store,
        store: {
          value,
          availableVaultsToAdd,
          currentVaults,
          teamRequiredVaultIds,
          isEditingSelf,
          handleRemoveVault,
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [];
    const currentUserId = getRootStore().environmentStore.currentUser.id;
    const editingSelf = currentUserId === value.id;

    if (availableVaultsToAdd.length > 0) {
      /**
       * Set up a typeahead select pulldown of the options
       */
      const addSelect = select({
        className: 'add-option-select',
        typeahead: true,
        key: '.addOption',
        placeholder: 'Add a vault',
        selectOptions: availableVaultsToAdd,
        optionFieldId: 'id',
        optionFieldLabel: 'name',
        autoFocus: true,

        // Before we set the value into the object from the multiple select, translate it from an array of selected ids
        // into an object mapping the team ids to the role
        translateSetValue: (vault: VaultWithProjectsSchema) => {
          store.handleAddVault(vault.id);
          return null;
        },
      });
      rows.push(row([addSelect]));
    }

    // vault role option for every vault that is required by a team
    rows.push(
      ...currentVaults.map(vault => row([
        typography({
          label: vault.name,
          className: 'permission-label',
          width: 'expand',
        }),

        select({
          key: `vault_permissions.${vault.id}.role_name`,
          id: `vault_permissions.${vault.id}.role_name`,
          selectOptions: vaultRoleOptions,
          placeholder: 'Select role',
          required: true,
          disabled: editingSelf,
        }),

        CDDElements.deleteIconButton({
          key: `delete_${vault.id}`,
          visible: !teamRequiredVaultIds.has(vault.id) && !isEditingSelf,
          onClickButton: () => {
            handleRemoveVault(vault.id);
          },
        }),
        CDDElements.groupIconButton({
          tooltip: 'Required due to team permissions',
          visible: teamRequiredVaultIds.has(vault.id),
        }),
      ])),
    );

    return page({ label: 'Vault Membership' }, rows);
  }

  private get layoutProjectsPage() {
    const {
      props: {
        store: {
          value,
          selectedVaultsWithProjects,
          projectPermissions,
          vaultsMap,
          projectsMap,
          handleAddProject,
          handleRemoveProject,
        },
      },
    } = this;

    const rows: Array<RowColumnDef> = [];
    if (selectedVaultsWithProjects.length >= 5) {
      rows.push(
        row([
          textInput({
            label: '🔍 Filter by vault',
            key: '.filterByVault',
            visible: selectedVaultsWithProjects.length >= 5,
          }),
        ],
        ));
    }

    selectedVaultsWithProjects.forEach(vault => {
      const vaultRows: Array<RowColumnDef> = [];

      // if a vault id is passed, determine if we should show as read-only
      const readOnlyVault = AccountsUtils.isReadonlyVaultRole(value.vault_permissions[vault.id]?.role_name ?? '');

      // the projects this user is specifically added to
      const userProjects =
        keysAsNumbers(value.project_permissions)
          .filter(id => !value.project_permissions[id].compiled_from_teams)
          .map(id => projectsMap[id]);

      // the projects that this user can be added to
      const availableProjectsToAdd =
        vaultsMap[vault.id].projects
          .filter(project => !userProjects.includes(project))
          .sort(AccountsUtils.compareNames);

      if (availableProjectsToAdd.length) {
        const addSelect = select({
          className: 'add-option-select',
          typeahead: true,
          key: '.addOption',
          placeholder: 'Add a project',
          selectOptions: availableProjectsToAdd,
          optionFieldId: 'id',
          optionFieldLabel: 'name',
          autoFocus: true,

          translateSetValue: (value: ProjectSchema) => {
            if (value) {
              handleAddProject(value);
            }
            return null;
          },
        });
        vaultRows.push(row([addSelect]));
      }

      vault.projects.forEach(project => {
        const perm = projectPermissions[project.id];
        if (perm) {
          vaultRows.push(
            AccountLayoutHelper.permissionsRow(
              project.name,
              `project_permissions.${project.id}`,
              perm,
              () => {
                handleRemoveProject(project);
              },
              readOnlyVault ? 'ReadOnlyVault' : false,
            ),
          );
        }
      });

      rows.push(column({ legend: vault.name }, vaultRows));
    });

    if (!selectedVaultsWithProjects.length) {
      rows.push(row([typography({ label: 'No available projects' })]));
    }
    return page({ label: 'Projects Membership' }, rows);
  }

  get steps() {
    return [
      this.layoutDetailsPage,
      this.layoutTeamPage,
      this.layoutVaultMembershipPage,
      this.layoutProjectsPage,
    ];
  }

  get layout() {
    return stepper(
      {
        key: '.step',
        className: 'mainStepper',
      },
      this.steps,
    );
  }

  get isCreatingNew() {
    return !this.props.store.value?.id;
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  get dialogTitle() {
    const disabledText = this.props.store.value.disabled ? 'Disabled ' : '';
    return this.isEditingExisting
      ? `Edit ${disabledText}User: ${AccountsUtils.formatUserName(
        this.props.store.value,
      )}`
      : 'Create User';
  }

  render() {
    const {
      props: {
        store: {
          value,
          isOpen,
          formState,
          handleCancelEdit,
          handleSubmit,
        },
      },
    } = this;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          className='EditUserDialog edit-account-object-dialog'
          PaperProps={{ className: 'main-dialog-paper' }}
        >
          {value && (
            <>
              <DialogTitle className='muiDialog-title'>
                {this.dialogTitle}
              </DialogTitle>

              <DialogContent>
                <CDDModalForm
                  data={value ?? {}}
                  formState={formState}
                  layout={this.layout}
                  showNextBackStepper={this.isCreatingNew}
                  onOK={handleSubmit}
                  onCancel={handleCancelEdit}
                  terminology={{ OK: 'Save' }}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}
