import React from 'react';
import { BioisosterFragment, BioisosterSettings, ReferenceStructure } from './BioisosterResultsTypes';
import { BioisosterView, ReferenceView } from './BioisosterView';
import { ExpandableGroup, CenterContent } from '../layout';
import { BioisosterInfoNoFragments } from './BioisosterInfoDialog';

type BioisosterResultsProps = {
  bioisosterFragments: Array<BioisosterFragment>;
  structure: string;
  loading?: boolean;
  settings: BioisosterSettings
  reference: ReferenceStructure;
  banExternalLinks: boolean;
};

export const BioisosterResults = (props: BioisosterResultsProps) => {
  const { loading, structure, bioisosterFragments, settings, reference } = props;

  if (loading) {
    return CenterContent(<div>Loading...</div>);
  }
  if (!bioisosterFragments || !bioisosterFragments.length) {
    return <BioisosterInfoNoFragments />;
  }
  return (
    <>{
      bioisosterFragments.map((bioisosterFragment, key) => {
        return (
          <BioisosterFragmentGrid
            bioisosterFragment={bioisosterFragment}
            banExternalLinks={props.banExternalLinks}
            structure={structure}
            reference={reference}
            key={key}
            settings={settings}
          />
        );
      })
    }</>
  );
};

const BioisosterFragmentGrid = (props: {
  bioisosterFragment: BioisosterFragment;
  structure: string;
  settings: BioisosterSettings;
  reference: ReferenceStructure;
  banExternalLinks: boolean;
}) => {
  const { bioisosterFragment, structure, settings, reference } = props;
  const { fragment, bioisosters } = bioisosterFragment;

  const groupNode = (
    <ReferenceView
      structure={structure}
      fragment={fragment}
      reference={reference}
      settings={settings}
    />
  );
  const group = bioisosters.map((bioisoster, key) => (
    <BioisosterView
      bioisoster={bioisoster}
      banExternalLinks={props.banExternalLinks}
      reference={reference}
      key={key}
      settings={settings}
    />
  ));

  return (
    <ExpandableGroup
      key={fragment}
      groupNode={groupNode}
      group={group}
    />
  );
};
