import React from 'react';

import { Terminology } from 'javascripts/cross_app_utilities.js';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { StringOrNumber } from '@/types';

type Props = {
  definition: FieldDefinition;
  objectName: string;
  attribute: string;
};

export default class Warning extends React.Component<Props> {
  static message(definition, objectName, attribute) {
    const getVal = (attribute: string, defaultValue: StringOrNumber = '') => {
      return definition.get?.(attribute, '') ?? definition[attribute] ?? defaultValue;
    };
    const name = getVal('name');
    const fields_count = getVal(attribute, 0);
    const prefix = `“${name}” will be removed`;

    // TODO: remove once inventory terminology is implemented
    const fallbackLabels = {
      inventory_event: ['inventory', 'inventories'],
      inventory_sample: ['sample', 'samples'],
      inventory: ['inventory', 'inventories'],
    };

    const objectLabel =
     (fields_count <= 1)
       ? Terminology.dictionary[objectName] ?? fallbackLabels[objectName]?.[0] ?? objectName
       : Terminology.dictionary[`${objectName}.other`] ?? fallbackLabels[objectName]?.[1] ?? (objectName + 's');

    if (fields_count === 0) {
      return `${prefix} (No ${objectLabel} data associated)`;
    } else {
      return `${prefix} from  ${fields_count} ${objectLabel}`;
    }
  }

  render() {
    const { definition, objectName, attribute } = this.props;

    return (
      <div className="removed_notice">
        <span className="warning">
          {Warning.message(definition, objectName, attribute)}
        </span>
      </div>
    );
  }
}
