import React from 'react'
import { DragLayer } from 'react-dnd'

const layerStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  opacity: '0.5',
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }
}

class CustomDragLayer extends React.Component {
  constructor(props) {
    super(props)

    this.getItemStyles = this._getItemStyles.bind(this)
  }

  _getItemStyles() {
    const { currentOffset, getBoundingClientRect } = this.props
    if (!currentOffset) {
      return {
        display: 'none',
      }
    }

    const currentY = currentOffset.y
    const { top } = getBoundingClientRect()
    const y = currentY - top

    return {
      transform: `translateY(${y}px) translateY(-50%)`,
    }
  }

  render() {
    const { isDragging, item, RowPreviewClass, rowPreviewProps } = this.props

    if (!isDragging) {
      return null
    }

    const props = rowPreviewProps(item.id)

    return (
      <div style={layerStyles}>
        <div style={this.getItemStyles()}>
          <RowPreviewClass {...props} />
        </div>
      </div>
    )
  }
}

export default DragLayer(collect)(CustomDragLayer)
