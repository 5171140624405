import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { InventoryAPIResultColumnIDs } from '@/Inventory/components/types';
import { LabelAndValue } from '@/models/Salt/SaltPicker';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FilterValueSelectProps } from './FilterValueSelect.types';

@observer
export class FilterUniqueListValue extends React.Component<
  FilterValueSelectProps<FieldDataType.PickList>
> {
  constructor(props) {
    super(props);
    makeObservable(
      this,
      {
        inputValue: observable,
        value: observable,
        suggestions: observable,
      },
      { autoBind: true },
    );
  }

  inputProps = {
    type: 'text',
  };

  value: LabelAndValue[] = [];

  inputValue = '';

  suggestions: LabelAndValue[] = [];

  // TODO do something to cache available options with a given search string
  get options(): LabelAndValue[] {
    return this.suggestions;
  }

  fetchMatchingOptions = async (inputValue) => {
    this.inputValue = inputValue;
    if (inputValue !== '') {
      const { suggestions } = await this.props.getAutocompleteOptions({
        select_value: this.props.fieldDefinition
          .id as InventoryAPIResultColumnIDs,
        searched_string: inputValue,
        field_type: this.props.fieldDefinition.field_type,
      });
      this.suggestions = suggestions;
    }
  };

  render() {
    return (
      <FormControl>
        <Autocomplete
          multiple
          inputValue={this.inputValue}
          value={this.value}
          onInputChange={(_event, value) => {
            this.fetchMatchingOptions(value);
          }}
          onChange={(_event, value) => {
            this.props.handleValueChange(
              value.map((val) => val.value) as string[],
            );
            this.value = value;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                className="filter__value__autocomplete"
                {...this.inputProps}
                InputProps={{
                  ...params.InputProps,
                  inputProps: {
                    ...params.inputProps,
                  },
                }}
                onPaste={(event) => {
                  const text = event.clipboardData.getData('text');
                  const idList = text
                    .replace(/,/g, '\n')
                    .split('\n')
                    .map((val) => val.trim())
                    .filter((val) => val !== '');

                  const value = [
                    ...this.value,
                    ...idList.map((id) => ({ label: id, value: id })),
                  ];
                  this.props.handleValueChange(
                    value.map((val) => val.value) as string[],
                  );
                  this.value = value;
                  this.inputValue = '';
                  event.preventDefault();
                }}
              />
            );
          }}
          autoHighlight
          sx={{ width: 300 }}
          options={this.options}
        />
      </FormControl>
    );
  }
}
