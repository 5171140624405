import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import React, { MouseEvent } from 'react';

export const ExpandMoreButton = (props: {
  expanded?: boolean;
  onClick: (e: MouseEvent) => void;
}) => {
  const { expanded } = props;
  const sx = {
    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: 'all 0.5s',
  };
  return (
    <IconButton
      onClick={props.onClick}
      color='primary'
      aria-label={expanded ? 'hide' : 'show'}
    >
      <ExpandMoreIcon sx={sx} />
    </IconButton>
  );
};
