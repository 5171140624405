import React from 'react';

const DEGREE_CHARACTER = '°';
const LOWERCASE_GREEK_LETTERS = ['α', 'β', 'γ', 'δ', 'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'σ', 'ς', 'τ', 'υ', 'φ', 'χ', 'ψ', 'ω'];
const UPPERCASE_GREEK_LETTERS = ['Α', 'Β', 'Γ', 'Δ', 'Ε', 'Ζ', 'Η', 'Θ', 'Ι', 'Κ', 'Λ', 'Μ', 'Ν', 'Ξ', 'Ο', 'Π', 'Ρ', 'Σ', 'Τ', 'Υ', 'Φ', 'Χ', 'Ψ', 'Ω'];
const TOP_ROW = UPPERCASE_GREEK_LETTERS.concat([DEGREE_CHARACTER]);
const BOTTOM_ROW = LOWERCASE_GREEK_LETTERS;

type Props = {
  name: string | number;
  onSelect: (value: string) => void;
};

type CharacterProps = {
  letter: string;
  onSelect: (value: string) => void;
}

function SpecialCharacterLink(props: CharacterProps) {
  const { letter, onSelect } = props;

  return (
    <>
      <a onClick={() => onSelect(letter)}>{letter}</a>
      <span> </span>
    </>
  );
}

export const SpecialCharacterSelect = React.memo((props: Props) => {
  const { name, onSelect } = props;

  return (
    <div
      id={`${name}_special_characters`}
      className="collapsible show-panel details collapsible-closed"
    >
      <h5 className="collapsible-title">
        <a id={`${name}_special_characters_title`} className="unbold">Special characters</a>
      </h5>
      <div id={`${name}_special_characters_inner`} className="collapsible-inner monospace">
        { TOP_ROW.map((letter) => <SpecialCharacterLink key={letter} letter={letter} onSelect={onSelect} />) }
        <br />
        { BOTTOM_ROW.map((letter) => <SpecialCharacterLink key={letter} letter={letter} onSelect={onSelect} />) }
      </div>
    </div>
  );
});
SpecialCharacterSelect.displayName = 'SpecialCharacterSelect';

export default SpecialCharacterSelect;
