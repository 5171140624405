import { LEFT_BUTTON } from './eventConstants.js'

const handleLeftClickLikeLink = url => event => {
  if (event.button === LEFT_BUTTON) {
    event.preventDefault()

    if (event.metaKey) {
      window.open(url)
    } else if (event.shiftKey) {
      window.open(url, '_blank')
    } else {
      window.location = url
    }
  }
}

export default handleLeftClickLikeLink
