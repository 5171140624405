import { FieldDataType, FilterStyleKey } from '@/FieldDefinitions/types';
import { TInventoryFilter } from '@/Search/SearchFilters/FilterValueSelect.types';
import constants from 'javascripts/constants.js';
const { SEARCH_FILTER_STYLES } = constants;

export function limitFilterStyles(
  data_type_name: TInventoryFilter['data_type_name'],
) {
  let applicableFilterStyles;

  if (
    data_type_name === FieldDataType.Date ||
    data_type_name === FieldDataType.Number
  ) {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter((filterStyle) => {
      return !(
        filterStyle.get('key') === FilterStyleKey.HAS ||
        filterStyle.get('bool') ||
        filterStyle.get('list')
      );
    }).toJS();
  } else if (data_type_name == FieldDataType.Boolean) {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
      (filterStyle) =>
        filterStyle.get('bool') ||
        filterStyle.get('key') == FilterStyleKey.ANY ||
        !filterStyle.get('list'),
    ).toJS();
    // } else if (data_type_name == FieldDataType.UniqueList) {
    //   applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
    //     (filterStyle) =>
    //       filterStyle.get('bool') ||
    //       filterStyle.get('key') == FilterStyleKey.ANY ||
    //       filterStyle.get('list'),
    //   ).toJS();
  } else {
    applicableFilterStyles = SEARCH_FILTER_STYLES.filter(
      (filterStyle) =>
        !(
          filterStyle.get('ranged') ||
          filterStyle.get('bool') ||
          filterStyle.get('list')
        ),
    ).toJS();
  }

  return applicableFilterStyles;
}
