import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { pick } from 'lodash'

import MultiSelect from 'shared/components/MultiSelect.jsx'

@inject(stores => ({
  ...pick(stores.store, [
    'elnWitnessingAdminIds',
    'elnWitnessingAdminsById',
    'onChange',
    'users',
  ]),
  disabled: !stores.store.elnWitnessingEnabled,
}))
@observer
export default class NotificationAdminList extends Component {
  onChange = event => {
    this.props.onChange({ elnWitnessingAdminIds: event.target.value })
  }

  onDelete = id => () => {
    const withDeleted = this.props.elnWitnessingAdminIds
      .filter(_id => id !== _id)

    this.props.onChange({ elnWitnessingAdminIds: withDeleted })
  }

  itemProps = ({ id, full_name }) => {
    const selected = !!this.props.elnWitnessingAdminsById[id]

    return ({
      id,
      selected,
      label: full_name,
      value: id,
    })
  }

  render() {
    const {
      disabled,
      elnWitnessingAdminIds,
      users,
    } = this.props

    return (
      <MultiSelect
        tagged
        className='eln-witnessing__admins'
        disabled={disabled}
        value={elnWitnessingAdminIds}
        onChange={this.onChange}
        onDelete={this.onDelete}
        inputProps={{ className: 'settings-page__multi-select' }}
        items={users}
        itemProps={this.itemProps}
      />
    )
  }
}
