import { Sample } from '@/Samples/types';
import { Tooltip } from '@mui/material';
import React from 'react';

type Props = {
  cellSize: number;
  cellPadding: number;
  position: number;
  selected: boolean;
  sample: Sample;
  onClick: (position: number) => void;
};

export class LocationBoxPosition extends React.Component<Props> {
  handleClick = () => {
    if (!this.props.sample) {
      this.props.onClick(this.props.position);
    }
  };

  render() {
    const { cellSize, cellPadding, position, selected, sample } = this.props;
    let className = `box-position-element ${selected ? 'box-position-selected' : ''}`;
    className += sample ? ' box-position-filled' : ' box-position-empty';

    const fontSize = Math.min(16, cellSize / 2);
    const element =
      <div
        className={className}
        onClick={this.handleClick}
        style={{ width: cellSize, height: cellSize, margin: cellPadding + 'px' }}>
        <label style={{ fontSize: (fontSize / 16) + 'rem' }}>{position}</label>
      </div>;
    if (sample) {
      return <Tooltip title={sample.name}>{element}</Tooltip>;
    }
    return element;
  }
}
