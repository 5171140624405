import * as React from 'react';
import { observer } from 'mobx-react';
import autobind from 'class-autobind';
import { LaunchStructureEditor } from '@/components';

type Props = {
  structure: string;
  onClick: () => void;
};

@observer
export class StructureEditorLauncherButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    autobind(this);
  }

  render() {
    const { structure, onClick } = this.props;

    return (
      <div className="structureContainerRow" >
        <LaunchStructureEditor frontendRenderOnly={true} src={structure} onClick={onClick}/>
      </div>
    );
  }
}
