/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/ban-types */
import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

type Props = {
  errorMessages: Object
};

export default class Errors extends React.Component<Props> {
  constructor(props) {
    super(props);

    // @ts-ignore
    this.visible = this._visible.bind(this);
  }

  componentDidUpdate() {
    // @ts-ignore
    if (this.visible()) {
      // @ts-ignore
      scrollIntoView(this.div, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }

  _visible() {
    // @ts-ignore
    const { disabled, errorMessages } = this.props;

    // @ts-ignore
    return !disabled && errorMessages && (errorMessages.size || errorMessages.length) > 0;
  }

  render() {
    let { errorMessages } = this.props;
    // @ts-ignore
    errorMessages = (errorMessages.toArray ? errorMessages.toArray() : errorMessages) || [];
    const classes = ['container--fade'];

    // @ts-ignore
    if (this.visible()) {
      classes.push('visible');
    }

    return (
      <div className={classes.join(' ')} ref={div => { // @ts-ignore
        this.div = div;
      }}>
        <div className="errorMessage">
          <ul>
            { // @ts-ignore
              errorMessages.map(function (errorMessage) {
                return <li key={errorMessage}>{errorMessage}</li>;
              })}
          </ul>
        </div>
      </div>
    );
  }
}
