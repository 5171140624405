import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

import Item from './Item.jsx'
import SubmitOrCancel from '@/shared/components/SubmitOrCancel.jsx'

const instructions = 'Type in a value and hit enter or paste in a list of values, one per line'

export default class Modal extends React.Component {
  handleInput = (event) => {
    this.props.updateInput(event.target.value)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      event.nativeEvent.stopImmediatePropagation()
      this.props.add(event.target.value)
    }
  }

  handlePaste = (event) => {
    const data = event.clipboardData.getData('Text')

    if (data) {
      event.stopPropagation()
      event.preventDefault()
      this.props.addMany(data.split('\n'))
    }
  }

  render() {
    const { input, batchFieldDefinition, pickListValues, open, invalidIds, notification, onSubmit, onCancel, update, remove } = this.props
    const disabled = invalidIds.size

    return (
      <Dialog
        open={open}
        onClose={onCancel}
        maxWidth='sm'
        fullWidth
        PaperProps={{ classes: { root: 'pick_list__container' } }}
      >
        <DialogTitle className="pickListTitle">
          Pick List
        </DialogTitle>
        <DialogContent id="pick-list-editor" className="batch-field-definitions">
          <div className="pickListEntryWrapper">
            <input
              autoFocus
              className="pickListItemTextBox"
              onChange={this.handleInput}
              onKeyDown={this.handleKeyDown}
              onPaste={this.handlePaste}
              value={input}
              ref={ref => (this.input = ref)}
              placeholder={instructions}
            />
            <div className="pickListNotification">
              {notification}
            </div>
          </div>
          <div className='pickListItems'>
            {pickListValues.map(pickListValue => {
              return (
                <Item
                  key={pickListValue.get('id')}
                  batchFieldDefinition={batchFieldDefinition}
                  pickListValue={pickListValue}
                  update={update}
                  remove={remove}
                  invalid={invalidIds.includes(pickListValue.get('id'))}
                  onSelect={() => { this.input.focus() }}
                />
              )
            })}
          </div>
        </DialogContent>
        <DialogActions className="pickListActionsContainer">
          <div className="pickListActions">
            <SubmitOrCancel
              disabled={disabled}
              onSubmit={onSubmit}
              onCancel={onCancel}
              green={false}
            >
              Update Pick List
            </SubmitOrCancel>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}
