/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { IconButton, Box } from '@mui/material/';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import constants from 'javascripts/constants.js';

const { DATE_DISPLAY_FORMAT } = constants;

type Props = {
  className?: string;
  onChange: (value: unknown) => void;
  value: unknown;
}

const QueryDateButton = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setIsOpen((isOpen) => !isOpen);
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className='molecule_criteria__query__date_button'>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          componentsProps={{
            actionBar: { actions: ['cancel', 'accept', 'clear'] },
          }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          value={props.value}
          onChange={(newValue) => {
            const formatted = (newValue === null) ? '' : moment(newValue).format(DATE_DISPLAY_FORMAT);
            props.onChange(formatted);
          }}
          PopperProps={{
            placement: 'bottom-end',
            anchorEl,
          }}
          renderInput={(params) => (
            <IconButton onClick={handleClick}>
              <CalendarMonthIcon/>
            </IconButton>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default QueryDateButton;
