import React from 'react';

type Props = {
  disabled?: boolean;
  style?: React.CSSProperties;
  onClick: () => void;
}

export default function PickListEditIcon(props: Props) {
  const { disabled, style, onClick } = props;

  if (disabled) {
    return (
      <span className="pick-list-edit fa fa-pencil disabled" style={style}/>
    );
  } else {
    return (
      <span
        className="pick-list-edit editPickListButton small-edit-icon fa fa-pencil"
        style={style}
        title="Edit Pick List values"
        onClick={onClick}
      />
    );
  }
}
