import { List, Map, Record, fromJS } from 'immutable'
import { camelCaseKeys } from '@/shared/utils/camelCaseKeys.js'

const HIGHLIGHT_DEFAULTS = {
  name: '',
  fragments: List(),
}

export class Highlight extends new Record(HIGHLIGHT_DEFAULTS) { }

const STRUCTURE_DEFAULTS = {
  name: '',
  imagePath: '',
  className: '',
  isMatching: false,
  mrv: '',
  molfile: '',
  highlightedStructures: [],
}

export class Structure extends new Record(STRUCTURE_DEFAULTS) {
  constructor(props) {
    super(camelCaseKeys(props))
  }
}

const DEFAULTS = {
  highlights: List(),
  structures: List(),
}

export class Result extends new Record(DEFAULTS) {
  constructor(result = {}) {
    const highlights = List(
      result.highlights
        .filter(highlight => highlight.fragments)
        .map(highlight => {
          return new Highlight(fromJS(highlight))
        })
    )
    const structures = List(
      result.structures.map(structure => new Structure(structure))
    )

    super({ highlights, structures })
  }

  isEmpty() {
    return this.highlights.isEmpty() && this.structures.isEmpty()
  }
}

const COLLECTION = {
  byId: Map(),
}

export class Results extends new Record(COLLECTION) {
  constructor(results = {}) {
    const byId = Object.keys(results).reduce((memo, id) => {
      return memo.set(id, new Result(results[id]))
    }, Map())

    super({ byId })
  }

  append(results = {}) {
    return this.mergeDeep(new Results(results))
  }
}
