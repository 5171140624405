import { combineReducers } from 'redux-immutable'
import { routerReducer } from 'react-router-redux'

import compose from '@/shared/utils/compose.js'
import {
  constants,
  query,
  request,
  status,
  state,
  Results,
  resultColumns,
  Status,
  page,
} from '../models'
import { Entries } from '@/models/Eln/Entry.js'
import { FieldDefinitions } from '@/Eln/Entry/models/FieldDefinition'

const reducer = compose(
  combineReducers({
    routing: routerReducer,
    elnEntries: (args = new Entries()) => args,
    elnFieldDefinitions: (args = new FieldDefinitions()) => args,
    constants,
    query,
    resultColumns,
    request: request,
    results: (args = new Results()) => args,
    status: (args = new Status()) => args,
    page,
  }),
  state,
  status
)

export default reducer
