import React from 'react';
import { ChemicalPropertiesSearchItem, SearchItem } from './ChemicalPropertiesSearchItem';
import { deepClone } from '@/Annotator/data/utils';

type Props = {
  fields: { [groupName: string]: { [termId: string] : string } }
  structure_criterion?: { [termId: string] : string }, // data that the server sends which we will convert to initialSearchTerms
  searchItems: SearchItem[];
  assignProperties?: (props: Partial<Props>) => void;
}

// convert either an object of key/value pairs (the format from a search) or an array of
// {name,value} objects (the format from History.restoreFormStateEvent) into a SearchItem[] array

const convertToInitialSearchTerms = (structure_criterion: {[key: string]: string}) => {
  const result: SearchItem[] = [];

  Object.keys(structure_criterion ?? {})
    .forEach(key => {
      const searchTermId = key.replace('_minimum', '').replace('_maximum', '');
      let item = result.find(item => (item.searchTermId === searchTermId));
      if (!item) {
        item = { searchTermId };
        result.push(item);
      }
      if (key.endsWith('_minimum')) {
        item.min = structure_criterion[key];
      } else {
        item.max = structure_criterion[key];
      }
    });

  if (!result.length) {
    result.push({});
  }
  return result;
};

const convertToStructureCriterion = (items: SearchItem[]) => {
  const result = {};
  items.forEach(item => {
    const { searchTermId } = item;
    if (searchTermId) {
      if (item.min !== undefined) {
        result[searchTermId + '_minimum'] = item.min;
      }
      if (item.max !== undefined) {
        result[searchTermId + '_maximum'] = item.max;
      }
    }
  });
  return result;
};

const ChemicalPropertiesSearchComponent = (props: Props) => {
  const searchTerms = props.searchItems ?? convertToInitialSearchTerms(props.structure_criterion ?? {});

  const updateSearchItems = (newSearchItems: SearchItem[]) => {
    props.assignProperties({
      structure_criterion: convertToStructureCriterion(newSearchItems),
      searchItems: newSearchItems,
    });
  };
  const handleAddTerm = () => {
    updateSearchItems([...searchTerms, {}]);
  };

  const handleRemoveTerm = (index) => {
    const newSearchItems = [...searchTerms];
    newSearchItems.splice(index, 1);
    if (!newSearchItems.length) {
      newSearchItems.push({});
    }
    updateSearchItems(newSearchItems);
  };

  const handleChangeItem = (index, newValues) => {
    const newSearchItems = [...searchTerms];
    Object.assign(newSearchItems[index], newValues);
    updateSearchItems(newSearchItems);
  };

  // form a set of all selected terms
  const setUsedSearchTerms = new Set();
  for (const item of searchTerms) {
    setUsedSearchTerms.add(item.searchTermId);
  }

  const allAvailableTerms = new Set(); // flattened set of available options (including ones in use)
  const searchTermIdToLabel = {}; // flat map of id to labels for search terms
  const searchTermIdToGroup = {}; // flat map of id to group name

  const availableOptions = deepClone(props.fields);

  for (const group in availableOptions) {
    for (const option in availableOptions[group]) {
      allAvailableTerms.add(option);
      searchTermIdToGroup[option] = group;
      searchTermIdToLabel[option] = availableOptions[group][option];
    }
  }

  // Remove selected terms that aren't in the set of available options. This can happen if the context is
  // changed after a search is run, and the selected option is no longer present,
  const filteredTerms = searchTerms.filter(term => {
    return (!term.searchTermId) || allAvailableTerms.has(term.searchTermId);
  });

  // if we have selected a search term from any group, hide all other groups
  if (filteredTerms.length && filteredTerms[0].searchTermId) {
    const selectedGroup = searchTermIdToGroup[filteredTerms[0].searchTermId];
    Object.keys(availableOptions).forEach(group => {
      if (group !== selectedGroup) {
        delete availableOptions[group];
      }
    });
  }

  if (!filteredTerms.length) {
    filteredTerms.push({});
  }

  const getAvailableOptionsForItem = (searchTermId: string) => {
    // filter out any options that were already selected
    const result = deepClone(availableOptions);

    // remove any terms in use from the available options
    for (const group in result) {
      for (const option in result[group]) {
        if (setUsedSearchTerms.has(option) && option !== searchTermId) {
          delete result[group][option];
        }
      }
      // delete any empty groups
      if (!Object.keys(result[group]).length) {
        delete result[group];
      }
    }
    return result;
  };

  return (
    <div>
      {filteredTerms.map((item, index) => (
        <ChemicalPropertiesSearchItem
          key={index}
          availableOptions={getAvailableOptionsForItem(item.searchTermId)}
          items={filteredTerms}
          itemIndex={index}
          label={searchTermIdToLabel[item.searchTermId]}
          value={item.searchTermId}
          onAddTerm={handleAddTerm}
          onRemoveTerm={handleRemoveTerm}
          onChangeItem={handleChangeItem}
        />
      ))}
    </div>
  );
};

export default function ChemicalPropertiesSearch(props: Props) {
  // convert options to JSON object if passed in as a string
  if (props.fields && typeof props.fields === 'string') {
    props.fields = JSON.parse(props.fields);
  }

  return <ChemicalPropertiesSearchComponent {...props}/>;
}
