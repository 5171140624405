import React from 'react'

export default function NameField(props) {
  const { value, onChange, readOnly, uneditable } = props

  if (uneditable) {
    return (<span>{value}</span>)
  } else {
    return (
      <input
        type="text"
        name="name"
        value={value || ''}
        onChange={onChange}
        size="14"
        maxLength="30"
        readOnly={readOnly}
      />
    )
  }
}
