/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldDef, FieldValueType, StepperDef, TabsDef } from '@/shared/components/DDForm/types';
import { Error } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { ddMuiRenderPage } from './ddMuiRenderPage';
import { getElementAttributes } from '../muiRenderers';

export const ddMuiRenderTabs: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string } | null,
) => {
  const field = {
    ...(element as FieldDef),
    valueType: 'number',
  } as TabsDef | StepperDef;

  const tabsValue = getValue(field) ?? 0;

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(field, parseInt(newValue, 10));
  };

  const attrs = getElementAttributes(field, props);
  return (
    <TabContext
      key={field.renderKey}
      value={'' + tabsValue} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} {...attrs}>
        <TabList
          onChange={handleChange}>
          {field.children.map((child, index) => {
            const showError = props.showErrors && !!formErrors?.[`${field.key}[${index}]`];

            return <Tab
              key={child.label + index}
              label={child.label}
              value={'' + index}
              sx={theme => showError
                ? {
                    color: theme.palette.error.main,
                    '&.Mui-selected': {
                      color: theme.palette.error.main,
                    },
                  }
                : {}}
              icon={child.error ? <Error /> : null}
              iconPosition='start'
            />;
          })}
        </TabList>
      </Box>
      {field.children.map((child, index) => (
        <TabPanel key={field.children[index].label + index} value={'' + index} className={field.type === 'stepper' ? 'stepper-page-container' : ''}>
          {ddMuiRenderPage(
            props,
            child,
            getValue,
            setValue,
            formErrors,
          )}
        </TabPanel>
      ))}
    </TabContext>);
};
