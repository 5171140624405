import persistable from '@/Eln/Entry/persistable.js'

export const CACHE_UPLOADED_FILE = 'eln/entry/uploadedFile/cache'
export const DETACH_UPLOADED_FILE = 'elnl/entry/uploadedFile/detach'
export const UPDATE_UPLOADED_FILE = 'eln/entry/uploadedFile/update'
export const UPDATE_UPLOADED_FILES = 'eln/entry/uploadedFiles/update'
export const READ_UPLOADED_FILE_CONTENT = 'eln/entry/uploadedFile/content/read'
export const UPDATE_UPLOADED_FILE_CONTENT = 'eln/entry/uploadedFile/content/update'

export const READ_STOICHIOMETRY_DATA = 'eln/entry/uploadedFile/stiochiometryData/read'

const throwIfMissing = () => {
  throw new Error('Invalid file parameters!')
}

export const cacheUploadedFile = (fileAssocationId = null, fileId = throwIfMissing(), nodeKey = throwIfMissing()) => ({
  type: CACHE_UPLOADED_FILE,
  payload: { fileAssocationId, fileId, nodeKey },
})

export const detachUploadedFile = (nodeKey = throwIfMissing()) => ({
  type: persistable.actionTypes.MODIFY,
  subtype: DETACH_UPLOADED_FILE,
  payload: { nodeKey },
})

export const updateUploadedFile = (file = throwIfMissing()) => ({
  type: persistable.actionTypes.MODIFY,
  subtype: UPDATE_UPLOADED_FILE,
  payload: {
    file: file.toJS ? file.toJS() : file,
  },
})

export const updateUploadedFiles = (files = throwIfMissing()) => ({
  type: UPDATE_UPLOADED_FILES,
  payload: {
    files,
  },
})

export const readUploadedFileContent = fileId => ({
  type: READ_UPLOADED_FILE_CONTENT,
  payload: { fileId },
})

export const updateUploadedFileContent = file => ({
  type: UPDATE_UPLOADED_FILE_CONTENT,
  payload: { file },
})

export const readStoichiometryData = fileId => ({
  type: READ_STOICHIOMETRY_DATA,
  payload: { fileId },
})
