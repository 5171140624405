import React from 'react';
import { Icon, IconButton } from '@mui/material';
import { EditFormDefinitionStore } from '../../stores/editFormDefinitionStore';
import { FormFieldContents } from './FieldContents';
import { observer } from 'mobx-react';
import { FormLayoutForEditing } from '@/FormDefinitions/types';

type Props = {
  field: FormLayoutForEditing;
  store: EditFormDefinitionStore;
}

type State = {
  in: boolean;
}

@observer
export class Field extends React.Component<Props, State> {
  ref = React.createRef<HTMLSpanElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      in: false,
    };
  }

  handleClickEdit = () => {
    const { store, field } = this.props;
    if (typeof field.fieldID !== 'string') {
      store.handleEditField(field);
    }
  };

  render() {
    const { field, store: { isRequired, dragStore: { dragState: { currentDraggableId } } } } = this.props;

    const showEdit = typeof field.fieldID !== 'string';

    let className = 'FormField';
    if (field.layoutID === currentDraggableId) {
      className += ' FormField-drag-placement';
    }

    const fieldIsRequired = isRequired(field) || (field.fieldID === 'protocol_name' || field.fieldID === 'run_date');

    const props = {
      ...this.props,
    };

    if (fieldIsRequired) {
      props.field = {
        ...props.field,
        label: `${props.field.label} *`,
      };
    }

    return <span className={className} data-draggable={field.layoutID}
      id={`draggable-${field.layoutID}`}
      onDoubleClick={this.handleClickEdit}>
      <FormFieldContents {...props}/>

    { showEdit
      ? <IconButton onClick={this.handleClickEdit} className='btn-edit-field'>
          <Icon className={'fa fa-pencil'} />
        </IconButton>
      : null}
    </span>;
  }
}
