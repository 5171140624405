import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'
import Icon from '@/shared/components/Icon.jsx'

export default class AddButton extends React.PureComponent {
  handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClick()
  }

  render() {
    const { onClick, text } = this.props

    return (
      <A className="add-associated-model" href="#" onClick={this.handleClick}>
        <Icon icon="add" forceSize="16" />
        <span>{' ' + text}</span>
      </A>
    )
  }
}
