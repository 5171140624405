const LOOKUP_CAS_NUMBERS = 'eln/entry/lookup_cas_numbers'
const lookupCASNumbers = ({ structureMrv }) => ({
  type: LOOKUP_CAS_NUMBERS,
  payload: { structureMrv },
})

const UPDATE_CAS_NUMBER_CACHE = 'eln/entry/update_cas_number_cache'
const updateCASNumberCache = ({ casNumbers }) => ({
  type: UPDATE_CAS_NUMBER_CACHE,
  payload: { casNumbers },
})

export {
  LOOKUP_CAS_NUMBERS,
  UPDATE_CAS_NUMBER_CACHE,
  lookupCASNumbers,
  updateCASNumberCache,
}
