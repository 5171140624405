import React from 'react'
import TextQueryForm from './TextQueryForm.jsx'

import constants from 'javascripts/constants.js'
const {
  NUMBER_QUERY_STYLE_LABELS,
} = constants

export default class NumberQueryForm extends TextQueryForm {
  isMultiline = false
  queryStyleLabels = NUMBER_QUERY_STYLE_LABELS

  getErrorMessage(query) {
    const messages = super.getErrorMessage(query)

    if (!!query && !$.isNumeric(query)) { // eslint-disable-line no-jquery/no-is-numeric
      if (messages.length) {
        messages.push(<br key={messages.length} />)
      }
      messages.push('Must be a number')
    }

    return messages
  }
}
