import React from 'react';
import { A } from '@/shared/components/sanitizedTags.js';

// a wrapper around A that adds a disabled class when disabled is true
type Props = React.HTMLProps<HTMLAnchorElement>;

export const CDDLink = (props:Props) => {
  let { className } = props;
  if (props.disabled) {
    className = (className ?? '') + ' disabled';
  }
  return <A href="#" {...props} className={className}>{props.children}</A>;
};
