import React from 'react';

import { KeysToCamelCase } from '@/typeCasings';
import constants from 'javascripts/constants.js';
import { InventorySearchViewAPIProps } from '../types';
const { INVENTORY_SEARCH_LIMIT, INVENTORY_SEARCH_MAX_SIZE } = constants;

// TODO dedupe with eln entries stuff where possible
export type EntriesCountProps = Pick<
  KeysToCamelCase<InventorySearchViewAPIProps>,
  'totalCount'
> & {
  showingCount: number;
  terminology: string;
};

export class EntriesCount extends React.Component<EntriesCountProps> {
  render() {
    const { showingCount, totalCount, terminology } = this.props;

    const trueCountStr =
      totalCount >= INVENTORY_SEARCH_MAX_SIZE
        ? `${totalCount}+`
        : `${totalCount}`;
    const countStr =
      totalCount > INVENTORY_SEARCH_LIMIT
        ? `${showingCount} of ${trueCountStr}`
        : showingCount;

    return (
      <p className='search-bar__entries_count'>
        Showing {countStr} {terminology}
      </p>
    );
  }
}
