import CancelablePromise from 'cancelable-promise'

import {
  requestFail,
  requestSent,
  requestSuccess,
} from '../actions'
import submit from '@/shared/utils/submit.js'
import { insertGlobalError } from 'javascripts/application_global'

const getEntries = ({ dispatch, getState }) => {
  const state = getState()
  const url = state.constants.elnEntryUrl
  const type = 'GET'
  const { page } = state
  const params = Object.assign(state.query.toParams(), { page })

  const options = {
    url,
    type,
    params,
  }

  const promise = new CancelablePromise((resolve, reject) => {
    submit(options).then(resolve, reject)
  }).then(
    ({ error_message, ...response }) => {
      insertGlobalError(error_message)
      dispatch(requestSuccess(response))
    },
    ({ errorMessages, status }) => dispatch(requestFail(errorMessages, status))
  )

  dispatch(requestSent(promise))

  return promise
}

export default getEntries
