import React, { PureComponent } from 'react'
import { Provider } from 'mobx-react'

import Store from './store.js'
import ElnWitnessing from './component.jsx'

class ElnWitnessingRoot extends PureComponent {
  constructor(props) {
    super(props)
    this.store = new Store(props)
  }

  render() {
    return (
      <Provider store={this.store}>
        <ElnWitnessing />
      </Provider>
    )
  }
}

export default ElnWitnessingRoot
