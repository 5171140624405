/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import axios from 'axios';
import { CDD } from '@/typedJS';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Typography } from '@mui/material';
import { ParsingType, ParsingSection, ParsingFixedValue, ParsingTabularData, ParsingPlateBlock, SavedTemplate, SavedTemplateContent, ParsingPlateBlockMultiStamp } from './parserUtil';
import { A, Img } from '@/shared/components/sanitizedTags';
import { copyText, downloadText } from '../DownloadMoleculeImage/downloadUtils';
import icons from '@/shared/utils/icons';

type Props = {
  savedTemplates: SavedTemplate[],
}

type State = {
  templateList: SavedTemplate[];
  copiedTypes: string[];
  viewDetails: SavedTemplate;
  requestForDelete: SavedTemplate;
  isImporting: boolean;
  isImportingBusy: boolean;
  importingName: string;
  importingText: string;
};

export class ParserManager extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      templateList: props.savedTemplates.sort((t1, t2) => t2.created_at.localeCompare(t1.created_at)),
      copiedTypes: [],
      viewDetails: null,
      requestForDelete: null,
      isImporting: false,
      isImportingBusy: false,
      importingName: '',
      importingText: '',
    };
  }

  public render(): JSX.Element {
    const { templateList, copiedTypes, viewDetails, requestForDelete } = this.state;

    const tableRows: JSX.Element[] = [];

    for (let n = 0; n < templateList.length; n++) {
      const template = templateList[n];

      let blockUser: JSX.Element = null;
      if (template.user) {
        const { first_name, last_name, email } = template.user;
        blockUser = (
          <A href={`mailto:${email}`}>{first_name} {last_name}</A>
        );
      }
      const date = new Date(Date.parse(template.created_at));

      const hasWritePermission = template.can_edit !== false;

      tableRows.push((
        <tr key={`row-${n}`}>
          <td>{template.name}</td>
          <td>{blockUser}</td>
          <td>{date?.toDateString()}</td>
          <td>
            <div className="PlateBlockImporter-upload-rowflex">
              <div key="view">
                <A
                  href="#"
                  onClick={() => this.handleView(n)}
                  >
                  View
                </A>
              </div>
              <div key="copy">
                <A
                  href="#"
                  onClick={() => this.handleCopy(n)}
                  >
                  Copy
                  {copiedTypes.length > 0 && (
                    <Fade in={true} timeout={1000}>
                      <Alert
                        className="DownloadMoleculeImage-alert"
                        severity="success"
                        >
                        Copied {copiedTypes[0]} to clipboard.
                      </Alert>
                    </Fade>
                  )}
                </A>
              </div>
              <div key="download">
                <A
                  href="#"
                  onClick={() => this.handleDownload(n)}
                  >
                  Download
                </A>
              </div>
              <div key="delete">
                <A
                  href="#"
                  className={hasWritePermission ? 'cancel' : 'disabled'}
                  onClick={hasWritePermission ? () => this.handleDelete(n) : null}
                  >
                  Delete
                </A>
              </div>
            </div>
          </td>
        </tr>
      ));
    }

    if (tableRows.length == 0) {
      tableRows.push((
        <tr key="empty">
          <td colSpan={4}>No saved parsers.</td>
        </tr>
      ));
    }

    return (
      <>
        <div className="PlateBlockManager-footer-buttons">
          <A href="#" onClick={this.handleImport}>
            <Img width="16" height="16" className="icon-16" alt="Disk" src={icons.disk}/>
            &nbsp;Import parser from clipboard…
          </A>
        </div>

        <table className="dataTable" id="parserTemplates">
          <thead>
            <tr>
              <th>Parser name</th>
              <th>Created by</th>
              <th className="sorted">Date created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>

        <Dialog
          key="view"
          open={viewDetails != null}
          onClose={this.handleViewClose}
          maxWidth="sm"
          fullWidth
          >
          <DialogTitle>{viewDetails?.name}</DialogTitle>
          <DialogContent>
            {this.renderViewTemplate(viewDetails)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleViewClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          key="delete"
          open={requestForDelete != null}
          maxWidth="sm"
          fullWidth
          >
          <DialogTitle>Confirm the action</DialogTitle>
          <DialogContent>
            <Typography>Delete parser <b>{requestForDelete?.name}</b>?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteTemplateCancel}>
              Cancel
            </Button>
            <Button onClick={this.handleDeleteTemplateConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.isImporting && this.renderDialogImporting()}
      </>
    );
  }

  private renderViewTemplate(template: SavedTemplate): JSX.Element {
    if (!template) return null;
    const { tabNames, parsingSections } = template.template_json;

    const sectionXY = (section: ParsingSection): { x: number, y: number } => {
      if (section.type == ParsingType.FixedValue) {
        const { areaValue } = section as ParsingFixedValue;
        if (areaValue) return areaValue;
      } else if (section.type == ParsingType.TabularData) {
        const { x, y } = (section as ParsingTabularData).areaContent;
        return { x, y };
      } else if (section.type == ParsingType.PlateBlock) {
        const { x, y } = (section as ParsingPlateBlock).areaBlock;
        return { x, y };
      }
      return { x: 0, y: 0 };
    };

    const sortedSections = parsingSections.sort((s1, s2) => {
      if (s1.tabIndex != s2.tabIndex) return s1.tabIndex - s2.tabIndex;

      const pos1 = sectionXY(s1);
      const pos2 = sectionXY(s2);

      if (pos1.y != pos2.y) return pos1.y - pos2.y;
      if (pos1.x != pos2.x) return pos1.x - pos2.x;
      return 0;
    });

    const renderFixedValue = (section: ParsingFixedValue): JSX.Element => {
      let payloadValue = <>{section.specificValue}</>;
      if (section.areaValue) {
        const { x, y } = section.areaValue;
        payloadValue = <>value of column {x + 1}, row {y + 1}</>;
      }
      return (
        <>
          <div key="left"><b>Fixed Value</b>:</div>
          <div key="right">{section.fieldName} = {payloadValue}</div>
        </>
      );
    };

    const renderTabularData = (section: ParsingTabularData): JSX.Element => {
      const { w, h } = section.areaContent;

      const payloadList: JSX.Element[] = [];

      payloadList.push(<div key="region">table of {w} columns, {h} rows</div>);

      if (Array.isArray(section.joinField)) {
        payloadList.push(<div key="join">join fields: {section.joinField.join(', ')}</div>);
      } else if (section.joinField) {
        payloadList.push(<div key="join">join fields: {section.joinField}</div>);
      }

      const replicateSets = section.replicateSets ?? [];
      for (let n = 0; n < replicateSets.length; n++) {
        payloadList.push(<div key={`repset${n}`}>replicate set: {replicateSets[n].join(', ')}</div>);
      }

      const replicateDetails = section.replicateDetails ?? [];
      for (let n = 0; n < replicateDetails.length; n++) {
        if (replicateDetails[n].fieldName) {
          payloadList.push(<div key={`repname${n}`}>replicate #{n + 1}: field name {replicateDetails[n].fieldName}</div>);
        }
        if (replicateDetails[n].columnValueField) {
          payloadList.push(<div key={`reppivot${n}`}>replicate #{n + 1}: pivot to {replicateDetails[n].columnValueField}</div>);
        }
      }

      const fixedValues = section.fixedValues ?? [];
      for (let n = 0; n < fixedValues.length; n++) {
        let payloadValue = <>{fixedValues[n].specificValue || 'page name'}</>;
        if (fixedValues[n].areaValue) {
          const { x, y } = fixedValues[n].areaValue;
          payloadValue = <>value of column {x + 1}, row {y + 1}</>;
        }
        payloadList.push(<div key={`fixed${n}`}>fixed value {fixedValues[n].fieldName} = {payloadValue}</div>);
      }

      return (
        <>
          <div key="left"><b>Tabular Data</b>:</div>
          <div key="right">{payloadList}</div>
        </>
      );
    };

    const renderPlateBlock = (section: ParsingPlateBlock): JSX.Element => {
      const { w, h } = section.areaBlock;

      const payloadList: JSX.Element[] = [];

      payloadList.push(<div key="region">block of width {w}, height {h}</div>);

      payloadList.push(<div key="name">well field name {section.fieldName}</div>);

      if (Array.isArray(section.joinField)) {
        payloadList.push(<div key="join">join fields: {section.joinField.join(', ')}</div>);
      } else if (section.joinField) {
        payloadList.push(<div key="join">join fields: {section.joinField}</div>);
      }

      const paddingText = section.wellNumberPadding > 0 ? `${section.wellNumberPadding} digits` : 'strip zeros';
      payloadList.push(<div key="wellpad">well padding: {paddingText}</div>);

      if (section.multiStamp == ParsingPlateBlockMultiStamp.Local) {
        payloadList.push(<div key="wellpad">repeat: this tab</div>);
      } else if (section.multiStamp == ParsingPlateBlockMultiStamp.Global) {
        payloadList.push(<div key="wellpad">repeat: all tabs</div>);
      }

      const fixedValues = section.fixedValues ?? [];
      for (let n = 0; n < fixedValues.length; n++) {
        let payloadValue = <>{fixedValues[n].specificValue}</>;
        if (fixedValues[n].areaValue) {
          const { x, y } = fixedValues[n].areaValue;
          payloadValue = <>value of column {x + 1}, row {y + 1}</>;
        }
        payloadList.push(<div key={`fixed${n}`}>fixed value {fixedValues[n].fieldName} = {payloadValue || 'page name'}</div>);
      }

      return (
        <>
          <div key="left"><b>Plate Block</b>:</div>
          <div key="right">{payloadList}</div>
        </>
      );
    };

    const payloadList: JSX.Element[] = [];
    for (let n = 0; n < sortedSections.length; n++) {
      const section = sortedSections[n];

      if (section.tabIndex >= 0 && (n == 0 || section.tabIndex != sortedSections[n - 1].tabIndex)) {
        payloadList.push((
          <div key={`tab${n}`} className="PlateBlockManager-tabgroup">
            Tab: <b>{tabNames[section.tabIndex]}</b>
          </div>
        ));
      }

      payloadList.push((
        <div key={`section${n}`} className="PlateBlockManager-section">
          {section.type == ParsingType.FixedValue && renderFixedValue(section as ParsingFixedValue)}
          {section.type == ParsingType.TabularData && renderTabularData(section as ParsingTabularData)}
          {section.type == ParsingType.PlateBlock && renderPlateBlock(section as ParsingPlateBlock)}
        </div>
      ));
    }

    return <>{payloadList}</>;
  }

  private renderDialogImporting(): JSX.Element {
    const { importingName, importingText, templateList, isImportingBusy } = this.state;
    const [breakdown, isPayloadValid] = this.payloadBreakdown(importingText.trim());
    const isNameValid = importingName && !templateList.find((look) => look.name == importingName);

    return (
      <Dialog
        key="import"
        open={true}
        onClose={this.handleImportCancel}
        maxWidth="sm"
        fullWidth
        >
        <DialogTitle>Import custom parser</DialogTitle>
        <DialogContent>
          <div key="input-name" className="PlateBlockManager-saveflex">
            <div key="title">Parser name:</div>
            <div key="value" style={{ flexGrow: 1 }}>
              <input
                className="input-text PlateBlockManager-savename-edit"
                type="text"
                value={importingName}
                onChange={this.handleImportChangeName}
                autoFocus
                />
            </div>
          </div>

          Paste the parser definition into the text box below:

          <textarea
            className="PlateBlockManager-textarea resizable OntologyTemplate-clipboardtext"
            cols={40}
            rows={6}
            value={importingText}
            onChange={this.handleImportChangeText}
            spellCheck={false}
          />
          <div key="breakdown">{breakdown}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleImportCancel}>
            Cancel
          </Button>
          <Button onClick={this.handleImportConfirm} disabled={isImportingBusy || !isNameValid || !isPayloadValid}>
            Import
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private payloadBreakdown(txt: string): [string, boolean] {
    if (!txt) return ['', false];

    let template: SavedTemplateContent = null;
    try {
      template = JSON.parse(txt);
    } catch (_) {
      return ['Definition text must be JSON-formatted.', false];
    }

    if (!Array.isArray(template.tabNames) || !Array.isArray(template.parsingSections) || template.parsingSections.length == 0) {
      return ['Definition text is not a custom parser.', false];
    }

    return [`Definition text is valid and contains ${template.parsingSections.length} section${template.parsingSections.length == 1 ? '' : 's'}.`, true];
  }

  private handleView(idx: number) {
    const template = this.state.templateList[idx];
    this.setState({ viewDetails: template });
  }

  private handleViewClose = () => {
    this.setState({ viewDetails: null });
  };

  private handleCopy(idx: number) {
    const json = this.state.templateList[idx].template_json;
    copyText(JSON.stringify(json, null, 2));

    this.setState({ copiedTypes: ['parser definition', ...this.state.copiedTypes] });
    setTimeout(() => {
      const { copiedTypes } = this.state;
      this.setState({ copiedTypes: copiedTypes.slice(0, copiedTypes.length - 1) });
    }, 10000);
  }

  private handleDownload(idx: number) {
    const template = this.state.templateList[idx];
    const json = template.template_json;

    let fn = '';
    for (const ch of template.name) {
      fn += /[A-Za-z0-9]/.test(ch) ? ch : '_';
    }
    fn += '.json';

    downloadText(JSON.stringify(json, null, 2), fn, 'text/plain');
  }

  private handleDelete(idx: number) {
    const template = this.state.templateList[idx];
    this.setState({ requestForDelete: template });
  }

  private handleDeleteTemplateCancel = (): void => {
    this.setState({ requestForDelete: null });
  };

  private handleDeleteTemplateConfirm = (): void => {
    const { requestForDelete } = this.state;
    if (!requestForDelete.id) return;
    const url = `/${CDD.ActiveDataContext.toContextParam}/plate_block_templates/${requestForDelete.id}.json`;

    (async () => {
      const response = await axios.delete(url);
      if (response.status >= 200 && response.status < 300) {
        const templateList = this.state.templateList.filter((template) => template.id != requestForDelete.id);
        this.setState({ templateList });
      } else {
        console.error('Deletion of parser failed.');
      }
      this.setState({ requestForDelete: null });
    })();
  };

  private handleImport = (): void => {
    this.setState({
      isImporting: true,
      importingName: '',
      importingText: '',
    });
  };

  private handleImportCancel = (): void => {
    this.setState({ isImporting: false });
  };

  private handleImportConfirm = (): void => {
    this.setState({ isImportingBusy: true });

    const { importingName, importingText } = this.state;
    const template_json = JSON.parse(importingText);

    const url = `/${CDD.ActiveDataContext.toContextParam}/plate_block_templates.json`;
    const template: SavedTemplate = {
      id: null,
      name: importingName,
      template_json,
    };

    (async () => {
      const response = await axios.post(url, { template, overwrite: false });
      this.setState({ isImporting: false, isImportingBusy: false });
      if (response.status == 200) {
        window.location.reload();
      } else {
        alert('Custom parser importing failed.');
      }
    })();
  };

  private handleImportChangeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ importingName: event.target.value });
  };

  private handleImportChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ importingText: event.target.value });
  };
}
