import React from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material'

export default class FilterStyleSelect extends React.Component {
  handleChange = (event) => {
    this.props.handleStyleChange(event.target.value)
  }

  render() {
    const { filter_style_key, applicableFilterStyles } = this.props

    return (
      <FormControl className='filter-style-select'>
        <Select
          value={filter_style_key || applicableFilterStyles[0].key}
          onChange={this.handleChange}
          inputProps={{ name: 'filterStyle' }}
          className='filter__style__select'
        >
          {applicableFilterStyles.map(filterStyle => (
            <MenuItem key={filterStyle.key} value={filterStyle.key}>
              <ListItemText primary={filterStyle.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}
