import { LOCATION_CHANGE } from 'react-router-redux'

import {
  GET_NEXT_PAGE,
  UPDATE_SORT_COLUMN,
  SUBMIT_QUERY,
  UPDATE_QUERY,
  UPDATE_DATA_SOURCES,
} from '../actions'

/*
 * The pagination reducer
 * @function
 * @param {integer}  state
 * @param {object} action
 * @return {integer} new state
 */
export const page = (state = 0, action) => {
  switch (action.type) {
    /*
     * Increment the page counter to get the next page of results.
     *
     */
    case GET_NEXT_PAGE: {
      return state + 1
    }
    case LOCATION_CHANGE:
    case UPDATE_SORT_COLUMN:
    case SUBMIT_QUERY:
    case UPDATE_QUERY:
    case UPDATE_DATA_SOURCES: {
      return 0
    }
    default: {
      return state
    }
  }
}
