import LegacyFileInput from '@/Annotator/Templates/LegacyFileInput';
import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';
import { DDFormUtils } from '@/shared/components/DDForm/DDFormUtils';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType } from '@/shared/components/DDForm/types';
import { getVaultContextFromLocation } from '@/shared/utils/urlContext';
import { AnyObject } from '@/types';
import { Typography } from '@mui/material';
import React from 'react';
import { LocationPickerField } from './location/LocationPickerField';

const { textInput, dateInput, select, custom, numberInput } = layoutBuilder;
export type GetFieldDefinitionLayoutFieldError = (
  fieldDefinition: FieldDefinition,
  value: unknown,
) => string | undefined;

export class FieldDefinitionFormUtils {
  static layoutForFieldDefinitions(
    fieldDefinitions: Array<FieldDefinition>,
    value: AnyObject,
    prefix: string,
    disableSampleIdField = false,
    getFieldError?: GetFieldDefinitionLayoutFieldError,
    isNewSample?: boolean,
  ) {
    // check if a value fulfills the required constraint (0 is legal)
    const fulfillsRequired = (value: unknown) => {
      return value === 0 || !!value;
    };

    const getRequiredFieldsErrorMessage = (
      fieldsWithIssues: FieldDefinition[],
    ) => {
      if (fieldsWithIssues.length === 1) {
        return 'This field is required';
      } else {
        const fieldNames = fieldsWithIssues
          .map((field) => field.name)
          .join(' or ');
        return `This field or ${fieldNames} is required`;
      }
    };

    const getError = (fieldDefinition: FieldDefinition) => {
      const fieldValues =
        DDFormUtils.getContainerForPath(
          `${prefix}.${fieldDefinition.id}`,
          value,
        ) ?? {};
      const fieldValue = fieldValues[fieldDefinition.id];
      const customError = getFieldError?.(fieldDefinition, fieldValue);
      const required_group_number = fieldDefinition.next_identifier ? null : fieldDefinition.required_group_number;

      if (customError) {
        return customError;
      }
      if (required_group_number && !fieldValue) {
        const allFieldsInGroup = fieldDefinitions.filter(
          (test) =>
            test.required_group_number ===
            required_group_number,
        );

        // Check if any field in the group has a value
        const hasValueInGroup = allFieldsInGroup.some((field) =>
          fulfillsRequired(fieldValues[field.id]),
        );

        if (!hasValueInGroup) {
          const fieldsWithIssues = allFieldsInGroup.filter(
            (field) => !fulfillsRequired(fieldValues[field.id]),
          );
          if (fieldsWithIssues.length > 0) {
            return getRequiredFieldsErrorMessage(fieldsWithIssues);
          }
        }
      }
      return null;
    };

    const layoutForDefinition = (
      fieldDefinition: FieldDefinition,
    ): ElementType => {
      const disabled = fieldDefinition.is_sample_id && disableSampleIdField;
      const key = `${prefix}.${fieldDefinition.id}`;
      const required_group_number = fieldDefinition.next_identifier ? null : fieldDefinition.required_group_number;

      const label = required_group_number
        ? `${fieldDefinition.name} *`
        : fieldDefinition.name;

      switch (fieldDefinition.data_type_name) {
        case FieldDataType.Location:
          return custom({
            key,
            label: fieldDefinition.name,
            disabled,
            render: () => {
              return (
                <LocationPickerField
                  error={getError(fieldDefinition)}
                  value={DDFormUtils.getValue(key, value)}
                  onSubmit={(newValue) => {
                    DDFormUtils.setValue(key, value, newValue);
                  }}
                />
              );
            },
          });
          break;

        case FieldDataType.PickList: {
          const emptyOption = { label: '(None)', value: '' };
          const convertedOptions = fieldDefinition.pick_list_values.map(
            (value) => {
              return { label: value.value, value: value.value };
            },
          );
          const selectOptions = [emptyOption, ...convertedOptions];
          return select({
            key,
            label,
            disabled,
            error: getError(fieldDefinition),
            selectOptions,
            optionFieldLabel: 'label',
            optionFieldId: 'value',
          });
          break;
        }

        case FieldDataType.Date:
          return dateInput({
            key,
            label,
            disabled,
            error: getError(fieldDefinition),
            valueType: 'string',
          });
          break;

        case FieldDataType.Number:
          return numberInput({
            key,
            label,
            disabled,
            error: getError(fieldDefinition),
          });
          break;

        case FieldDataType.File: {
          const context = getVaultContextFromLocation();
          const formName = key;
          const fileValue = DDFormUtils.getValue(key, value);
          return custom({
            key,
            label: fieldDefinition.name,
            disabled,
            render: () => {
              return (
                <form>
                  <table className='field_file_uploader'>
                    <tbody>
                      <tr>
                        <td>
                          <Typography>{fieldDefinition.name}</Typography>
                        </td>
                        <td>
                          <LegacyFileInput
                            context={context}
                            fieldValue={
                              fileValue
                                ? {
                                    uploaded_file_id: fileValue.value,
                                    text_value: fileValue.file_name,
                                  }
                                : {}
                            }
                            name={formName}
                            onFileChanged={(fileID, filename, formData) => {
                              DDFormUtils.setValue(key, value, {
                                text_value: filename,
                                uploaded_file_id: fileID,
                                formData,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              );
            },
          });
        }
      }

      const control: ElementType = textInput({
        key,
        label,
        disabled: disabled || !!fieldDefinition.next_identifier,
        error: getError(fieldDefinition),
        translateGetValue: (value) => {
          if (fieldDefinition.next_identifier) {
            if (isNewSample) {
              return 'Autogenerated';
            }
          }
          return value;
        },
      });
      return control;
    };
    return fieldDefinitions
      .map((fieldDefinition) => layoutForDefinition(fieldDefinition))
      .flat();
  }
}
