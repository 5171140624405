import React from 'react'

import EntryList from './components/EntryList'
import SearchBar from './components/SearchBar'

export default class ElnEntries extends React.Component {
  render() {
    const { updateDataSources } = this.props

    CDD.DataSourceSelection.updateCallback = updateDataSources

    return (
      <div className="container">
        <SearchBar />
        <EntryList />
      </div>
    )
  }
}
