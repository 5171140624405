import React from 'react'

const ItemActions = (props) => {
  const { pickListValue, update, remove } = props

  const hidden = pickListValue.get('hidden')
  const deletable = pickListValue.get('deletable')

  const hiddenTitle = hidden ? 'currently hidden' : 'currently visible'
  const removeTitle = deletable ? 'click to remove item' : 'unable to remove item with data'

  const toggleVisibility = () => update(pickListValue.get('id'), { hidden: !hidden })
  const _remove = () => { deletable && remove(pickListValue.get('id')) }

  return (
    <div className="pickListItemActions">
      <span
        className={`hideIcon fa fa-eye${hidden ? '-slash' : ''}`}
        title={`hide/show value -- ${hiddenTitle}`}
        onClick={toggleVisibility}
      />
      <span
        className={`deleteIcon fa fa-times ${deletable ? '' : 'disabled'}`}
        title={removeTitle}
        onClick={_remove}
      />
    </div>
  )
}

export default ItemActions
