import React from 'react';
import {
  TableCell,
  TableSortLabel,
} from '@mui/material';
import { ColumnDirection } from '@/Protocols/types';

type Props = {
  id: string,
  label: string,
  direction: ColumnDirection,
  onClick: (id: string) => void
}

export class TableHeader extends React.Component<Props> {
  handleClick = () => {
    this.props.onClick(this.props.id);
  };

  render() {
    const { id, label, direction } = this.props;

    return (
      <TableCell
        key={id}
        className='search_results__item'>
        <TableSortLabel
          className='search_results__item__sort'
          active={!!direction}
          direction={direction || 'desc'}
          onClick={this.handleClick}>
          {label}
        </TableSortLabel>
      </TableCell>
    );
  }
}
