import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SearchField from './component.jsx'
import { submitQuery, updateQuery } from '@/Eln/Entries/actions'

function mapStateToProps(state) {
  return {
    text: state.query.text,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    submitQuery,
    updateQuery,
  }, dispatch)
}

const SearchFieldContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchField)

export default SearchFieldContainer
