export const customNucleotideUnmodifiedCodeOptions = [
  { id: 'A', label: 'A' },
  { id: 'C', label: 'C' },
  { id: 'T', label: 'T' },
  { id: 'G', label: 'G' },
  { id: 'U', label: 'U' },
];

export const customNucleotideStructures = {
  A: '6083\n  Mrv1903 03242215272D          \n\n  0  0  0     0  0            999 V3000\nM  V30 BEGIN CTAB\nM  V30 COUNTS 24 26 0 0 1\nM  V30 BEGIN ATOM\nM  V30 1 P 4.3725 -3.9881 0 0\nM  V30 2 O 8.2761 -1.2708 0 0\nM  V30 3 O 12.1847 -2.0477 0 0\nM  V30 4 O 10.2107 -4.7548 0 0\nM  V30 5 O 5.6202 -3.085 0 0\nM  V30 6 O 3.125 -4.8912 0 0\nM  V30 7 O 3.4696 -2.7406 0 0\nM  V30 8 O 5.2756 -5.2356 0 0\nM  V30 9 N 10.22 0.6664 0 0\nM  V30 10 N 10.22 3.1451 0 0\nM  V30 11 N 13.011 0.3659 0 0\nM  V30 12 N 14.3448 2.6757 0 0\nM  V30 13 N 13.011 4.9859 0 0\nM  V30 14 C 10.6447 -2.0449 0 0 CFG=2\nM  V30 15 C 9.7373 -3.2893 0 0 CFG=1\nM  V30 16 C 9.7416 -0.7974 0 0 CFG=1\nM  V30 17 C 8.2734 -2.8108 0 0 CFG=1\nM  V30 18 C 7.0259 -3.7139 0 0\nM  V30 19 C 11.6773 1.1359 0 0\nM  V30 20 C 9.3212 1.9057 0 0\nM  V30 21 C 11.6773 2.6757 0 0\nM  V30 22 C 13.011 3.4459 0 0\nM  V30 23 C 14.3448 1.1359 0 0\nM  V30 24 H 8.3662 1.9057 0 0\nM  V30 END ATOM\nM  V30 BEGIN BOND\nM  V30 1 1 1 5\nM  V30 2 1 1 6\nM  V30 3 1 1 7\nM  V30 4 2 1 8\nM  V30 5 1 2 16\nM  V30 6 1 2 17\nM  V30 7 1 14 3 CFG=3\nM  V30 8 1 15 4 CFG=3\nM  V30 9 1 5 18\nM  V30 10 1 16 9 CFG=1\nM  V30 11 1 9 19\nM  V30 12 1 9 20\nM  V30 13 2 10 20\nM  V30 14 1 10 21\nM  V30 15 2 11 19\nM  V30 16 1 11 23\nM  V30 17 1 12 22\nM  V30 18 2 12 23\nM  V30 19 1 13 22\nM  V30 20 1 14 15\nM  V30 21 1 14 16\nM  V30 22 1 15 17\nM  V30 23 1 17 18 CFG=1\nM  V30 24 1 19 21\nM  V30 25 1 20 24\nM  V30 26 2 21 22\nM  V30 END BOND\nM  V30 END CTAB\nM  END\n',
  C: '6131\n  Mrv1903 03242215272D          \n\n  0  0  0     0  0            999 V3000\nM  V30 BEGIN CTAB\nM  V30 COUNTS 21 22 0 0 1\nM  V30 BEGIN ATOM\nM  V30 1 P 10.612 -5.0461 0 0\nM  V30 2 O 7.7459 -1.2506 0 0\nM  V30 3 O 3.7897 -0.7748 0 0\nM  V30 4 O 9.7068 -3.8001 0 0\nM  V30 5 O 9.1675 1.1946 0 0\nM  V30 6 O 11.5173 -6.292 0 0\nM  V30 7 O 11.8579 -4.1409 0 0\nM  V30 8 O 9.3662 -5.9512 0 0\nM  V30 9 N 6.5001 1.1946 0 0\nM  V30 10 N 7.8337 3.5046 0 0\nM  V30 11 N 6.5001 5.8146 0 0\nM  V30 12 C 5.7301 -2.7153 0 0 CFG=1\nM  V30 13 C 5.2542 -1.2506 0 0 CFG=2\nM  V30 14 C 7.2701 -2.7153 0 0 CFG=1\nM  V30 15 C 6.5001 -0.3454 0 0 CFG=1\nM  V30 16 C 8.1753 -3.9612 0 0\nM  V30 17 C 5.1664 1.9646 0 0\nM  V30 18 C 7.8337 1.9646 0 0\nM  V30 19 C 5.1664 3.5046 0 0\nM  V30 20 C 6.5001 4.2746 0 0\nM  V30 21 O 4.8248 -3.9612 0 0\nM  V30 END ATOM\nM  V30 BEGIN BOND\nM  V30 1 1 1 4\nM  V30 2 1 1 6\nM  V30 3 1 1 7\nM  V30 4 2 1 8\nM  V30 5 1 2 14\nM  V30 6 1 2 15\nM  V30 7 1 13 3 CFG=1\nM  V30 8 1 4 16\nM  V30 9 2 5 18\nM  V30 10 1 15 9 CFG=3\nM  V30 11 1 9 17\nM  V30 12 1 9 18\nM  V30 13 1 10 18\nM  V30 14 2 10 20\nM  V30 15 1 11 20\nM  V30 16 1 12 13\nM  V30 17 1 12 14\nM  V30 18 1 13 15\nM  V30 19 1 14 16 CFG=3\nM  V30 20 2 17 19\nM  V30 21 1 19 20\nM  V30 22 1 12 21 CFG=1\nM  V30 END BOND\nM  V30 END CTAB\nM  END\n',
  G: '135398631\n  Mrv1903 03242215282D          \n\n  0  0  0     0  0            999 V3000\nM  V30 BEGIN CTAB\nM  V30 COUNTS 24 26 0 0 1\nM  V30 BEGIN ATOM\nM  V30 1 P 15.5534 -3.7315 0 0\nM  V30 2 O 11.6501 -1.0142 0 0\nM  V30 3 O 7.7414 -1.7911 0 0\nM  V30 4 O 9.7154 -4.4981 0 0\nM  V30 5 O 14.3059 -2.8284 0 0\nM  V30 6 O 16.8009 -4.6346 0 0\nM  V30 7 O 16.4565 -2.484 0 0\nM  V30 8 O 6.9153 5.2423 0 0\nM  V30 9 O 14.6503 -4.979 0 0\nM  V30 10 N 9.7061 0.9229 0 0\nM  V30 11 N 9.7061 3.4016 0 0\nM  V30 12 N 6.9153 0.6223 0 0\nM  V30 13 N 5.5815 2.9323 0 0\nM  V30 14 N 4.2478 0.6223 0 0\nM  V30 15 C 9.2814 -1.7883 0 0 CFG=2\nM  V30 16 C 10.1888 -3.0326 0 0 CFG=1\nM  V30 17 C 10.1845 -0.5408 0 0 CFG=1\nM  V30 18 C 11.6527 -2.5543 0 0 CFG=1\nM  V30 19 C 12.9002 -3.4573 0 0\nM  V30 20 C 8.249 1.3923 0 0\nM  V30 21 C 10.6047 2.1622 0 0\nM  V30 22 C 8.249 2.9323 0 0\nM  V30 23 C 6.9153 3.7023 0 0\nM  V30 24 C 5.5815 1.3923 0 0\nM  V30 END ATOM\nM  V30 BEGIN BOND\nM  V30 1 1 1 5\nM  V30 2 1 1 6\nM  V30 3 1 1 7\nM  V30 4 2 1 9\nM  V30 5 1 2 17\nM  V30 6 1 2 18\nM  V30 7 1 15 3 CFG=1\nM  V30 8 1 16 4 CFG=1\nM  V30 9 1 5 19\nM  V30 10 2 8 23\nM  V30 11 1 17 10 CFG=3\nM  V30 12 1 10 20\nM  V30 13 1 10 21\nM  V30 14 2 11 21\nM  V30 15 1 11 22\nM  V30 16 1 12 20\nM  V30 17 2 12 24\nM  V30 18 1 13 23\nM  V30 19 1 13 24\nM  V30 20 1 14 24\nM  V30 21 1 15 16\nM  V30 22 1 15 17\nM  V30 23 1 16 18\nM  V30 24 1 18 19 CFG=3\nM  V30 25 2 20 22\nM  V30 26 1 22 23\nM  V30 END BOND\nM  V30 END CTAB\nM  END\n',
  T: '9700\n  Mrv1903 03242215272D          \n\n  0  0  0     0  0            999 V3000\nM  V30 BEGIN CTAB\nM  V30 COUNTS 22 23 0 0 1\nM  V30 BEGIN ATOM\nM  V30 1 P 9.8593 -4.8074 0 0\nM  V30 2 O 6.9933 -1.0119 0 0\nM  V30 3 O 4.0722 -3.7225 0 0\nM  V30 4 O 8.9542 -3.5614 0 0\nM  V30 5 O 8.4148 1.4333 0 0\nM  V30 6 O 5.7473 6.0533 0 0\nM  V30 7 O 10.7645 -6.0533 0 0\nM  V30 8 O 11.1053 -3.9022 0 0\nM  V30 9 O 8.6134 -5.7125 0 0\nM  V30 10 N 5.7473 1.4333 0 0\nM  V30 11 N 7.0811 3.7433 0 0\nM  V30 12 C 4.9773 -2.4766 0 0 CFG=2\nM  V30 13 C 6.5173 -2.4766 0 0 CFG=1\nM  V30 14 C 4.5014 -1.0119 0 0\nM  V30 15 C 5.7473 -0.1067 0 0 CFG=1\nM  V30 16 C 7.4225 -3.7225 0 0\nM  V30 17 C 4.4137 2.2033 0 0\nM  V30 18 C 7.0811 2.2033 0 0\nM  V30 19 C 4.4137 3.7433 0 0\nM  V30 20 C 5.7473 4.5133 0 0\nM  V30 21 C 3.08 4.5133 0 0\nM  V30 22 H 3.5868 1.7259 0 0\nM  V30 END ATOM\nM  V30 BEGIN BOND\nM  V30 1 1 1 4\nM  V30 2 1 1 7\nM  V30 3 1 1 8\nM  V30 4 2 1 9\nM  V30 5 1 2 13\nM  V30 6 1 2 15\nM  V30 7 1 12 3 CFG=1\nM  V30 8 1 4 16\nM  V30 9 2 5 18\nM  V30 10 2 6 20\nM  V30 11 1 15 10 CFG=3\nM  V30 12 1 10 17\nM  V30 13 1 10 18\nM  V30 14 1 11 18\nM  V30 15 1 11 20\nM  V30 16 1 12 13\nM  V30 17 1 12 14\nM  V30 18 1 13 16 CFG=3\nM  V30 19 1 14 15\nM  V30 20 2 17 19\nM  V30 21 1 17 22\nM  V30 22 1 19 20\nM  V30 23 1 19 21\nM  V30 END BOND\nM  V30 END CTAB\nM  END\n',
  U: '6030\n  Mrv1903 03252214572D          \n\n  0  0  0     0  0            999 V3000\nM  V30 BEGIN CTAB\nM  V30 COUNTS 21 22 0 0 1\nM  V30 BEGIN ATOM\nM  V30 1 P 10.612 -4.8074 0 0\nM  V30 2 O 7.7459 -1.0119 0 0\nM  V30 3 O 4.8248 -3.7225 0 0\nM  V30 4 O 3.7897 -0.5361 0 0\nM  V30 5 O 9.7068 -3.5614 0 0\nM  V30 6 O 9.1675 1.4333 0 0\nM  V30 7 O 11.5173 -6.0533 0 0\nM  V30 8 O 11.8579 -3.9022 0 0\nM  V30 9 O 9.3662 -5.7125 0 0\nM  V30 10 O 6.5001 6.0533 0 0\nM  V30 11 N 6.5001 1.4333 0 0\nM  V30 12 N 7.8337 3.7433 0 0\nM  V30 13 C 5.7301 -2.4766 0 0 CFG=1\nM  V30 14 C 5.2542 -1.0119 0 0 CFG=2\nM  V30 15 C 7.2701 -2.4766 0 0 CFG=1\nM  V30 16 C 6.5001 -0.1067 0 0 CFG=1\nM  V30 17 C 8.1753 -3.7225 0 0\nM  V30 18 C 7.8337 2.2033 0 0\nM  V30 19 C 5.1664 2.2033 0 0\nM  V30 20 C 5.1664 3.7433 0 0\nM  V30 21 C 6.5001 4.5133 0 0\nM  V30 END ATOM\nM  V30 BEGIN BOND\nM  V30 1 1 1 5\nM  V30 2 1 1 7\nM  V30 3 1 1 8\nM  V30 4 2 1 9\nM  V30 5 1 2 15\nM  V30 6 1 2 16\nM  V30 7 1 13 3 CFG=1\nM  V30 8 1 14 4 CFG=1\nM  V30 9 1 5 17\nM  V30 10 2 6 18\nM  V30 11 2 10 21\nM  V30 12 1 16 11 CFG=3\nM  V30 13 1 11 18\nM  V30 14 1 11 19\nM  V30 15 1 12 18\nM  V30 16 1 12 21\nM  V30 17 1 13 14\nM  V30 18 1 13 15\nM  V30 19 1 14 16\nM  V30 20 1 15 17 CFG=3\nM  V30 21 2 19 20\nM  V30 22 1 20 21\nM  V30 END BOND\nM  V30 END CTAB\nM  END\n',
};

Object.freeze(customNucleotideUnmodifiedCodeOptions);
Object.freeze(customNucleotideStructures);
