import React from 'react'

import { Form, Iframe, Input } from '@/shared/components/sanitizedTags.js'

export default class WopiView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: `Office frame ${Math.random().toString(36).substr(2, 9)}`,
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.reload()
  }

  componentDidUpdate() {
    this.reload()
  }

  reload() {
    this.formRef.current.submit()
  }

  shouldComponentUpdate(nextProps, nextState) {
    // only update on reload
    return this.props.reloadToken != nextProps.reloadToken
  }

  render() {
    const { name } = this.state
    const { className, office365_convert, office365_edit, office365_embedded, office365_view,
      token, ttl_milliseconds } = this.props

    const action = office365_embedded || office365_convert || office365_view || office365_edit
    if (!action) { throw new Error('no URL supplied to Office Viewer') }

    let sandbox = 'allow-scripts allow-same-origin'
    if (!office365_embedded && !office365_convert) {
      sandbox += ' allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox'
    }

    return (
      <React.Fragment>
        <Form
          ref={this.formRef}
          target={name}
          action={action}
          method='post'
        >
          <Input
            name='access_token'
            value={token}
            type='hidden'
          />
          <Input
            name='access_token_ttl'
            value={ttl_milliseconds}
            type='hidden'
          />
        </Form>
        <Iframe
          className={className}
          name={name}
          // The title should be set for accessibility
          title='Office Frame'
          // This attribute allows true fullscreen mode in slideshow view
          // when using PowerPoint's 'view' action.
          allowFullScreen={true}
          // The sandbox attribute is needed to allow automatic redirection to the O365 sign-in page in the business user flow
          sandbox={sandbox}
        >
        </Iframe>
      </React.Fragment>
    )
  }
}
