import { AutoEllipsisTooltip, ColumnDef, ReorderDataTable, SimpleDataTable } from '@/components';
import { A, Img } from '@/shared/components/sanitizedTags';
import tickIcon from 'ASSETS/images/cdd30/icons/tick.png';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ReadoutDefinitionUtils } from '../readoutDefinitionUtils';
import { CustomCalculationObject, DoseResponseCalculationObject, ReadoutDefinitionObject } from '../types';

type Props = {
  readonly?: boolean;
  rows: ReadoutDefinitionObject[];
  doseResponseCalculations: Array<DoseResponseCalculationObject>;
  customCalculations: Array<CustomCalculationObject>;
  onChangeOrder: (rows: Array<ReadoutDefinitionObject>) => void;
  onClickRow: (row: ReadoutDefinitionObject) => void;
}

interface DisplayRow extends Omit<ReadoutDefinitionObject, 'description'> {
  format_unit_label: string;
  description: string | JSX.Element;
}

const columns: Array<ColumnDef & { id: keyof DisplayRow }> = [
  {
    label: 'Name',
    id: 'name',
    width: 300,
    type: 'link',
  },
  {
    label: 'Data Type',
    id: 'data_type_name',
    type: 'pickList',
    width: 120,
  },
  {
    label: 'Unit',
    id: 'unit_label',
    width: 70,
  },
  {
    label: 'Required',
    id: 'required',
    type: 'checkmark',
    width: 80,
    style: { textAlign: 'center' },
  },
  {
    label: 'Condition',
    id: 'protocol_condition',
    width: 80,
    style: { textAlign: 'center' },
    className: 'conditionColumn',
  },
  {
    label: 'Description',
    id: 'description',
    className: 'descriptionColumn',
    width: 300,
  },
];

@observer
export class ProtocolReadoutDefinitionsTable extends React.Component<Props> {
  clickToCopyMessage = '(click on formula to copy to clipboard)';

  constructor(props: Props) {
    super(props);

    makeObservable(this, {
      subRows: computed,
    });
  }

  getDoseResponseForReadoutDefinition = (readoutDefinition: ReadoutDefinitionObject) => {
    for (const drc of this.props.doseResponseCalculations) {
      if (drc.id === readoutDefinition.calculation_id) {
        return drc;
      }
    }
    return null;
  };

  getRowAttributes = (row: ReadoutDefinitionObject) => {
    // custom-calculation-216111132-show
    const result = {
      'data-readout-id': row.id,
      'data-calculation-id': row.calculation_id,
      id: undefined,
      className: undefined,
    };

    if (this.isReadonly(row)) {
      result.className = 'read-only-row';
    }

    switch (ReadoutDefinitionUtils.getType(row)) {
      case 'readout':
        result.id = `readout-${row.id}-show`;
        break;

      case 'calculated':
        result.id = `custom-calculation-${row.calculation_id}-show`;
        break;

      case 'dose-response':
        result.id = `dose_response_calculation_${row.calculation_id}`;
        break;
    }
    return result;
  };

  /**
   * Return a map of rows to optional subrows, displayed below the row.
   */
  get subRows() {
    const result = new Map<number, Array<Partial<DisplayRow>>>();

    for (const row of this.props.rows) {
      const subrows: Array<Partial<DisplayRow>> = [];

      const rowType = ReadoutDefinitionUtils.getType(row);

      const addNormalizationSubrows = () => {
        row.normalized_calculations_attributes.forEach(normalized_calculation => {
          subrows.push({
            name: normalized_calculation.name,
            format_unit_label: normalized_calculation.output_readout_definition?.format_unit_label,
            description: normalized_calculation.description,
          });
        });
      };

      switch (rowType) {
        case 'dose-response': {
          const doseResponse = this.getDoseResponseForReadoutDefinition(row);
          const doseResponseAttributes = doseResponse.dose_readout_definition_attributes;
          if (doseResponse) {
            if (doseResponse.has_fit_parameter_constraints) {
              subrows.push({
                description: doseResponse.minimum_activity_message,
              });
            }

            subrows.push({
              name: doseResponse.dose_readout_definition_attributes.name,
              format_unit_label: (doseResponseAttributes as any).format_unit_label,
            });

            const normalized_response_readout_definition = doseResponse.normalized_response_readout_definition;
            if (normalized_response_readout_definition) {
              subrows.push({
                name: normalized_response_readout_definition.name,
                format_unit_label: normalized_response_readout_definition.format_unit_label,
                description: normalized_response_readout_definition.description,
              });
            } else {
              subrows.push({
                name: doseResponse.response_readout_definition_attributes.name,
                format_unit_label: doseResponse.response_readout_definition_attributes.format_unit_label,
              });
            }
          }
          break;
        }

        case 'calculated': {
          if (row.description) {
            const customCalc = this.props.customCalculations.find(calc => calc.id === row.calculation_id);
            subrows.push({
              description: <span className='indent-first-child'>{this.renderCustomCalculationFormula(customCalc)}</span>,
            });
          }
          addNormalizationSubrows();
          break;
        }

        default: {
          addNormalizationSubrows();
          break;
        }
      }
      if (subrows.length) {
        result.set(row.id, subrows);
      }
    }

    return result;
  }

  isReadonly(row: Partial<DisplayRow>) {
    if (this.props.readonly) {
      return true;
    }
    switch (row.calculation_type) {
      case 'DoseResponseCalculation': {
        const doseResponse = this.getDoseResponseForReadoutDefinition(row as ReadoutDefinitionObject);
        if (doseResponse?.deletion_allowed === false) {
          return true;
        }
      }
    }
    return !row.deletion_allowed;
  }

  handleClickRow = (row: ReadoutDefinitionObject) => {
    if (!this.isReadonly(row)) {
      this.props.onClickRow(row);
    }
  };

  renderCustomCalculationFormula = (customCalc: CustomCalculationObject) => {
    const parts: JSX.Element[] = [];
    const { description_parts = null, critical_parts, raw_parts } = customCalc.calculation_formula_with_definition_description;
    if (!description_parts?.length && !critical_parts?.length && raw_parts) {
      parts.push(<>{raw_parts}</>);
    }
    if (description_parts?.length) {
      description_parts.forEach((part, i) => {
        parts.push(<>
          {(i === 0 && critical_parts?.length > 0) ? <><span className='critical'>⚠ </span>{part}</> : part}
        </>);
      });
    }

    parts.push(<i>{this.clickToCopyMessage}</i>);

    if (critical_parts?.length) {
      critical_parts.forEach(part => {
        parts.push(<span className='critical'>{part}</span>);
      });
    }

    return <span data-column-id='formula'>
      {parts.map((part, i) => <span data-readout-name key={i}>{i ? <div style={{ marginBottom: '1rem' }} /> : null}{part}</span>)}
    </span>;
  };

  renderCell = (columnId: string, row: Partial<DisplayRow>, subrow = false) => {
    const blank = '';
    let result: string | JSX.Element | boolean = row[columnId];
    const readonly = this.isReadonly(row);

    const autoEllipsisProps: React.ComponentProps<typeof AutoEllipsisTooltip> = {
      clickToCopy: true,
      copyTextPreprocessFunc: text => {
        ['Aggregate by ', this.clickToCopyMessage].forEach(str => {
          const i = text.indexOf(str);
          if (i !== -1) {
            text = text.substring(0, i);
          }
        });
        return text;
      },
    };

    if (row.calculation_type === 'DoseResponseCalculation') {
      const doseResponse = this.getDoseResponseForReadoutDefinition(row as ReadoutDefinitionObject);
      if (doseResponse) {
        const doseResponseAttributes = doseResponse.dose_readout_definition_attributes;
        switch (columnId) {
          case 'name':
            result = doseResponse.sentenced_intercept_names;
            break;

          case 'data_type_name':
            result = 'Plot';
            break;

          case 'unit_label':
            result = (doseResponseAttributes as any).format_unit_label;
            break;

          case 'required':
            result = doseResponseAttributes.required;
            break;

          case 'description':
            result = doseResponse.fit_parameters_summary;
            break;
        }
      }
    } else if (row.calculation_type === 'CustomCalculation') {
      const customCalc = this.props.customCalculations.find(calc => calc.id === row.calculation_id);
      if (columnId === 'description' && customCalc) {
        if (row.description) {
          result = row.description;
        } else {
          result = this.renderCustomCalculationFormula(customCalc);
        }
      }
      if (columnId === 'data_type_name' && customCalc) {
        result = 'Calculated';
      }
    } else {
      if (row.pick_list_values?.length && columnId === 'description') {
        const description = row[columnId] || '';
        const separator = (description as string).endsWith('.') ? '' : '.';
        const picklistValues = `Values: ${row.pick_list_values.map(v => `"${v.value}"`).join(', ')}`;

        result = <>
          {row[columnId] ? `${row[columnId]}${separator} ${picklistValues}` : picklistValues}
        </>;
      }
      if (columnId === 'unit_label') {
        result = row.format_unit_label;
      }
    }
    if (result === true || result === false) {
      result = result
        ? <Img width={16} height={16} className='icon-16'
          alt={columnId === 'required' ? 'required' : 'condition'}
          src={tickIcon} />
        : blank;
    }

    result = result || row[columnId] || blank;
    if (columnId === 'unit_label' && result && typeof result === 'string') {
      if (!result.startsWith('(')) {
        result = `(${result})`;
      }
    }

    if (columnId === 'name') {
      if (subrow) {
        result = <span data-readout-name className='subrow-name'>{result}</span>;
      } else {
        let id = '';
        switch (ReadoutDefinitionUtils.getType(row as ReadoutDefinitionObject)) {
          case 'readout':
            id = `edit_link_readout_definition_${row.id}`;
            break;

          case 'dose-response':
            id = `edit_link_dose_response_calculation_${row.calculation_id}`;
            break;

          case 'calculated':
            id = `edit_link_custom_calculation_${row.calculation_id}`;
            break;
        }

        result = <A href='#' id={id} data-readout-name data-readout-id={row.id} className={readonly ? 'disabled' : ''}>
          <b>{result}</b>
        </A>;
      }
    }

    const subrows = this.subRows.get(row.id);
    if (subrows) {
      return [
        result,
        ...subrows.map(row => this.renderCell(columnId, row, true)),
      ].map((cell, i) => <AutoEllipsisTooltip key={i} {...autoEllipsisProps}>{cell}</AutoEllipsisTooltip >);
    }

    return subrow ? result : <AutoEllipsisTooltip {...autoEllipsisProps}>{result}</AutoEllipsisTooltip>;
  };

  render() {
    const { readonly } = this.props;
    if (readonly) {
      return <SimpleDataTable
        columns={columns}
        rows={this.props.rows}
        renderCell={this.renderCell}
        showHover={false}
        getRowAttributes={this.getRowAttributes} />;
    }

    return <ReorderDataTable className='ProtocolReadoutDefinitionsTable'
      {...this.props}
      onClickRow={this.handleClickRow}
      columns={columns}
      renderCell={this.renderCell}
      getRowAttributes={this.getRowAttributes}
      showDelete={false}
      rowIdPrefix='readout-definition-'
    />;
  }
}
