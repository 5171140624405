import React from 'react'
import moment from 'moment'

import TextQueryForm from './TextQueryForm.jsx'
import QueryDateField from './QueryDateField.jsx'
import constants from 'javascripts/constants.js'
const { DATE_DISPLAY_FORMAT, DATE_QUERY_STYLE_LABELS } = constants

export default class DateQueryForm extends TextQueryForm {
  isMultiline = false
  queryStyleLabels = DATE_QUERY_STYLE_LABELS
  TextField = QueryDateField

  /* eslint-disable-next-line no-dupe-class-members */
  isMultiline() {
    return false
  }

  getErrorMessage(query) {
    const messages = super.getErrorMessage(query)

    if (query && !(query.match(/^\d\d\d\d-\d\d-\d\d$/) && moment(query).isValid())) {
      if (messages.length) {
        messages.push(<br key={messages.length} />)
      }
      messages.push(`Must be ${DATE_DISPLAY_FORMAT}`)
    }

    return messages
  }
}
