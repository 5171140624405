import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { accountsService } from '@/Accounts/accountsServices'

const ElnEntry = (props) => {
  const [ElnEntry, setComponent] = useState(undefined)
  useEffect(() => {
    import('@cdd/eln/entry').then(({ default: ElnEntry }) => {
      setComponent(() => ElnEntry)
    })
  }, [])

  if (!ElnEntry) {
    return null
  }
  return <ElnEntry {...props} getUsersInProjectWithPermissions={accountsService.getUsersInProjectWithPermissions} />
}

export function ElnEntryRenderer(props) {
  if (module.hot) {
    module.hot.accept(['./container.jsx', './actions/index.js'], ElnEntryRenderer)
  }

  const container = document.getElementById('elnEntry')
  container.className += " wrapper-class"
  const root = createRoot(container)

  return root.render(
    <ElnEntry {...props} />
  )
}
