import React from 'react';
import { DDFormProps } from '../DDForm/DDForm';
import { MuiDDForm, MuiDDFormProps, MuiDDModalForm, MuiDDModalFormProps } from '../DDForm/mui/MuiDDForm';

type Props = MuiDDFormProps;

const defaults: DDFormProps['defaults'] = {
  default: {
    size: 'medium',
    width: 'expand',
  },
  button: {
    variant: 'contained',
  },
};

export const CDDForm: React.FunctionComponent<Props> = (props) => (
  <MuiDDForm {...props} defaults={defaults}></MuiDDForm>
);

export const CDDModalForm: React.FunctionComponent<MuiDDModalFormProps> = (
  props,
) => <MuiDDModalForm {...props} defaults={defaults}></MuiDDModalForm>;
