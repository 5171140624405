import React from 'react'

export default function PickListEditIcon(props) {
  const { disabled, edit } = props

  if (disabled) {
    return (
      <span className="pick-list-edit fa fa-pencil disabled" />
    )
  } else {
    return (
      <span
        className="pick-list-edit small-edit-icon fa fa-pencil"
        title="Edit Pick List values"
        onClick={edit}
      />
    )
  }
}
