import React from 'react';

import { FieldDataType } from '@/FieldDefinitions/types';
import { FilterDateValue } from './FilterDateValue';
import { FilterNumberRangeValue } from './FilterNumberRangeValue';
import { FilterPickListValue } from './FilterPickListValue';
import { FilterTextValue } from './FilterTextValue';
import { FilterUniqueListValue } from './FilterUniqueListValue';
import { FilterValueSelectProps } from './FilterValueSelect.types';

type FilterValueSelectComponent = typeof React.Component<
  FilterValueSelectProps<FieldDataType>
>;

// TODO dedupe with eln entries stuff where possible
const componentMap = new Proxy<
  Partial<Record<FieldDataType, FilterValueSelectComponent>>
>(
  {
    PickList: FilterPickListValue,
    UniqueList: FilterUniqueListValue,
    Date: FilterDateValue,
    Text: FilterTextValue,
    Number: FilterNumberRangeValue,
  },
  {
    get: (map, type) => map[type] || FilterTextValue,
  },
);

export class FilterValueSelect extends React.Component<
  FilterValueSelectProps<FieldDataType>
> {
  get is_unique_list(): boolean {
    const {
      fieldDefinition: { id, field_type },
      id_list_fd_ids,
    } = this.props;
    if (id_list_fd_ids === undefined) {
      return false;
    }
    return id_list_fd_ids[field_type].includes(id as number);
  }

  render() {
    const {
      fieldDefinition: { data_type_name },
    } = this.props;
    const Component = this.is_unique_list
      ? componentMap[FieldDataType.UniqueList]
      : componentMap[data_type_name];
    return (
      <span className="filter-value-select">
        <Component {...this.props} />
      </span>
    );
  }
}
