import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'

export default function Actions(props) {
  const { disabled, disabledTitle, onClick, uneditable } = props

  if (uneditable || disabled) {
    return (
      <span title={disabledTitle || ''} className="cancel disabled">
        Remove
        <span className="separator"> · </span>
      </span>
    )
  } else {
    return (
      <span>
        <A
          className="cancel"
          name="remove"
          onClick={onClick}
          href="#"
        >
          Remove
        </A>
        <span className="separator"> · </span>
      </span>
    )
  }
}
