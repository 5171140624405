import React from 'react'
import { List } from 'immutable'

import JunctionButton from './JunctionButton/index.js'

const JUNCTION_LABELS = List([
  'and',
  'or',
])

export default class CriterionJunction extends React.PureComponent {
  render() {
    const { selectedJunction, criterionIndex, onJunctionChange } = this.props

    let junctionButtons = JUNCTION_LABELS.map((junctionLabel, index) => {
      return (
        <label key={index}>
          <JunctionButton
            selectedJunction={selectedJunction}
            junctionLabel={junctionLabel}
            onJunctionChange={onJunctionChange}
            criterionIndex={criterionIndex}
          />
        </label>
      )
    })

    return (
      <div className="inlineRadios search_criterion_icon_junction">
        {junctionButtons}
      </div>
    )
  }
}
