import { Record, List } from 'immutable'

import constants from 'javascripts/constants.js'
const {
  QUERY_LESS_STYLES,
  DOUBLE_QUERY_STYLES,
  NEGATION_EXISTS_QUERY_STYLE,
  NEGATION_FULL_QUERY_STYLES,
  DEFAULT_EXISTS_QUERY_STYLE,
  DEFAULT_FULL_QUERY_STYLES,
  RANGE_QUERY_STYLES,
  NUMBER_QUERY_STYLES,
  DATE_QUERY_STYLES,
} = constants

const BLANK_QUERY = ['']

const toCollection = Class => (array = []) => {
  // Handle index keyed object
  if (array && typeof array === 'object') {
    array = Object.values(array)
  }

  const collection = List(array).map(el => new Class(el))
  return collection.size > 0 ? collection : List([new Class()])
}

const DEFAULT_QUERY = ''
const DEFAULT_QUERY_STYLE = DEFAULT_EXISTS_QUERY_STYLE
const DEFAULT_JUNCTION = 'AND'

export class MoleculeCriterion extends new Record({
  query: DEFAULT_QUERY,
  second_query: DEFAULT_QUERY,
  query_style: DEFAULT_QUERY_STYLE,
  junction: DEFAULT_JUNCTION,
  field: '',
  path: '',
  data_type_name: 'Text',
}) {
  static toAttributes(fields, molecule_criteria) {
    if (molecule_criteria.size == 1 && molecule_criteria.first().isDefault()) {
      return List()
    }

    molecule_criteria = MoleculeCriteriaField.rescope(fields, molecule_criteria)

    return molecule_criteria.map((molecule_criterion) => {
      const { path, field } = molecule_criterion
      let criterion_field = fields.find(criteriaField => criteriaField.path === path && criteriaField.field === field)

      if (criterion_field) {
        molecule_criterion = molecule_criterion.merge({
          field: criterion_field.get('field'),
          path: criterion_field.get('path'),
        })
      }

      return molecule_criterion
    }).filter(molecule_criterion => !molecule_criterion.isEmpty())
  }

  updateQuery(query) {
    let { query_style } = this
    if (query === '') {
      if (DEFAULT_FULL_QUERY_STYLES.includes(query_style)) {
        query_style = DEFAULT_EXISTS_QUERY_STYLE
      } else if (NEGATION_FULL_QUERY_STYLES.includes(query_style)) {
        query_style = NEGATION_EXISTS_QUERY_STYLE
      }
    } else {
      const get_new_query_style = (negate) => {
        let { data_type_name } = this
        if (data_type_name === 'Number' || data_type_name === 'Date') {
          return RANGE_QUERY_STYLES.first()
        } else if (negate) {
          return NEGATION_FULL_QUERY_STYLES.first()
        } else {
          return DEFAULT_FULL_QUERY_STYLES.first()
        }
      }

      if (query_style === DEFAULT_EXISTS_QUERY_STYLE) {
        query_style = get_new_query_style()
      } else if (query_style === NEGATION_EXISTS_QUERY_STYLE) {
        query_style = get_new_query_style(true)
      }
    }

    return this.merge({ query, query_style })
  }

  updateSecondQuery(second_query) {
    return this.merge({ second_query })
  }

  updateQueryStyle(query_style) {
    let { query, second_query } = this
    if (QUERY_LESS_STYLES.includes(query_style)) {
      query = second_query = ''
    }

    return this.merge({ query, second_query, query_style })
  }

  updateField({ path, field, data_type_name }) {
    let { query_style } = this
    if (data_type_name === 'Number' && !NUMBER_QUERY_STYLES.includes(query_style)) {
      query_style = RANGE_QUERY_STYLES.first()
    } else if (data_type_name === 'Date' && !DATE_QUERY_STYLES.includes(query_style)) {
      query_style = RANGE_QUERY_STYLES.first()
    } else if (!field && RANGE_QUERY_STYLES.includes(query_style)) {
      query_style = 'has'
    }

    return this.merge({ query_style, path, field, data_type_name })
  }

  updateJunction(junction) {
    return this.merge({ junction })
  }

  isEmpty() {
    return !QUERY_LESS_STYLES.includes(this.query_style) &&
    BLANK_QUERY.includes(this.query) &&
    (
      !DOUBLE_QUERY_STYLES.includes(this.query_style) ||
      BLANK_QUERY.includes(this.second_query)
    )
  }

  isDefault() {
    return this.equals(new MoleculeCriterion())
  }
}

MoleculeCriterion.buildCollection = toCollection(MoleculeCriterion)

export class MoleculeCriteriaField extends new Record({
  label: 'Any field',
  field: '',
  path: '',
  data_type_name: 'Text',
}) {
  static getDataTypeName(fields, path, field) {
    const criteriaField = fields.find((criteriaField) => {
      return criteriaField.path === path && criteriaField.field === field
    })
    return criteriaField && criteriaField.data_type_name ? criteriaField.data_type_name : 'Text'
  }

  static rescope(fields, molecule_criteria) {
    return molecule_criteria.map(molecule_criterion => {
      const { path, field } = molecule_criterion

      let matchingFields = fields.filter(criteriaField => criteriaField.path === path && typeof field === 'string' && field.toUpperCase() === criteriaField.field.toUpperCase())
      if (matchingFields.size <= 0) {
        molecule_criterion = molecule_criterion.delete('field')
        // use the 'youngest' path available
        // style of path is of grandparent.parent.child, so youngest is longest
        matchingFields = fields.filter(criteriaField => {
          if (criteriaField.field || !path) return false

          const pathParts = path.split('.')
          const criteriaPathParts = criteriaField.path.split('.')

          if (pathParts.length < criteriaPathParts.length) return false

          for (let i = 0; i < criteriaPathParts.length; i++) {
            if (criteriaPathParts[i] !== pathParts[i]) return false
          }
          return true
        })
        if (matchingFields.size <= 0) {
          molecule_criterion = molecule_criterion.delete('path')
        } else {
          molecule_criterion = molecule_criterion.set('path', matchingFields.maxBy(criteriaField => criteriaField.path.length).path)
        }
      }

      return molecule_criterion
    })
  }

  constructor([label, attributes] = []) {
    super({ ...attributes, label })
  }
}

MoleculeCriteriaField.buildCollection = toCollection(MoleculeCriteriaField)
