import { sanitizeESFragment } from '@/shared/components/sanitizeTags'
import React from 'react'
export default class Highlights extends React.Component {
  render() {
    const { highlights } = this.props

    if (highlights && highlights.size === 0) {
      return null
    } else {
      const rendered = highlights
        .filter(highlight => (highlight.name !== 'identifier' && highlight.name !== 'title'))
        .map(highlight => {
          const { fragments, name } = highlight

          // We 'dangerouslySetInnerHTML' because the fragments coming from
          // elasticsearch will have 'em' tags for emphasis. Elasticsearch should
          // escape unsafe html content, but we additionally run it through a sanitize call
          // to be extra safe.
          return [
          <li key={name}>
              <span className="search_results__key">{name}</span>
              {fragments.map(fragment => {
                const sanitizedFragment = sanitizeESFragment(fragment)
                return <span
                  className="search_results__value"
                  key={sanitizedFragment}
                  dangerouslySetInnerHTML={ { __html: sanitizedFragment } }
                />
              })}
            </li>,
          ]
        }).flatten().toArray()

      return (
        <ul className="search_results__highlight">
          {rendered}
        </ul>
      )
    }
  }
}
