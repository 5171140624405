import React from 'react';
import { getRootStore } from '@/stores/rootStore';
import { observer } from 'mobx-react';
import { MuiDDForm } from '@/shared/components/DDForm/mui/MuiDDForm';
import { CondensedTheme } from '@/shared/components/MuiTheme';

@observer
export class ProtocolSearchCriteriaView extends React.Component {
  static noTheme = true;

  render() {
    const {
      protocolSearchCriteriaStore: { data, layout, termIds, handleSetValue },
    } = getRootStore();

    return <CondensedTheme>

    <div className={'ProtocolSearchCriteriaView'} id={'ProtocolSearchCriteriaView'} data-term-ids={termIds.join()}>
      <MuiDDForm
        size={'small'}
        data={data}
        layout={layout}
        onAfterSetValue={handleSetValue}
        renderNameAttribute={false}
      />
    </div>
    </CondensedTheme>;
  }
}
