import { FieldPickValue } from '@/FieldDefinitions/types';
import { StringOrNumber } from '@/types';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import React from 'react';

type Props = {
  pickListValue: FieldPickValue;
  showSingleUseOption: boolean;
  unmodifiableSingleUseItems?: Set<StringOrNumber>;
  update: (id: StringOrNumber, value: Partial<FieldPickValue>) => void;
  remove: (id: StringOrNumber) => void;
};

const ItemActions = (props: Props) => {
  const { showSingleUseOption, pickListValue, unmodifiableSingleUseItems, update, remove } = props;

  const { hidden, deletable, is_single_use } = pickListValue;

  const hiddenTitle = hidden ? 'currently hidden' : 'currently visible';
  const removeTitle = deletable ? 'click to remove item' : 'unable to remove item with data';

  const preventModifyingSingleUse = unmodifiableSingleUseItems?.has(pickListValue.id);

  const toggleVisibility = () => update(pickListValue.id, { hidden: !hidden });
  const toggleCheckSingleUse = () => { !preventModifyingSingleUse && update(pickListValue.id, { is_single_use: !is_single_use }); };
  const handleClickRemove = () => { deletable && remove(pickListValue.id); };

  const singleUseTooltip = preventModifyingSingleUse ? 'To modify Single Use, delete all existing samples or contact support' : null;
  return (
    <div className="pickListItemActions">
      <Tooltip title={`hide/show value -- ${hiddenTitle}`}>
        <span
          className={`hideIcon fa fa-eye${hidden ? '-slash disabled' : ''}`}
          onClick={toggleVisibility}
        />
      </Tooltip>

      <Tooltip title={removeTitle}>
        <span
          className={`deleteIcon fa fa-times ${deletable ? '' : 'disabled'}`}
          onClick={handleClickRemove}
        />
      </Tooltip>

      {showSingleUseOption &&
        <Tooltip title={singleUseTooltip}>
          <FormControlLabel
            style={{ marginLeft: '0.5rem' }}
            label="Single Use"
            control={<Checkbox
              inputProps={{ 'aria-label': singleUseTooltip }}
              readOnly={preventModifyingSingleUse}
              checked={!!pickListValue.is_single_use}
              onClick={toggleCheckSingleUse}
            />
            } />
        </Tooltip>
      }
    </div>
  );
};

export default ItemActions;
