import { HasIdAndName } from '@/Accounts/types';
import { ColumnDef, SimpleDataTable } from '@/components';
import { ColumnsEditor } from '@/components/ColumnsEditor/ColumnsEditor';
import { AnyObject } from '@/types';
import { observer } from 'mobx-react';
import React from 'react';
import MultipleTablesWithStickyHeaders from './MultipleTablesWithStickyHeaders';
import './StickyDataTable.sass';
import { SimpleDataTableRenderType } from '../SimpleDataTable/SimpleDataTable';

type Props = React.ComponentProps<typeof MultipleTablesWithStickyHeaders> & {
  group: HasIdAndName;
  rows: Array<AnyObject>;
  renderCustomHeaderCell?: (column: ColumnDef, group: HasIdAndName) => SimpleDataTableRenderType;
};

@observer
export default class StickyDataTable extends React.Component<Props> {
  renderCustomHeaderCell = (column: ColumnDef) => {
    if (column.id === 'btn_edit_columns') {
      return <ColumnsEditor
        availableColumns={this.props.availableColumns}
        value={this.props.visibleColumns}
        onChangeValue={this.props.setVisibleColumns}
      />;
    }
    return this.props.renderCustomHeaderCell?.(column, this.props.group);
  };

  render() {
    return <div className='StickyDataTable' data-project-name={this.props.group.name}>
      <SimpleDataTable
        {...this.props}
        stickyHeaders={true}
        renderCustomHeaderCell={this.renderCustomHeaderCell}
      />
    </div>;
  }
}
