import React from 'react';

import { getRootStore } from '@/stores/rootStore';
import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { EditLocationsDialog } from '../location_editor/EditLocationsDialog';
import { A } from '@/shared/components/sanitizedTags';
import { Img } from '@/shared/components/sanitizedTags.js';
import editIcon from 'ASSETS/images/cdd30/icons/pencil.png';

@observer
export class LocationEditorField extends React.Component {
  render() {
    const { handleEditLocations } = getRootStore().locationStore;

    return <>

      <Tooltip title='Edit Locations'>
        <A onClick={handleEditLocations}>
          <Img
            width={16}
            height={16}
            className='icon-16'
            alt='Edit sample'
            src={editIcon}
          />
          Edit Locations
        </A>
      </Tooltip>
      <EditLocationsDialog />
    </>;
  }
}
