export const ASSIGN_WITNESS = 'eln/entry/witness/asssign'
export const READ_WITNESSES = 'eln/entry/witness/read'
export const CACHE_WITNESSES = 'eln/entry/witness/cache'

export const readWitnesses = () => ({
  type: READ_WITNESSES,
  payload: {},
})

export const cacheWitnesses = (witnesses) => ({
  type: CACHE_WITNESSES,
  payload: { witnesses },
})

export const assignWitness = (elnWitnessId) => ({
  type: ASSIGN_WITNESS,
  payload: { elnWitnessId },
})
