/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, StepperDef } from '@/shared/components/DDForm/types';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { runInAction } from 'mobx';
import { getElementAttributes, renderElement } from '../muiRenderers';
import { ddMuiRenderTabs } from './ddMuiRenderTabs';

/**
 * Render a stepper and pages
 * @param props
 * @param field
 * @param getValue
 * @param setValue
 * @returns
 */
export const ddMuiRenderStepper: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as StepperDef;

  const activeStep = (getValue(field) as number) ?? 0;

  const setActiveStep = (step: number) => {
    runInAction(() => {
      setValue(field, step);
    });
  };

  const activePage = field.children[activeStep];
  if (!field.useStepper) {
    const tabs = {
      ...field,
      children: field.children.map((page, index) => ({
        ...page,
        error: props.showErrors && !!formErrors?.[`${field.key}[${index}]`],
      })),
    };
    return ddMuiRenderTabs(props, tabs, getValue, setValue, formErrors);
  }
  return (
    <div key={field.renderKey}>
      <Stepper
        activeStep={activeStep}
        nonLinear={field.allowDirectSwitching !== false}
        {...getElementAttributes(field, props)}
      >
        {field.children.map((child, index) => (
          <Step key={'' + child.label}>
            <StepButton
              className='element-step-button'
              onClick={() => {
                setActiveStep(index);
              }}
            >
              <StepLabel
                error={
                  props.showErrors && !!formErrors?.[`${field.key}[${index}]`]
                }
              >
                {'' + child.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className='stepper-page-container'>
        {renderElement(props, activePage, getValue, setValue, formErrors)}
      </div>
    </div>
  );
};
