import { AccountUserSettingsSchema, UserSchema, UserWithPermissionsSchema } from '@/Accounts/types';
import { ColumnDef, SimpleDataTable } from '@/components';
import { SortByColumnProps } from '@/components/SimpleDataTable/SortBy.types';
import { A } from '@/shared/components/sanitizedTags.js';
import { AnyObject } from '@/types';
import autobind from 'class-autobind';
import * as React from 'react';

type Props = SortByColumnProps & {
  rows: Array<UserSchema>;
  onClickRow: (user: UserSchema) => void;
};

type prefixedKeyOf<P extends string, T extends AnyObject> = keyof {
  [K in keyof T as K extends string ? `${P}.${K}` : never]: unknown
};

export type UserColumnId =
  | 'name'
  | 'teams_count'
  | 'vaults_count'
  | 'projects_count'
  | keyof UserWithPermissionsSchema
  | prefixedKeyOf<'account_user_settings', AccountUserSettingsSchema>;

// Table with: Name, Email, Vaults/Projects (project count as a link - projects should be organized by Vault and alphabetical),
const columns: Array<ColumnDef & { id: UserColumnId; }> = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Email',
    id: 'email',
  },
  {
    label: 'ELN ID',
    id: 'account_user_settings.identifier',
  },
  {
    label: '# Teams',
    id: 'teams_count',
  },
  {
    label: '# Vaults',
    id: 'vaults_count',
  },
  {
    label: '# Projects',
    id: 'projects_count',
  },
  {
    label: 'Disabled',
    id: 'disabled',
    type: 'checkmark',
  },
  {
    label: 'Masked Structure',
    id: 'mask_structures',
    type: 'checkmark',
  },
  {
    label: '# API keys',
    id: 'api_keys_count',
  },
  {
    label: 'Last login',
    id: 'last_logged_in',
    type: 'date',
  },
  {
    label: 'Date added',
    id: 'created_at',
    type: 'date',
  },
];

export class UsersTable extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    autobind(this);
  }

  renderCell(columnId: UserColumnId, user: UserWithPermissionsSchema) {
    switch (columnId) {
      case 'name':
        return (
          <A
            data-id={user.id}
            onClick={() => { /* no-op, this is handled in handleClickRow */ }}
          >{`${user.first_name} ${user.last_name}`}</A>
        );
    }
  }

  // for sorting
  getFieldValue(columnId: UserColumnId, user: UserWithPermissionsSchema) {
    switch (columnId) {
      case 'name':
        return `${user.last_name}, ${user.first_name}`;

      case 'teams_count':
        return Object.keys(user.team_memberships).length ?? 0;

      case 'vaults_count':
        return Object.keys(user.vault_permissions).length ?? 0;

      case 'projects_count':
        return Object.keys(user.project_permissions).length ?? 0;
    }
  }

  render() {
    return (
      <SimpleDataTable
        stickyHeaders
        columns={columns}
        rows={this.props.rows}
        onClickRow={this.props.onClickRow}
        renderCell={this.renderCell}
        sortBy={this.props.sortBy}
        setSortBy={this.props.setSortBy}
        getFieldValue={this.getFieldValue}
      />
    );
  }
}
