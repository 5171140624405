/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, NumberInputDef } from '@/shared/components/DDForm/types';
import { TextField } from '@mui/material';
import { getElementAttributes, getInputProps } from '../muiRenderers';

/**
 * Render a field as a numeric input
 * @param field
 * @returns
 */
export const ddMuiRenderNumericInput: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as NumberInputDef;
  const error = props.showErrors && formErrors && formErrors[field.key];
  const value = getValue(field) ?? '';
  const passValue = isNaN(parseFloat('' + value)) ? '' : value.toString();
  const inputProps = getInputProps(field);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(field, e.currentTarget.value);
  };

  return (
    <TextField
      {...getElementAttributes(field, props)}
      type='number'
      placeholder={field.placeholder}
      key={field.renderKey}
      label={field.label}
      inputProps={inputProps}
      error={!!error}
      helperText={error}
      // for some reason, this doesn't well work as a controlled input (https://www.pivotaltracker.com/story/show/185912367)
      // probably it's fine to have it uncontrolled, but this should be investigated
      defaultValue={passValue}
      onChange={handleChange}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled ?? false}
      onFocus={event => {
        // when this gets focus, select the contents. Otherwise since the default is "0", typing "1" may result in "10"
        event.target.select();

        // there's a scenario in which you have two numeric inputs on a page and one or both has validation errors, and
        // without stopping the event propagation the focus rapidly cycles between the two
        event.stopPropagation();
        event.preventDefault();

        // I don't know why this ugly hack is necessary, but sometimes the selection is reset when clicking
        setTimeout(() => {
          event.target.select();
        });
      }}
    />
  );
};
