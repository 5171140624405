import React from 'react'

export default class CriterionField extends React.PureComponent {
  onCriterionFieldChange = (event) => {
    const { criterionIndex, onFieldChange, fields } = this.props
    const { value } = event.target

    return onFieldChange(criterionIndex, fields.get(value))
  }

  render() {
    const { className = 'molecule_criteria__select', fields, path, field, data_type_name } = this.props
    const index = fields.findIndex((criteriaField) => {
      return criteriaField.path === path && criteriaField.field.toLowerCase() === field.toLowerCase() && criteriaField.data_type_name === data_type_name
    })

    return (
      <div className="item">
        <select className={className} onChange={this.onCriterionFieldChange} value={index}>
          {fields.map((field, idx) => {
            return <option key={idx} value={idx}>{field.label}</option>
          })}
        </select>
      </div>
    )
  }
}
