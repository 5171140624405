import React from 'react'

import { limitFilterStyles } from '@/Search/SearchFilters/limitSearchFilterStyles.ts'

import { FilterValueSelect } from '@/Search/SearchFilters/FilterValueSelect.tsx'
import constants from 'javascripts/constants.js'
import FilterFieldsRow from '../FilterFieldsRow.jsx'
import FilterFieldSelect from './FilterFieldSelect.jsx'
import FilterStyleSelect from './FilterStyleSelect.jsx'
const { SEARCH_FILTER_STYLES } = constants

export default class FilterItem extends React.Component {
  handleFieldChange = (field_select_value) => {
    const { index, elnFieldDefinitions } = this.props
    const fieldDefinition = elnFieldDefinitions.bySelectValue.get(field_select_value)
    const fieldName = fieldDefinition.name
    this.props.updateQueryFilter(
      {
        index,
        filter: {
          eln_field_definition_id: fieldDefinition.id,
          field_name: fieldName,
          data_type_name: fieldDefinition.data_type_name,
          field_style: '',
          field_values: [],
        },
      }
    )
  }

  handleStyleChange = (filter_style_key) => {
    const { index, filter } = this.props
    this.props.updateQueryFilter(
      {
        index,
        filter: {
          eln_field_definition_id: filter.eln_field_definition_id,
          field_name: filter.field_name,
          data_type_name: filter.data_type_name,
          filter_style_key: filter_style_key,
          field_values: [],
        },
      }
    )
  }

  handleValueChange = (field_values) => {
    const { index, filter } = this.props
    this.props.updateQueryFilter(
      {
        index,
        filter: {
          eln_field_definition_id: filter.eln_field_definition_id,
          field_name: filter.field_name,
          data_type_name: filter.data_type_name,
          filter_style_key: filter.filter_style_key,
          field_values: field_values,
        },
      }
    )
  }

  handleRemove = () => {
    const { index } = this.props
    this.props.removeQueryFilter(index)
  }

  renderFilterField = () => {
    const { elnFieldDefinitions, filter, selectedFieldValues } = this.props
    const { field_select_value } = filter

    return (
      <FilterFieldSelect
        selectedValues={field_select_value}
        className={'filter__field__select'}
        elnFieldDefinitions={elnFieldDefinitions}
        selectedFieldValues={selectedFieldValues}
        handleFieldChange={this.handleFieldChange}
        multiple={false}
      />
    )
  }

  renderFilterStyle = () => {
    const { filter } = this.props
    const { field_select_value } = filter

    if (!field_select_value) {
      return null
    }

    const { filter_style_key, data_type_name } = filter

    let applicableFilterStyles = limitFilterStyles(data_type_name)

    return (
      <FilterStyleSelect
        filter_style_key={filter_style_key}
        handleStyleChange={this.handleStyleChange}
        applicableFilterStyles={applicableFilterStyles}
      />
    )
  }

  renderFilterValue = () => {
    const { elnFieldDefinitions, filter } = this.props
    const { field_select_value, filter_style_key } = filter

    if (SEARCH_FILTER_STYLES
      .filter((filterStyle) => (filterStyle.get('general')))
      .map((filterStyle) => (filterStyle.get('key')))
      .includes(filter_style_key)) {
      return null
    }

    return (
      <FilterValueSelect
        fieldDefinition={elnFieldDefinitions.bySelectValue.get(field_select_value)}
        filter={filter}
        handleValueChange={this.handleValueChange}
      />
    )
  }

  render() {
    return (
      <FilterFieldsRow
        filterField={this.renderFilterField()}
        filterStyle={this.renderFilterStyle()}
        filterValue={this.renderFilterValue()}
        isRemovable={true}
        onRemoveClick={this.handleRemove}
      />
    )
  }
}
