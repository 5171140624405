/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType } from '@/shared/components/DDForm/types';
import { PageElementDef } from '../../types/pageElementDef';
import { renderElement } from '../muiRenderers';
import { getGroupRenderKey } from './ddMuiRenderSimpleGroup';

/**
 * Render a page (row or column) containing other elements, to be used in tabs or stepper.
 * @param props
 * @param field
 * @param getValue
 * @param setValue
 * @returns
 */
export const ddMuiRenderPage: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as PageElementDef;
  const contents = { ...field.contents };
  if (contents.type === 'row' || contents.type === 'column') {
    contents.spacing = 1;
  }
  return <span key={getGroupRenderKey(field)}>
    {renderElement(props, field.contents, getValue, setValue, formErrors)}
  </span>;
};
