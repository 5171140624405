import { List, Record } from 'immutable'
import constants from 'javascripts/constants.js'
const { SEARCH_FILTER_STYLES } = constants

const DEFAULTS = {
  eln_field_definition_id: '',
  field_name: '',
  data_type_name: '',
  filter_style_key: SEARCH_FILTER_STYLES.first().get('key'),
  field_values: [],
}

export class Filter extends new Record(DEFAULTS) {
  get field_select_value() {
    return this.eln_field_definition_id ? this.eln_field_definition_id + '' : this.field_name
  }
}

export const Filters = (filters = []) => {
  return List(filters.map(filter => new Filter(filter)))
}
