import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { Theme } from '@mui/system';
import axios from 'axios';
import React from 'react';

import { CondensedTheme } from '@/shared/components/MuiTheme';
import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx';
import { Terminology } from 'javascripts/cross_app_utilities';

type BatchFieldData = {
  id: number | null;
  batch_id: number | null;
  batch_field_definition_id: number | null;
  float_value: null;
  text_value: string | null;
  date_value: null;
  uploaded_file_id: null;
  pick_list_value_id: null;
  batch_link_id: number | null;
}

export type SelectedBatchOption = {
  value: number | string;
  label: string;
} | null;

type Props = {
  classes?: { [className: string]: string };
  context: string;
  defaultValue: BatchFieldData;
  disabled?: boolean;
  name: string;
  onChange: (option: SelectedBatchOption) => void | null;
  placeholder?: string;
  theme?: Theme;
}

type State = {
  selectedOption: SelectedBatchOption | null;
  options: SelectedBatchOption[];
  focused: boolean;
}

class MoleculeBatchIdentifierPicker extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption:
        this.props.defaultValue?.batch_link_id
          ? {
              label: this.props.defaultValue?.text_value ?? '',
              value: this.props.defaultValue?.batch_link_id ?? '',
            }
          : null,
      options: [],
      focused: false,
    };
  }

  // Fetch for any number (normal) or there are at least 2 characters (non-reg)
  shouldFetch = (input) => {
    return /\d/.test(input) || input.length >= 2;
  };

  // Called from UI
  getPromise = (input) => {
    if (!this.shouldFetch(input)) {
      return Promise.resolve([]);
    }
    axios.get(
      `${this.props.context}/specified_batches/search.json`,
      { responseType: 'json', params: { molecule_batch_identifier: input } },
    ).then(response => {
      const options = response.data.map((batch) => ({
        label: batch.molecule_batch_identifier,
        value: batch.id,
      }));
      this.setState({ options });
      return options;
    });
  };

  setOption = (event, option) => {
    console.log('setOption', event, option);
    const { onChange: onChangeCallback } = this.props;
    onChangeCallback?.(option);
    this.setState({ selectedOption: option });
  };

  noOptionsMessage = ({ inputValue }) => {
    if (!this.shouldFetch(inputValue)) {
      return 'Paste the ' + Terminology.dictionary.molecule + ' ' + Terminology.dictionary.batch + ' identifier or search using the ID';
    } else {
      return 'None found.';
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedOption, options } = this.state;

    return (
      <CondensedTheme>
        <Autocomplete
          fullWidth
          disableClearable
          className='molecule-batch-identifier'
          classes={classes}
          options={options}
          filterOptions={(options) => options}
          getOptionLabel={(option: SelectedBatchOption) => option.label}
          value={selectedOption}
          onInputChange={(event, inputValue) => this.state.focused && this.getPromise(inputValue)}
          onChange={this.setOption}
          renderInput={(params) => (
            <TextField
              {...params}
              label={this.props.placeholder}
              variant="outlined"
              disabled={this.props.disabled}
              onFocus={() => this.setState({ focused: true })}
              onBlur={() => this.setState({ focused: false })}
            />
          )}
          noOptionsText={this.noOptionsMessage({ inputValue: '' })}
        />
        <RailsHiddenFields name={this.props.name} value={selectedOption?.value} />
      </CondensedTheme>
    );
  }
}

export default MoleculeBatchIdentifierPicker;
