import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'
import ExportLink from '@/shared/components/ExportLink'

export default class EntriesExportLinks extends React.Component {
  render() {
    const { hasEntries, canExport, elnEntriesExportUrl, elnSummaryExportUrl, elnEntriesExportProgressUrl } = this.props

    return <React.Fragment>
      <ExportLink
        className='search-bar__export_link'
        hidden={!canExport}
        disabled={!hasEntries}
        data-export-path={elnEntriesExportUrl}
        data-export-progress-path={elnEntriesExportProgressUrl}
      >
        Export {Terminology.dictionary['entry.other']}
      </ExportLink>
      <ExportLink
        className='search-bar__export_link'
        hidden={!canExport}
        disabled={!hasEntries}
        data-export-path={elnSummaryExportUrl}
        data-export-progress-path={elnEntriesExportProgressUrl}
      >
        Export summary
      </ExportLink>
    </React.Fragment>
  }
}
