import React from 'react'

import Icon from '@/shared/components/Icon.jsx'
import { A } from '@/shared/components/sanitizedTags.js'

export default function EditLink(props) {
  const { onClick, disabled, hidden } = props

  if (hidden) {
    return null
  } else if (disabled) {
    return (
      <A href="#" className="editableData-editLink disabled">
        <Icon forceSize="16" icon="pencil" />
        {props.children}
      </A>
    )
  } else {
    return (
      <A href="#" className="editableData-editLink" onClick={onClick}>
        <Icon forceSize="16" icon="pencil" />
        {props.children}
      </A>
    )
  }
}
