/* eslint-disable multiline-ternary, no-nested-ternary */

import React from 'react';
import { TemplateAssignment } from '../Annotator/data/templates';
import '../Annotator/Templates/Template.sass';
import { Schema, SchemaBranch } from '../Annotator/data/Schema';
import { areOntologiesInitialized, collapsePrefixes, expandPrefixes, initializeOntologies } from '../Annotator/data/utils';
import Tooltip from '@mui/material/Tooltip';
import { OntologyTree } from '../Annotator/data/OntologyTree';
import { Checkbox, Typography } from '@mui/material';
import { ProtocolsStore } from './protocolsStore';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import { A, Img } from '@/shared/components/sanitizedTags.js';
import { observer } from 'mobx-react';
import { FilterLayer, FilterLayerType, FilterLayerOntologyTerm, gatherTermCounts, filteredProtocolsByLayer, FilterLayerProtocolField, filterLayerIsBlank, FilterLayerFieldDataType } from './advancedSearchHelper';
import '@/shared/components/CDDForm/cddElements.sass';
import { AdvancedSearchFieldDialog } from './AdvancedSearchFieldDialog';
import { AdvancedSearchPickListDialog } from './AdvancedSearchPickListDialog';
import { PickTermDialog } from '@/Annotator/Templates/PickTermDialog';
import { SearchCache, SearchCacheRoot } from '@/Annotator/data/SearchCache';
import { Protocol } from './types';

type Props = {
  store: ProtocolsStore,
};

type State = {
  loaderBump: number;
  isSelectFieldOpen: boolean,
  selectFieldLayerIndex: number,
  selectFieldEligibleProtocols: Protocol[],
  isPickListOpen: boolean,
  pickListLayerIndex: number,
  pickListFieldTitle: string,
  pickListSelected: string[],
  pickListObserved: string[],
  pickListUnion: string[],
  isPickTermOpen: boolean,
  pickTermLayerIndex: number,
  pickTermAssn: TemplateAssignment,
  pickTermNest: string[],
  pickTermRoots: SchemaBranch[],
  pickTermSelected: string[],
  pickTermSearch: SearchCache,
  pickTermCount: Record<string, number>,
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
};

@observer
export class AdvancedSearchWidget extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loaderBump: 0,
      isSelectFieldOpen: false,
      selectFieldLayerIndex: null,
      selectFieldEligibleProtocols: null,
      isPickListOpen: false,
      pickListLayerIndex: 0,
      pickListFieldTitle: null,
      pickListSelected: null,
      pickListObserved: null,
      pickListUnion: null,
      isPickTermOpen: false,
      pickTermLayerIndex: 0,
      pickTermAssn: null,
      pickTermNest: null,
      pickTermRoots: null,
      pickTermSelected: null,
      pickTermSearch: null,
      pickTermCount: null,
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
    };
  }

  public render(): JSX.Element {
    const { store } = this.props;
    const { templateList } = store;
    const schema = store.currentTemplateSchema();

    // ontologies are probably loaded already, but it's not guaranteed
    if (!areOntologiesInitialized()) {
      setTimeout(async () => {
        await initializeOntologies();
        this.setState({ loaderBump: this.state.loaderBump + 1 });
        store.updateFilterResults();
      }, 1);
      return (<>Loading ontologies...</>);
    }

    let canAddNew = store.filterLayers.length == 0 || store.eligibleProtocols?.length > 0;
    if (filterLayerIsBlank(store.filterLayers.slice(-1)[0])) canAddNew = false;

    return (
      <>
        {templateList.length > 1 && (
          <div key="advanced-search-templates" style={{ marginTop: '0.5em' }}>
            Ontology Template:&nbsp;

            <select
              className="pick-list"
              value={store.ontologySchemaPrefix}
              onChange={(event) => this.handleChangeTemplate(event.target.value)}
              >
              {
                templateList.map((template, idx) => (
                  <option
                    key={`template-opt-${idx}`}
                    value={template?.schemaPrefix ?? ''}
                  >
                    {template.root.name}
                  </option>
                ))
              }
            </select>
          </div>
        )}

        <div
          key="advanced-search-layers"
          className="AdvancedSearch-layergrid"
          >
          {store.filterLayers.map((layer, idx) => this.renderLayer(layer, idx, schema))}
        </div>

        {canAddNew && (
          <div key="advanced-search-buttons" style={{ marginTop: '0.5em' }}>
            <A href="#" onClick={this.handleAddLayer}>
              <Img width={16} height={16} className='icon-16' alt='Add' src={addIcon}/>
              Add a new filter layer
            </A>
          </div>
        )}

        <AdvancedSearchFieldDialog
          store={this.props.store}
          isOpen={this.state.isSelectFieldOpen}
          layerIndex={this.state.selectFieldLayerIndex}
          eligibleProtocols={this.state.selectFieldEligibleProtocols}
          onDialogClose={this.handleSelectFieldClose}
        />
        <AdvancedSearchPickListDialog
          isOpen={this.state.isPickListOpen}
          fieldTitle={this.state.pickListFieldTitle}
          selectedValues={this.state.pickListSelected}
          observedValues={this.state.pickListObserved}
          unionOfValues={this.state.pickListUnion}
          onDialogCancel={this.handlePickListDialogCancel}
          onDialogSubmit={this.handlePickListDialogSubmit}
        />
        <PickTermDialog
          isOpen={this.state.isPickTermOpen}
          template={schema?.template}
          assn={this.state.pickTermAssn}
          groupNest={this.state.pickTermNest}
          roots={this.state.pickTermRoots || []}
          selected={this.state.pickTermSelected || []}
          search={this.state.pickTermSearch}
          multiSelect={true}
          termCounts={this.state.pickTermCount}
          disableUnknown={true}
          onDialogCancel={this.handlePickTermDialogCancel}
          onDialogSubmitSelected={this.handlePickTermDialogSubmit}
        />
      </>
    );
  }

  private renderLayer(layer: FilterLayer, idx: number, schema: Schema): JSX.Element {
    const gridRow = `${idx + 1}`;

    return (
      <React.Fragment key={`adv-layer-${idx}`}>
        <div key={`adv-term-${idx}-btn`} style={{ gridRow, gridColumn: 'button', marginTop: '-0.25em' }}>
          <A href="#" className="AdvancedSearch-redzaplink" onClick={() => this.handleDeleteLayer(idx)}>
            {'\u{229D}'}
          </A>
        </div>
        <div key={`adv-term-${idx}-title`} style={{ gridRow, gridColumn: 'title' }}>
          Filter Layer {idx + 1}:
        </div>
        {layer.type == FilterLayerType.ProtocolField ? this.composeProtocolField(idx, layer as FilterLayerProtocolField)
          : layer.type == FilterLayerType.OntologyTerm ? this.composeOntologyTerm(idx, layer as FilterLayerOntologyTerm, schema)
            : null}
      </React.Fragment>
    );
  }

  private composeProtocolField(idx: number, layer: FilterLayerProtocolField): JSX.Element {
    const gridRow = `${idx + 1}`;
    const { store } = this.props;

    const setProtocolFieldValues = (valueProps: Record<string, unknown>) => {
      store.setFilterLayerProtocolFieldValue(idx, valueProps);
      store.updateFilterResults();
    };
    const setProtocolFieldMinMax = (strMin: string, strMax: string) => {
      const [valueLow, valueHigh] = Array.isArray(layer.value) ? layer.value as string[] : [null, null];
      store.setFilterLayerProtocolFieldValue(idx, { value: [strMin == null ? valueLow : strMin, strMax == null ? valueHigh : strMax] });
      store.updateFilterResults();
    };

    const editingForString = () => {
      const value = typeof layer.value == 'string' ? layer.value as string : '';
      const orNull = (str: string) => str == '' ? null : str;

      return (
        <>
          <input
            className='input-text'
            size={40}
            value={value}
            placeholder="search filter"
            onChange={(event) => setProtocolFieldValues({ value: orNull(event.currentTarget.value) })}
            />
          <label className="AdvancedSearch-checklabel">
            <Checkbox
              checked={layer.stringCaseSensitive ?? false}
              onChange={(_, checked) => setProtocolFieldValues({ stringCaseSensitive: checked })}
              />
            Case Sensitive
          </label>
          <label className="AdvancedSearch-checklabel">
            <Checkbox
              checked={layer.stringWholeMatch ?? false}
              onChange={(_, checked) => setProtocolFieldValues({ stringWholeMatch: checked })}
              />
            Whole Match
          </label>
        </>
      );
    };
    const editingForNumber = () => {
      const [valueLow, valueHigh] = Array.isArray(layer.value) ? layer.value as string[] : [null, null];

      return (
        <>
          Range from
          <input
            className='input-text'
            size={10}
            value={valueLow == null ? '' : valueLow.toString()}
            placeholder="min"
            onChange={(event) => setProtocolFieldMinMax(event.currentTarget.value, null)}
            />
          to
          <input
            className='input-text'
            size={10}
            value={valueHigh == null ? '' : valueHigh.toString()}
            placeholder="max"
            onChange={(event) => setProtocolFieldMinMax(null, event.currentTarget.value)}
            />
        </>
      );
    };
    const editingForPickList = () => {
      const valueElements: JSX.Element[] = [];

      const valueList = layer.value as string[];
      for (let n = 0; n < valueList?.length; n++) {
        valueElements.push((
          <div key={`adv-term-${idx}-value-${n}`}>
            <div className="TermPicker-label">
              <A href="#" onClick={() => this.handleSelectPickListValues(idx)}>
                {valueList[n]}
              </A>
              &nbsp;
              <A href="#" className="AdvancedSearch-redzaplink" onClick={() => this.handleDeleteValue(idx, n)}>
                {'\u{229D}'}
              </A>
            </div>
          </div>
        ));
      }

      if (valueElements.length == 0) {
        valueElements.push((
          <div key={`adv-field-${idx}-addnew`}>
            <A href="#" onClick={() => this.handleSelectPickListValues(idx)}>
              <Img width={16} height={16} className='icon-16' alt='Add' src={addIcon}/>
              Select Values
            </A>
          </div>
        ));
      }

      return (<>{valueElements}</>);
    };

    return (
      <>
        <div key={`adv-term-${idx}-field`} style={{ gridRow, gridColumn: 'field', whiteSpace: 'nowrap' }}>
          <A href="#" onClick={() => this.handleSelectField(idx)}>
            {layer.defn.name}
          </A>
        </div>
        <div key={`adv-term-${idx}-value`} style={{ gridRow, gridColumn: 'value' }}>
          <div className="AdvancedSearch-filterterms">
            {
              layer.dataType == FilterLayerFieldDataType.String ? editingForString()
                : layer.dataType == FilterLayerFieldDataType.Number ? editingForNumber()
                  : layer.dataType == FilterLayerFieldDataType.PickList ? editingForPickList()
                    : null
            }
          </div>
        </div>
      </>
    );
  }

  private composeOntologyTerm(idx: number, layer: FilterLayerOntologyTerm, schema: Schema): JSX.Element {
    const gridRow = `${idx + 1}`;
    const assn = schema.findAssignment(layer.propURI, layer.groupNest);
    const propName = assn?.name || 'select from template';

    const valueElements: JSX.Element[] = [];
    for (let n = 0; n < layer.valueURIList.length; n++) {
      const uri = layer.valueURIList[n];
      const label = schema.labelForURI(layer.propURI, layer.groupNest, uri) || OntologyTree.values.cachedLabel(uri) || '?';

      const popoverID = 'value-' + uri;
      const tooltip = popoverID == this.state.popoverID ? (
        <Typography className="ProtocolAnnotator-tooltip">
          <b>{this.state.popoverURI}</b>
          <br/>
          {this.state.popoverDescr}
        </Typography>
      ) : '';

      valueElements.push((
        <div key={`adv-term-${idx}-value-${n}`}>
          <div style={{ display: 'inline-block' }}>
            <Tooltip
              title={tooltip}
              arrow
              placement="top"
              >
              <div
                className="TermPicker-label"
                onMouseEnter={() => this.handlePopoverOpen(popoverID, uri)}
                >
                <A href="#" onClick={() => this.handleSelectOntologyValues(idx)}>
                  {label}
                </A>
              </div>
            </Tooltip>
          </div>
          <A href="#" className="AdvancedSearch-redzaplink" onClick={() => this.handleDeleteValue(idx, n)}>
            {'\u{229D}'}
          </A>
        </div>
      ));
    }
    if (assn && layer.valueURIList.length == 0) {
      valueElements.push((
        <div key={`adv-term-${idx}-addnew`}>
          <A href="#" onClick={() => this.handleSelectOntologyValues(idx)}>
            <Img width={16} height={16} className='icon-16' alt='Add' src={addIcon}/>
            Select Terms
          </A>
        </div>
      ));
    }

    return (
      <>
        <div key={`adv-term-${idx}-field`} style={{ gridRow, gridColumn: 'field', whiteSpace: 'nowrap' }}>
          <A href="#" onClick={() => this.handleSelectField(idx)}>
            {propName}
          </A>
        </div>
        {assn ? <div key={`adv-term-${idx}-value`} style={{ gridRow, gridColumn: 'value' }}>
          <div className="AdvancedSearch-filterterms">
            {valueElements}
          </div>
        </div> : null}
      </>
    );
  }

  private handleAddLayer = (): void => {
    (async () => {
      const { store } = this.props;
      const schema = store.currentTemplateSchema();
      let eligibleProtocols = store.protocols;
      for (let n = 0; n < store.filterLayers.length; n++) {
        eligibleProtocols = await filteredProtocolsByLayer(eligibleProtocols, store.fieldDefinitions, schema, store.filterLayers[n]);
      }

      this.setState({
        isSelectFieldOpen: true,
        selectFieldLayerIndex: this.props.store.filterLayers.length,
        selectFieldEligibleProtocols: eligibleProtocols,
      });
    })();
  };

  private handleDeleteLayer(idx: number): void {
    const { store } = this.props;
    store.deleteFilterLayer(idx);
    store.updateFilterResults();
  }

  private handleDeleteValue(idx: number, pos: number): void {
    const { store } = this.props;
    store.deleteFilterLayerValue(idx, pos);
    store.updateFilterResults();
  }

  private handleChangeTemplate(pfx: string): void {
    const { store } = this.props;
    store.setOntologySchemaPrefix(pfx);
    store.updateFilterResults();
  }

  private handleSelectField(idx: number): void {
    (async () => {
      const { store } = this.props;
      const schema = store.currentTemplateSchema();
      let eligibleProtocols = store.protocols;
      for (let n = 0; n < idx; n++) {
        eligibleProtocols = await filteredProtocolsByLayer(eligibleProtocols, store.fieldDefinitions, schema, store.filterLayers[n]);
      }

      this.setState({
        isSelectFieldOpen: true,
        selectFieldLayerIndex: idx,
        selectFieldEligibleProtocols: eligibleProtocols,
      });
    })();
  }

  private handleSelectPickListValues(idx: number): void {
    const { store } = this.props;
    const layer = store.filterLayers[idx] as FilterLayerProtocolField;
    const selectedValues = layer.value as string[];
    const observedValues = layer.observedValues;
    const unionOfValues = layer.unionOfValues.sort();

    this.setState({
      isPickListOpen: true,
      pickListLayerIndex: idx,
      pickListFieldTitle: layer.defn.name,
      pickListSelected: selectedValues,
      pickListObserved: observedValues,
      pickListUnion: unionOfValues,
    });
  }

  private handleSelectOntologyValues(idx: number): void {
    (async () => {
      const { store } = this.props;
      const schema = store.currentTemplateSchema();
      const layer = store.filterLayers[idx] as FilterLayerOntologyTerm;
      const assn = schema.findAssignment(layer.propURI, layer.groupNest);
      const roots = await schema.composeBranch(assn, layer.valueURIList) ?? [];

      const cacheRoots: SearchCacheRoot[] = roots.map((branch) => {
        return {
          assn,
          groupNest: layer.groupNest,
          branch,
        };
      });

      let eligibleProtocols = store.protocols;
      for (let n = 0; n < idx; n++) {
        eligibleProtocols = await filteredProtocolsByLayer(eligibleProtocols, store.fieldDefinitions, schema, store.filterLayers[n]);
      }

      const termCount = gatherTermCounts(layer.propURI, layer.groupNest, eligibleProtocols);

      this.setState({
        isPickTermOpen: true,
        pickTermLayerIndex: idx,
        pickTermAssn: assn,
        pickTermNest: layer.groupNest,
        pickTermRoots: roots,
        pickTermSelected: collapsePrefixes(layer.valueURIList),
        pickTermSearch: new SearchCache(schema, cacheRoots),
        pickTermCount: termCount,
      });
    })();
  }

  private handlePickListDialogCancel = (): void => {
    this.setState({ isPickListOpen: false });
  };

  private handlePickListDialogSubmit = (selectedValues: string[]): void => {
    const { store } = this.props;
    const { pickListLayerIndex } = this.state;
    store.setFilterLayerProtocolFieldValue(pickListLayerIndex, { value: selectedValues });
    store.updateFilterResults();
  };

  private handlePickTermDialogCancel = (): void => {
    this.setState({ isPickTermOpen: false });
  };

  private handlePickTermDialogSubmit = (selectedURIList: string[]): void => {
    const { store } = this.props;
    const { pickTermLayerIndex } = this.state;
    store.setFilterLayerOntologyValues(pickTermLayerIndex, expandPrefixes(selectedURIList));
    store.updateFilterResults();
  };

  private handleSelectFieldClose = (): void => {
    this.setState({ isSelectFieldOpen: false });
  };

  private handlePopoverOpen(popoverID: string, uri: string): void {
    let branch = OntologyTree.values.cachedBranch(uri);
    if (branch.description !== undefined) {
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch.description });
      return;
    }

    (async () => {
      branch = await OntologyTree.values.getBranch(uri, true, true);
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch.description });
    })();
  }
}
