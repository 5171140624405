import persistable from '@/Eln/Entry/persistable.js'

export {
  readAuditTrailEvents,
  cacheAuditTrailEvents,
  READ_AUDIT_TRAIL_EVENTS,
  CACHE_AUDIT_TRAIL_EVENTS,
} from './auditTrailEvents.js'

export {
  CACHE_ATTACHED_STRUCTURE,
  LINK_KEY_AND_ATTACHED_STRUCTURE_ID,
  UNLINK_KEY_AND_ATTACHED_STRUCTURE_ID,
  cacheAttachedStructure,
  linkKeyAndAttachedStructureId,
  unlinkKeyAndAttachedStructureId,
} from './attachedStructures.js'

export {
  getTempLink,
  removeTempLink,
  getTempLinkResponse,
  addLink,
  addUpdateLink,
  addUpdateLinkResponse,
  addUpdateLinkById,
  removeStateLink,
  generateLinkDetails,
  generateLinkDetailsResponse,
  cancelLinkDetails,
  GET_TEMP_LINK,
  GET_TEMP_LINK_RESPONSE,
  REMOVE_TEMP_LINK,
  LINK_ADD,
  LINK_ADD_UPDATE,
  LINK_ADD_UPDATE_RESPONSE,
  LINK_ADD_UPDATE_BY_ID,
  LINK_STATE_REMOVE,
  LINK_DETAILS,
  LINK_DETAILS_RESPONSE,
  LINK_DETAILS_CANCEL,
} from './internalLinks.js'

export {
  EDITOR_WORK_START,
  EDITOR_WORK_FINISH,
  editorWorkStart,
  editorWorkFinish,
} from './editor.js'

export {
  GET_LINK_SUGGESTIONS,
  SUGGEST_LINKS,
  getLinkSuggestions,
  suggestLinks,
} from '@/shared/actions/linkSuggestions.js'

export {
  GET_MOLECULE_SUGGESTIONS,
  SUGGEST_MOLECULES,
  getMoleculeSuggestions,
  suggestMolecules,
} from '@/shared/actions/moleculeSuggestions'

export {
  READ_UPLOADED_FILE_CONTENT,
  CACHE_UPLOADED_FILE,
  DETACH_UPLOADED_FILE,
  UPDATE_UPLOADED_FILE,
  UPDATE_UPLOADED_FILES,
  UPDATE_UPLOADED_FILE_CONTENT,
  READ_STOICHIOMETRY_DATA,
  readUploadedFileContent,
  cacheUploadedFile,
  detachUploadedFile,
  updateUploadedFile,
  updateUploadedFiles,
  updateUploadedFileContent,
  readStoichiometryData,
} from './fileData.js'

export {
  LOOKUP_CAS_NUMBERS,
  UPDATE_CAS_NUMBER_CACHE,
  lookupCASNumbers,
  updateCASNumberCache,
} from './casNumberCache'

export {
  FINALIZE,
  DISCARD,
  ACCEPT_ENTRY,
  REJECT_ENTRY,
  CANCEL_ENTRY,
  REOPEN_ENTRY,
  WITNESS_ACTION_COMPLETE,
  finalize,
  discard,
  acceptEntry,
  rejectEntry,
  cancelEntry,
  reopenEntry,
  completeWitnessAction,
} from './transitions.js'

export {
  assignWitness,
  readWitnesses,
  cacheWitnesses,
  ASSIGN_WITNESS,
  READ_WITNESSES,
  CACHE_WITNESSES,
} from './witnesses.js'

export {
  readAdmins,
  cacheAdmins,
  READ_ADMINS,
  CACHE_ADMINS,
} from './admins.js'

export {
  abortPollFileAssociationsRequest,
  pollFileAssociationsTick,
  setAbortPollFileAssociationsRequest,
  setPollFileAssociationsRequest,
  ABORT_POLL_FILE_ASSOCIATIONS_REQUEST,
  POLL_FILE_ASSOCIATIONS_TICK,
  SET_ABORT_POLL_FILE_ASSOCIATIONS_REQUEST,
  SET_POLL_FILE_ASSOCIATIONS_REQUEST,
} from './pollFileAssociations.js'

export const UPDATE = 'eln/entry/update'
export const UPDATE_PROJECT_SET_ID = 'eln/entry/projectSetId/update'
export const UPDATE_FIELD = 'eln/entry/field/update'
export const UPDATE_FIELD_FILE = 'eln/entry/field/file/update'

export const RETRY_TICK = 'eln/entry/retry/tick'
export const NO_QUEUED_SAVE_REQUESTS = 'eln/entry/no_queued_save_requests'

export const { ADD_ERRORS } = persistable.actionTypes

export const {
  addErrors,
  componentBusy,
  componentFail,
  componentSucceed,
} = persistable.actions

export function update(attr, value) {
  return {
    type: persistable.actionTypes.MODIFY,
    subtype: UPDATE,
    attr,
    value,
  }
}

export function updateNoModify(attr, value) {
  return {
    type: UPDATE,
    attr,
    value,
  }
}

export function updateBody(body) {
  return (dispatch, getState) => {
    const { elnEntry } = getState()

    // Only trigger saves when the actual content changes
    if (elnEntry.body.document !== body.document) {
      return dispatch(update('body', body))
    } else {
      return dispatch(updateNoModify('body', body))
    }
  }
}

export function updateProjectSetId(id) {
  return {
    type: UPDATE_PROJECT_SET_ID,
    id,
  }
}

export function trySave() {
  return {
    type: persistable.actionTypes.RETRY,
  }
}

export function updateField(id, attr, value) {
  return {
    type: persistable.actionTypes.MODIFY,
    subtype: UPDATE_FIELD,
    id,
    attr,
    value,
  }
}

export function updateFieldFile(fileId, file, elnFieldDefinitionId) {
  return {
    type: persistable.actionTypes.MODIFY,
    subtype: UPDATE_FIELD_FILE,
    fileId,
    file,
    elnFieldDefinitionId,
  }
}

export function retryTick() {
  return {
    type: RETRY_TICK,
  }
}

export const ELN_ERROR = 'eln/entry/error'
export const elnError = () => ({ type: ELN_ERROR })
