import React from 'react'

import Icon from '@/shared/components/Icon.jsx'

export default class JunctionButton extends React.PureComponent {
  onCriterionJunctionClick = (event) => {
    const { criterionIndex, onJunctionChange } = this.props
    const { value } = event.target
    return onJunctionChange(criterionIndex, value)
  }

  render() {
    const { junctionLabel, selectedJunction } = this.props

    return (
      <React.Fragment>
        <input type="radio" value={junctionLabel.toUpperCase()} checked={selectedJunction === junctionLabel.toUpperCase()} onChange={this.onCriterionJunctionClick}></input>
        <span> {junctionLabel} </span>
        <Icon icon={junctionLabel + '_small'} />
      </React.Fragment>
    )
  }
}
