export const INITIALIZED = 'eln/entries/initialized'

export {
  updateQuery,
  submitQuery,
  addQueryFilter,
  updateQueryFilter,
  removeQueryFilter,
  UPDATE_QUERY,
  SUBMIT_QUERY,
  ADD_QUERY_FILTER,
  UPDATE_QUERY_FILTER,
  REMOVE_QUERY_FILTER,
} from './query.js'

export {
  addResultColumn,
  removeResultColumn,
  replaceResultColumns,
  updateSortColumn,
  saveResultColumns,
  cacheResultColumns,
  ADD_RESULT_COLUMN,
  REMOVE_RESULT_COLUMN,
  REPLACE_RESULT_COLUMNS,
  UPDATE_SORT_COLUMN,
  SAVE_RESULT_COLUMNS,
  CACHE_RESULT_COLUMNS,
} from './resultColumns.js'

export const GET_NEXT_PAGE = 'eln/entries/page/next'

export const UPDATE_DATA_SOURCES = 'eln/entries/dataSources/update'
export const REFRESH_ENTRIES = 'eln/entries/refresh'

export const REQUEST_SENT = 'eln/entries/request/sent'
export const REQUEST_SUCCESS = 'eln/entries/request/success'
export const REQUEST_FAIL = 'eln/entries/request/fail'

export function getNextPage() {
  return { type: GET_NEXT_PAGE }
}

export function updateDataSources() {
  return { type: UPDATE_DATA_SOURCES }
}

export function initialized() {
  return { type: INITIALIZED }
}

export function requestSent(promise, requestType) {
  return {
    type: REQUEST_SENT,
    promise,
    requestType,
  }
}

export function requestSuccess(response) {
  return {
    type: REQUEST_SUCCESS,
    response,
  }
}

export function requestFail(messages, status) {
  return {
    type: REQUEST_FAIL,
    messages,
    status,
  }
}
