import { Map, Record } from 'immutable'

import {
  UploadedFile as File,
} from './UploadedFile'

const DEFAULTS = {
  id: null,
  value: '',
  eln_field_definition_id: null,
  text_value: null,
  file: null,
}
export class Field extends new Record(DEFAULTS) {
  get persistable() {
    // We've been saved before, or we're setting the value for the first time
    return this.id || this.value
  }
}

const COLLECTIONS = {
  byDefinitionId: Map(),
}

export class Fields extends new Record(COLLECTIONS) {
  static initialState({
    eln_fields = [],
    eln_field_definitions = [],
  } = {}) {
    return new Fields({
      byDefinitionId: eln_field_definitions.reduce((memo, efd) => {
        const elnField = eln_fields.find(eln_field => {
          return eln_field.eln_field_definition_id === efd.id
        })

        if (elnField) {
          if (elnField.file) {
            elnField.file = new File(elnField.file)
          }
          return memo.set(efd.id, new Field(elnField))
        } else {
          return memo
        }
      }, Map()),
    })
  }

  createOrUpdate(eln_field_definition_id, attrs) {
    let elnField = this.byDefinitionId.get(eln_field_definition_id)

    if (attrs.file) {
      attrs.file = new File(attrs.file)
    }

    if (elnField) {
      elnField = elnField.merge(attrs)
    } else {
      elnField = new Field(Object.assign({ eln_field_definition_id }, attrs))
    }

    return this.setIn(['byDefinitionId', eln_field_definition_id], elnField)
  }

  updateIds({
    eln_fields = [],
  } = {}) {
    return eln_fields.reduce((state, eln_field) => {
      let elnField = this.byDefinitionId.get(eln_field.eln_field_definition_id)
      if (elnField && eln_field.id != elnField.id) {
        return this.setIn(['byDefinitionId', eln_field.eln_field_definition_id], elnField.set('id', eln_field.id))
      } else {
        return state
      }
    }, this)
  }
}
