import { List, Record } from 'immutable'
import { get } from 'lodash'

const DEFAULTS = {
  dirty: false,
  saving: false,
  lastSave: null,
  busyComponents: List(),
  failedComponents: List(),
  errorMessages: List(),
}
class Persistable extends Record(DEFAULTS) { }

export default function persistable(namespace) {
  const SAVING = `${namespace}/persistable/saving`
  const SUCCEED = `${namespace}/persistable/succeed`
  const FAIL = `${namespace}/persistable/fail`
  const MODIFY = `${namespace}/persistable/modify`
  const RETRY = `${namespace}/persistable/retry`
  const COMPONENT_BUSY = `${namespace}/persistable/component/busy`
  const COMPONENT_FAIL = `${namespace}/persistable/component/fail`
  const COMPONENT_SUCCEED = `${namespace}/persistable/component/succeed`
  const ADD_ERRORS = `${namespace}/persistable/errors/add`

  const initialState = () => new Persistable()

  return {
    initialState,
    actionTypes: {
      SAVING,
      SUCCEED,
      FAIL,
      MODIFY,
      RETRY,
      COMPONENT_BUSY,
      COMPONENT_FAIL,
      COMPONENT_SUCCEED,
      ADD_ERRORS,
    },
    actions: {
      saving: () => ({ type: SAVING }),
      savingSucceed: (response) => ({ type: SUCCEED, response }),
      savingFail: (responseJSON, status) => ({ type: FAIL, errorMessages: responseJSON, status }),
      modify: () => ({ type: MODIFY }),
      componentBusy: (name) => ({ type: COMPONENT_BUSY, name }),
      componentFail: (name) => ({ type: COMPONENT_FAIL, name }),
      componentSucceed: (name) => ({ type: COMPONENT_SUCCEED, name }),
      addErrors: errorMessages => ({ type: ADD_ERRORS, errorMessages }),
    },
    reducer: reducer => (state = initialState(), action) => {
      const newState = reducer(state, action)

      switch (action.type) {
        case SAVING:
          return newState.merge({
            saving: true,
            errorMessages: List(),
          })
        case SUCCEED: {
          let changes = {
            dirty: false,
            saving: false,
            lastSave: Date.now(),
          }

          // So it works with editable module
          if (newState.get('editing')) {
            changes.editing = false
          }

          return newState.merge(changes)
        }
        case FAIL: {
          const messages = get(action, ['errorMessages', 'fullMessages'], action.errorMessages || List())
          return newState.merge({
            saving: false,
            errorMessages: messages,
          })
        }
        case MODIFY:
          return newState.merge({
            dirty: true,
            errorMessages: List(),
          })
        case RETRY:
          return newState.merge({
            errorMessages: List(),
          })
        case COMPONENT_BUSY:
        case COMPONENT_FAIL:
        case COMPONENT_SUCCEED: {
          const { name } = action
          const currentBusy = newState.get('busyComponents')
          const currentFailed = newState.get('failedComponents')
          const indexBusy = currentBusy.indexOf(name)
          const indexFailed = currentFailed.indexOf(name)
          let newBusy = currentBusy
          let newFailed = currentFailed

          switch (action.type) {
            case COMPONENT_BUSY: {
              if (indexBusy < 0) {
                newBusy = currentBusy.push(name)
              }

              if (indexFailed > -1) {
                newFailed = currentFailed.splice(indexFailed, 1)
              }

              break
            }
            case COMPONENT_FAIL: {
              if (indexBusy < 0) {
                newBusy = currentBusy.push(name)
              }

              if (indexFailed < 0) {
                newFailed = currentFailed.push(name)
              }

              break
            }
            case COMPONENT_SUCCEED: {
              if (indexBusy > -1) {
                newBusy = currentBusy.splice(indexBusy, 1)
              }

              if (indexFailed > -1) {
                newFailed = currentFailed.splice(indexFailed, 1)
              }

              break
            }
          }
          return newState.merge({
            busyComponents: newBusy,
            failedComponents: newFailed,
          })
        }
        case ADD_ERRORS: {
          return newState.set('errorMessages', List(action.errorMessages))
        }
        default:
          return newState
      }
    },
  }
}
