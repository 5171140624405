import { List, Map, Record } from 'immutable'

import { PickListValue } from './PickListValue'

const DEFAULTS = {
  id: null,
  name: null,
  display_order: null,
  data_type_name: null,
  vault_name: null,
  unique: false,
  required: false,
  pick_list_values: [],
}

const PROJECT_PLACEHOLDER = {
  id: 'FAKE-ID-PROJECT-PLACEHOLDER',
  name: 'Project',
  data_type_name: 'Project',
  required: true,
  pick_list_values: [],
}

export class FieldDefinition extends new Record(DEFAULTS) {
  get selectValue() {
    return this.id ? this.id + '' : this.name
  }
}

function hidePickListValues(elnFieldDefinitions, elnFields) {
  return elnFieldDefinitions.map(definition => {
    const pick_list_values = definition.pick_list_values || []
    const visiblePickListValues = pick_list_values.filter(value => {
      const field = elnFields.byDefinitionId.get(definition.id)
      return !value.hidden || (field && field.value === value.value)
    })
      .map(value => new PickListValue(value))

    return new FieldDefinition(Object.assign({}, definition, {
      pick_list_values: visiblePickListValues,
    }))
  })
}

function missingIndex(integers) {
  const indexTotal = ((integers.length * (integers.length + 1)) / 2)

  return integers.reduce((memo, i) => { return memo - i }, indexTotal)
}

const COLLECTIONS = {
  byVault: Map(),
  bySelectValue: Map(),
  all: List(),
}

export class FieldDefinitions extends new Record(COLLECTIONS) {
  static initialState(elnFieldDefinitions, elnFields, includeProjects = true) {
    const display_order = missingIndex(elnFieldDefinitions.map(efd => efd.display_order))

    if (includeProjects) {
      const projectPlaceholder = Object.assign({}, PROJECT_PLACEHOLDER, { display_order })
      elnFieldDefinitions.push(projectPlaceholder)
    }

    const withHidden = hidePickListValues(elnFieldDefinitions, elnFields)
    const all = List(
      withHidden
        .sort((a, b) => a.display_order - b.display_order)
    )

    const bySelectValue = withHidden.reduce((memo, efd) => {
      const fieldDefinition = new FieldDefinition(efd)
      return memo.set(fieldDefinition.selectValue, fieldDefinition)
    }, Map())

    const byVault = withHidden.reduce((memo, efd) => {
      const fieldDefinition = new FieldDefinition(efd)
      const vaultName = fieldDefinition.vault_name
      if (memo.has(vaultName)) {
        return memo.set(vaultName, memo.get(vaultName).push(fieldDefinition))
      } else {
        return memo.set(vaultName, List([fieldDefinition]))
      }
    }, Map())

    return new FieldDefinitions({ bySelectValue, byVault, all })
  }
}
