import React from 'react'
import keycode from 'keycode'

import ItemActions from './ItemActions.jsx'

export default class Item extends React.Component {
  constructor(props) {
    super(props)

    this.state = { value: props.pickListValue.get('value') }
  }

  handleChange = event => {
    const value = event.target.value
    const id = this.props.pickListValue.get('id')

    this.setState({ value }, () => { this.props.update(id, { value }) })
  }

  handleKeyDown = event => {
    switch (keycode(event)) {
      case 'enter': {
        event.nativeEvent.stopImmediatePropagation()
        const { onSelect } = this.props
        if (onSelect) {
          onSelect()
        }
        break
      }
    }
  }

  render() {
    const { batchFieldDefinition, pickListValue, update, remove, invalid } = this.props

    return (
      <div id={pickListValue.get('id')} className="pickListItem">
        <input
          className={`pickListItemEditor ${invalid ? 'errors' : ''}`}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        <ItemActions
          batchFieldDefinition={batchFieldDefinition}
          pickListValue={pickListValue}
          update={update}
          remove={remove}
        />
      </div>
    )
  }
}
