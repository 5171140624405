import { Map, Record, fromJS } from 'immutable'

import {
  CACHE_UPLOADED_FILE,
  DETACH_UPLOADED_FILE,
  UPDATE_UPLOADED_FILE,
  UPDATE_UPLOADED_FILES,
  UPDATE_UPLOADED_FILE_CONTENT,
} from '@/Eln/Entry/actions'
import persistable from '../persistable.js'
import FilePlugin from '../components/Editor/plugins/FilePlugin'

const filePlugin = new FilePlugin()

const DEFAULTS = {
  id: null,
  file_association_id: null,
  filename: null,
  locked: false,
  name: null,
  format: null,
  thumbnails_allowed: null,
  thumbnail_width: null,
  pasted: null,
  content_as_base64: null,
  extension: null,
  invalid_structure: false,
  isOffice365: null,
  office365_app_name: null,
  stoichiometry_data: null,
}

export class UploadedFile extends new Record(DEFAULTS) {
  constructor(props = {}) {
    if (props['thumbnails_allowed?']) {
      const thumbnails_allowed = props['thumbnails_allowed?']
      props = Object.assign({}, props, { thumbnails_allowed })
    }

    if (props.stoichiometry_data) {
      props.stoichiometry_data = fromJS(props.stoichiometry_data)
    }

    if (props['office365?']) {
      props.isOffice365 = props['office365?']
    }

    super(props)
  }
}

const EMBEDDED_OFFICE_DEFAULTS = {
  office365_convert: '',
  office365_embedded: '',
  office365_view: '',
  token: '',
  ttl_milliseconds: 0,
  version: 0,
}

export class EmbeddedOffice extends new Record(EMBEDDED_OFFICE_DEFAULTS) {}

const COLLECTIONS = {
  byAssociationId: Map(),
  byId: Map(),
  idByNodeKey: Map(),
  associationIdByNodeKey: Map(),
  embeddedOfficeByAssociationId: Map(),
}

export class UploadedFiles extends new Record(COLLECTIONS) {
  static initialState(uploadedFiles = []) {
    const byAssociationId = Map().asMutable()
    const byId = Map().asMutable()
    const embeddedOfficeByAssociationId = Map().asMutable()

    uploadedFiles.forEach(fileData => {
      const file = new UploadedFile(fileData)
      byAssociationId.set(file.file_association_id, file)
      byId.set(file.id, file)
      embeddedOfficeByAssociationId.set(file.file_association_id, new EmbeddedOffice(fileData))
    })

    return new UploadedFiles({
      byAssociationId: byAssociationId.asImmutable(),
      byId: byId.asImmutable(),
      embeddedOfficeByAssociationId: embeddedOfficeByAssociationId.asImmutable(),
    })
  }

  get attached() {
    return this.idByNodeKey.valueSeq()
  }

  get versions() {
    return this.embeddedOfficeByAssociationId.map(embedded => embedded.version)
  }

  get someOfficePreviews() {
    return this.associationIdByNodeKey.some(associationId => {
      const embedded = this.embeddedOfficeByAssociationId.get(associationId)
      return embedded.office365_convert || embedded.office365_embedded || embedded.office365_view
    })
  }
}

export function uploadedFiles(state = UploadedFiles.initialState(), action) {
  switch (action.type) {
    case UPDATE_UPLOADED_FILES: {
      return state.mergeDeep(UploadedFiles.initialState(action.payload.files))
    }
    case persistable.actionTypes.SUCCEED: {
      if (action && action.response) {
        return state.mergeDeep(UploadedFiles.initialState(action.response.attached_files))
      }

      return state
    }
    case persistable.actionTypes.MODIFY: {
      switch (action.subtype) {
        case DETACH_UPLOADED_FILE: {
          const { nodeKey } = action.payload
          return state.deleteIn(['idByNodeKey', nodeKey])
            .deleteIn(['associationIdByNodeKey', nodeKey])
        }
        case UPDATE_UPLOADED_FILE: {
          const { file } = action.payload
          const newFile = new UploadedFile(file)

          return state.setIn(['byAssociationId', newFile.file_association_id], newFile)
            .setIn(['byId', newFile.id], newFile)
            .setIn(['embeddedOfficeByAssociationId', newFile.file_association_id], new EmbeddedOffice(file))
        }
        default:
          return state
      }
    }
    case CACHE_UPLOADED_FILE: {
      const { fileAssocationId, fileId, nodeKey } = action.payload
      return state.setIn(['idByNodeKey', nodeKey], fileId)
        .setIn(['associationIdByNodeKey', nodeKey], fileAssocationId)
    }
    case UPDATE_UPLOADED_FILE_CONTENT: {
      const { file } = action.payload
      return state.mergeIn(['byAssociationId', file.file_association_id], file)
        .mergeIn(['byId', file.id], file)
    }
    default:
      return state
  }
}

export const uploadedFileSubscriber = {
  select: state => !state.status.fresh && state.uploadedFiles,
  getOperation: (currState, nextState) => {
    if (!nextState) {
      return null
    }

    const filesToUpdateByNodeKey = Map().withMutations(map => {
      nextState.idByNodeKey.forEach((id, key) => {
        if (!nextState.associationIdByNodeKey.get(key)) {
          const file = nextState.byId.get(id)
          if (file) {
            map.set(key, file)
          }
        }
      })

      nextState.associationIdByNodeKey.forEach((file_association_id, key) => {
        if (!file_association_id) {
          return
        }

        const id = nextState.idByNodeKey.get(key)
        const file = nextState.byAssociationId.get(file_association_id)
        if (file && file.id && file.id != id) {
          map.set(key, file)
        }
      })
    })

    if (!filesToUpdateByNodeKey.size) {
      return null
    }

    return value => {
      let shouldReturn = false
      const change = value.change({ save: true })
      filesToUpdateByNodeKey.forEach((file, key) => {
        const node = value.document.findDescendant(n => n.key === key)

        if (node) {
          const inlineFile = filePlugin.utils.createFileNode(file.toJS(), node.data.get('size'))
          change.setNodeByKey(key, inlineFile)
          shouldReturn = true
        }
      })
      if (shouldReturn) {
        return change.value
      } else {
        return null
      }
    }
  },
}
