import React from 'react'

import { MoleculeCriterion, MoleculeCriteriaField } from './models.js'

export default function withStore(WrappedComponent) {
  /*
   * When changing the project selection on the search page, part or all
   * of the page will get reloaded. To get our form data, we keep a
   * local cache, but the available fields will certainly have changed.
   */
  let molecule_criteria_cache

  return class WithStore extends React.PureComponent {
    constructor(props) {
      super(props)

      let { fields, molecule_criteria } = props

      fields = MoleculeCriteriaField.buildCollection(fields)

      if (molecule_criteria_cache) {
        molecule_criteria = MoleculeCriteriaField.rescope(fields, molecule_criteria_cache)
      } else {
        molecule_criteria = MoleculeCriterion.buildCollection(molecule_criteria)
      }

      this.state = { fields, molecule_criteria }
    }

    onChange = (updateFunction) => {
      return (index, value) => {
        let { molecule_criteria } = this.state

        let molecule_criterion = molecule_criteria.get(index)
        molecule_criterion = molecule_criterion[updateFunction](value)

        molecule_criteria = molecule_criteria.set(index, molecule_criterion)

        return this.setState({ molecule_criteria })
      }
    }

    onClickRemoveCriterion = (index, event) => {
      let { molecule_criteria } = this.state

      molecule_criteria = molecule_criteria.delete(index)

      if (molecule_criteria.size > 0) {
        molecule_criteria = molecule_criteria.update(0, molecule_criterion => molecule_criterion.delete('junction'))
      }

      return this.setState({ molecule_criteria })
    }

    onClickAddCriterion = (event) => {
      let { molecule_criteria } = this.state

      molecule_criteria = molecule_criteria.push(new MoleculeCriterion())

      return this.setState({ molecule_criteria })
    }

    componentDidUpdate(prevProps, prevState) {
      molecule_criteria_cache = this.state.molecule_criteria
    }

    /*
     * Only used in testing
     */
    resetCache() {
      molecule_criteria_cache = null
    }

    render() {
      const { onSubmit } = this.props
      let { molecule_criteria, fields } = this.state

      return (
        <React.Fragment>
          <div
            style={{ display: 'none' }}
            data-form-data={JSON.stringify({ molecule_criteria })}
          />
          <WrappedComponent
            molecule_criteria={molecule_criteria}
            fields={fields}
            onQueryChange={this.onChange('updateQuery')}
            onSecondQueryChange={this.onChange('updateSecondQuery')}
            onQueryStyleChange={this.onChange('updateQueryStyle')}
            onFieldChange={this.onChange('updateField')}
            onJunctionChange={this.onChange('updateJunction')}
            onClickRemoveCriterion={this.onClickRemoveCriterion}
            onClickAddCriterion={this.onClickAddCriterion}
            onSubmit={onSubmit}
          />
        </React.Fragment>
      )
    }
  }
}
