import { connect } from 'react-redux'

import FilterSummary from './component.jsx'

function mapStateToProps(state) {
  const { query, elnFieldDefinitions } = state
  const { query_filters } = query

  const activeFilterNames = []
  for (let filter of query_filters) {
    const elnFieldDefinition = elnFieldDefinitions.bySelectValue.get(filter.field_select_value)
    if (elnFieldDefinition) {
      activeFilterNames.push(elnFieldDefinition.name)
    }
  }

  return {
    activeFilterNames,
  }
}

const FilterSummaryContainer = connect(
  mapStateToProps
)(FilterSummary)

export default FilterSummaryContainer
