import { Img } from '@/shared/components/sanitizedTags';
import { IconButton, ToggleButton, Tooltip } from '@mui/material';
import depleteIcon from 'ASSETS/images/cdd30/icons/deplete.png';
import React from 'react';

export const ToolbarIconButton = (props: {
  tooltip?: string;
  ariaLabel?: string;
  selected?: boolean;
  disabled?: boolean;
  img: Img;
  onClick: (e: React.MouseEvent) => void;
}) => {
  const { disabled, tooltip, ariaLabel, selected, img, onClick } = props;
  if (disabled) {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          aria-label={ariaLabel}
          className='sample-icon-button disabled-icon'
          data-title={tooltip}
        >
          {img}
        </IconButton>
      </Tooltip>
    );
  }
  const handleClick = (e) => {
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    onClick(e);
  };

  const style: React.CSSProperties = {
    width: 16,
    height: 16,
  };
  if (selected) {
    style.border = '1px solid #f8f8f8';
    style.borderStyle = 'inset';
  } else {
    style.border = '1px solid transparent';
  }

  const contents = (
    <ToggleButton
      href='#'
      style={style}
      className={'sample-icon-button ' + (disabled ? 'disabled' : '')}
      aria-label={ariaLabel}
      data-title={tooltip}
      value={selected ?? false}
      selected={selected ?? false}
      onClick={handleClick}
    >
      {img}
    </ToggleButton>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{contents}</Tooltip>;
  }
  return contents;
};
export const DepleteButton = (props: {
  onClick: (e: React.MouseEvent) => void;
  depleted: boolean;
}) => {
  const tooltip = props.depleted ? 'Restore sample' : 'Deplete sample';
  return (
    <ToolbarIconButton
      data-title={tooltip}
      onClick={props.onClick}
      selected={props.depleted ?? false}
      img={
        <Img
          width={16}
          height={16}
          className='icon-16'
          alt={tooltip}
          src={depleteIcon}
        />
      }
      tooltip={tooltip}
      ariaLabel={tooltip}
    />
  );
};
