import React from 'react'

import { A } from '@/shared/components/sanitizedTags.js'
import Icon from '@/shared/components/Icon.jsx'

export default class RemoveButton extends React.PureComponent {
  render() {
    const { onClick, text } = this.props

    return (
      <A className="remove-associated-model cancel" href="#" onClick={onClick}>
        <Icon icon="delete" forceSize="16" />
        <span>{' ' + text}</span>
      </A>
    )
  }
}
