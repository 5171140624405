import { ParsingFixedValue, ParsingPlateBlock, ParsingSection, ParsingTabularData, ParsingType, ParserUtil, ReplicateDetails, TabbedCells } from './parserUtil';

interface ComposeColumn {
  tabIndex: number;
  fieldName: string;
  rows: string[];
}

export interface ComposeProblem {
  tabIndex: number;
  message: string;
}

export const FIELD_WELLNAME = 'Well Name';
const JOIN_STR = '::';

export class TableDataComposer {
  public problems: ComposeProblem[] = [];

  private identityValues: string[] = [];
  private assembledColumns: ComposeColumn[] = [];
  private joinByWellName: boolean[] = null;

  constructor(private tabNames: string[], private sheets: TabbedCells[], private parsingSections: ParsingSection[]) {
  }

  public generate(): void {
    const fixedValueSections = this.parsingSections.filter((section) => section.type == ParsingType.FixedValue) as ParsingFixedValue[];
    fixedValueSections.sort((s1, s2) => {
      if (s1.tabIndex != s2.tabIndex) return s1.tabIndex - s2.tabIndex;
      return s1.areaValue?.y - s2.areaValue?.y;
    });

    const tabularDataSections = this.parsingSections.filter((section) => section.type == ParsingType.TabularData) as ParsingTabularData[];
    tabularDataSections.sort((s1, s2) => {
      if (s1.tabIndex != s2.tabIndex) return s1.tabIndex - s2.tabIndex;
      return s1.areaContent.y - s2.areaContent.y;
    });

    const plateBlockSections = [
      ...this.parsingSections.filter((section) => section.type == ParsingType.PlateBlock) as ParsingPlateBlock[],
      ...ParserUtil.impliedStampedBlocks(this.parsingSections, this.sheets),
    ];
    plateBlockSections.sort((s1, s2) => {
      if (s1.tabIndex != s2.tabIndex) return s1.tabIndex - s2.tabIndex;
      return s1.areaBlock.y - s2.areaBlock.y;
    });

    try {
      // do plate blocks first because they always have well defined mapping

      for (const section of plateBlockSections) {
        const { identity, payloads } = this.processPlateBlock(section);
        this.augmentColumns(identity, payloads);

        if (!this.joinByWellName) {
          let joinFields = section.joinField ?? [];
          if (typeof joinFields == 'string') joinFields = [joinFields];
          this.joinByWellName = joinFields.map((fv) => fv == FIELD_WELLNAME);
        }
      }

      // do tabular blocks next: they may occasionally be done out of order, due to the need to reconcile named columns

      while (tabularDataSections.length > 0) {
        let anything = false;
        for (let n = 0; n < tabularDataSections.length; n++) {
          const { identity, payloads } = this.processTabularData(tabularDataSections[n]);
          if (this.augmentColumns(identity, payloads)) {
            tabularDataSections.splice(n, 1);
            anything = true;
            break;
          }
        }
        if (!anything) {
          const section = tabularDataSections[0];
          this.problems.push({ tabIndex: section.tabIndex, message: `Unable to join tabular data field "${section.joinField}".` });
          throw new Error();
        }
      }

      // fixed values: straightforward

      for (const section of fixedValueSections) {
        let value = section.specificValue;
        if (section.areaValue) {
          const { x, y } = section.areaValue;
          value = this.sheets[section.tabIndex][y][x];
        }
        if (!value) {
          this.problems.push({ tabIndex: section.tabIndex, message: `Fixed value named "${section.fieldName}" is blank.` });
          throw new Error();
        }
        const nrows = this.assembledColumns.length == 0 ? 1 : this.assembledColumns[0].rows.length;
        const rows = new Array(nrows).fill(value);
        this.assembledColumns.push({ tabIndex: section.tabIndex, fieldName: section.fieldName, rows });
      }
    } catch (ex) {
      this.problems.push({ tabIndex: null, message: 'Exception reported: ' + ex });
    }

    this.assembledColumns.sort((col1, col2) => col1.tabIndex - col2.tabIndex);
  }

  public buildMatrix(): string[][] {
    if (this.assembledColumns.length == 0) return [];

    const data: string[][] = [this.assembledColumns.map((column) => column.fieldName)];
    const nrows = this.assembledColumns[0].rows.length;
    for (let n = 0; n < nrows; n++) {
      data.push(this.assembledColumns.map((column) => column.rows[n]));
    }
    return data;
  }

  public buildTextFile(): string {
    const escapeCell = (cell: string) => {
      if (!cell.match(/[,"\t\n]/)) return cell;
      return '"' + cell.replace(/"/g, '""') + '"';
    };
    const squishRow = (row) => {
      return row.map((cell) => escapeCell(cell ?? '')).join(',');
    };
    return this.buildMatrix().map((row) => squishRow(row)).join('\n');
  }

  private processTabularData(section: ParsingTabularData): { identity: string[], payloads: ComposeColumn[] } {
    let payloads: ComposeColumn[] = [];

    const sheet = this.sheets[section.tabIndex];
    const x1 = section.areaContent.x, x2 = x1 + section.areaContent.w;
    const y1 = section.areaContent.y, y2 = y1 + section.areaContent.h;
    const fieldNames = ParserUtil.tableFieldNames(sheet, section, false);

    for (let n = 0; n < section.fixedValues?.length; n++) {
      const fixedValue = section.fixedValues[n];
      if (!fixedValue.fieldName) continue;

      let value = fixedValue.specificValue;
      if (fixedValue.areaValue) {
        const { x, y } = fixedValue.areaValue;
        value = sheet[y][x];
      } else if (!fixedValue.specificValue) {
        value = this.tabNames[section.tabIndex];
      }
      const rows = new Array(section.areaContent.h).fill(value ?? '');
      payloads.push({ tabIndex: section.tabIndex, fieldName: fixedValue.fieldName, rows });
    }

    for (let x = x1, i = 0; x < x2; x++, i++) {
      const payload: ComposeColumn = { tabIndex: section.tabIndex, fieldName: fieldNames[i], rows: [] };
      for (let y = y1; y < y2; y++) {
        payload.rows.push(sheet[y][x]);
      }
      payloads.push(payload);
    }

    let joinFields = section.joinField ?? [];
    if (typeof joinFields == 'string') joinFields = [joinFields];
    const joinIndices = joinFields.map((fv) => payloads.findIndex((look) => look.fieldName == fv));
    if (joinIndices.length == 0 || joinIndices.some((idx) => idx < 0)) {
      this.problems.push({ tabIndex: section.tabIndex, message: `Table with field names [${fieldNames.join(',')}] has no join fields.` });
      throw new Error();
    }

    let identity: string[] = [];
    for (let n = 0; n < section.areaContent.h; n++) {
      const bits = joinIndices.map((idx) => payloads[idx].rows[n]);
      for (let i = 0; i < this.joinByWellName?.length && i < joinIndices.length; i++) {
        if (this.joinByWellName[i]) {
          const match = /^([A-Z]+)0+(\d)+?$/.exec(bits[i]);
          if (match) bits[i] = `${match[1]}${match[2]}`;
        }
      }

      identity.push(bits.join(JOIN_STR));
    }

    if (section.replicateSets.length > 0) {
      const expanded = this.expandReplicates(identity, payloads, section.replicateSets, section.replicateDetails ?? []);
      identity = expanded.identity;
      payloads = expanded.columns;
    }

    return { identity, payloads };
  }

  private expandReplicates(identity: string[], columns: ComposeColumn[], replicateSets: string[][], details: ReplicateDetails[]): { identity: string[], columns: ComposeColumn[] } {
    const outputIdent: string[] = [];
    const duplColumns: ComposeColumn[] = [];
    const repSetColumns: ComposeColumn[][] = [];

    for (const repSet of replicateSets) {
      const repCols: ComposeColumn[] = [];
      for (let n = 0; n < repSet.length; n++) {
        const fieldName = repSet[n];
        const idx = columns.findIndex((column) => column.fieldName == fieldName);
        if (idx == 0) {
          this.problems.push({ tabIndex: columns[0].tabIndex, message: `Replication column '${fieldName}' cannot be the identity.` });
          throw new Error();
        }
        if (idx < 0) {
          this.problems.push({ tabIndex: columns[0].tabIndex, message: `Replication column '${fieldName}' not found in tabular data.` });
          throw new Error();
        }

        const column = { ...columns[idx] };

        if (details[n] && details[n].columnValueField) {
          repCols.push({
            tabIndex: column.tabIndex,
            fieldName: details[n].columnValueField,
            rows: new Array(column.rows.length).fill(column.fieldName),
          });
        }

        if (details[n] && details[n].fieldName) {
          column.fieldName = details[n].fieldName;
        }
        repCols.push(column);
        columns.splice(idx, 1);
      }

      for (let n = 0; n < repCols.length; n++) {
        if (n >= duplColumns.length) {
          duplColumns.push({ ...repCols[n], rows: [] });
        }
      }
      repSetColumns.push(repCols);
    }

    const keepColumns = columns.map((column) => { return { ...column, rows: [] }; });

    const nrows = columns[0].rows.length;
    for (let n = 0; n < nrows; n++) {
      let anything = false;
      for (const repCols of repSetColumns) {
        if (!repCols.some((column) => !!column.rows[n])) continue;
        anything = true;

        outputIdent.push(identity[n]);
        for (let i = 0; i < columns.length; i++) keepColumns[i].rows.push(columns[i].rows[n]);
        for (let i = 0; i < repCols.length; i++) duplColumns[i].rows.push(repCols[i].rows[n]);
      }

      if (!anything) {
        outputIdent.push(identity[n]);
        for (let i = 0; i < columns.length; i++) keepColumns[i].rows.push(columns[i].rows[n]);
        for (const column of duplColumns) column.rows.push('');
      }
    }

    return { identity: outputIdent, columns: [...keepColumns, ...duplColumns] };
  }

  private processPlateBlock(section: ParsingPlateBlock): { identity: string[], payloads: ComposeColumn[] } {
    const identity: string[] = [];
    const wellNames: ComposeColumn = { tabIndex: section.tabIndex, fieldName: FIELD_WELLNAME, rows: [] };
    const cellValues: ComposeColumn = { tabIndex: section.tabIndex, fieldName: section.fieldName, rows: [] };
    const fixedBlocks: ComposeColumn[] = [];

    const sheet = this.sheets[section.tabIndex];
    const x1 = section.areaBlock.x, x2 = x1 + section.areaBlock.w;
    const y1 = section.areaBlock.y, y2 = y1 + section.areaBlock.h;

    const firstHeaderCol = sheet[section.areaColumns.y][x1] ?? '';
    const firstHeaderRow = sheet[y1][section.areaRows.x] ?? '';

    const fieldNames = ParserUtil.plateFieldNames(section, true);
    let joinFields = section.joinField ?? [];
    if (typeof joinFields == 'string') joinFields = [joinFields];
    joinFields = joinFields.filter((fv) => fieldNames.includes(fv));
    if (joinFields.length == 0) joinFields = [FIELD_WELLNAME];

    const makeIdentity = (wellName: string, cellValue: string): string => {
      const bits: string[] = [];

      for (const fv of joinFields) {
        if (fv == FIELD_WELLNAME) {
          const match = /^([A-Z]+)0+(\d)+?$/.exec(wellName);
          if (match) wellName = `${match[1]}${match[2]}`;
          bits.push(wellName);
        } else if (fv == section.fieldName) {
          bits.push(cellValue);
        } else {
          const fixedValue = (section.fixedValues ?? []).find((look) => look.fieldName == fv);
          if (fixedValue) {
            if (fixedValue.areaValue) {
              const { x, y } = fixedValue.areaValue;
              bits.push(sheet[y][x]);
            } else {
              bits.push(fixedValue.specificValue);
            }
          }
        }
      }

      return bits.join(JOIN_STR);
    };

    const adjustNumberPadding = (numstr: string): string => {
      if (!numstr) return '';

      if (section.wellNumberPadding >= 2) {
        const padsz = Math.max(0, section.wellNumberPadding - numstr.length);
        return '0'.repeat(padsz) + numstr;
      } else {
        while (numstr.startsWith('0')) {
          numstr = numstr.substring(1);
        }
        return numstr;
      }
    };

    const numberToLetter = (str: string): string => {
      const val = (parseInt(str) || 0) - 1;
      if (val < 0) return '?';
      if (val < 26) return String.fromCharCode(65 + val);
      const v1 = Math.min(25, Math.floor(val / 26) - 1), v2 = val % 26;
      return String.fromCharCode(65 + v1) + String.fromCharCode(65 + v2);
    };

    if (firstHeaderCol.match(/^[A-Z]/)) {
      for (let x = x1; x < x2; x++) {
        for (let y = y1; y < y2; y++) {
          const wellName = sheet[section.areaColumns.y][x] + adjustNumberPadding(sheet[y][section.areaRows.x]), cellValue = sheet[y][x] ?? '';
          identity.push(makeIdentity(wellName, cellValue));
          wellNames.rows.push(wellName);
          cellValues.rows.push(cellValue);
        }
      }
    } else if (firstHeaderRow.match(/^[A-Z]/)) {
      for (let y = y1; y < y2; y++) {
        for (let x = x1; x < x2; x++) {
          const wellName = sheet[y][section.areaRows.x] + adjustNumberPadding(sheet[section.areaColumns.y][x]), cellValue = sheet[y][x] ?? '';
          identity.push(makeIdentity(wellName, cellValue));
          wellNames.rows.push(wellName);
          cellValues.rows.push(cellValue);
        }
      }
    } else {
      for (let y = y1; y < y2; y++) {
        for (let x = x1; x < x2; x++) {
          const wellName = numberToLetter(sheet[y][section.areaRows.x]) + adjustNumberPadding(sheet[section.areaColumns.y][x]), cellValue = sheet[y][x] ?? '';
          identity.push(makeIdentity(wellName, cellValue));
          wellNames.rows.push(wellName);
          cellValues.rows.push(cellValue);
        }
      }
    }

    for (let n = 0; n < section.fixedValues?.length; n++) {
      const fixedValue = section.fixedValues[n];
      if (!fixedValue.fieldName) continue;

      let value = fixedValue.specificValue;
      if (fixedValue.areaValue) {
        const { x, y } = fixedValue.areaValue;
        value = sheet[y][x];
      } else if (!fixedValue.specificValue) {
        value = this.tabNames[section.tabIndex];
      }
      const nrows = section.areaBlock.w * section.areaBlock.h;
      const rows = new Array(nrows).fill(value ?? '');
      fixedBlocks.push({ tabIndex: section.tabIndex, fieldName: fixedValue.fieldName, rows });
    }

    return { identity, payloads: [...fixedBlocks, wellNames, cellValues] };
  }

  private augmentColumns(identity: string[], payloads: ComposeColumn[]): boolean {
    const { identityValues, assembledColumns } = this;

    if (assembledColumns.length == 0) {
      this.identityValues = identity;
      this.assembledColumns = payloads;
      return true;
    }

    const blocksOld: Record<string, number[]> = {};
    const blocksNew: Record<string, number[]> = {};

    for (let n = 0; n < identityValues.length; n++) {
      const v = identityValues[n];
      blocksOld[v] = [...(blocksOld[v] ?? []), n];
    }
    for (let n = 0; n < identity.length; n++) {
      const v = identity[n];
      blocksNew[v] = [...(blocksNew[v] ?? []), n];
    }

    const prevNumRows = assembledColumns[0].rows.length;
    const duplColumns = payloads.map((column) => { return { ...column, rows: new Array(prevNumRows).fill('') }; });

    const assimilateNewRows = (idval: string, idxListNew: number[]): void => {
      for (let n = 0; n < idxListNew.length; n++) {
        identityValues.push(idval);
        for (let i = 0; i < assembledColumns.length; i++) {
          assembledColumns[i].rows.push('');
        }
        for (let i = 0; i < payloads.length; i++) {
          duplColumns[i].rows.push(payloads[i].rows[idxListNew[n]]);
        }
      }
    };

    const assimilatePairedRows = (idxListOld: number[], idxListNew: number[]): void => {
      for (let n = 0; n < idxListNew.length; n++) {
        for (let i = 0; i < payloads.length; i++) {
          duplColumns[i].rows[idxListOld[n]] = payloads[i].rows[idxListNew[n]];
        }
      }
    };

    const assimilateByExpanding = (idxOld: number, idxListNew: number[]): void => {
      for (let n = 0; n < idxListNew.length; n++) {
        const row = idxOld + n;
        if (n == 0) {
          for (let i = 0; i < duplColumns.length; i++) {
            duplColumns[i].rows[row] = payloads[i].rows[idxListNew[n]];
          }
        } else {
          identityValues.splice(row, 0, identityValues[idxOld]);
          for (let i = 0; i < assembledColumns.length; i++) {
            assembledColumns[i].rows.splice(row, 0, assembledColumns[i].rows[idxOld]);
          }
          for (let i = 0; i < duplColumns.length; i++) {
            duplColumns[i].rows.splice(row, 0, payloads[i].rows[idxListNew[n]]);
          }
        }
      }

      for (const idxList of Object.values(blocksOld)) {
        if (idxList[0] > idxOld) {
          for (let n = 0; n < idxList.length; n++) idxList[n] += idxListNew.length - 1;
        }
      }
    };

    const assimilateByReplicating = (idxListOld: number[], idxNew: number): void => {
      for (let n = 0; n < idxListOld.length; n++) {
        for (let i = 0; i < payloads.length; i++) {
          duplColumns[i].rows[idxListOld[n]] = payloads[i].rows[idxNew];
        }
      }
    };

    for (const [idval, idxListNew] of Object.entries(blocksNew)) {
      const idxListOld = blocksOld[idval];

      if (!idxListOld) {
        assimilateNewRows(idval, idxListNew);
      } else if (idxListOld.length == idxListNew.length) {
        assimilatePairedRows(idxListOld, idxListNew);
      } else if (idxListOld.length == 1) {
        assimilateByExpanding(idxListOld[0], idxListNew);
      } else if (idxListNew.length == 1) {
        assimilateByReplicating(idxListOld, idxListNew[0]);
      } else {
        this.problems.push({ tabIndex: payloads[0].tabIndex, message: `Matching many-to-many with fields [${payloads.map((c) => c.fieldName).join(',')}].` });
        throw new Error();
      }
    }

    for (const dcol of duplColumns) {
      const acol = assembledColumns.find((look) => look.fieldName == dcol.fieldName);
      if (acol) {
        for (let n = 0; n < acol.rows.length; n++) {
          acol.rows[n] = acol.rows[n] || dcol.rows[n];
        }
      } else {
        assembledColumns.push(dcol);
      }
    }

    return true;
  }
}
