import { connect } from 'react-redux'

import EntryList from './component.jsx'
import {
  getNextPage,
  addResultColumn,
  removeResultColumn,
  replaceResultColumns,
  resetSortColumn,
  updateSortColumn,
} from '@/Eln/Entries/actions'

function mapStateToProps(state) {
  const { elnEntries, elnFieldDefinitions, results, resultColumns, status, constants } = state

  return {
    elnEntries,
    elnFieldDefinitions,
    elnEntryUrl: constants.elnEntryUrl,
    results,
    resultColumns,
    status,
  }
}

const mapDispatchToProps = {
  getNextPage,
  addResultColumn,
  removeResultColumn,
  replaceResultColumns,
  updateSortColumn,
}

const EntryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryList)

export default EntryListContainer
