import { VaultWithProjectsSchema } from '@/Accounts/types';
import { AddButton, DownloadButton } from '@/shared/components';
import { pluralize } from '@/shared/utils/stringUtils';
import { getRootStore } from '@/stores/rootStore';
import { Box, Grid, TextField, Typography, Autocomplete } from '@mui/material';
import autobind from 'class-autobind';
import { observer } from 'mobx-react';
import React from 'react';
import {
  ColumnDef,
  SimpleDataTable,
  SimpleFilter,
} from '@/components';
import { ProjectSchema } from '../types';
import { EditProjectDialog } from './components/EditProjectDialog';
import './ProjectsPage.sass';
import { CatchUnhandledErrors } from '@/ErrorReporting/CatchUnhandledErrors';
import { accountsService } from '@/Accounts/accountsServices';
import { CDD } from '@/typedJS';

const columns: Array<ColumnDef & { id: keyof ProjectSchema }> = [
  {
    id: 'name',
    label: 'Name',
    type: 'link',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'users_count',
    label: '# Members',
  },
  {
    id: 'created_at',
    label: 'Created date',
    type: 'date',
  },
  {
    id: 'updated_at',
    label: 'Last updated',
    type: 'date',
  },
];

interface NoProjectMembershipMessages {
  admin: string;
  manage: string;
  nothing: string;
}

interface ProjectsPageProps {
  singleVault: boolean;
  noProjectMembershipMessages: NoProjectMembershipMessages | undefined;
}

@observer
export class ProjectsPage extends React.Component {
  private singleVault: boolean;
  // We need that for https://www.pivotaltracker.com/story/show/181717565
  private readonly noProjectMembershipMessages: NoProjectMembershipMessages;

  constructor(props: ProjectsPageProps) {
    super(props);
    getRootStore(); // force initialization of singletons to avoid NPE
    this.singleVault = props.singleVault || false;
    this.noProjectMembershipMessages = props.noProjectMembershipMessages;
    autobind(this);
  }

  handleClickRow(project: ProjectSchema) {
    if (project) {
      const {
        accountsStore: {
          vaultsAndProjectsStore: {
            editProjectStore: { handleEditProject },
          },
        },
      } = getRootStore();

      handleEditProject(project);
    }
  }

  handleClickNew() {
    const {
      accountsStore: {
        vaultsAndProjectsStore: {
          editProjectStore: { handleCreateProject },
        },
      },
    } = getRootStore();

    handleCreateProject();
  }

  downloadCsv() {
    const {
      accountsStore: {
        vaultsAndProjectsStore: { selectedVault },
      },
    } = getRootStore();
    accountsService.getVaultExportUsers(selectedVault.id).then(response => {
      CDD.insertGlobalMessage(response.data.message);
      CDD.Pollers.start('export', `/vaults/${response.data.vault}/export_progress`, 2);
    });
  }

  messageNoProject(canAdmin: boolean, allowedCreation: boolean) {
    if (canAdmin) {
      return this.noProjectMembershipMessages.admin;
    } else if (allowedCreation) {
      return this.noProjectMembershipMessages.manage;
    } else {
      return this.noProjectMembershipMessages.nothing;
    }
  }

  renderTopRow() {
    const {
      accountsStore: {
        vaultsAndProjectsStore,
        vaultsAndProjectsStore: {
          vaults,
          selectedVault,
          currentUserIsVaultAdmin,
          projectsForSelectedVault,
          showVaultSelection,
          filterText,
          setFilterText,
        },
      },
    } = getRootStore();

    const handleSelectVault = (e: never, value: VaultWithProjectsSchema) => {
      vaultsAndProjectsStore.setSelectedVault(value);
    };

    const generateTextField = (params) => (
      <TextField
        style={{ padding: 0 }}
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
        label='Select a vault'
        variant='standard'
        placeholder='Select vault'
      />
    );

    return (
      <div className='top-row'>
        {!selectedVault && <h1>No vault selected</h1>}

        {selectedVault && (
          <div>
            {showVaultSelection && (
              <Grid container>
                <Grid item xs={4}>
                  <Autocomplete
                    className='vault-select'
                    options={vaults}
                    getOptionLabel={(option: VaultWithProjectsSchema) =>
                      '' + option.name
                    }
                    value={(selectedVault ?? null) as any} // eslint-disable-line @typescript-eslint/no-explicit-any
                    onChange={handleSelectVault}
                    disableClearable={true}
                    renderInput={generateTextField}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container alignItems='center'>
              <Grid item xs={5}>
                <Box display='flex' justifyContent='left' alignItems='center'>
                  <SimpleFilter
                    value={filterText}
                    onChangeValue={setFilterText}
                  />
                  <div className="counts">
                    <Typography>
                      {pluralize(projectsForSelectedVault.length, 'Project')}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={7}>
                {currentUserIsVaultAdmin && (
                  <Box display='grid' justifyContent='end'>
                    <AddButton
                      onClick={this.handleClickNew}
                      text={'Create a new project'}
                    />
                    <br/><DownloadButton
                    className='add-associated-model'
                    onClick={this.downloadCsv}
                    text={'Export vault members (csv)'}
                  /></Box>)}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      accountsStore: {
        vaultsAndProjectsStore: {
          editProjectStore,
          currentUserIsVaultAdmin,
          currentUserHasProjectMemberships,
          displayRows,
          sortBy,
          setSortBy,
        },
      },
      loading: rootLoading,
    } = getRootStore();

    return (
      <div className='ProjectsPage'>
        {!rootLoading && (
          <>
            {!currentUserHasProjectMemberships && this.noProjectMembershipMessages && (<div className='section'>
              <div className='message no_project_membership'>
                <h3>You aren&apos;t a member of any projects!</h3>
                <p dangerouslySetInnerHTML={{
                  __html: this.messageNoProject(currentUserIsVaultAdmin, false),
                }}
                />
              </div>
            </div>)}
            {this.renderTopRow()}
            <SimpleDataTable
              stickyHeaders
              columns={columns}
              rows={displayRows}
              onClickRow={this.handleClickRow}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </>
        )}
        <EditProjectDialog
          store={editProjectStore}
        />
        <CatchUnhandledErrors/>
      </div>
    );
  }

  readonly styleSelectLabel: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
}
