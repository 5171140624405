import React from 'react'

import SearchField from './SearchField'
import FilterSummary from './FilterSummary'
import EntriesCount from './EntriesCount'
import EntriesExportLinks from './EntriesExportLinks'

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.structuresVisible = !CDD.features.structuresMasked
  }

  state = {
    filterPanelVisible: false,
  }
  structuresVisible = false

  handleFilterPanelClose = () => {
    this.setState({ filterPanelVisible: false })
  }

  handleFilterPanelToggle = () => {
    this.setState({ filterPanelVisible: !this.state.filterPanelVisible })
  }

  render() {
    const { filterPanelVisible } = this.state

    return (
      <div className="search-bar">
        <SearchField
          filterPanelVisible={filterPanelVisible}
          handleFilterPanelClose={this.handleFilterPanelClose}
          handleFilterPanelToggle={this.handleFilterPanelToggle}
          structuresVisible={this.structuresVisible}
        />
        <div className="search-bar__summary">
          <FilterSummary
            handleFilterPanelToggle={this.handleFilterPanelToggle}
          />
          <div className="search-bar__summary_text">
            <EntriesCount />
            <EntriesExportLinks />
          </div>
        </div>
      </div>
    )
  }
}
