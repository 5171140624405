import React from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AnyObject } from '@/types';
import { DDForm, DDFormProps, DDFormState } from './DDForm';
import { DDFormUtils } from './DDFormUtils';
import { layoutBuilder } from './layoutBuilder';
import { FieldDef } from './types/base/fieldDef';
import { ElementType } from './types/index';
import { RowDef } from './types/rowColumnDef';
import { StepperDef } from './types/stepperDef';

import _ from 'lodash';

const {
  row,
  column,
  button,
} = layoutBuilder;

export interface DDModalFormProps extends DDFormProps {
  bottomRowDef?: RowDef;
  bottomLeftElements?: Array<ElementType>;
  showNextBackStepper?: boolean;
  terminology?: {
    OK?: string;
    CANCEL?: string;
    BACK?: string;
    NEXT?: string;
  };
  okDisabled?: boolean;

  onOK: (data: AnyObject) => void;
  onCancel?: () => void;
}

@observer
export class DDModalForm extends React.Component<DDModalFormProps> {
  ref = React.createRef<HTMLDivElement>();
  internalFormState: DDFormState = { data: {}, errors: null };
  disposers: Array<() => void> = [];

  submitted = false;

  constructor(props: DDModalFormProps) {
    super(props);

    makeObservable(this, {
      internalFormState: observable,
      submitted: observable,
      setSubmitted: action,
      setFieldValue: action,
      layout: computed,
      stepperDef: computed,
    });
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (this.ref.current?.contains(event.target as Node)) {
      if (event.key === 'Enter') {
        // if the focused element can be expanded (like a select), don't close the dialog
        if (document.activeElement.attributes.getNamedItem('aria-expanded')?.value) {
          return;
        }
        if (!['BUTTON', 'TEXTAREA', 'LI'].includes(document.activeElement.tagName)) {
          event.preventDefault();

          if (this.layoutBottomRow.children.some(b => b.key === 'NEXT')) {
            this.buttonNext.onClickButton(null);
          } else {
            this.props.onOK?.(this.props.data);
          }
        }
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        this.props.onCancel?.();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.disposers.push(
      () => window.removeEventListener('keydown', this.handleKeyDown),
    );
  }

  componentWillUnmount() {
    this.disposers.forEach((disposer) => disposer());
  }

  setSubmitted(value: boolean) {
    this.submitted = value;
  }

  get formState() {
    return this.props.formState ?? this.internalFormState;
  }

  get stepperDef(): StepperDef | null {
    let result: StepperDef | null = null;

    DDFormUtils.iterateThroughLayout(this.props.layout, (element) => {
      if (element.type === 'stepper') {
        if (result) {
          console.error('DDModalForm can only have one stepper');
        }
        result = element as StepperDef;
      }
    });
    return result;
  }

  get terminology(): DDModalFormProps['terminology'] {
    return {
      ...{
        OK: 'OK',
        CANCEL: 'Cancel',
        BACK: '< Back',
        NEXT: 'Next >',
      },
      ...this.props.terminology,
    };
  }

  get buttonOK() {
    return button({
      key: 'button_ok',
      label: this.terminology?.OK,
      color: 'primary',
      disabled: this.props.okDisabled,
      onClickButton: () => {
        const { errors } = this.formState;
        if (errors && !_.isEmpty(_.omitBy(errors, _.isNil))) {
          this.setSubmitted(true);
        } else {
          this.props.onOK(this.props.data);
        }
      },
    });
  }

  get buttonCancel() {
    return button({
      key: 'button_cancel',
      label: this.terminology?.CANCEL,
      variant: 'text',
      onClickButton: () => {
        this.props.onCancel?.();
      },
    });
  }

  setStepperValue(value: { offset: number } | number) {
    const stepper = this.stepperDef;
    if (stepper) {
      const stepperValue = this.getFieldValue(stepper, 'number') as number;
      // if current page has errors, then don't change stepper
      if (
        this.formState.errors &&
        this.formState.errors[`${stepper.key}[${stepperValue}]`]
      ) {
        this.setSubmitted(true);
      } else {
        if (typeof value === 'number') {
          this.setFieldValue(stepper, value);
        } else {
          this.setFieldValue(stepper, stepperValue + value.offset);
        }
      }
    }
  }

  get buttonBack() {
    return button({
      key: 'BACK',
      variant: 'outlined',
      label: this.terminology?.BACK,
      onClickButton: () => {
        this.setStepperValue({ offset: -1 });
      },
    });
  }

  get buttonNext() {
    return button({
      key: 'NEXT',
      color: 'primary',
      label: this.terminology?.NEXT,
      onClickButton: () => {
        this.setStepperValue({ offset: 1 });
      },
    });
  }

  get layoutBottomRow() {
    // if passed an entire row then render it
    if (this.props.bottomRowDef) {
      return this.props.bottomRowDef;
    }

    // if stepper control is present, render stepper buttons
    const { bottomLeftElements, onCancel } = this.props;
    const stepper = this.stepperDef;

    if (stepper && this.props.showNextBackStepper !== false) {
      const stepperValue = this.getFieldValue(stepper, 'number') as number;
      const numSteps = stepper.children.length;
      return row({ className: 'bottom-modal-row' }, [
        // any elements to be added on the left side of the bottom row
        ...(bottomLeftElements ?? []).map((element, index) => ({ ...element, renderKey: `bottomLeftElements-${index}` })),

        // spacer to force everything to the right
        column({ width: 'expand' }, []),

        // cancel/back/next or ok
        ...(onCancel ? [this.buttonCancel] : []),
        { ...this.buttonBack, disabled: stepperValue === 0 },
        stepperValue === numSteps - 1 ? this.buttonOK : this.buttonNext,
      ]);
    }

    return row({ className: 'bottom-modal-row' }, [
      // any elements to be added on the left side of the bottom row
      ...(bottomLeftElements ?? []),

      // spacer to force everything to the right
      column({ width: 'expand' }, []),

      // cancel/ok
      ...(onCancel ? [this.buttonCancel] : []),
      this.buttonOK,
    ]);
  }

  get layout() {
    return column({ noPadding: true }, [
      column({ noPadding: true, className: 'dialog-content' }, [
        this.props.layout,
      ]),

      this.layoutBottomRow,
    ]);
  }

  getFieldValue(field: FieldDef, valueType?: string) {
    const data = field.key.startsWith('.')
      ? this.formState?.data
      : this.props.data;
    const key = field.key.startsWith('.') ? field.key.substring(1) : field.key;
    return DDFormUtils.getValue(key, data, valueType);
  }

  setFieldValue(field: FieldDef, value: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    const data = field.key.startsWith('.')
      ? this.formState?.data
      : this.props.data;
    const key = field.key.startsWith('.') ? field.key.substring(1) : field.key;
    return DDFormUtils.setValue(key, data, value);
  }

  handleSetValue = async (
    path: string,
    data: object,
    value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    valueType: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    field: FieldDef,
  ) => {
    if (this.stepperDef?.key === field.key) {
      this.setStepperValue(value);
      return false;
    }
    return true;
  };

  render() {
    const { props } = this;

    if (this.submitted && !this.formState.errors) {
      setTimeout(() => {
        this.setSubmitted(false);
      });
    }

    return (
      <div ref={this.ref}>
        <DDForm
          {...props}
          layout={this.layout}
          formState={this.formState}
          showErrors={this.submitted || props.showErrors}
          onBeforeSetValue={this.handleSetValue}
        ></DDForm>
      </div>
    );
  }
}
