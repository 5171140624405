import FilterFields from './component.jsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addQueryFilter } from '@/Eln/Entries/actions'

function mapStateToProps(state) {
  const { query, elnFieldDefinitions } = state
  const { query_filters } = query
  const numFilters = state.query.query_filters.size
  let activeFilterNames = []
  query_filters.forEach((filter) => {
    const elnFieldDefinition = elnFieldDefinitions.bySelectValue.get(filter.field_select_value)
    if (elnFieldDefinition) {
      activeFilterNames.push(elnFieldDefinition.name)
    }
  })

  return {
    numFilters,
    activeFilterNames,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addQueryFilter,
  }, dispatch)
}

const FilterFieldsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterFields)

export default FilterFieldsContainer
