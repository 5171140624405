/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, SelectMultipleDef, SelectSingleDef } from '@/shared/components/DDForm/types';
import { TestableAutocomplete, TestableSelect, TestableTextFieldSelect } from '@/shared/components/TestableMuiComponents/TestableSelect';
import { AnyObject } from '@/types';
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Paper, SelectChangeEvent, TextField } from '@mui/material';
import { DDFormUtils } from '../../DDFormUtils';
import { getInputProps, getOuterElementAttributes, getInnerElementAttributes, getStyleElementAttributes } from '../muiRenderers';
import { AutoEllipsisTooltip } from '@/components';

const DEFAULT_MAX_WIDTH = 1000;

const getMaxWidth = (field: SelectSingleDef | SelectMultipleDef) => {
  return field.autoEllipsisMaxWidth ?? DEFAULT_MAX_WIDTH;
};

const calcMenuWidthFromContents = (field: SelectSingleDef | SelectMultipleDef) => {
  const { selectOptions } = field;
  if (!selectOptions || selectOptions.length === 0) {
    return 0;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set font properties (font size, family, etc.)
  ctx.font = '15px Roboto, Helvetica, Arial, sans-serif';

  const optionFieldLabel = field.optionFieldLabel ?? 'label';

  // Loop through menuItems to append them to the temp container and measure width
  let maxWidth = 0;

  const stringsToMeasure: string[] = [];
  const includeLabel = field.label && (typeof field.label === 'string');
  if (includeLabel) {
    stringsToMeasure.push(field.label as any as string);
  }

  selectOptions.forEach(option => {
    if (option[optionFieldLabel]) {
      stringsToMeasure.push(option[optionFieldLabel]);
    }
    (option.children ?? []).forEach(child => {
      if (child[optionFieldLabel]) {
        stringsToMeasure.push(child[optionFieldLabel]);
      }
    });
  });

  stringsToMeasure.forEach((item, index) => {
    let itemWidth = ctx.measureText(item).width;

    if (includeLabel && index === 0) {
      itemWidth *= 0.75;
    }
    maxWidth = Math.max(maxWidth, itemWidth);
  });

  return Math.min(maxWidth + 45, getMaxWidth(field));
};

/**
 * Render a field as a select control (single or multiple)
 * @param field
 * @returns
 */
const renderSelectCore: RenderElementFunction = (
  formProps: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as SelectSingleDef | SelectMultipleDef;
  const error = formProps.showErrors && formErrors && formErrors[field.key];
  const isMultiple = field.type === 'multiselect';
  const optionFieldId = field.optionFieldId ?? 'id'; // also value
  const optionFieldLabel = field.optionFieldLabel ?? 'label';
  const optionFieldGroup = field.optionFieldGroup ?? 'group';

  const elementAttributes = getInnerElementAttributes(field, formProps);
  elementAttributes.style = elementAttributes.style ?? {};
  if (!field.width || field.width === 'auto') {
    elementAttributes.style.width = calcMenuWidthFromContents(field) + 'px';
  }
  elementAttributes.style.maxWidth = getMaxWidth(field);

  const { typeahead = false } = field;

  const title = field.tooltip && typeof field.tooltip === 'string' ? field.tooltip : null;

  const toSafeValue = (value) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    if (value === '' || value === undefined) {
      return ' '; // TODO: fix warning "MUI: You have provided an out-of-range value ` ` for the select...""
    }
    if (Array.isArray(value)) {
      return value.map(item => toSafeValue(item));
    }
    return value;
  };

  const fromSafeValue = (value) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    if (value === ' ') {
      return '';
    }
    if (Array.isArray(value)) {
      return value.map(item => fromSafeValue(item));
    }
    return value;
  };

  const handleChangeSelect = (e: SelectChangeEvent<{ name?: string; value: unknown; }>) => {
    // MUI Select doesn't allow for blank values, so use ' ' to mean ''
    const value = e.target.value as string;
    setValue(field, fromSafeValue(value));
  };

  const handleChangeMultipleSelect = (e: SelectChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as unknown as string[]));
  };

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as string));
  };

  const handleChangeMultiple = (e: React.ChangeEvent<{ name?: string; value: unknown; }>) => {
    setValue(field, fromSafeValue(e.target.value as unknown as string[]));
  };

  let value = getValue(field);
  if (isMultiple) {
    value = DDFormUtils.convertValueToArrayString(value);
  }

  value = toSafeValue(value);

  if (typeahead) {
    const commonTypeAhead: Partial<React.ComponentProps<typeof Autocomplete>> = {
      ...elementAttributes,
      disabled: field.disabled ?? false,
      options: field.selectOptions ?? [],
      getOptionLabel: (option) => ('' + (option as AnyObject)[optionFieldLabel]),
      PaperComponent: (props) => (
        <Paper {...props} className={`${props.className ?? ''} MuiDDForm-select-paper-${formProps.size}`} />),
      title,
    };

    // create a new array of options with the id made safe ('' => ' '), but otherwise entries
    // are left unchanged, which is important because the options/values may be objects
    commonTypeAhead.options = commonTypeAhead.options.map((option: object) => {
      const newId = toSafeValue(option[optionFieldId]);
      if (newId !== option[optionFieldId]) {
        return { ...option, [optionFieldId]: newId };
      }
      return option;
    });

    // if any items have the group property, then display the group UI
    for (let i = 0; i < commonTypeAhead.options.length; i++) {
      if (commonTypeAhead.options[i][optionFieldGroup]) {
        commonTypeAhead.groupBy = option => (option as any)[optionFieldGroup];
        break;
      }
    }

    if (isMultiple) {
      const selectValue = field.selectOptions?.filter((option) =>
        (value as Array<string>).includes('' + option[optionFieldId]),
      );
      const handleChange = (
        e: React.ChangeEvent<AnyObject>,
        value: Array<AnyObject> | null,
      ) => {
        setValue(field, value ? value.map((item) => item[optionFieldId]) : []);
      };

      return (
        <TestableAutocomplete
          options={commonTypeAhead.options}
          multiple
          value={selectValue}
          onChange={handleChange}
          {...commonTypeAhead}
          renderInput={(params) => (
            <TextField
              {...params}
              id={null}
              error={!!error}
              autoFocus={field.autoFocus}
              inputProps={getInputProps(field)}
              InputProps={{ ...params.InputProps }}
              label={(field.required && field.label) ? `${field.label} *` : field.label}
              placeholder={field.placeholder}
            />
          )}
          placeholder={field.placeholder}
        />
      );
    } else {
      const id =
        '' +
        (typeof value === 'object'
          ? (value as AnyObject)[optionFieldId]
          : value);
      const selectValue =
        field.selectOptions?.find(
          (option) => id === '' + option[optionFieldId],
        ) ?? '';

      const handleChange = (e: any, value: unknown) => {
        // Pass a value, or pass the ID?
        // The default is to pass an object, but you can specify to use the id.
        if (field.passIdOnSelect === true) {
          setValue(field, value?.[optionFieldId] ?? '');
        } else {
          setValue(field, value as AnyObject);
        }
      };

      // when the text changes, automatically select any matching option
      const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const option = (field.selectOptions ?? [])
          .find(option => (option[optionFieldLabel].toString().toLowerCase() === e.target.value.toLowerCase()));
        if (field.passIdOnSelect === true) {
          setValue(field, option?.[optionFieldId] ?? '');
        } else {
          setValue(field, option as AnyObject);
        }
      };

      return (
        <>
          <TestableAutocomplete
            {...commonTypeAhead}
            options={commonTypeAhead.options}
            // this is a bit of a hack to force the control to clear when the options change
            key={JSON.stringify(field.selectOptions)}
            value={selectValue || null}
            onChange={handleChange}
            clearOnBlur={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                // not sure why the label isn't showing, but this will force it to be visible
                InputLabelProps={{ shrink: !!field.label && !field.placeholder }}
                InputProps={{ ...params.InputProps }}
                autoFocus={field.autoFocus}
                label={(field.required && field.label) ? `${field.label} *` : field.label}
                placeholder={field.placeholder}
                onChange={handleChangeText}
              />
            )}
            placeholder={field.placeholder}
          />
        </>
      );
    }
  }

  // non-typeahead case
  const menuItems: Array<JSX.Element> = [];
  if (!value && field.placeholder && !field.selectOptions.some(option => option[optionFieldId] === '')) {
    menuItems.push((
    <MenuItem value={'|-|'} disabled className='select-placeholder' data-placeholder={field.placeholder}>
      <i style={{ color: 'gray' }}>{field.placeholder}</i>
    </MenuItem>));
  }

  const addMenuOptions = (srcArray: SelectSingleDef['selectOptions'], indentationLevel = 0) => {
    const useAutoEllipsis = field.autoEllipsisMaxWidth && !isMultiple;
    srcArray.forEach((item, index) => {
      if (item.measurementOnly) {
        return;
      }
      const key = `${item[optionFieldId] ?? index}.${indentationLevel}`;
      const value = toSafeValue(`${item[optionFieldId] ?? index}`);
      const label = `${item[optionFieldLabel] ?? index}`;
      if (item.children) {
        // simulation of OptGroup
        menuItems.push(<MenuItem key={key} value={value} disabled>{label}</MenuItem>);
        addMenuOptions(item.children, 1);
      } else {
        menuItems.push(
          <MenuItem style={{ marginLeft: 20 * indentationLevel, maxWidth: getMaxWidth(field) }} key={key}value={value}>
            {useAutoEllipsis ? <AutoEllipsisTooltip tooltipContent={label}>{label}</AutoEllipsisTooltip> : label}
          </MenuItem>);
      }
    });
  };

  addMenuOptions(field.selectOptions);

  const labelId: string | undefined = field.label ? `${field.key}-select-label` : undefined;

  if (labelId && !isMultiple) {
    return <TestableTextFieldSelect
      {...elementAttributes}
      title={title}
      label={field.label}
      inputProps={getInputProps(field)}
      error={!!error}
      value={(!value && field.placeholder) ? '|-|' : value}
      onChange={!isMultiple ? handleChange : handleChangeMultiple}
      autoFocus={field.autoFocus}
      required={!!field.required}
      disabled={field.disabled ?? false}
      select // tell TextField to render select
      placeholder={field.placeholder}
    >{menuItems}</TestableTextFieldSelect>;
  }

  if (Array.isArray(value)) {
    value = value.map(item => toSafeValue(item));
  }

  const select = (
    <TestableSelect
      {...elementAttributes}
      title={title}
      labelId={labelId}
      label={field.label}
      inputProps={{ maxLength: 100 }}
      error={!!error}
      value={value || '|-|'}
      onChange={!isMultiple ? handleChangeSelect : handleChangeMultipleSelect}
      autoFocus={field.autoFocus}
      multiple={isMultiple}
      required={!!field.required}
      disabled={field.disabled ?? false}
      placeholder={field.placeholder}
      displayEmpty
      sx={{
        minWidth: 'auto', // Let it grow naturally based on the content
      }}
      MenuProps={{
        PaperProps: {
          style: {
            width: 'auto', // Ensure the menu is as wide as needed for the content
            maxWidth: 'none', // Prevent any constraints on the width
          },
        },
      }}

    >{menuItems}</TestableSelect>
  );

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{field.label}</InputLabel>
      {select}
    </FormControl>
  );
};

export const ddMuiRenderSelect: RenderElementFunction = (props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as SelectSingleDef | SelectMultipleDef;
  const error = props.showErrors && formErrors && formErrors[field.key];
  const outerElementAttributes = { ...getOuterElementAttributes(field, props), ...getStyleElementAttributes(field, props) };
  return <FormControl key={field.renderKey} {...outerElementAttributes}>
    {renderSelectCore(props, element, getValue, setValue, formErrors)}
    {error && <FormHelperText id={field.key + '-helper'} error={true}>{error}</FormHelperText>}
  </FormControl>;
};
