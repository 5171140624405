/* eslint-disable multiline-ternary, no-nested-ternary */

import React from 'react';
import { OntologyTemplate, TemplateGroup, TemplateAssignment } from '../data/templates';
import './Template.sass';
import { Annotation } from '../data/annotations';
import { Schema, SchemaBranch } from '../data/Schema';
import { deepClone, keyPropGroup, keyPropGroupValue, samePropGroupNest } from '../data/utils';
import Tooltip from '@mui/material/Tooltip';
import { OntologyTree } from '../data/OntologyTree';
import { Typography } from '@mui/material';

interface ResultRoster {
  assn: TemplateAssignment;
  groupNest: string[];
}

interface ResultGroupValue {
  uri: string;
  label: string;
  compat: number;
  hierarchy: string[];
}

interface ResultGroup {
  label: string;
  propURI: string;
  groupNest: string[];
  valueList: ResultGroupValue[];
}

type Props = {
  template: OntologyTemplate,
  ontologyAnnotations: Annotation[],
  onSelectTerm: (annotation: Annotation) => void,
};

type State = {
  searchText: string;
  hasFocus: boolean;
  loading: number;
  resultGroups: ResultGroup[];
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
};

export class GlobalAnnotationSearch extends React.Component<Props, State> {
  private debounce = 0;
  private loading = 0;

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      hasFocus: true,
      loading: 0,
      resultGroups: [],
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
    };
  }

  public render(): JSX.Element {
    const { template } = this.props;

    if (!template) return null;

    const divResults = this.state.searchText && this.state.hasFocus ? (
      <div
        className="results"
        onClick={this.handleConsumeFocus}
        onMouseDown={this.handleConsumeFocus}
        >
          {this.renderResults()}
      </div>
    ) : null;

    return (
      <div className="GlobalTemplateSearch-enclosingdiv">
        <input
          className="input-text"
          type="text"
          placeholder=" ... search ontology"
          value={this.state.searchText}
          onChange={this.handleChangeSearchText}
          onFocus={this.handleGotFocus}
          onBlur={this.handleLostFocus}
          />
        {divResults}
        <div
          className="progress"
          style={{ display: this.state.loading > 0 ? 'inline-block' : 'none' }}
          >
          Searching...
        </div>
      </div>
    );
  }

  private renderResults(): JSX.Element {
    const { resultGroups } = this.state;
    if (resultGroups.length == 0) {
      return (
        <div className="GlobalTemplateSearch-noresults">
          No matching results.
        </div>
      );
    }

    const renderValue = (result: ResultGroup, value: ResultGroupValue): JSX.Element => {
      const popoverID = 'srchpop-' + keyPropGroupValue(result.propURI, result.groupNest, value.uri);
      const vkey = 'srch-' + keyPropGroupValue(result.propURI, result.groupNest, value.uri);

      const viewHierarchy: JSX.Element[] = [];
      let dividx = 0;
      for (let n = 0; n < value.hierarchy.length; n++) {
        viewHierarchy.push((
          <div
            key={`tip-${vkey}-subdiv${++dividx}`}
            style={{ paddingLeft: `${n}em` }}
            >
            {n > 0 ? '\u{21B3} ' : '\u{2192} '}
            {value.hierarchy[n]}
          </div>
        ));
      }

      const tooltip = popoverID == this.state.popoverID ? (
        <Typography
          className="ProtocolAnnotator-tooltip"
          component="div"
          >
          <div key={`tip-${vkey}-subdiv${++dividx}`}><b>{this.state.popoverURI}</b></div>
          {viewHierarchy}
          <div key={`tip-${vkey}-subdiv${++dividx}`}>{this.state.popoverDescr}</div>
        </Typography>
      ) : '';

      return (
        <Tooltip
          key={`tip-${vkey}`}
          title={tooltip}
          arrow
          placement="right"
          >
          <div
            key={vkey}
            className="GlobalTemplateSearch-resultvalue"
            onClick={() => this.handleSelectValue(result.propURI, result.groupNest, value.uri)}
            onMouseEnter={() => this.handleValuePopoverOpen(popoverID, value.uri)}
            >
            {value.label}
          </div>
        </Tooltip>
      );
    };

    const things: JSX.Element[] = [];
    for (const result of resultGroups) {
      const pkey = 'srch-' + keyPropGroup(result.propURI, result.groupNest);
      things.push((
        <div key={pkey} className="GlobalTemplateSearch-resultprop">
          {result.label}
        </div>
      ));
      for (const value of result.valueList) things.push(renderValue(result, value));
    }

    return (
      <div className="GlobalTemplateSearch-resultflex">
        {things}
      </div>
    );
  }

  private async searchNextBatch(searchText: string, schema: Schema, bounce: number, isFirst: boolean, roster: ResultRoster[]): Promise<void> {
    if (bounce != this.debounce) {
      this.setState({ loading: --this.loading });
      return;
    }

    const candidate = roster.shift();
    const { assn, groupNest } = candidate;
    const result: ResultGroup = {
      label: assn.name,
      propURI: assn.propURI,
      groupNest,
      valueList: [],
    };

    const scanBranch = (branch: SchemaBranch) => {
      if (branch.inSchema) {
        if (this.props.ontologyAnnotations?.some((annot) => samePropGroupNest(assn.propURI, groupNest, annot.propURI, annot.groupNest) && branch.uri == annot.valueURI)) {
          // nop
        } else {
          const compat = this.measureCompatibility(searchText, branch);
          if (compat != null) {
            const hierarchy: string[] = [];
            for (let look = branch.parent; look; look = look.parent) {
              if (Schema.isPlaceholderURI(look.uri)) continue; // it's a book-end
              hierarchy.unshift(look.label);
            }
            result.valueList.push({ uri: branch.uri, label: branch.label, compat, hierarchy });
          }
        }
      }
      for (const child of branch.children) scanBranch(child as SchemaBranch);
    };

    const branches = await schema.composeBranch(candidate.assn);
    for (const branch of branches ?? []) scanBranch(branch);

    if (bounce != this.debounce) {
      this.setState({ loading: --this.loading });
      return;
    }

    const LIMIT = 10;
    result.valueList.sort((v1, v2) => v1.compat - v2.compat);
    if (result.valueList.length > LIMIT) result.valueList = result.valueList.slice(0, LIMIT);

    const resultGroups = isFirst ? [] : deepClone(this.state.resultGroups);
    if (result.valueList.length > 0) {
      resultGroups.push(result);
      resultGroups.sort((rg1, rg2) => {
        const c1 = rg1.valueList[0].compat, c2 = rg2.valueList[0].compat;
        return c1 - c2;
      });
    }
    this.setState({ resultGroups });

    if (roster.length > 0) {
      setTimeout(() => this.searchNextBatch(searchText, schema, bounce, false, roster).then(), 1);
    } else {
      this.setState({ loading: --this.loading });
    }
  }

  // returns an indication of match compatibility: null means none; 0 means really good, >0 means decreasingly good
  private measureCompatibility(searchText: string, branch: SchemaBranch): number {
    const query = searchText, queryLC = searchText.toLowerCase();
    const target = branch.label, targetLC = target.toLowerCase();

    // exact matches
    if (query == target) return 0;
    if (queryLC == targetLC) return 1;

    // if there are at least N numeric characters in the query string, match the URI/IRI
    let numNumeric = 0;
    const ASCII_ZERO = 48, MIN_NUMERIC = 3;
    for (let n = 0; n < searchText.length; n++) {
      const code = searchText.charCodeAt(n);
      if (code >= ASCII_ZERO && code < ASCII_ZERO + 10) numNumeric++;
    }
    if (numNumeric >= MIN_NUMERIC && branch.uri.toLowerCase().includes(queryLC)) return 2;

    // word surrounded by whitespace
    if (target.includes(` ${query} `) || target.startsWith(query) || target.endsWith(query)) return 3;
    if (targetLC.includes(` ${queryLC} `) || target.startsWith(queryLC) || target.endsWith(queryLC)) return 4;

    // whitespace after/before
    if (target.includes(`${query} `)) return 5;
    if (target.includes(` ${query}`)) return 6;
    if (targetLC.includes(`${queryLC} `)) return 7;
    if (targetLC.includes(` ${queryLC}`)) return 8;

    // NOTE: searching altLabels as well would be nice, but they have to be loaded from the same files as the descriptions,
    //       and this is very bandwidth intensive, so just have to live without it for now

    // maybe check for almost-but-not-quite options...
    return null;
  }

  private handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.currentTarget.value;
    this.setState({
      searchText,
      loading: ++this.loading,
    });

    const schema = new Schema(this.props.template);
    const roster: ResultRoster[] = [];
    const appendOntologyGroup = (group: TemplateGroup, groupNest: string[]) => {
      for (const assn of group.assignments ?? []) {
        if (assn.values.length > 0) {
          roster.push({ assn, groupNest });
        }
      }
      for (const sgrp of group.subGroups ?? []) {
        appendOntologyGroup(sgrp, [sgrp.groupURI, ...groupNest]);
      }
    };
    appendOntologyGroup(schema.template.root, []);

    setTimeout(() => this.searchNextBatch(searchText.trim(), schema, ++this.debounce, true, roster).then(), 100);
  };

  private handleConsumeFocus = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
  };

  private handleGotFocus = () => this.setState({ hasFocus: true });
  private handleLostFocus = () => this.setState({ hasFocus: false });

  private handleSelectValue(propURI: string, groupNest: string[], valueURI: string): void {
    const annotation = { propURI, groupNest, valueURI };
    this.props.onSelectTerm(annotation);
    this.setState({ searchText: '' });
  }

  private handleValuePopoverOpen(popoverID: string, uri: string): void {
    const branch = OntologyTree.values.cachedBranch(uri);
    if (branch && branch.description != null) {
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch.description });
      return;
    }

    (async () => {
      const branch = await OntologyTree.values.getBranch(uri, true, true);
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch ? branch.description : null });
    })();
  }
}
