export const FINALIZE = 'eln/entry/actions/finalize'
export const DISCARD = 'eln/entry/actions/discard'
export const ACCEPT_ENTRY = 'eln/entry/actions/accept'
export const REJECT_ENTRY = 'eln/entry/actions/reject'
export const CANCEL_ENTRY = 'eln/entry/actions/cancel'
export const REOPEN_ENTRY = 'eln/entry/actions/reopen'
export const WITNESS_ACTION_COMPLETE = 'eln/entry/actions/complete'

export function finalize() {
  return {
    type: FINALIZE,
  }
}

export function discard() {
  return {
    type: DISCARD,
  }
}

export function acceptEntry() {
  return {
    type: ACCEPT_ENTRY,
  }
}

export function rejectEntry(rejectionReason) {
  return {
    type: REJECT_ENTRY,
    payload: { rejectionReason },
  }
}

export function cancelEntry() {
  return {
    type: CANCEL_ENTRY,
  }
}

export function reopenEntry(reopeningReason) {
  return {
    type: REOPEN_ENTRY,
    payload: { reopeningReason },
  }
}

export function completeWitnessAction({ eln_witness_id, status, lock_version }) {
  return {
    type: WITNESS_ACTION_COMPLETE,
    payload: {
      eln_witness_id,
      status,
      lock_version,
    },
  }
}
