/* eslint-disable multiline-ternary, no-nested-ternary */
import { CellState, PropertyGridData } from './PropertyGridData';

const FONT_SIZE = 10, FONT_FAMILY = 'sans-serif', FONT = `${FONT_SIZE}px ${FONT_FAMILY}`;
const BRACKET_WIDTH = 20, HIER_INDENT = 20, TITLE_GAP = 5;
const CELL_SIZE = 15;

function encodeHTMLText(str: string): string {
  return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

class SVGBuilder {
  private lines: string[] = [];

  constructor(private width: number, private height: number, private ctx: CanvasRenderingContext2D) {
  }

  public rect(x: number, y: number, w: number, h: number, fill: string): void {
    this.lines.push(`<rect x="${x}" y="${y}" width="${w}" height="${h}" fill="${fill}"/>`);
  }

  public diamond(x: number, y: number, w: number, h: number, fill: string): void {
    const xx = x + w, yy = y + h, xm = x + 0.5 * w, ym = y + 0.5 * h;
    this.lines.push(`<path d="M ${x},${ym} L ${xm},${y} L ${xx},${ym} L ${xm} ${yy} Z" fill="${fill}"/>`);
  }

  public circle(x: number, y: number, r: number, stroke: string, fill: string): void {
    const attrStroke = stroke != null ? ` stroke="${stroke}"` : '';
    const attrFill = fill != null ? ` fill="${fill}"` : '';
    this.lines.push(`<circle cx="${x}" cy="${y}" r="${r}"${attrStroke}${attrFill}/>`);
  }

  public line(x1: number, y1: number, x2: number, y2: number, sz: number, col: string, extra = ''): void {
    this.lines.push(`<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="${col}" stroke-width="${sz}"${extra}/>`);
  }

  public path(path: string, sz: number, stroke: string, fill: string = null): void {
    const attrStroke = stroke != null ? ` stroke="${stroke}" stroke-width="${sz}"` : '';
    const attrFill = fill != null ? ` fill="${fill}"` : ' fill-opacity="0"';
    this.lines.push(`<path d="${path}"${attrStroke}${attrFill}/>`);
  }

  public text(txt: string, x: number, y: number, col: string, extra = ''): void {
    this.lines.push(`<text x="${x}" y="${y}" fill="${col}" font-family="${FONT_FAMILY}" font-size="${FONT_SIZE}"${extra}>${encodeHTMLText(txt)}</text>`);
  }

  public verticalText(txt: string, cx: number, cy: number, col: string) {
    const tw = this.ctx.measureText(txt).width;
    this.text(txt, cx - 0.5 * tw, cy + 0.35 * FONT_SIZE, col, ` transform="rotate(-90 ${cx},${cy})"`);
  }

  public build(): string {
    return [
      `<svg xmlns="http://www.w3.org/2000/svg" style="display: block;" width="${this.width}" height="${this.height}" viewBox="0 0 ${this.width} ${this.height}">`,
      ...this.lines,
      '</svg>',
    ].join('\n');
  }
}

export type PropertyGridFocusPos = { blk: number, col: number, row: number }

export enum SVGBlockDetailType {
  ColumnProtocol,
  RowCategory,
  RowField,
  RowProperty,
  Cell,
}

export interface SVGBlockDetail {
  type: SVGBlockDetailType,
  label: string;
  uri?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  tipPos: 'top' | 'bottom' | 'left' | 'right';
  linkURL?: string;
  focusPos?: PropertyGridFocusPos;
}

export interface SVGBlock {
  svg: string;
  width: number;
  height: number;
  details: SVGBlockDetail[];
}

export class PropertyGridRender {
  public ncols: number;
  public totalWidth: number;
  public titleWidth: number;
  public titleHeight: number;

  private blkHeader: SVGBlock = null;
  private blkCategories: SVGBlock[] = null;

  constructor(private data: PropertyGridData, private ctx: CanvasRenderingContext2D) {
    this.ctx.font = FONT;
  }

  // establish basic boundaries, prior to generating SVG blocks
  public arrange(): void {
    this.ncols = this.data.protocols.length;
    this.totalWidth = this.titleWidth = this.titleHeight = 0;

    for (const catData of this.data.categoryData) {
      for (const row of (catData.rows ?? [])) {
        const label = this.fieldLabel(row.label, catData.presentAssignment && !row.uri);
        const w = BRACKET_WIDTH + row.indent * HIER_INDENT + this.ctx.measureText(label).width + TITLE_GAP;
        this.titleWidth = Math.max(this.titleWidth, w);
      }
    }
    this.totalWidth = this.titleWidth + this.ncols * CELL_SIZE;

    for (const protocol of this.data.protocols) {
      const label = this.protocolLabel(protocol.name);
      const h = this.ctx.measureText(label).width + TITLE_GAP;
      this.titleHeight = Math.max(this.titleHeight, h);
    }

    this.blkHeader = null;
    this.blkCategories = null;
  }

  // create a row for the header, which contains the protocol info
  public makeSVGHeader(): SVGBlock {
    if (this.blkHeader) return this.blkHeader;

    const svg = new SVGBuilder(this.totalWidth, this.titleHeight, this.ctx);
    const details = this.drawSVGHeader(svg);
    this.blkHeader = { svg: svg.build(), width: this.totalWidth, height: this.titleHeight, details };
    return this.blkHeader;
  }

  private drawSVGHeader(svg: SVGBuilder): SVGBlockDetail[] {
    svg.line(this.titleWidth - 0.5, this.titleHeight - 0.5, this.totalWidth, this.titleHeight - 0.5, 1, 'black');

    const details: SVGBlockDetail[] = [];

    let x = this.titleWidth;
    for (const protocol of this.data.protocols) {
      const label = this.protocolLabel(protocol.name);
      const h = this.ctx.measureText(label).width;
      svg.verticalText(label, x + 0.5 * CELL_SIZE, this.titleHeight - 1 - 0.5 * h, 'black');
      details.push({
        type: SVGBlockDetailType.ColumnProtocol,
        label: protocol.name,
        x,
        y: 0,
        w: CELL_SIZE,
        h: this.titleHeight,
        tipPos: 'top',
        linkURL: protocol.url,
      });
      x += CELL_SIZE;
    }

    return details;
  }

  // create a row for a single category, with title info on the left and grid cells to the right
  public makeSVGCategory(idx: number): SVGBlock {
    if (!this.blkCategories) this.blkCategories = new Array(this.data.categoryData.length).fill(null);
    const catData = this.data.categoryData[idx];
    if (!catData.rows) return null;
    if (this.blkCategories[idx]) return this.blkCategories[idx];

    const blockHeight = CELL_SIZE * catData.rows.length;
    const svg = new SVGBuilder(this.totalWidth, blockHeight, this.ctx);
    const details = this.drawSVGCategory(idx, svg);
    this.blkCategories[idx] = { svg: svg.build(), width: this.totalWidth, height: blockHeight, details };
    return this.blkCategories[idx];
  }

  private drawSVGCategory(idx: number, svg: SVGBuilder, offsetY = 0): SVGBlockDetail[] {
    const catData = this.data.categoryData[idx];
    const { ncols } = this;
    const nrows = catData.rows.length;
    const blockHeight = CELL_SIZE * catData.rows.length;

    svg.rect(this.titleWidth, offsetY, this.totalWidth - this.titleWidth, blockHeight, '#FBFBFF');

    const details: SVGBlockDetail[] = [];

    // draw the grid boundaries
    for (let n = 0, x = this.titleWidth; n <= ncols; n++) {
      svg.line(x - 0.5, offsetY, x - 0.5, offsetY + blockHeight, 1, '#CCD9E8');
      x += CELL_SIZE;
    }
    for (let n = 0, y = 0; n < nrows; n++) {
      y += CELL_SIZE;
      const col = n == nrows - 1 ? 'black' : '#CCD9E8';
      svg.line(this.titleWidth - 0.5, offsetY + y - 0.5, this.totalWidth, offsetY + y - 0.5, 1, col);
    }

    // draw the title text
    for (let n = 0, y = 0; n < nrows; n++) {
      const row = catData.rows[n];
      const x = BRACKET_WIDTH + row.indent * HIER_INDENT;
      const cy = y + 0.5 * CELL_SIZE;
      const label = this.fieldLabel(row.label, catData.presentAssignment && !row.uri);
      svg.text(label, x, offsetY + cy + 0.5 * FONT_SIZE * 0.7, 'black');

      details.push({
        type: SVGBlockDetailType.RowField,
        label,
        uri: row.uri,
        x: BRACKET_WIDTH,
        y: offsetY + y,
        w: this.titleWidth - BRACKET_WIDTH,
        h: CELL_SIZE,
        tipPos: 'left',
      });

      const rx = x + this.ctx.measureText(row.label).width + TITLE_GAP;
      for (let x = this.titleWidth - 3; x > rx; x -= 2) {
        svg.circle(x, offsetY + cy, 0.5, null, '#C0C0C0');
      }

      y += CELL_SIZE;
    }

    // the values
    const rad = 0.5 * CELL_SIZE - 0.5;
    for (let r = 0; r < nrows; r++) {
      const row = catData.rows[r];
      const y = r * CELL_SIZE;
      const cy = y + 0.5 * CELL_SIZE - 0.5;
      for (let c = 0; c < ncols; c++) {
        const x = this.titleWidth + c * CELL_SIZE;
        const cx = x + 0.5 * CELL_SIZE - 0.5;
        if (row.cells[c] == CellState.Present) {
          if (catData.presentField) {
            svg.circle(cx, offsetY + cy, rad - 1, null, '#1362B3');
          } else if (catData.presentAssignment) {
            if (row.uri) {
              svg.rect(cx - rad, offsetY + cy - rad, 2 * rad, 2 * rad, '#1362B3');
            } else {
              svg.diamond(cx - rad, offsetY + cy - rad, 2 * rad, 2 * rad, '#1362B3');
            }
          }
        } else if (row.cells[c] == CellState.Ancestor) {
          svg.rect(cx - 0.5 * rad, offsetY + cy - rad, rad, 2 * rad, '#1362B3');
        }

        details.push({
          type: SVGBlockDetailType.Cell,
          label: null,
          x,
          y: offsetY + y,
          w: CELL_SIZE,
          h: CELL_SIZE,
          tipPos: 'right',
          focusPos: { blk: idx, col: c, row: r },
        });
      }
    }

    // hierarchy
    for (let n = 0; n < nrows; n++) {
      const depth = catData.rows[n].indent;
      let m = 0;
      for (let i = n + 1; i < nrows && catData.rows[i].indent > depth; i++) {
        if (catData.rows[i].indent == depth + 1) m = i;
      }

      if (m > n) {
        const x = BRACKET_WIDTH + (depth + 0.5) * HIER_INDENT;
        const y1 = (n + 1) * CELL_SIZE, y2 = (m + 0.5) * CELL_SIZE;
        svg.line(x, offsetY + y1, x, offsetY + y2, 1, '#313A44'); // vertical
      }

      if (depth > 0) {
        const x1 = BRACKET_WIDTH + (depth - 0.5) * HIER_INDENT, x2 = BRACKET_WIDTH + depth * HIER_INDENT - 2;
        const y = (n + 0.5) * CELL_SIZE;
        svg.line(x1, offsetY + y, x2, offsetY + y, 1, '#313A44'); // horizontal
      }
    }

    // category bracket
    const bx1 = BRACKET_WIDTH - 3, bx2 = bx1 + 4, bx3 = bx2 + 2;
    const by1 = offsetY + 1.5, by4 = offsetY + blockHeight - 1.5, by2 = by1 + 4, by3 = by4 - 4;
    const path = [
      `M ${bx3} ${by1}`,
      `L ${bx2} ${by1}`,
      `Q ${bx1} ${by1} ${bx1} ${by2}`,
      `L ${bx1} ${by3}`,
      `Q ${bx1} ${by4} ${bx2} ${by4}`,
      `L ${bx3} ${by4}`,
    ].join(' ');
    svg.path(path, 1, '#1362B3');

    // category label
    const label = catData.presentField ? catData.presentField.defn.name : catData.presentAssignment ? catData.presentAssignment.assn.name : '?';
    const tw = this.ctx.measureText(label).width;
    if (tw < blockHeight - 2) {
      svg.verticalText(label, 0.5 * bx1, offsetY + 0.5 * blockHeight, '#1362B3');
    } else {
      const rwh = 0.3 * BRACKET_WIDTH;
      svg.rect(0.5 * (bx1 - rwh), offsetY + 0.5 * (blockHeight - rwh), rwh, rwh, '#1362B3');
    }

    details.push({
      type: SVGBlockDetailType.RowCategory,
      label,
      uri: catData.presentAssignment ? catData.presentAssignment.assn.propURI : null,
      x: 0,
      y: offsetY,
      w: BRACKET_WIDTH,
      h: blockHeight,
      tipPos: 'left',
    });

    return details;
  }

  // draw the selection cross-hairs
  public makeSVGFocus(focusPos: PropertyGridFocusPos, blkHeader: SVGBlock, blkCategory: SVGBlock[]): string {
    if (blkCategory.length == 0) return null;

    let totalHeight = blkHeader.height;
    for (const blk of blkCategory) totalHeight += blk.height;

    const svg = new SVGBuilder(this.totalWidth, totalHeight, this.ctx);

    let x = 0, y = 0, w = 0, h = 0;
    let topY = blkHeader.height;
    for (const blk of blkCategory) {
      for (const detail of blk.details) {
        const fp = detail.focusPos;
        if (detail.type == SVGBlockDetailType.Cell && fp.blk == focusPos.blk && fp.col == focusPos.col && fp.row == focusPos.row) {
          x = detail.x;
          y = detail.y + topY;
          w = detail.w;
          h = detail.h;
        }
      }
      topY += blk.height;
    }

    const EDGE = 5; // enough to move the spline off the edge of the page
    const x0 = -EDGE, y0 = -EDGE, x3 = this.totalWidth + EDGE, y3 = totalHeight + EDGE;
    const x1 = x - 1, x2 = x + w;
    const y1 = y - 1, y2 = y + h;
    const D = 6; // curvature

    const lastCol = x + w + 1 >= this.totalWidth, lastRow = y + h + 1 >= totalHeight;

    const pathNodes = [
      `M${x0},${y1}`,
      `L${x1 - D},${y1}`,
      `Q${x1},${y1} ${x1},${y1 - D}`,
      `L${x1},${y0}`,

      `L${x2},${y0}`,
      ...(!lastCol ? [`L${x2},${y1 - D}`, `Q${x2},${y1} ${x2 + D},${y1}`] : [`L${x2},${y1}`]),
      `L${x3},${y1}`,

      `L${x3},${y2}`,
      ...(!lastCol && !lastRow ? [`L${x2 + D},${y2}`, `Q${x2},${y2} ${x2},${y2 + D}`] : [`L${x2},${y2}`]),
      `L${x2},${y3}`,

      `L${x1},${y3}`,
      ...(!lastRow ? [`L${x1},${y2 + D}`, `Q${x1},${y2} ${x1 - D}, ${y2}`] : [`L${x1},${y2}`]),
      `L${x0},${y2}`,

      'Z',

      `M${x1},${y1} L${x1},${y2} L${x2},${y2} L${x2},${y1} Z`,
    ];
    svg.path(pathNodes.join(' '), 1, 'black', 'rgb(0, 0, 0, 0.1)');

    return svg.build();
  }

  public makeSVGEverything(): { svg: string, width: number, height: number } {
    const { data } = this;
    let totalHeight = this.titleHeight;
    const blockHeights: number[] = [];
    for (let n = 0; n < data.categoryData.length; n++) {
      const h = CELL_SIZE * data.categoryData[n].rows.length;
      totalHeight += h;
      blockHeights.push(h);
    }

    const svg = new SVGBuilder(this.totalWidth, totalHeight, this.ctx);

    this.drawSVGHeader(svg);
    let offsetY = this.titleHeight;
    for (let n = 0; n < data.categoryData.length; n++) {
      this.drawSVGCategory(n, svg, offsetY);
      offsetY += blockHeights[n];
    }

    return { svg: svg.build(), width: this.totalWidth, height: offsetY };
  }

  private protocolLabel(label: string): string {
    label = label.trim();
    const MAXCH = 40;
    if (label.length > MAXCH) label = label.substring(0, MAXCH).trim() + '\u{2026}';
    return label;
  }

  private fieldLabel(label: string, inQuotes: boolean): string {
    label = label.trim();
    if (inQuotes) label = `"${label}"`;
    const MAXCH = 60;
    if (label.length > MAXCH) label = label.substring(0, MAXCH).trim() + '\u{2026}';
    return label;
  }
}

export type RenderSVGResult = { svg: string, width: number, height: number };

export async function renderSVGGraphicsForGrid(gridData: PropertyGridData): Promise<RenderSVGResult> {
  gridData.prebuild();
  while (await gridData.buildBlock()) {
    // do them all
  }

  const canvas = document.createElement('canvas');
  document.body.append(canvas);
  const ctx = canvas.getContext('2d');
  canvas.remove();

  const gridRender = new PropertyGridRender(gridData, ctx);
  gridRender.arrange();
  return gridRender.makeSVGEverything();
}
