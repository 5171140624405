import axios from 'axios'

export default function submit({
  type = 'PUT',
  dataType = 'json',
  data = '',
  jsonData = null,
  params = {},
  ...options
} = {}) {
  const headers = {}

  if (dataType === 'json') {
    headers['Accept'] = 'application/json'
  }

  if (data) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
  } else if (jsonData) {
    headers['Content-Type'] = 'application/json;charset=UTF-8'
  }

  return new Promise((resolve, reject) => {
    axios({
      method: type.toLowerCase(),
      url: options.url,
      data: data || jsonData,
      params: params,
      headers: headers,
      paramsSerializer: {
        serialize: (params) => $.param(params),
      },
    })
      .then(
        ({ data }) => resolve(data),
        ({ response: { data: errorMessages, status } }) => {
          if (errorMessages) {
            errorMessages = errorMessages instanceof Array ? errorMessages : [errorMessages]
          } else if (status === 403) {
            errorMessages = [CDD.Axios.invalidCSRFMessage]
          } else {
            errorMessages = [
              "There was an error while submitting. Please contact support@collaborativedrug.com if the problem persists",
            ]
          }

          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ errorMessages, status })
        }
      )
  })
}
