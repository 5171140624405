/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ButtonDef, ElementDef } from '@/shared/components/DDForm/types';
import { Button, Fab, IconButton, Link } from '@mui/material';
import { getElementAttributes } from '../muiRenderers';

export const ddMuiRenderButton: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
) => {
  const field = element as ButtonDef;

  const buttonContents = (
    <span className='button-contents'>
      {field.icon && <field.icon />} {field.label}
    </span>
  );
  const buttonAttributes = {
    ...getElementAttributes(field, props),
    disabled: field.disabled ?? false,
    'aria-label': field.ariaLabel ?? (typeof field.tooltip === 'string' ? field.tooltip : null),
  };

  const handleClickByForm = (evt: React.MouseEvent) => {
    if (props.onClickElement) {
      props.onClickElement(evt, element);
    }
  };
  const handleClick = field.onClickButton ?? handleClickByForm;

  const color: (React.ComponentProps<typeof Button>)['color'] = (field.color ??
    (field.buttonType === 'primary'
      ? 'primary'
      : 'inherit'));

  switch (field.buttonType) {
    case 'round':
      return (
        <Fab key={field.renderKey} onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </Fab>
      );

    case 'link':
      return (
        <Link key={field.renderKey} href='#' onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </Link>
      );

    case 'icon':
      return (
        <IconButton key={field.renderKey} onClick={field.onClickButton} {...buttonAttributes}>
          {buttonContents}
        </IconButton>
      );
    case 'default':
    default: {
      return (
        <Button
          key={field.renderKey}
          onClick={handleClick}
          {...buttonAttributes}
          variant={field.variant}
          color={color}
        >
          {buttonContents}
        </Button>
      );
    }
  }
};
