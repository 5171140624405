import { components } from '@/schemas/cdd_openapi_internal';

type schemas = components['schemas'];

export type UserSchema = schemas['user'];
export type AccountUserSettingsSchema = schemas['account_user_settings'];

export type ProjectSchema = schemas['project'];
export type ProjectDetailsSchema = schemas['project_show'];
export type ProjectUsersWithPermissions = schemas['project_users_with_permissions'];

export type TeamSchema = schemas['team'] & {
  users_count?: number;
  projects_count?: number;
  vaults_count?: number;
};

export type TeamWithProjectIdsSchema = TeamSchema & {
  project_ids: Array<number>;
};

export type TeamWithProjectsSchema = schemas['team_with_projects'] & { name: string; };
export type VaultWithProjectsSchema = schemas['vault_details_with_projects'];

export type RoleName = schemas['role_name'];

export type AccountSchema = schemas['account'];
export type VaultUserWithPermissions = schemas['vault_user_with_permissions'];
export type ProjectPermissions = schemas['project_permissions'];
export type AccountUserPermissions = schemas['account_user_permissions'];
export type VaultPermissions = schemas['vault_permissions'];
export type TeamMembership = schemas['team_membership'];
export type VaultUserCreate = components['schemas']['vault_user_create'];

export type HasIdAndName = {
  id: number;
  name: string;
};

export type WithLabel<T> = T & { label: string; };

export const vaultRoleOptions = [
  { id: 'readonly', label: 'readonly' },
  { id: 'read-download', label: 'read-download' },
  { id: 'read-export', label: 'read-export' },
  { id: 'biology-read-add', label: 'biology-read-add' },
  { id: 'read-add', label: 'read-add' },
  { id: 'biology full access', label: 'biology full access' },
  { id: 'full access', label: 'full access' },
  { id: 'vault admin', label: 'vault admin' },
];

export type UserWithPermissionsSchema = UserSchema & AccountUserPermissions;

export type EditUserSchema = UserSchema & {
  vault_permissions: { [vaultId: number]: VaultPermissions; };
  project_permissions: { [projectId: number]: ProjectPermissions; };
  team_memberships: { [teamId: number]: TeamMembership; };
};

export type EditTeamSchema = TeamSchema & {
  user_membership: { [userId: number]: TeamMembership; };
  add_users_to_vaults: { [userId: number]: { [vaultId: number]: string; }; };
  project_ids: Array<number>;
};

export type EditProjectSchema = ProjectDetailsSchema & {
  project_permissions: { [userId: number]: ProjectPermissions; };
  add_users_to_vault: { [userId: number]: VaultUserCreate; };
  team_ids: Array<number>;
  user_order: Array<number>;
};

export type UsersExportResponse = {
  message: unknown;
  vault: number;
  export: number;
};
