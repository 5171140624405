import React from 'react'
import moment from 'moment'
import QueryTextField from './QueryTextField.tsx'
import constants from 'javascripts/constants.js'
import QueryDateButton from './QueryDateButton'
const { DATE_DISPLAY_FORMAT } = constants

export default class QueryDateField extends QueryTextField {
  isMultiline() {
    return false
  }

  getEndAdornment() {
    const { query, errorMessage } = this.props
    const date = query && !errorMessage.length ? moment(query) : moment()

    return <QueryDateButton
      className='molecule_criteria__query__date_button'
      onChange={this.onChange}
      onError={this.onError}
      value={date}
    />
  }

  onError = () => { }

  formatQueryChange(eventOrValue) {
    let value = super.formatQueryChange(eventOrValue)

    if (value && value.format) {
      value = value.format(DATE_DISPLAY_FORMAT)
    }

    return value
  }
}
