/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, ToggleDef } from '@/shared/components/DDForm/types';
import { Grid, Switch, Typography } from '@mui/material';
import { DDFormUtils } from '../../DDFormUtils';
import { getElementAttributes } from '../muiRenderers';

/**
 * Render a field as a toggle control
 * @param field
 * @returns
 */
export const ddMuiRenderToggle: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: boolean) => void,
) => {
  const field = element as ToggleDef;
  const value = DDFormUtils.convertValueToBoolean(getValue(field));

  const handleChange = (): void => {
    setValue(field, !value);
  };

  return (
    <Typography component='div' key={field.renderKey}>
      <Grid component='label' container alignItems='center' spacing={1}>
        {(!field.labelDirection || field.labelDirection === 'left') && (
          <Grid item>{field.label}</Grid>
        )}
        <Grid item>
          <Switch
            {...getElementAttributes(field, props)}
            checked={value}
            onChange={handleChange}
            color={'primary'}
          />
        </Grid>
        {field.labelDirection === 'right' && <Grid item>{field.label}</Grid>}
      </Grid>
    </Typography>
  );
};
