export const EDITOR_WORK_START = 'eln/entry/editor/work/start'
export const EDITOR_WORK_FINISH = 'eln/entry/editor/work/finished'

export function editorWorkStart() {
  return {
    type: EDITOR_WORK_START,
  }
}

export function editorWorkFinish() {
  return {
    type: EDITOR_WORK_FINISH,
  }
}
