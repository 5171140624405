import { DEFAULT_BLOCK } from './components/Editor/constants.js'
import { Map } from 'immutable'

export const AUTOSAVE_TIMEOUT = 1000
export const RETRY_INTERVAL = process.env.RAILS_ENV === 'production' ? 30000 : 3000
export const RETRY_ATTEMPTS = 3
export const STOICHIOMETRY_TABLE_WIDTH = 860
export const LABEL_COLUMN_MIN_WIDTH = 115

export const STOICHIOMETRY_LINK_REMOVED_TEXT = '(REMOVED FROM TABLE)'

export const plainSerializerOptions = {
  defaultBlock: DEFAULT_BLOCK,
}

export const PAGE_SIZES = Map({
  'portrait': 898, // full width when in screen. We now do fixed width printing and just let the entry shrink to the page width
  //  'A4 portrait': 642,
  //  "letter portrait": 816,
  //  "letter landscape": 1056,
  //  "A4 landscape": 1122,
})
