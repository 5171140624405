import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Icon, IconButton } from '@mui/material'

export default class ApiKeyCopyButton extends React.Component {
  state = {
    value: '',
    copied: false,
    hover: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.value,
      copied: false,
      hover: false,
    }
  }

  handleMouseOver = () => {
    this.setState({ hover: true })
  }

  handleMouseOut = () => {
    this.setState({ hover: false })
  }

  render() {
    return (
      <CopyToClipboard text={this.state.value} onCopy={() => this.setState({ copied: true })}>
        <IconButton
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <Icon className={`fa fa-clipboard ${this.state.hover ? 'hover' : ''}`} />
        </IconButton>
      </CopyToClipboard>
    )
  }
}
