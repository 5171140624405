/*
  Datastructures for storing annotations, which are associated with a template.
*/

import { OntologyTemplate, TemplateGroup } from './templates';
import { compatibleGroupNest } from './utils';

export interface Annotation {
  propURI: string;
  groupNest: string[]; // location in template tree by group URI in reverse order, i.e. [closest, next-closest, ..., rootURI]
  valueURI?: string; // either this or label...
  valueLabel?: string; // ... must be defined
}

// adapts ontology annotations to a template as best possible, given that the groupNest embedding sequence may vary between templates
export function harmonizeTemplateAnnotations(template: OntologyTemplate, annotations: Annotation[]): void {
  if (!template) return;

  const mapPropURI = new Map<string, string[][]>(); // to groupNest sequence

  const scanGroup = (group: TemplateGroup, groupNest: string[]) => {
    for (const sg of group.subGroups ?? []) {
      scanGroup(sg, [sg.groupURI, ...groupNest]);
    }
    for (const assn of group.assignments) {
      let nestList = mapPropURI.get(assn.propURI);
      if (!nestList) mapPropURI.set(assn.propURI, nestList = []);
      nestList.push(groupNest);
    }
  };
  scanGroup(template.root, []);

  for (const nestList of mapPropURI.values()) {
    nestList.sort((gn1, gn2) => gn2.length - gn1.length);
  }

  for (const annot of annotations) {
    const nestList = mapPropURI.get(annot.propURI);
    if (!nestList?.length) continue;

    for (let zap = annot.groupNest.length; zap >= 0; zap--) {
      let hit = false;
      for (const lookNest of nestList) {
        if (compatibleGroupNest(annot.groupNest, lookNest)) {
          annot.groupNest = lookNest;
          hit = true;
          break;
        }
      }
      if (hit) break;

      annot.groupNest.pop();
    }
  }
}
