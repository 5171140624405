import add from 'ASSETS/images/cdd30/icons/add.png'
import arrow_rotate_anticlockwise from 'ASSETS/images/cdd30/icons/arrow_rotate_anticlockwise.png'
import attach from 'ASSETS/images/cdd30/icons/attach.png'
import calendar from 'ASSETS/images/cdd30/icons/calendar.png'
import close from 'ASSETS/images/cdd30/actions/x-blue.gif'
import deleteIcon from 'ASSETS/images/cdd30/icons/delete.png'
import disabled_drag from 'ASSETS/images/layout/sort/disabled_drag.svg'
import disk from 'ASSETS/images/cdd30/icons/disk.png'
import drag_handle from 'ASSETS/images/cdd30/layout/sort/drag.svg'
import lock from 'ASSETS/images/cdd30/icons/lock.png'
import pageWhitePut from 'ASSETS/images/cdd30/icons/page_white_put.png'
import pencil from 'ASSETS/images/cdd30/icons/pencil.png'
import spinner from 'ASSETS/images/spinner.gif'
import tick from 'ASSETS/images/cdd30/icons/tick.png'
import and_small from 'ASSETS/images/cdd30/booleans/and_small.gif'
import or_small from 'ASSETS/images/cdd30/booleans/or_small.gif'

const icons = {
  add,
  arrow_rotate_anticlockwise,
  attach,
  calendar,
  close,
  delete: deleteIcon,
  disabled_drag,
  disk,
  drag_handle,
  lock,
  pageWhitePut,
  pencil,
  spinner,
  tick,
  and_small,
  or_small,
}

export default icons
