import {
  EditUserSchema,
  ProjectPermissions,
  EditTeamSchema,
  EditProjectSchema,
  TeamSchema,
  TeamMembership,
  UserSchema,
  ProjectSchema,
} from './types';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { CDDElements } from '@/shared/components/CDDForm/cddElements';
import { runInAction } from 'mobx';
import { AccountsUtils } from './accountsUtils';

const {
  row,
  typography,
  checkbox,
} = layoutBuilder;

// ReadOnlyVault shows controls with edit/manage set to false and disabled, ViewOnly just disables edit/manage
type ReadOnlyMode = 'ReadOnlyVault' | 'ViewOnly' | 'ProjectMemberInTeam' | false;

export class AccountLayoutHelper {
  static projectRow(
    label: string,
    project: ProjectSchema,
    onDelete?: (project: ProjectSchema) => void,
  ) {
    return row({ key: '' + project.id, className: 'project-membership-row' }, [
      typography({
        label,
        className: 'permission-label',
        width: 'expand',
      }),
      CDDElements.deleteIconButton({
        key: `delete_${project.id}`,
        tooltip: 'Remove project',
        onClickButton: () => {
          runInAction(() => {
            if (onDelete) {
              onDelete(project);
            }
          });
        },
      }),
    ]);
  }

  static permissionsRow(
    label: string,
    permissionPath: string,
    perm: ProjectPermissions | TeamMembership,
    onDelete?: (perm: ProjectPermissions | TeamMembership) => void,
    readOnlyMode?: ReadOnlyMode,
    readOnlyRole?: string,
  ) {
    // compiled_from_teams will be true when editing a user, viewing their project membership, and seeing that they belong
    // to a project because they are on a team that's assigned to the project.
    const compiled_from_teams = 'compiled_from_teams' in perm && perm.compiled_from_teams;
    const hideCanEditAndManage = compiled_from_teams || readOnlyMode === 'ReadOnlyVault' || readOnlyMode === 'ViewOnly' || readOnlyRole;
    const projectMemberInTeam = readOnlyMode === 'ProjectMemberInTeam';

    const key = permissionPath.replace('.', '_'); // for testing

    return row({ key, className: 'user-membership-row' }, [
      typography({
        label,
        className: 'permission-label',
        width: 'expand',
      }),
      checkbox({
        key: `${permissionPath}.can_manage_project`,
        id: 'can_manage_project',
        label: 'Can manage',
        width: 'default',
        visible: !hideCanEditAndManage,
        disabled: projectMemberInTeam,
        translateGetValue: (value: boolean) => {
          return (compiled_from_teams || projectMemberInTeam) ? perm?.can_manage_project : value;
        },
      }),
      checkbox({
        key: `${permissionPath}.can_edit_data`,
        id: 'can_edit_data',
        label: 'Can edit',
        width: 'default',
        visible: !hideCanEditAndManage,
        disabled: projectMemberInTeam,
        translateGetValue: (value: boolean) => {
          return (compiled_from_teams || projectMemberInTeam) ? perm?.can_edit_data : value;
        },
      }),
      typography({
        label: readOnlyRole,
        visible: !!readOnlyRole,
        className: 'readonly-role-text',
      }),

      // show delete only if permission isn't inherited
      CDDElements.deleteIconButton({
        key: `delete_${permissionPath}`,
        visible: !projectMemberInTeam && !compiled_from_teams && readOnlyMode !== 'ViewOnly',
        onClickButton: () => {
          runInAction(() => {
            if (onDelete) {
              onDelete(perm);
            }
          });
        },
      }),
      CDDElements.groupIconButton({
        visible: !!compiled_from_teams,
        tooltip: 'Required due to team permissions',
      }),
      CDDElements.helpIconButton({
        visible: !!readOnlyRole && projectMemberInTeam,
        tooltip: `This user has ${readOnlyRole} access to the Vault so their team permissions (Can Edit, Can Manage) have been overridden`,
      }),
    ]);
  }

  static teamPermissionForUserRow(
    team: TeamSchema,
    user: EditUserSchema,
    onDelete?: (perm: ProjectPermissions | TeamMembership) => void,
    readOnlyMode?: ReadOnlyMode,
  ) {
    const editUser = user as EditUserSchema;
    const perm = editUser.team_memberships[team.id];

    const handleDelete = () => {
      if (onDelete) {
        onDelete(perm);
      } else {
        runInAction(() => {
          delete editUser.team_memberships[team.id];
        });
      }
    };
    return AccountLayoutHelper.permissionsRow(
      team.name,
      `team_memberships.${team.id}`,
      perm,
      handleDelete,
      readOnlyMode,
    );
  }

  static userPermissionForTeamRow(
    user: UserSchema,
    team: EditTeamSchema,
    onDelete?: (perm: ProjectPermissions | TeamMembership) => void,
    readOnlyMode?: ReadOnlyMode,
  ) {
    const editTeam = team as EditTeamSchema;
    const perm = editTeam.user_membership[user.id];

    const handleDelete = () => {
      if (onDelete) {
        onDelete(perm);
      } else {
        runInAction(() => {
          delete editTeam.user_membership[user.id];
        });
      }
    };
    return AccountLayoutHelper.permissionsRow(
      AccountsUtils.formatUserName(user),
      `user_membership.${user.id}`,
      perm,
      handleDelete,
      readOnlyMode,
    );
  }

  static userPermissionForProjectRow(
    value: EditProjectSchema,
    user: UserSchema,
    onDelete?: (perm: ProjectPermissions | TeamMembership) => void,
    readOnlyMode?: ReadOnlyMode,
    readOnlyRole?: string,
  ) {
    const perm = value.project_permissions[user.id];

    const handleDelete = () => {
      if (onDelete) {
        onDelete(perm);
      } else {
        runInAction(() => {
          delete value.project_permissions[user.id];
        });
      }
    };
    return AccountLayoutHelper.permissionsRow(
      AccountsUtils.formatUserName(user),
      `project_permissions.${user.id}`,
      perm,
      handleDelete,
      readOnlyMode,
      readOnlyRole,
    );
  }
}
