/* eslint-disable no-nested-ternary */
import React from 'react';
import { SavedTemplate, ParsingSection } from './parserUtil';
import icons from '@/shared/utils/icons';
import { A, Img } from '@/shared/components/sanitizedTags.js';
import { CDD } from '@/typedJS';
import axios from 'axios';
import { Alert, Fade, Popover } from '@mui/material';

export type Props = {
  savedTemplates: SavedTemplate[],
  selectedTemplateName: string;
  tabNames: string[];
  parsingSections: ParsingSection[];
  undoStack: ParsingSection[][];
  redoStack: ParsingSection[][];
  handleClear: () => void;
  handleUndo: () => void;
  handleRedo: () => void;
}

type State = {
  isOpen: boolean;
  templateName: string;
  errorMessage: string;
  shouldClobber: boolean;
  recentSaveCount: number;
};

export class TemplateSaver extends React.Component<Props, State> {
  private anchorEl: HTMLElement = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      templateName: props.selectedTemplateName ?? '',
      errorMessage: null,
      shouldClobber: false,
      recentSaveCount: 0,
    };
  }

  public render(): JSX.Element {
    const { savedTemplates } = this.props;
    const { parsingSections, undoStack, redoStack, handleClear, handleUndo, handleRedo } = this.props;
    const { isOpen, templateName, errorMessage, shouldClobber, recentSaveCount } = this.state;

    const alreadyExists = savedTemplates.some((template) => template.name == templateName);
    const isForbidden = savedTemplates.some((template) => template.name == templateName && template.can_edit === false);
    const canSave = templateName.length > 0 && !(alreadyExists && !shouldClobber && !isForbidden);

    return (
      <>
        <div className="PlateBlockImporter-footer-save" ref={(el) => this.anchorEl = el}>
          <A key="undo" href="#" className={undoStack.length == 0 ? 'disabled' : ''} onClick={undoStack.length > 0 ? handleUndo : null}>
            Undo
          </A>
          <A key="redo" href="#" className={redoStack.length == 0 ? 'disabled' : ''} onClick={redoStack.length > 0 ? handleRedo : null}>
            Redo
          </A>
          <A key="clear" href="#" className={parsingSections.length == 0 ? 'disabled' : ''} onClick={parsingSections.length > 0 ? handleClear : null}>
            Clear all
          </A>
          <A key="save" href="#" className={parsingSections.length == 0 ? 'disabled' : ''} onClick={parsingSections.length > 0 ? this.handleOpen : null}>
            <Img width="16" height="16" className="icon-16" alt="Disk" src={icons.disk}/>
            &nbsp;Save custom parser…
          </A>
        </div>
        <Popover
          open={isOpen}
          anchorEl={this.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          >
          <div className="PlateBlockImporter-popover-outline">
            <A className="popup-close close-popup" href="#" onClick={this.handleClose}>
              <span className="actionIcon">
                <Img alt="x" title="close" src={icons.close}/>
              </span>
            </A>

            <table className="simple form">
              <tbody>
                <tr>
                  <th>Save custom parser as:</th>
                  <td>
                    <input
                      className="input-text PlateBlockImporter-savename-edit"
                      type="text"
                      value={templateName}
                      onChange={this.handleChangeName}
                      autoFocus
                      />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ maxWidth: '21em' }}>
                    All assignments will be saved in the template so that they can be applied in the same way to future documents.
                    The saved parser can be used by anyone with access to the Vault.
                  </td>
                </tr>
                {errorMessage && (<tr id="MappingTemplateForm_error">
                  <th></th>
                  <td>
                    <p className="critical">{errorMessage}</p>
                  </td>
                </tr>)}
                {alreadyExists && !isForbidden && (
                  <tr>
                    <td>
                      <p className="critical">
                        Custom parser already exists.
                      </p>
                    </td>
                    <td>
                      <label>
                        <input className="input-checkbox" type="checkbox" checked={shouldClobber} onChange={this.handleShouldClobber}/>
                        Overwrite it.
                      </label>
                    </td>
                  </tr>
                )}
                {isForbidden && (
                  <tr>
                    <td colSpan={2}>
                      <p className="critical">
                        Custom parser already exists, and cannot be overwritten.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="buttons-right">
              <button
                className={`MappingTemplateForm-button buttony buttony-small ${!canSave ? 'disabled' : ''}`}
                disabled={!canSave}
                onClick={this.handleSave}
                >
                Save Custom Parser
              </button>
              <span className="orcancel orcancel-small">
                or&nbsp;
                <A className="cancel close-popup" href="#" onClick={this.handleClose}>cancel</A>
              </span>
            </div>
          </div>
        </Popover>

        {recentSaveCount > 0 && (
          <Fade in={true} timeout={1000}>
            <Alert
              className="PlateBlockImporter-alert"
              severity="success"
              >
              Saved parser successfully.
            </Alert>
          </Fade>
        )}
      </>
    );
  }

  private handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ templateName: event.currentTarget.value });
  };

  private handleShouldClobber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ shouldClobber: event.currentTarget.checked });
  };

  private handleOpen = () => {
    this.setState({ isOpen: true, errorMessage: null, shouldClobber: false });
  };

  private handleClose = () => {
    this.setState({ isOpen: false, errorMessage: null, shouldClobber: false });
  };

  private handleSave = () => {
    const { savedTemplates, tabNames, parsingSections } = this.props;
    const { templateName, shouldClobber } = this.state;

    const alreadyExists = savedTemplates.some((template) => template.name == templateName);
    const canSave = templateName.length > 0 && !(alreadyExists && !shouldClobber);
    if (!canSave) return;

    const url = `/${CDD.ActiveDataContext.toContextParam}/plate_block_templates.json`;
    const template: SavedTemplate = {
      id: null,
      name: templateName,
      template_json: { tabNames, parsingSections },
    };

    (async () => {
      const response = await axios.post(url, { template, overwrite: shouldClobber });
      if (response.status == 200) {
        this.setState({ isOpen: false, errorMessage: null, shouldClobber: false, recentSaveCount: this.state.recentSaveCount + 1 });
        setTimeout(() => this.setState({ recentSaveCount: this.state.recentSaveCount - 1 }), 10000);
      } else {
        this.setState({ isOpen: false, errorMessage: 'Save failed.', shouldClobber: false });
      }
    })();
  };
}
