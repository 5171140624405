import { connect } from 'react-redux'

import EntriesExportLinks from './component.jsx'

function mapStateToProps(state) {
  const { status, constants } = state
  const { trueCount } = status
  const { elnEntriesExportUrl, elnSummaryExportUrl, elnEntriesExportProgressUrl } = constants
  const hasEntries = trueCount > 0
  const canExport = !!elnEntriesExportUrl

  return {
    hasEntries,
    canExport,
    elnEntriesExportUrl,
    elnSummaryExportUrl,
    elnEntriesExportProgressUrl,
  }
}

const mapDispatchToProps = {}

const EntriesExportLinksContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntriesExportLinks)

export default EntriesExportLinksContainer
