import { FilterTextValue } from './FilterTextValue';

export class FilterNumberValue extends FilterTextValue {
  inputProps = {
    type: 'number',
    inputProps: {
      step: 'any',
    },
  };
}
