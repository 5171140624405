import React from 'react';
import { AxiosError } from 'axios';
import { subscribeToUnhandledErrors, unsubscribeToUnhandledErrors } from '@/ErrorReporting/subscribe';
import { displayUnhandledErrorDialog } from './UnhandledErrorDialog';

/**
 * CatchUnhandledErrors is a non-visual React component that can be inserted anywhere you want errors
 * (from failing APIs and JS errors) to be handled.
 *
 * It allows a filter function to determine if errors should be handled or ignored, a list of urls to
 * ignore, and an optional function to be called when the error is to be handled.
 *
 * The default action is to display the UnhandledErrorDialog.
 *
 * The error handling kicks in when this component is mounted, and is removed when unmounted. Multiple
 * instances can exist simultaneously, with the last instances called first.
 */

type Props = {
  ignoreUrlErrors?: Array<string | RegExp>;
  testShouldDisplayError?: (error: ErrorEvent | AxiosError) => boolean;
  actOnUnhandledError?: (error: ErrorEvent | AxiosError) => void;
};

export class CatchUnhandledErrors extends React.Component<Props> {
  handleErrors = (error: ErrorEvent | AxiosError) => {
    try {
      // Test to see if we should ignore this error.

      if (this.props.testShouldDisplayError && this.props.testShouldDisplayError(error) === false) {
        // error was suppressed, so do nothing
        return;
      }

      const ignoreUrlErrors = this.props.ignoreUrlErrors;
      if ('config' in error && error.config.url && ignoreUrlErrors) {
        const url = error.config.url.toLowerCase();
        for (let i = 0; i < ignoreUrlErrors.length; i++) {
          const toIgnore = ignoreUrlErrors[i];
          if (typeof toIgnore === 'string') {
            if (url.includes(toIgnore.toLowerCase())) {
              return;
            }
          } else {
            if (toIgnore.test(url)) {
              return;
            }
          }
        }
      }

      if (this.props.actOnUnhandledError) {
        this.props.actOnUnhandledError(error);
      } else {
        displayUnhandledErrorDialog(error);
      }
      return true; // was handled, so stop processing
    } catch (e) {
      console.error('error during error handling');
      console.error(e);
    }
  };

  showErrorDialog = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  componentDidMount(): void {
    subscribeToUnhandledErrors(this.handleErrors);
  }

  componentWillUnmount(): void {
    unsubscribeToUnhandledErrors(this.handleErrors);
  }

  render() {
    return null; // this isn't a visual component
  }
}
