import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { CircularProgress, Table, TableCell, TableHead, TableRow } from '@mui/material'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import Row from './Row.jsx'
import ResultColumnsEditor from './ResultColumnsEditor'
import ResultHeader from './ResultHeader.jsx'

export default class EntryList extends React.Component {
  handleFieldClick = ({ fieldDefinition, currentlySelected }) => {
    if (currentlySelected) {
      this.props.removeResultColumn({ fieldDefinition })
    } else {
      this.props.addResultColumn({ fieldDefinition })
    }
  }

  handleReplaceColumns = (values) => {
    const { elnFieldDefinitions } = this.props
    const defs = elnFieldDefinitions.bySelectValue.toArray()
    const lookup = defs.reduce((acc, current) => {
      acc[current.selectValue] = current
      return acc
    }, {})

    this.props.replaceResultColumns({ fieldDefinitions: values.map(key => lookup[key]) })
  }

  handleHeaderClick = ({ selectValue }) => {
    this.props.updateSortColumn({ selectValue })
  }

  renderTableHeader = () => {
    const { elnFieldDefinitions, resultColumns } = this.props

    return (
      <TableHead className='search_results__header'>
        <TableRow className='search_results__row'>
          {
            resultColumns
              .filter(rc => rc.selectValue != 'Structure')
              .map((column) => (
                <ResultHeader
                  key={column.selectValue}
                  direction={column.direction}
                  fieldDefinition={elnFieldDefinitions.bySelectValue.get(column.selectValue + '')}
                  handleHeaderClick={this.handleHeaderClick}
                />
              ))
          }
          <TableCell className='search_results__item search_results__item__edit'>
          <ResultColumnsEditor
              elnFieldDefinitions={elnFieldDefinitions}
              selectedValues={resultColumns.map((column) => (column.selectValue + ''))}
              handleFieldClick={this.handleFieldClick}
              handleReplaceColumns={this.handleReplaceColumns}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  render() {
    const { elnEntries, getNextPage, status, elnEntryUrl, results, resultColumns } = this.props

    if (elnEntries.all.size == 0) {
      return (
        <div id="elnEntries-show" className='noDataMessage'>
          {`No ${Terminology.dictionary['entry.other']} found.`}
        </div>
      )
    }

    const filteredResultCols = resultColumns.filter(rc => rc.selectValue != 'Structure')
    const displayStructureRow = resultColumns.filter(rc => rc.selectValue == 'Structure').size > 0

    return (
      <InfiniteScroll
        element='div'
        pageStart={0}
        loadMore={getNextPage}
        hasMore={elnEntries.all.size !== status.trueCount}
        loader={
          <CircularProgress key={0}
            className="circular_progress infinite_scroll__loader"
          />
        }
      >
        <div className='search_results__container'>
          <Table className='search_results__table eln_search_results__table'>
            {this.renderTableHeader()}
            {elnEntries.all.map(id => {
              const entry = elnEntries.byId.get(id)

              return <Row
                key={entry.id}
                elnEntry={entry}
                elnEntryUrl={elnEntryUrl}
                result={results.byId.get(id.toString())}
                resultColumns={filteredResultCols}
                displayStructureRow={displayStructureRow}
              />
            })}
          </Table>
        </div>
      </InfiniteScroll>
    )
  }
}
