/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { CheckboxDef, ElementDef, FieldValueType } from '@/shared/components/DDForm/types';
import { Checkbox, FormControlLabel } from '@mui/material';
import { DDFormUtils } from '../../DDFormUtils';
import { getElementAttributes, getInnerElementAttributes, getOuterElementAttributes, getStyleElementAttributes } from '../muiRenderers';

export const ddMuiRenderCheckbox: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: boolean) => void,
) => {
  const field = element as CheckboxDef;
  const untypedValue = getValue(field);
  const value = DDFormUtils.convertValueToBoolean(untypedValue);

  if (field.valueType === undefined || field.valueType === 'auto' || field.valueType === 'boolean') {
    // force this value to be a boolean if it's not one already.
    if (typeof untypedValue !== 'boolean') {
      setValue(field, value);
    }
  }

  const handleChange = (): void => {
    setValue(field, !value);
  };

  return (
    <FormControlLabel
      {...getOuterElementAttributes(field, props)}
      {...getStyleElementAttributes(field, props)}
      key={field.renderKey}
      disabled={field.disabled}
      control={
        <Checkbox
          {...getInnerElementAttributes(field, props)}
          checked={value}
          onChange={handleChange}
          color='default'
        />
      }
      label={<span className='checkbox-label'>{field.label}</span>}
    />
  );
};
