import React from 'react'

import QueryTextField from './QueryTextField.tsx'

import constants from 'javascripts/constants.js'
const {
  TEXT_QUERY_STYLE_LABELS,
  QUERY_STYLES_EXTRA_LABELS,
  DOUBLE_QUERY_STYLES,
} = constants

export default class TextQueryForm extends React.PureComponent {
  isMultiline = true
  queryStyleLabels = TEXT_QUERY_STYLE_LABELS
  TextField = QueryTextField

  getHelperText(query) {
    if (!query && !this.isDoubleQuery() && this.isMultiline) {
      return 'Type or paste a list, Shift+Enter to insert a new line'
    }
  }

  getErrorMessage(query) {
    if (query.includes('\n') && (this.isDoubleQuery() || !this.isMultiline)) {
      return ['Must be a single value']
    }

    return []
  }

  isDoubleQuery = () => {
    const { query_style } = this.props

    return DOUBLE_QUERY_STYLES.includes(query_style)
  }

  onQueryStyleChange = (event) => {
    const { criterionIndex, onQueryStyleChange } = this.props
    const { value } = event.target
    return onQueryStyleChange(criterionIndex, value)
  }

  renderRange(extraQueryClassNames) {
    const { second_query, query_style, onSecondQueryChange } = this.props

    return (
      <React.Fragment>
        <div className="item item__inline_label">
          {QUERY_STYLES_EXTRA_LABELS.get(query_style).first()}
        </div>
        {this.renderQuery(second_query, onSecondQueryChange, extraQueryClassNames)}
      </React.Fragment>
    )
  }

  renderQuery(query, onChange, className = '', textFieldClassName = '', endAdornment = <React.Fragment />) {
    const { id, criterionIndex, onSubmit } = this.props

    const props = {
      id,
      query,
      className,
      textFieldClassName,
      endAdornment,
      helperMessage: this.getHelperText(query),
      errorMessage: this.getErrorMessage(query),
      isMultiline: this.isMultiline && !this.isDoubleQuery(),
      onChange,
      onSubmit,
      criterionIndex,
    }

    return (
      <this.TextField {...props} />
    )
  }

  render() {
    const { query, query_style, onQueryChange } = this.props
    let rangeIfNeeded = <React.Fragment />
    let extraQueryClassNames = ''

    if (this.isDoubleQuery()) {
      extraQueryClassNames = 'item__inline_input'
      rangeIfNeeded = this.renderRange(extraQueryClassNames)
    }

    return (
      <React.Fragment>
        <div className="clear" />
        <div className="item">
          <select className='molecule_criteria__select molecule_criteria__query_style' onChange={this.onQueryStyleChange} value={query_style}>
            {this.queryStyleLabels.map((label, value) => {
              return <option key={value} value={value}>{label}</option>
            }).toArray()}
          </select>
        </div>
        {this.renderQuery(query, onQueryChange, extraQueryClassNames)}
        {rangeIfNeeded}
        <div className="clear" />
      </React.Fragment>
    )
  }
}
