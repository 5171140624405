import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import StatusFilter from './component.jsx'
import { submitQuery, updateQuery } from '@/Eln/Entries/actions'

function mapStateToProps(state) {
  const { query } = state
  const { mrv } = query

  return {
    mrv,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    submitQuery,
    updateQuery,
  }, dispatch)
}

const StatusFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusFilter)

export default StatusFilterContainer
