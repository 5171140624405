import { Divider, Slider, Stack } from '@mui/material';
import React from 'react';

export const CountSelection = (props: { count: number, onChange: (count: number) => void, values: number[], label: string }) => {
  const { count, onChange, label } = props;
  const values = props.values.sort((n1, n2) => n1 - n2);
  const marks = values.map((value) => ({ value, label: `${value}` }));
  return (
    <Stack direction='column' alignItems='left' sx={{ pt: 0, pb: '8px' }}>
      <span>{label}</span>
      <Divider orientation="horizontal" sx={{ pt: 0, pb: 0 }} />
      <Stack direction='row' justifyContent='flex-end'>
        <Slider
          aria-label="Count"
          value={count}
          min={values[0]} max={values[values.length - 1]}
          step={values[1] - values[0]}
          getAriaValueText={(value: number) => `${value}`}
          valueLabelDisplay="auto"
          marks={marks}
          onChange={(_, value) => onChange(value as number)}
          sx={{ 'mb': '2em', 'mr': '1em', 'width': '85%' }}
        />
      </Stack>
    </Stack>
  );
};
