import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OntologyTemplate } from './templates';

export interface TemplateServicesCheck {
  valid: boolean;
  reason?: string;
}

class http {
  static get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return axios.get<T, R>('/api/internal/v1' + url, config);
  }

  static put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.put<T, R>('/api/internal/v1' + url, data, config);
  }

  static post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.post<T, R>('/api/internal/v1' + url, data, config);
  }

  static patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.patch<T, R>('/api/internal/v1' + url, data, config);
  }

  static delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return axios.delete<T, R>('/api/internal/v1' + url, config);
  }
}

export class TemplateServices {
  constructor(public vaultURLBase: string, public accountID: number) {
  }

  public async getTemplateList(): Promise<string[]> {
    return (await http.get<string[]>(`/accounts/${this.accountID}/ontology_templates`)).data;
  }

  public async checkTemplateDuplicate(schemaPrefix: string) : Promise<TemplateServicesCheck> {
    return (await http.get<TemplateServicesCheck>(`/accounts/${this.accountID}/ontology_templates/check_schema_prefix`, { params: { schemaPrefix } })).data;
  }

  public async submitTemplate(template: OntologyTemplate): Promise<TemplateServicesCheck> {
    const result = (await http.post<unknown>(`/accounts/${this.accountID}/ontology_templates`, { template }));
    if (result.status >= 200 && result.status < 300) {
      return { valid: true };
    } else {
      return { valid: false, reason: `${result.status}: ${JSON.stringify(result.data)}` };
    }
  }

  public async deleteTemplate(schemaPrefix: string): Promise<void> {
    return (await http.delete<void>(`/accounts/${this.accountID}/ontology_templates/0`, { params: { schemaPrefix } })).data;
  }

  public async makeTemplatePublic(schemaPrefix: string): Promise<void> {
    return (await http.put<void>(`/accounts/${this.accountID}/ontology_templates/make_template_public`, { schemaPrefix })).data;
  }
}
