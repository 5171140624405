import { Record } from 'immutable'

const DEFAULTS = {
  elnEntryUrl: '',
  elnEntriesExportUrl: '',
  elnSummaryExportUrl: '',
  elnEntriesExportProgressUrl: '',
  elnResultColumnUrl: '',
}

export class Constants extends Record(DEFAULTS) { }

export const constants = (state = new Constants()) => {
  return state
}
