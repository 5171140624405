import FilterItem from './component.jsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateQueryFilter, removeQueryFilter } from '@/Eln/Entries/actions'

function mapStateToProps(state, props) {
  const { query, elnFieldDefinitions } = state
  const { index } = props
  const { query_filters } = query
  const filter = query_filters.get(index)
  const selectedFieldValues = query_filters.map((filter) => (filter.field_select_value))

  return {
    elnFieldDefinitions,
    selectedFieldValues,
    filter,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateQueryFilter,
    removeQueryFilter,
  }, dispatch)
}

export const FilterItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterItem)

export FilterItem from './component.jsx'
export FilterFieldSelect from './FilterFieldSelect.jsx'
export FilterStyleSelect from './FilterStyleSelect.jsx'
