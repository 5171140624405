import React from 'react';
import {
  Box,
  Stack,
  Checkbox,
  Link,
  Divider,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { molecule_properties } from './calculator';

type PropertySelectionProps = {
  selected: string[];
  onChange: (selection: string[]) => void;
};

const PropertySelection = (props: PropertySelectionProps) => {
  const { selected, onChange } = props;

  const selectAll = () => {
    onChange(molecule_properties.map((property) => property.key));
  };
  const selectNone = () => {
    onChange([]);
  };
  const content = (
    <FormGroup>
      {molecule_properties.map((property, index) => {
        const chkbox = <Checkbox
          sx={{ pt: '0.1em', pb: '0.1em' }}
          checked={selected.includes(property.key)}
          onChange={(e) => {
            if (e.target.checked) {
              onChange([...selected, property.key]);
            } else {
              onChange(selected.filter((item) => item !== property.key));
            }
          }} />;
        return <FormControlLabel key={index} control={chkbox} label={property.label} />;
      })}
    </FormGroup>
  );

  return (
    <Stack direction='column' data-testid='property-selection-list'>
      <Stack direction='row' justifyContent='space-between'>
        <Box>Select properties</Box>
        <Box><Link onClick={selectAll}>All</Link> / <Link onClick={selectNone}>None</Link></Box>
      </Stack>
      <Divider orientation="horizontal" sx={{ pt: 0, pb: 0 }} />
      {content}
    </Stack>
  );
};

export {
  PropertySelection,
};
