import React from 'react'
import { createRoot } from 'react-dom/client'

import MoleculeKeywordSearch from './component.jsx'

export default function MoleculeKeywordSearchRenderer(props) {
  const root = createRoot(document.getElementById('moleculeKeywordSearch'))
  return root.render(
    <MoleculeKeywordSearch {...props} />
  )
}
