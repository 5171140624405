import React, { useState } from 'react';
import { Link } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';
import { term } from '@/shared/utils/stringUtils';
import { SimilarityResultsDialog } from './SimilarityResultsDialog';
import { DLSimilarityResponse, DLError } from './SimilarityResultsTypes';
import chemblIcon from 'ASSETS/images/cdd30/icons/chembl.svg';
import { processSimilarityResults } from './SimilarityResultsUtilities';
import { allCollections } from './Collections';

const fetchDLSimilarity = async (
  smiles: string,
  count: number,
): Promise<DLSimilarityResponse | DLError> => {
  // remove CXSMILES information if present
  const structure = smiles.trim().split(/(\s+)/)[0];
  try {
    const payload = {
      structure,
      count,
      return_patents: true,
      collections: allCollections,
    };
    const response = await fetch('/models/deep_learning_similarity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const json = await response.json();
    return processSimilarityResults(json);
  } catch (e) {
    return { status: 'ERROR', status_code: 400, message: e };
  }
};

type LaunchSimilarityResultsDialogProps = {
  name: string;
  smiles: string;
  ban_external_links: boolean;
  skip_external_link_warning: boolean;
  fetchDLSimilarity?: (
    smiles: string,
    count: number,
  ) => Promise<DLSimilarityResponse | DLError>;
};

const LaunchSimilarityResultsDialog = (
  props: LaunchSimilarityResultsDialogProps,
) => {
  const [open, setOpen] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    setOpen(true);
    e.preventDefault();
  };

  const onClose = () => setOpen(false);

  return (
    <>
      <Link href={'#'} onClick={onClick} style={{ textDecoration: 'none' }}>
        <Img
          width={16}
          height={16}
          className='icon-16'
          alt='explore'
          src={chemblIcon}
        />
        Find ChEMBL, patented, and commercial {term('molecule.other', true).toLowerCase()}{' '}
        using deep learning similarity
      </Link>
      <SimilarityResultsDialog
        open={open}
        fetchDLSimilarity={props.fetchDLSimilarity || fetchDLSimilarity}
        name={props.name}
        smiles={props.smiles}
        banExternalLinks={props.ban_external_links}
        skipExternalLinkWarning={props.skip_external_link_warning}
        onClose={onClose}
      />
    </>
  );
};

export { LaunchSimilarityResultsDialog };
