/*
  SearchCache: supporting functionality for search-by-text, within some number of template branches.
*/

import { TemplateAssignment } from './templates';
import { OntologyTree, Branch } from './OntologyTree';
import { Schema, SchemaBranch } from './Schema';

export interface SearchCacheRoot {
  assn: TemplateAssignment,
  groupNest: string[],
  branch: SchemaBranch,
}

export interface SearchCacheHit extends SearchCacheRoot {
  sequence: string[];
}

export class SearchCache {
  private watermark = 0;
  private lastText = '';

  constructor(public schema: Schema, public roots: SearchCacheRoot[]) {
  }

  // ensure that all of the labels in the branch are loaded, and then do the search: could be fast or slow; the callback will be called
  // only if the search completes without being superceded; the "hits" are all of the items that matched the search criteria, whereas the
  // "hitUnion" includes the URIs of everything that hit + their ancestors
  public performSearch(text: string, callback: (hits: SearchCacheHit[], hitUnion: Set<string>) => void): void {
    if (text == this.lastText) return;
    const watermark = ++this.watermark;
    this.lastText = text;

    (async () => {
      for (const root of this.roots) {
        if (watermark != this.watermark) return;
        await this.ensureAllLabels(root.branch);
      }
      if (watermark != this.watermark) return;

      const hits: SearchCacheHit[] = [];
      const hitUnion = new Set<string>();
      for (const root of this.roots) {
        this.searchBranch(text.toLowerCase(), root.assn, root.groupNest, root.branch, hits, hitUnion);
      }
      callback(hits, hitUnion);
    })();
  }

  private async ensureAllLabels(branch: Branch) {
    const roster: Branch[] = [];
    const scan = (branch: Branch): void => {
      if (!branch.label) roster.push(branch);
      for (const child of branch.children) scan(child);
    };
    scan(branch);

    for (const branch of roster) {
      branch.label = OntologyTree.values.cachedLabel(branch.uri);
      if (!branch.label) branch.label = (await OntologyTree.values.getBranch(branch.uri, true, false)).label;
    }
  }

  private searchBranch(textLowCase: string, assn: TemplateAssignment, groupNest: string[], branch: SchemaBranch, hits: SearchCacheHit[], hitUnion: Set<string>): void {
    if (!(branch.inSchema === false) && branch.label.toLowerCase().includes(textLowCase)) {
      const sequence = [branch.uri];
      hitUnion.add(branch.uri);
      for (let look = branch.parent; look; look = look.parent) {
        sequence.unshift(look.uri);
        hitUnion.add(look.uri);
      }
      hits.push({
        assn,
        groupNest,
        branch,
        sequence,
      });
    }

    for (const child of branch.children) this.searchBranch(textLowCase, assn, groupNest, child as SchemaBranch, hits, hitUnion);
  }
}
