/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { DateInputDef, ElementDef, FieldValueType } from '@/shared/components/DDForm/types';
import { FormControl, TextField } from '@mui/material';
import { getElementAttributes } from '../muiRenderers';

/**
 * Render a field as a text input
 * @param field
 * @returns
 */
export const ddMuiRenderDate: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const field = element as DateInputDef;
  const error = props.showErrors && formErrors && formErrors[field.key];
  const format = field.format ?? 'YYYY-MM-DD';

  const handleChange = (date: { format?: (string) => string } | null) => {
    setValue(field, date?.format(format) ?? '');
  };

  const value = getValue(field);

  return (
    <FormControl {...field?.formOptions} key={field.renderKey}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...getElementAttributes(field, props)}
          label={field.label}
          inputFormat={format}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField
            {...params}
            error={!!error}
            helperText={error}
          />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
