/* eslint-disable no-nested-ternary, multiline-ternary */
import React from 'react';
import { TabbedCells, SavedTemplate, SavedTemplateMatch } from './parserUtil';
import icons from '@/shared/utils/icons';
import { A, Img } from '@/shared/components/sanitizedTags.js';
import { Popover, Tooltip, Typography } from '@mui/material';

export type Props = {
  matchedTemplates: SavedTemplateMatch[],
  selectedTemplateName: string;
  tabNames: string[];
  sheets: TabbedCells[];
  isUploading: boolean;
  handleApplyMatchedTemplate: (template: SavedTemplate) => void;
  handleDeleteTemplate: (template: SavedTemplate) => void;
}

type State = {
  isOpen: boolean;
};

export class TemplateLoader extends React.Component<Props, State> {
  private anchorEl: HTMLElement = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public render(): JSX.Element {
    const { selectedTemplateName, isUploading } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        {isOpen && this.renderList()}

        {!isUploading && (
          <div className="PlateBlockImporter-header-load" ref={(el) => this.anchorEl = el}>
            {selectedTemplateName ? (
              <>
                Applied saved parser <i>{selectedTemplateName}</i>
                &nbsp;
                (<A href="#" onClick={this.handleOpen}>change</A>
                ,&nbsp;
                <A href="#" onClick={() => this.handleApply(null)}>un-apply</A>)
              </>
            ) : (
              <>
                Click to apply a <A href="#" onClick={this.handleOpen}>previously saved parser</A>.
              </>
            )}
          </div>
        )}
      </>
    );
  }

  private renderList(): JSX.Element {
    const { matchedTemplates, selectedTemplateName } = this.props;

    const tooltipActive = (
      <Typography>
        Click to apply this parser to the current import file.
      </Typography>
    );

    const tooltipInactive = (
      <Typography>
        This parser does not match the current file.
      </Typography>
    );

    const prepareTemplate = (template: SavedTemplateMatch): JSX.Element => {
      const payload = template.score > 0 ? (
        <div key={template.name}>
          <Tooltip
            key="tip-fixedvalue"
            title={tooltipActive}
            arrow
            placement="right"
            PopperProps={{ style: { zIndex: 20000 } }}
            >
            <A href="#" onClick={() => this.handleApply(template)}>
              {template.name}
            </A>
          </Tooltip>
          {template.name == selectedTemplateName && ' (current selection)'}
        </div>
      ) : (
        <div key={template.name} className="PlateBlockImporter-unmatched">
          <Tooltip
            key="tip-fixedvalue"
            title={tooltipInactive}
            arrow
            placement="right"
            PopperProps={{ style: { zIndex: 20000 } }}
            >
            <span>{template.name}</span>
          </Tooltip>
        </div>
      );

      const hasWritePermission = template.can_edit !== false;

      return (
        <tr key={`apply-${template.name}`}>
          <td key="label" className="PlateBlockImporter-td-padleft">{payload}</td>
          <td key="value" className="PlateBlockImporter-td-padleft">
            {hasWritePermission && <A className="cancel" href="#" onClick={() => this.props.handleDeleteTemplate(template)}>
              <Img alt="Delete" title="Delete" className="icon-16" width="16" height="16" src={icons.delete}/>
              &nbsp;Delete
            </A>}
          </td>
        </tr>
      );
    };

    return (
      <>
        <Popover
          open={true}
          anchorEl={this.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          >
          <div className="PlateBlockImporter-popover-outline">
            <A className="popup-close close-popup" href="#" onClick={this.handleClose}>
              <span className="actionIcon">
                <Img alt="x" title="close" src={icons.close}/>
              </span>
            </A>

            <div key="title" className="PlateBlockImporter-dialogtitle">Previously saved custom parsers:</div>

            <table>
              <tbody>
                {matchedTemplates.map((template) => prepareTemplate(template))}
              </tbody>
            </table>
          </div>
        </Popover>
      </>
    );
  }

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleClose = () => {
    this.setState({ isOpen: false });
  };

  private handleApply(template: SavedTemplateMatch): void {
    this.setState({ isOpen: false });
    this.props.handleApplyMatchedTemplate(template);
  }
}
