export const UPDATE_QUERY = 'eln/entries/query/update'
export const SUBMIT_QUERY = 'eln/entries/query/submit'
export const ADD_QUERY_FILTER = 'eln/entries/query/filters/add'
export const UPDATE_QUERY_FILTER = 'eln/entries/query/filters/update'
export const REMOVE_QUERY_FILTER = 'eln/entries/query/filters/remove'

export function updateQuery(query) {
  return {
    type: UPDATE_QUERY,
    query,
  }
}

export function submitQuery() {
  return { type: SUBMIT_QUERY }
}

export function addQueryFilter() {
  return {
    type: ADD_QUERY_FILTER,
  }
}

export function updateQueryFilter({ index, filter }) {
  return {
    type: UPDATE_QUERY_FILTER,
    index,
    filter,
  }
}

export function removeQueryFilter(index) {
  return {
    type: REMOVE_QUERY_FILTER,
    index,
  }
}
