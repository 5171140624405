import React from 'react';
import {
  Tab,
  Tabs,
} from '@mui/material';
import autobind from 'class-autobind';
import { observer } from 'mobx-react';
import { getRootStore } from '@/stores/rootStore';
import { UsersPage } from './Users/UsersPage';
import { ProjectsPage } from './Projects/ProjectsPage';
import { CustomNucleotidesPage } from './CustomNucleotides/CustomNucleotidesPage';
import { CustomAminoAcidsPage } from './CustomAminoAcids/CustomAminoAcidsPage';
import { TeamsPage } from './Teams/TeamsPage';
import { CatchUnhandledErrors } from '@/ErrorReporting/CatchUnhandledErrors';

@observer
export default class AccountsView extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  get store() {
    return getRootStore().accountsStore;
  }

  get routerStore() {
    return getRootStore().routerStore;
  }

  get pages() {
    return [
      {
        title: 'Projects',
        routes: ['#projects', '#', ''],
        component: ProjectsPage,
      },
      {
        title: 'Teams',
        routes: ['#teams'],
        component: TeamsPage,
      },
      {
        title: 'Users',
        routes: ['#users'],
        component: UsersPage,
      },
      {
        title: 'Custom Nucleotides',
        routes: ['#customNucleotides'],
        component: CustomNucleotidesPage,
      },
      {
        title: 'Custom Amino Acids',
        routes: ['#customAminoAcids'],
        component: CustomAminoAcidsPage,
      },
    ];
  }

  // Should this be handled by the routerStore?

  get hash() {
    let { hash } = this.routerStore.url;
    const { pages } = this;

    if (!hash || !pages.some(page => page.routes.includes(hash))) {
      hash = pages[0].routes[0];
    }

    return hash;
  }

  handleChangeTab(event: React.ChangeEvent, val: string) {
    this.routerStore.goHash(val);
  }

  render() {
    const { hash, pages } = this;

    return (
      <div className='AccountsView'>
        <Tabs
          value={hash}
          onChange={this.handleChangeTab}
          aria-label='Accounts'
        >
          {pages.map((page) => (
            <Tab
              key={page.routes[0]}
              label={page.title}
              value={page.routes[0]}
            ></Tab>
          ))}
        </Tabs>

        {pages.filter(page => page.routes.includes(hash))
          .map(page => <page.component key={page.routes[0]}/>)}

        <CatchUnhandledErrors
          ignoreUrlErrors={[
            'marvinjs_webservices',
            /\/vaults\/(?:...)+\/molecules\//,
          ]}
        />
      </div>
    );
  }
}
