import React from 'react';
import { DDForm, DDFormProps } from '../DDForm';
import { DDModalForm, DDModalFormProps } from '../DDModalForm';
import { muiRenderElement } from './muiRenderers';

export type MuiDDFormProps = DDFormProps;
export type MuiDDModalFormProps = DDModalFormProps;

export const MuiDDForm: React.FC<MuiDDFormProps> = (props: MuiDDFormProps) => {
  return <DDForm
    {...props}
    renderElement={muiRenderElement}
  />;
};

export const MuiDDModalForm: React.FC<MuiDDFormProps> = (props: MuiDDModalFormProps) => {
  return <DDModalForm
    {...props}
    renderElement={muiRenderElement}
  />;
};
