import { Terminology } from 'javascripts/cross_app_utilities.js'

export default class DataType {
  // DataType.displayNameForDataTypeName(name) Converts BatchLink to Lot Link
  static displayNameForDataTypeName(name) {
    if (name == 'BatchLink') {
      name = Terminology.dictionary.batch.titleize() + 'Link'
    }
    name = _.startCase(name)

    return name
  }
}
